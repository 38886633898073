import { gql } from "@apollo/client";

export const UPLOAD_STAFF_DOCUMENT = gql`
  mutation UploadStaffDocument($input: DocumentInput) {
    uploadStaffDocument(input: $input) {
      success
      message
    }
  }
`;

export const GET_DOCUMENT_WITH_PAGINATION = gql`
  query GetDocumentPagination(
    $page: Int
    $limit: Int
    $keyword: String
    $pagination: Boolean
    $staffId: ID
  ) {
    getDocumentPagination(
      page: $page
      limit: $limit
      keyword: $keyword
      pagination: $pagination
      staffId: $staffId
    ) {
      data {
        _id
        documentName
        documentURL
        createdAt
        updatedAt
      }
      paginator {
        slNo
        prev
        next
        perPage
        totalPosts
        totalPages
        currentPage
        hasPrevPage
        hasNextPage
        totalDocs
      }
    }
  }
`;

export const RENAME_DOCUMENT = gql`
  mutation RenameDocument($id: ID, $documentName: String) {
    renameDocument(_id: $id, documentName: $documentName) {
      success
      message
    }
  }
`;

export const DELETE_DOCUMENT = gql`
  mutation DeleteDocument($id: ID) {
    deleteDocument(_id: $id) {
      success
      message
    }
  }
`;
