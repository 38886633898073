
export default function TextAreaCustomStyle(){
  return  `
    width: 100%;
    font-family: "Century Gothic";
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.5;
    padding: 8px 12px;
    border-radius: 5px;
    color: '#1C2025';
    background: '#ffff';
    border: 1px solid '#B0B8C4';
    box-shadow: 0px 2px 2px '#F3F6F9';
  
    &:focus {
      border-color: ${'#3399FF'};
      box-shadow: 0 0 0 1px ${'#3399FF'};
    }
  
    // firefox
    &:focus-visible {
      outline: 0;
    }
  `
}