import {
  Avatar,
  Box,
  List,
  ListItemText,
  useTheme,
  ListItem,
  Link,
  Divider,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import "../dashboard.scss";
import { useQuery } from "@apollo/client";
import { GET_LAST_OVERTIME } from "../../../Schema/Dashboard";
import moment from "moment";

export default function LastOvertime() {
  const [lastOvertime, setlastOvertime] = useState([]);

  //get userLoging from local
  const userLogin = JSON.parse(window.localStorage.getItem("userLogin"));

  const theme = useTheme();

  const { data, refetch } = useQuery(GET_LAST_OVERTIME, {
    variables: {
      userId: userLogin?._id,
    },
    onCompleted: ({ lastOvertime }) => {
      setlastOvertime(lastOvertime);
    },
  });
  // console.log("lastOvertime", lastOvertime);

  //For Request Take Leave
  const [requestIndex, setRequestIndex] = React.useState(0);
  const handleRequestListItemClick = (event, index) => {
    setRequestIndex(index);
  };

  return (
    <Box
      className="box-container"
      sx={{
        padding: "20px",
        opacity: "93%",
      }}
    >
      <Box className="card-title-center">Last Overtime</Box>
      <List component="nav" aria-label="main mailbox folders">
        {lastOvertime?.map((element, index) => {
          return (
            <div key={index}>
              <ListItem
              //sx={{ height:"100%"}}
              //selected={requestIndex === index}
              // onClick={(event) => handleRequestListItemClick(event, index)}
              >
                <Avatar
                  alt={
                    element?.createdBy?.staffProfile?.englishData?.lastNameLatin
                  }
                  src={element?.createdBy?.staffProfile?.imageSrc}
                  sx={{ width: 54, height: 54}}
                  // sx={{ backgroundColor: theme.palette.fourth.main }}
                ></Avatar>
                <ListItemText
                  primary={
                    userLogin?._id === element?.createdBy?._id
                      ? "Your requested"
                      : element?.createdBy?.staffProfile?.englishData
                          ?.firstNameLatin +
                        " " +
                        element?.createdBy?.staffProfile?.englishData
                          ?.lastNameLatin
                  }
                  // secondary={`${element?.status}-${moment(element?.createdAt).format("Do MMMM, YYYY")}`}
                  secondary={
                    <>
                      <Typography
                        sx={{ display: "inline" }}
                        // component="span"
                        variant="body2"
                        color={
                          element?.status === "Approved"
                            ? "#128A43"
                            : element?.status === "Denied"
                            ? "#F26469"
                            : "orange"
                        }
                      >
                        {element?.status}
                      </Typography>
                      {` — ${element?.totalOvertimeAsHours} hours OT from 
                        ${moment(element?.startDate).format("Do MMMM, hh:mm a")}
                        to ${moment(element?.endDate).format(
                          "Do MMMM, hh:mm a"
                        )}`}
                    </>
                  }
                  sx={{ marginLeft: "15px" }}
                />
              </ListItem>
              <Divider variant="inset" component="li" />
            </div>
          );
        })}
      </List>
      <Link
        href="/over-time"
        color="inherit"
        style={{ display: "flex", justifyContent: "center", opacity: "70%" }}
        underline="none"
      >
        {"see more"}
      </Link>
    </Box>
  );
}
