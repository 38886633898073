import React, { useContext, useEffect, useState } from "react";
import "../../../Style/createstyle.scss";
import { useFormik, Form, FormikProvider } from "formik";
import * as Yup from "yup";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import {
  Grid,
  Stack,
  Box,
  TextField,
  Typography,
  Button,
  FormHelperText,
  FormControl,
  Select,
  MenuItem,
} from "@mui/material";
import { useMutation } from "@apollo/client";
import PropTypes from "prop-types";
import LinearProgress from "@mui/material/LinearProgress";
//Icons
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
//date picker
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import moment from "moment";
//Src
import { AuthContext } from "../../../Context/AuthContext";
import {
  SelectPositions,
  SelectOfficeBase,
  SelectWorkingBase,
  SelectWorkingPlace,
} from "../../../DynamicComp/DynamicSelect";
import {
  CREATE_STAFF_CONTRACT,
  UPDATE_STAFF_CONTRACT,
} from "../../../Schema/StaffContract";
import FileThumnail from "../../../DynamicComp/FileThumnail";
import ViewDocument from "../Document/ViewDocument";
import EmptyPdfImage from "../../../Assets/empty-image.png";

function LinearProgressWithLabel(props) {
  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <Box sx={{ width: "100%", mr: 1 }}>
        <LinearProgress variant="determinate" {...props} />
      </Box>
      <Box sx={{ minWidth: 35 }}>
        <Typography variant="body2" color="text.secondary">{`${Math.round(
          props.value
        )}%`}</Typography>
      </Box>
    </Box>
  );
}
LinearProgressWithLabel.propTypes = {
  value: PropTypes.number.isRequired,
};

export default function CreateCovidVaccination({
  open,
  handleClose,
  dialogTitle,
  staffId,
  editData,
  setRefetch,
}) {
  const { setAlert } = useContext(AuthContext);
  const [loading, setLoading] = useState(false);
  const [positionsVal, setPositionsVal] = useState({ id: "", title: "" });
  const [officeBaseVal, setOfficeBaseVal] = useState({ id: "", title: "" });
  const [workingBaseVal, setWorkingBaseVal] = useState({ id: "", title: "" });
  const [workingPlaceVal, setWorkingPlaceVal] = useState({ id: "", title: "" });

  //---------------Progress------------------
  const [statusProgress, setStatusProgress] = React.useState(false);
  const [progress, setProgress] = React.useState(10);
  // console.log("statusProgress", statusProgress);
  // console.log("progress", progress);
  React.useEffect(() => {
    const timer = setInterval(() => {
      setProgress((prevProgress) =>
        prevProgress >= 100 ? 100 : prevProgress + 10
      );
    }, 500);
    return () => {
      clearInterval(timer);
    };
  }, []);

  // upload Image
  const [pdfFile, setPdfFile] = useState(null);
  const [uploadFile, setUploadFile] = useState(null);
  const [documentSrc, setDocumentSrc] = useState("");
  const [openView, setOpenView] = useState(false);
  const handleCloseView = () => {
    setOpenView(false);
    setPdfFile(null);
  };
  const fileType = ["application/pdf"];
  const handleUploadImage = (e) => {
    let fileSelected = e.target.files[0];
    setUploadFile(fileSelected);
    //For thumbnails
    if (fileSelected) {
      if (fileSelected && fileType.includes(fileSelected.type)) {
        let reader = new FileReader();
        reader.readAsDataURL(fileSelected);
        reader.onload = (e) => {
          setPdfFile(e.target.result);
        };
      } else {
        setPdfFile(null);
      }
    } else {
      console.log("Please select file!");
    }
    setOpenView(true);
  };

  //----------------- End -------------------------

  const [createContract, { data, error }] = useMutation(CREATE_STAFF_CONTRACT, {
    onCompleted: ({ createContract }) => {
      setLoading(false);
      // console.log(createContract, "createContract");
      if (createContract?.success === true) {
        setAlert(true, "success", createContract?.message);
        handleClose();
        resetForm();
        setRefetch();
        setDocumentSrc("");
        setUploadFile(null);
        setPdfFile(null);
        setPositionsVal({ id: "", title: "" });
        setOfficeBaseVal({ id: "", title: "" });
        setWorkingBaseVal({ id: "", title: "" });
        setWorkingPlaceVal({ id: "", title: "" });
      } else {
        setAlert(true, "error", createContract?.message);
      }
    },
    onError: (error) => {
      setLoading(false);
      setAlert(true, "error", error?.message);
    },
  });

  const [updateContract] = useMutation(UPDATE_STAFF_CONTRACT, {
    onCompleted: ({ updateContract }) => {
      // console.log(updateContract);
      setLoading(false);
      if (updateContract?.success === true) {
        setAlert(true, "success", updateContract?.message);
        handleClose();
        setRefetch();
        setDocumentSrc("");
        setUploadFile(null);
        setPdfFile(null);
      } else {
        setAlert(true, "error", updateContract?.message);
      }
    },
    onError: (error) => {
      setLoading(false);
      setAlert(true, "error", error?.message);
    },
  });

  //fromik
  const CreateContract = Yup.object().shape({
    positions: Yup.string().required("require!"),
    startDate: Yup.date().required("require!"),
    endDate: Yup.date().required("require!"),
    typeOfEmployment: Yup.string().required("require!"),
    officeBase: Yup.string(),
    workingBase: Yup.string(),
    workingPlace: Yup.string(),
    isActive: Yup.string(),
    remark: Yup.string(),
  });

  const formik = useFormik({
    initialValues: {
      positions: "",
      startDate: moment(),
      endDate: moment(),
      typeOfEmployment: "Full Time",
      officeBase: "",
      workingBase: "",
      workingPlace: "",
      isActive: true,
      remark: "",
    },

    validationSchema: CreateContract,
    onSubmit: async (values) => {
      setLoading(true);
      // console.log("values:::", staffId, documentSrc);
      if (dialogTitle !== "Create") {
        updateContract({
          variables: {
            id: editData?._id,
            input: {
              ...values,
              fileUpload: documentSrc ? documentSrc : "",
            },
          },
        });
      } else {
        createContract({
          variables: {
            staffInfoId: staffId,
            input: {
              ...values,
              fileUpload: documentSrc,
            },
          },
        });
      }
    },
  });

  const {
    errors,
    touched,
    handleSubmit,
    getFieldProps,
    setFieldValue,
    values,
    resetForm,
  } = formik;

  // console.log("editData::", editData);
  // console.log("documentSrc", documentSrc);

  useEffect(() => {
    if (editData) {
      setPositionsVal({
        id: editData?.positions?._id,
        title: editData?.positions?.positionName,
      });
      setOfficeBaseVal({
        id: editData?.officeBase?._id,
        title: editData?.officeBase?.officeBaseName,
      });
      setWorkingBaseVal({
        id: editData?.workingBase?._id,
        title: editData?.workingBase?.workingBaseName,
      });
      setWorkingPlaceVal({
        id: editData?.workingPlace?._id,
        title: editData?.workingPlace?.workingPlaceName,
      });
      // setUploadFile()
      setDocumentSrc(editData?.fileUpload);
      setFieldValue("positions", editData?.positions?._id);
      setFieldValue("startDate", editData?.startDate);
      setFieldValue("endDate", editData?.endDate);
      setFieldValue("typeOfEmployment", editData?.typeOfEmployment);
      setFieldValue("officeBase", editData?.officeBase?._id);
      setFieldValue("workingBase", editData?.workingBase?._id);
      setFieldValue("workingPlace", editData?.workingPlace?._id);
      setFieldValue("remark", editData?.remark);
    }
  }, [editData]);

  useEffect(() => {
    if (positionsVal?.id !== "") {
      setFieldValue("positions", positionsVal?.id);
    } else {
      setFieldValue("positions", "");
    }
  }, [positionsVal?.id]);

  useEffect(() => {
    if (officeBaseVal?.id !== "") {
      setFieldValue("officeBase", officeBaseVal?.id);
    } else {
      setFieldValue("officeBase", "");
    }
  }, [officeBaseVal?.id]);

  useEffect(() => {
    if (workingBaseVal?.id !== "") {
      setFieldValue("workingBase", workingBaseVal?.id);
    } else {
      setFieldValue("workingBase", "");
    }
  }, [workingBaseVal?.id]);

  useEffect(() => {
    if (workingPlaceVal?.id !== "") {
      setFieldValue("workingPlace", workingPlaceVal?.id);
    } else {
      setFieldValue("workingPlace", "");
    }
  }, [workingPlaceVal?.id]);

  return (
    <Dialog open={open} className="create-container">
      <DialogTitle sx={{ padding: "10px 20px" }}>
        <Stack direction="row" spacing={2}>
          <Stack direction="column" justifyContent="center">
            <Typography className="dialog-title">
              {dialogTitle} Staff's Contract
            </Typography>
          </Stack>

          <Box sx={{ flexGrow: 1 }} />
          <IconButton onClick={handleClose}>
            <CloseIcon className="close-icon" />
          </IconButton>
        </Stack>
      </DialogTitle>

      <DialogContent dividers>
        <FormikProvider value={formik}>
          <Form noValidate autoComplete="off" onSubmit={handleSubmit}>
            <Grid container rowSpacing={2} columnSpacing={4}>
              <Grid item xs={12}>
                <Stack direction="column" spacing={1}>
                  <Stack direction="row" justifyContent="center">
                    {/* =============================== For view outside​ =================================  */}
                    {!openView ? (
                      <Button component="label">
                        <TextField
                          type="file"
                          id="image"
                          sx={{ display: "none" }}
                          onChange={handleUploadImage}
                        />
                        {editData?.fileUpload ? (
                          <FileThumnail pdfFile={editData?.fileUpload} />
                        ) : (
                          <>
                            {uploadFile ? (
                              <FileThumnail pdfFile={pdfFile} />
                            ) : (
                              <img
                                style={{ width: "200px", height: "200px" }}
                                src={EmptyPdfImage}
                              />
                            )}
                          </>
                        )}
                        <input type="file" hidden />
                      </Button>
                    ) : (
                      <ViewDocument
                        setProgress={setProgress}
                        setStatusProgress={setStatusProgress}
                        open={openView}
                        setOpenView={setOpenView}
                        handleClose={handleCloseView}
                        pdfFile={pdfFile}
                        uploadFile={uploadFile}
                        setUploadFile={setUploadFile}
                        setDocumentSrc={setDocumentSrc}
                      />
                    )}
                  </Stack>

                  <Stack
                    direction="row"
                    justifyContent="center"
                    spacing={2}
                    sx={{ mt: 1 }}
                  >
                    {statusProgress && progress < 100 ? (
                      <Box sx={{ width: "100%", marginLeft: "20px" }}>
                        <LinearProgressWithLabel value={progress} />
                      </Box>
                    ) : (
                      <Typography className="text-En">Upload pdf</Typography>
                    )}
                  </Stack>
                </Stack>
              </Grid>
              <Grid item xs={6}>
                <Typography className="field-title">Positions</Typography>
                <SelectPositions
                  selectedVal={positionsVal}
                  setSelectedVal={setPositionsVal}
                />
                {!!errors.positions && touched.positions && (
                  <FormHelperText error id="outlined-adornment-password">
                    {errors.positions}
                  </FormHelperText>
                )}
              </Grid>
              <Grid item xs={6}>
                <Typography className="field-title">Office Base</Typography>
                <SelectOfficeBase
                  selectedVal={officeBaseVal}
                  setSelectedVal={setOfficeBaseVal}
                />
                {!!errors.officeBase && touched.officeBase && (
                  <FormHelperText error id="outlined-adornment-password">
                    {errors.officeBase}
                  </FormHelperText>
                )}
              </Grid>
              <Grid item xs={6}>
                <Typography className="field-title">Start Date</Typography>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                    value={values?.startDate}
                    onChange={(e) => setFieldValue("startDate", e)}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        className="text-field"
                        size="small"
                        fullWidth
                      />
                    )}
                  />
                </LocalizationProvider>
              </Grid>
              <Grid item xs={6}>
                <Typography className="field-title">End Date</Typography>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                    value={values?.endDate}
                    onChange={(e) => setFieldValue("endDate", e)}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        className="text-field"
                        size="small"
                        fullWidth
                      />
                    )}
                  />
                </LocalizationProvider>
              </Grid>
              <Grid item xs={6}>
                <Typography className="field-title">Working Base</Typography>
                <SelectWorkingBase
                  selectedVal={workingBaseVal}
                  setSelectedVal={setWorkingBaseVal}
                />
                {!!errors.department && touched.department && (
                  <FormHelperText error id="outlined-adornment-password">
                    {errors.department}
                  </FormHelperText>
                )}
              </Grid>
              <Grid item xs={6}>
                <Typography className="field-title">Working Place</Typography>
                <SelectWorkingPlace
                  selectedVal={workingPlaceVal}
                  setSelectedVal={setWorkingPlaceVal}
                />
                {!!errors.department && touched.department && (
                  <FormHelperText error id="outlined-adornment-password">
                    {errors.department}
                  </FormHelperText>
                )}
              </Grid>
              <Grid item xs={6}>
                <Typography className="field-title">
                  Tyoe of employment
                </Typography>
                <FormControl size="small" fullWidth>
                  <Select
                    labelId="demo-select-small"
                    id="demo-select-small"
                    value={values?.typeOfEmployment}
                    onChange={(event) => {
                      setFieldValue("typeOfEmployment", event.target.value);
                    }}
                    error={Boolean(
                      touched.typeOfEmployment && errors.typeOfEmployment
                    )}
                    helperText={
                      touched.typeOfEmployment && errors.typeOfEmployment
                    }
                  >
                    <MenuItem value={"Board of Directors"}>
                      Board of Directors
                    </MenuItem>
                    <MenuItem value={"Full Time"}>Full Time</MenuItem>
                    <MenuItem value={"Part Time"}>Part Time</MenuItem>
                    <MenuItem value={"Probation"}>Probation</MenuItem>
                    <MenuItem value={"Volunteer"}>Volunteer</MenuItem>
                    <MenuItem value={"Internship"}>Internship</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={6}>
                <Typography className="field-title">Remark</Typography>
                <TextField
                  fullWidth
                  size="small"
                  className="text-field"
                  placeholder="remark"
                  {...getFieldProps("remark")}
                  error={Boolean(touched.remark && errors.remark)}
                  helperText={touched.remark && errors.remark}
                />
              </Grid>
            </Grid>
          </Form>
        </FormikProvider>
      </DialogContent>

      <DialogActions>
        <Stack direction="row" spacing={4} className="action-container">
          <Button className="btn-cencel" fullWidth onClick={handleClose}>
            Cencel
          </Button>

          {loading ? (
            <Button className="btn-create" fullWidth>
              Loading...
            </Button>
          ) : (
            <Button className="btn-create" fullWidth onClick={handleSubmit}>
              {dialogTitle}
            </Button>
          )}
        </Stack>
      </DialogActions>
    </Dialog>
  );
}
