import React, { useState } from "react";
import {
  Box,
  Stack,
  Avatar,
  Grid,
  Button,
  Divider,
  Typography,
} from "@mui/material";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import CheckIcon from "@mui/icons-material/Check";
import PriorityHighIcon from "@mui/icons-material/PriorityHigh";
import WarningRounded from "@mui/icons-material/WarningRounded";
import moment from "moment";
//Srcs
import "../../Style/requestStyle.scss";
import LoadingPage from "../Include/LoadingPage";
import EmptyData from "../Include/EmptyData";
import TakeLeaveAction from "./TakeleaveAction";
import ApproveAndDeny from "./ApproveAndDeny";
import ApproveAndDenyRequestModify from "./ApproveAndDenyRequestModify"

export default function TakeleaveCard({
  loading,
  setRefetch,
  userLogin,
  takeLeaveDetails,
  tableData,
}) {
  const [open, setOpen] = useState(false);
  const handleClose = () => setOpen(false);
  const [requestStatus, setRequestStatus] = useState("");

  const handleApprove = () => {
    setOpen(true);
    setRequestStatus("Approve");
  };

  const handleDeny = () => {
    setOpen(true);
    setRequestStatus("Deny");
  };

  const [openRequestModify, setOpenRequestModify] = useState(false);
  const handleCloseRequestModify = () => setOpenRequestModify(false);
  const [RequestModifyStatus, setRequestModifyStatus] = useState("");

  const handleApproveModify = () => {
    setOpenRequestModify(true);
    setRequestModifyStatus("Approve");
  };

  const handleDenyModify = () => {
    setOpenRequestModify(true);
    setRequestModifyStatus("Deny");
  };


  // console.log("takeLeaveDetails::", takeLeaveDetails, userLogin);

  return (
    <Box key={takeLeaveDetails?.userName} className="request-card-container">
      {takeLeaveDetails === undefined ? (
        <Stack direction="row" justifyContent="center" width="100%">
          <EmptyData />
        </Stack>
      ) : (
        <Stack direction="row" justifyContent="center" width="100%">
          {loading ? (
            <LoadingPage />
          ) : (
            <Box
              sx={{ width: { xs: "90%", md: "100%", lg: "80%", xl: "70%" } }}
            >
              <Grid item container spacing={1}>
                <Grid item xs={12} lg={1.5}>
                  <Stack direction="row" justifyContent="right">
                    <Avatar
                      alt="User"
                      src={takeLeaveDetails?.createdBy?.staffProfile?.imageSrc}
                      sx={{ width: 50, height: 50 }}
                    />
                  </Stack>
                </Grid>
                <Grid item xs={12} lg={10.5}>
                  <Stack
                    direction="column"
                    spacing={1.5}
                    className="card-container"
                  >
                    <Stack direction="row" justifyContent="space-between">
                      <Stack direction="column" justifyContent="center">
                        <Typography className="request-card-request-type">
                          {takeLeaveDetails?.createdBy?.staffProfile?.englishData
                            ?.firstNameLatin +
                            " " +
                            takeLeaveDetails?.createdBy?.staffProfile
                              ?.englishData?.lastNameLatin}
                        </Typography>
                      </Stack>
                      <Stack direction="column" justifyContent="center">
                        <Typography className="request-card-reason">
                          {moment(takeLeaveDetails?.createdAt)
                            .utc("UTC+07:00")
                            .format("DD MMM YY, HH:mm")}
                        </Typography>
                      </Stack>
                      <Box
                        sx={{
                          display:
                            userLogin?._id === takeLeaveDetails?.createdBy?._id 
                              // && takeLeaveDetails?.status === "Pending"
                              ? "block"
                              : "none",
                        }}
                      >
                        <TakeLeaveAction
                          userLogin={userLogin}
                          editData={takeLeaveDetails}
                          setRefetch={setRefetch}
                        />
                      </Box>
                    </Stack>

                    <Box className="request-card-header">
                      <Box sx={{ padding: "4px" }}>
                        <Typography className="request-card-request-type">
                          {takeLeaveDetails?.takeLeaveType}
                        </Typography>
                        <Stack direction="column">
                          <Typography className="request-card-request-type">
                            Total leave : {takeLeaveDetails?.totalLeaveAsDay}{" "}
                            {takeLeaveDetails?.totalLeaveAsDay <= 1
                              ? "day"
                              : "days"}
                          </Typography>
                          <Typography className="request-card-request-type">
                            Take leave type used :
                          </Typography>
                          {
                            takeLeaveDetails?.takeLeaveTypeUsed?.map(e => {
                              return (
                                <Typography className="request-card-item">
                                  - {e.takeLeaveType} : {e.quantityDayLeave}{" "}{e.quantityDayLeave <= 1 ? "day" : "days"}
                                </Typography>
                              )
                            })
                          }
                          <br />
                          <Typography className="request-card-item">
                            From date :{" "}
                            {moment(takeLeaveDetails?.startDate).format(
                              "DD-MMM-YYYY hh:mm A"
                            )}
                          </Typography>
                          <Typography className="request-card-item">
                            To date :{" "}
                            {moment(takeLeaveDetails?.endDate).format(
                              "DD-MMM-YYYY hh:mm A"
                            )}
                          </Typography>
                          <Typography className="request-card-item">
                            Return date :{" "}
                            {moment(takeLeaveDetails?.returnDate).format(
                              "DD-MMM-YYYY hh:mm A"
                            )}
                          </Typography>
                        </Stack>
                        <br />
                        <Stack direction="column">
                          <Typography className="request-card-request-type">
                            Reasons :
                          </Typography>
                          <Typography className="request-card-reason">
                            {takeLeaveDetails?.reason}
                          </Typography>
                        </Stack>
                      </Box>
                    </Box>

                    {/* Warning message*/}
                    {
                      takeLeaveDetails?.warningMessage?.length > 0 ? (
                        <Box className="request-card-warning">
                          <Box sx={{ padding: "4px" }}>
                            <Stack direction="row" alignItems={"center"}>
                              <WarningRounded
                                sx={{ color: "#ddd200", }}
                              />
                              <Typography className="request-card-request-type">
                                Warning:
                              </Typography>
                            </Stack>
                            <Typography className="request-card-item">

                            </Typography>
                            <Stack direction="column">
                              {
                                takeLeaveDetails?.warningMessage?.map(element => {
                                  return (
                                    <Typography className="request-card-item">
                                      - {element}
                                    </Typography>
                                  )
                                })
                              }
                            </Stack>
                          </Box>
                        </Box>

                      ) : (
                        ""
                      )
                    }

                    {/* Take leave record*/}
                    {
                      takeLeaveDetails?.paymentOfTakeLeaveQuantity?.length > 0 ? (
                        <Box className="request-card-take-leave-record">
                          <Box sx={{ padding: "4px" }}>
                            <Typography className="request-card-request-type">
                              Take Leave record :
                            </Typography>
                            <Stack direction="column">
                              {
                                takeLeaveDetails?.paymentOfTakeLeaveQuantity?.map(element => {
                                  return (
                                    <>
                                      <Typography className="request-card-item">
                                        <ul>
                                          <li>{element.takeLeaveType} :
                                            <ul>
                                              {element.takeLeaveType === "Annual Leave" ? <li>Last year remain = {element.lastYearRemainQty} {element.lastYearRemainQty === 1 || element.lastYearRemainQty === 0 ? "day" : "days"}</li> : ""}
                                              {element.takeLeaveType === "Annual Leave" ? (<li>Current year = {element.currentYearQty} {element.currentYearQty === 1 || element.currentYearQty === 0 ? "day" : "days"}</li>)
                                                : element.takeLeaveType === "Compensate Leave" ? (<li>Available quantity = {element.currentYearQty * 8} hours {`(${element.currentYearQty} ${element.currentYearQty === 1 || element.currentYearQty === 0 ? "day" : "days"})`}</li>)
                                                  : (<li>Available quantity = {element.currentYearQty} {element.currentYearQty === 1 || element.currentYearQty === 0 ? "day" : "days"}</li>)
                                              }
                                              <li>Used quantity = {element.UsedQty} {element.UsedQty === 1 || element.UsedQty === 0 ? "day" : "days"}</li>
                                            </ul>
                                          </li>
                                        </ul>
                                      </Typography>
                                    </>
                                  )
                                })
                              }
                            </Stack>
                          </Box>
                        </Box>

                      ) : (
                        ""
                      )
                    }

                    <Divider />

                    <Grid container>
                      <Grid
                        item
                        xs={
                          userLogin?._id !== takeLeaveDetails?.createdBy?._id &&
                            takeLeaveDetails?.status === "Pending"
                            ? 6
                            : 12
                        }
                      >
                        <Stack direction="column" spacing={1}>
                          <Typography className="request-card-reason">
                            Manager respone
                          </Typography>

                          {takeLeaveDetails?.status === "Pending" ? (
                            <Stack direction="row" spacing={1}>
                              <HelpOutlineIcon
                                sx={{
                                  fontSize: "18px",
                                  color: "orange",
                                }}
                              />
                              <Stack
                                direction="column"
                                justifyContent="center"
                                className="request-card-item"
                              >
                                {takeLeaveDetails?.status}
                              </Stack>
                            </Stack>
                          ) : takeLeaveDetails?.status === "Denied" ? (
                            <Stack direction="row" spacing={2}>
                              <Avatar
                                alt="Manager"
                                src={
                                  takeLeaveDetails?.approvedOrDeniedBy
                                    ?.staffProfile?.imageSrc
                                }
                                sx={{ width: "40px", height: "40px" }}
                              />
                              <Stack direction="column" spacing={1}>
                                <Stack direction="row" spacing={1}>
                                  <Typography className="request-card-request-type">
                                    {takeLeaveDetails?.approvedOrDeniedBy
                                      ?.staffProfile?.englishData
                                      ?.firstNameLatin +
                                      " " +
                                      takeLeaveDetails?.approvedOrDeniedBy
                                        ?.staffProfile?.englishData
                                        ?.lastNameLatin}{" "}
                                    :
                                  </Typography>
                                  <Typography className="request-card-item">
                                    {moment(takeLeaveDetails?.statusDate)
                                      .utc()
                                      .format("DD, MMM YY")}
                                  </Typography>
                                </Stack>
                                <Stack direction="row">
                                  <PriorityHighIcon
                                    sx={{ color: "red", fontSize: "16px" }}
                                  />
                                  <Stack
                                    direction="column"
                                    justifyContent="center"
                                    className="request-card-item"
                                  >
                                    {takeLeaveDetails?.status}
                                  </Stack>
                                </Stack>
                                <Stack
                                  direction="column"
                                  sx={{
                                    display:
                                      takeLeaveDetails?.managerReply === ""
                                        ? "none"
                                        : "flex",
                                  }}
                                >
                                  <Typography className="request-card-reason">
                                    Note
                                  </Typography>
                                  <Typography className="request-card-reason">
                                    {takeLeaveDetails?.managerReply}
                                  </Typography>
                                </Stack>
                              </Stack>
                            </Stack>
                          ) : (
                            <Stack direction="row" spacing={2}>
                              <Avatar
                                alt="Manager"
                                src={
                                  takeLeaveDetails?.approvedOrDeniedBy
                                    ?.staffProfile?.imageSrc
                                }
                                sx={{ width: "40px", height: "40px" }}
                              />
                              <Stack direction="column" spacing={1}>
                                <Stack direction="row" spacing={1}>
                                  <Typography className="request-card-request-type">
                                    {takeLeaveDetails?.approvedOrDeniedBy
                                      ?.staffProfile?.englishData
                                      ?.firstNameLatin +
                                      " " +
                                      takeLeaveDetails?.approvedOrDeniedBy
                                        ?.staffProfile?.englishData
                                        ?.lastNameLatin}{" "}
                                    :
                                  </Typography>
                                  <Typography className="request-card-item">
                                    {moment(takeLeaveDetails?.approvedOrDeniedDate)
                                      .utc("UTC+07:00")
                                      .format("DD MMM YY, HH:mm")}
                                  </Typography>
                                </Stack>
                                <Stack direction="row" spacing={1}>
                                  <CheckIcon
                                    sx={{ color: "#198f4a", fontSize: "16px" }}
                                  />
                                  <Stack
                                    direction="column"
                                    justifyContent="center"
                                    className="request-card-item"
                                  >
                                    {takeLeaveDetails?.status}
                                  </Stack>
                                </Stack>
                                <Stack
                                  direction="column"
                                  sx={{
                                    display:
                                      takeLeaveDetails?.managerReply === ""
                                        ? "none"
                                        : "flex",
                                  }}
                                >
                                  {/* <Typography className="request-card-reason">
                                    Manager Reply:
                                  </Typography> */}
                                  <Typography className="request-card-reason">
                                    {takeLeaveDetails?.managerReply}
                                  </Typography>
                                </Stack>
                              </Stack>
                            </Stack>
                          )}
                        </Stack>
                      </Grid>
                      <Grid item xs={6}>
                        <Stack
                          direction="row"
                          spacing={2}
                          justifyContent="right"
                          sx={{
                            display:
                              userLogin?._id !==
                                takeLeaveDetails?.createdBy?._id &&
                                takeLeaveDetails?.status === "Pending"
                                ? "flex"
                                : "none",
                          }}
                        >
                          <Button className="btn-dennie" onClick={handleDeny}>
                            Denie
                          </Button>
                          <Button
                            className="btn-approve"
                            onClick={handleApprove}
                          >
                            Approve
                          </Button>
                          <ApproveAndDeny
                            open={open}
                            handleClose={handleClose}
                            userLogin={userLogin}
                            editData={takeLeaveDetails}
                            requestStatus={requestStatus}
                            setRefetch={setRefetch}
                          />
                        </Stack>
                      </Grid>
                      <Grid item xs={6}>
                        <Stack
                          direction="row"
                          spacing={2}
                          justifyContent="right"
                          sx={{
                            display:
                              userLogin?._id !==
                                takeLeaveDetails?.createdBy?._id &&
                                takeLeaveDetails?.requestModifyStatus === "Pending"
                                ? "flex"
                                : "none",
                          }}
                        >
                          <Button className="btn-dennie" onClick={handleDenyModify}>
                            Denie
                          </Button>
                          <Button
                            className="btn-approve"
                            onClick={handleApproveModify}
                          >
                            Approve Modify
                          </Button>
                          <ApproveAndDenyRequestModify
                            open={openRequestModify}
                            handleClose={handleCloseRequestModify}
                            userLogin={userLogin}
                            editData={takeLeaveDetails}
                            requestStatus={RequestModifyStatus}
                            setRefetch={setRefetch}
                          />
                        </Stack>
                      </Grid>
                    </Grid>
                    <Stack
                      sx={{
                        display:
                            takeLeaveDetails?.requestModifyStatus === "Pending"
                            ? "flex"
                            : "none",
                      }}
                    >
                      <Typography className="request-card-reason">Modify as below :</Typography>
                      <Box className="request-card-header">
                        <Box sx={{ padding: "4px" }}>
                          <Typography className="request-card-request-type">
                            {takeLeaveDetails?.requestModify?.takeLeaveType}
                          </Typography>
                          <Stack direction="column">
                            <Typography className="request-card-request-type">
                              Total leave : {takeLeaveDetails?.requestModify?.totalLeaveAsDay}{" "}
                              {takeLeaveDetails?.requestModify?.totalLeaveAsDay <= 1
                                ? "day"
                                : "days"}
                            </Typography>
                            <Typography className="request-card-request-type">
                              Take leave type used :
                            </Typography>
                            {
                              takeLeaveDetails?.requestModify?.takeLeaveTypeUsed?.map(e => {
                                return (
                                  <Typography className="request-card-item">
                                    - {e.takeLeaveType} : {e.quantityDayLeave}{" "}{e.quantityDayLeave <= 1 ? "day" : "days"}
                                  </Typography>
                                )
                              })
                            }
                            <br />
                            <Typography className="request-card-item">
                              From date :{" "}
                              {moment(takeLeaveDetails?.requestModify?.startDate).format(
                                "DD-MMM-YYYY hh:mm A"
                              )}
                            </Typography>
                            <Typography className="request-card-item">
                              To date :{" "}
                              {moment(takeLeaveDetails?.requestModify?.endDate).format(
                                "DD-MMM-YYYY hh:mm A"
                              )}
                            </Typography>
                            <Typography className="request-card-item">
                              Return date :{" "}
                              {moment(takeLeaveDetails?.requestModify?.returnDate).format(
                                "DD-MMM-YYYY hh:mm A"
                              )}
                            </Typography>
                          </Stack>
                          <br />
                          <Stack direction="column">
                            <Typography className="request-card-request-type">
                              Reasons :
                            </Typography>
                            <Typography className="request-card-reason">
                              {takeLeaveDetails?.requestModify?.reason}
                            </Typography>
                          </Stack>
                        </Box>
                      </Box>
                    </Stack>
                  </Stack>
                </Grid>
              </Grid>
            </Box>
          )}
        </Stack>
      )}
    </Box>
  );
}
