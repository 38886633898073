import { gql } from "@apollo/client";

export const CREATE_STAFF_DRIVERLICENSE = gql`
  mutation CreateStaffDrivingLicense(
    $staffInfoId: ID
    $input: DrivingLicenseInput
  ) {
    createStaffDrivingLicense(staffInfoId: $staffInfoId, input: $input) {
      success
      message
    }
  }
`;

export const UPDATE_STAFF_DRIVERLICENSE = gql`
  mutation UpdateStaffDrivingLicense($id: ID, $input: DrivingLicenseInput) {
    updateStaffDrivingLicense(_id: $id, input: $input) {
      success
      message
    }
  }
`;

export const GET_DRIVERLICENSE_BY_STAFF = gql`
  query GetDrivingLincenseByStaffId($staffId: ID!) {
    getDrivingLincenseByStaffId(staffId: $staffId) {
      _id
      drivingId
      imageName
      imageSrc
      manualOrAuto
      expiryDate
      issueDate
      drivingType
      remark
    }
  }
`;

export const DELETE_STAFF_DRIVERLICENSE = gql`
  mutation DeleteStaffDrivingLicense($id: ID) {
  deleteStaffDrivingLicense(_id: $id) {
    success
    message
  }
}
`;
