import React, { useState, useContext, useEffect } from "react";
import "../../../Style/pagestyle.scss";
import {
  Button,
  Stack,
  Typography,
  MenuItem,
  IconButton,
  Menu,
} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import BorderColorOutlinedIcon from "@mui/icons-material/BorderColorOutlined";
import DeleteForeverOutlinedIcon from "@mui/icons-material/DeleteForeverOutlined";
//src
import FileThumnail from "../../../DynamicComp/FileThumnail";
import ViewDocument from "./ViewDocument";
import RenameDocument from "./RenameDocument";
import DeleteDocument from "./DeleteDocument";
import moment from "moment";

export default function PdfCard({ data, setRefetch, userRole }) {
  // console.log("data::", data);
  const [pdfFile, setPdfFile] = useState(null);
  const [openView, setOpenView] = useState(false);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const openMenu = Boolean(anchorEl);
  const handleOpenMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleOpenView = (url) => {
    setOpenView(true);
    setPdfFile(url);
  };
  const handleCloseView = () => {
    setOpenView(false);
    setPdfFile(null);
  };
  //Modal delete
  const [openDelete, setOpenDelete] = useState(false);
  const handleOpenDelete = (data) => {
    setOpenDelete(true);
  };
  const handleCloseDelete = () => setOpenDelete(false);

  //Modal rename
  const [openRename, setOpenRename] = useState(false);
  const handleOpenRename = (data) => {
    setOpenRename(true);
  };

  const handleCloseRename = () => setOpenRename(false);
  return (
    <Stack direction="column" spacing={1} className="document-list">
      <Stack
        direction="row"
        justifyContent="space-between"
        sx={{ padding: "10px 10px 0px 10px" }}
      >
        <Stack direction="column" justifyContent="center">
          <Typography>{data?.documentName}</Typography>
        </Stack>

        <Stack direction="column" justifyContent="center">
          {userRole === "Admin" ? (
            <IconButton sx={{ width: 40, height: 40 }} onClick={handleOpenMenu}>
              <MoreVertIcon />
            </IconButton>
          ) : null}

          <Menu
            anchorEl={anchorEl}
            id="account-menu"
            open={openMenu}
            onClose={handleCloseMenu}
            onClick={handleCloseMenu}
            PaperProps={{
              elevation: 0,
              sx: {
                overflow: "visible",
                filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                mt: 1.5,
                "& .MuiAvatar-root": {
                  width: 32,
                  height: 32,
                  ml: -0.5,
                  mr: 1,
                },
                "&:before": {
                  content: '""',
                  display: "block",
                  position: "absolute",
                  top: 0,
                  right: 14,
                  width: 10,
                  height: 10,
                  bgcolor: "background.paper",
                  transform: "translateY(-50%) rotate(45deg)",
                  zIndex: 0,
                },
              },
            }}
            transformOrigin={{
              horizontal: "right",
              vertical: "top",
            }}
            anchorOrigin={{
              horizontal: "right",
              vertical: "bottom",
            }}
          >
            <MenuItem
              onClick={() => {
                handleOpenRename(data);
                handleCloseMenu();
              }}
            >
              <Stack direction="row" spacing={1}>
                <BorderColorOutlinedIcon className="update-icon" />
                <Typography className="update-title">Rename</Typography>
              </Stack>
            </MenuItem>
            <MenuItem
              onClick={() => {
                handleOpenDelete(data);
                handleCloseMenu();
              }}
            >
              <Stack direction="row" spacing={1}>
                <DeleteForeverOutlinedIcon className="delete-icon" />
                <Typography className="update-title">Delete</Typography>
              </Stack>
            </MenuItem>
          </Menu>
        </Stack>
      </Stack>
      <Stack direction="row" justifycontent="center">
        <Button
          // onClick={() => handleOpenView(data?.documentURL)}
          sx={{ width: "100%" }}
        >
          <a
            href={data?.documentURL}
            target="_blank"
            rel="noopener noreferrer"
          >
            {/* <IoDocumentTextSharp
                className="more-icon"
                style={{ color: "green" }}
              /> */}
            <FileThumnail pdfFile={data?.documentURL} />
          </a>

          {/* <embed
            src={data?.documentURL}
            type="application/pdf"
            style={{
              width: "100%",
              height: "360px",
              overflow: "hidden",
              backgroundColor: "white",
              color: "#000",
            }}
          /> */}
        </Button>
      </Stack>

      <Stack direction="row" sx={{ padding: "10px" }}>
        Upload at {moment(data?.updatedAt).format("DD-MM-YYYY")}
      </Stack>

      <ViewDocument
        pdfFile={pdfFile}
        open={openView}
        setOpenView={setOpenView}
        handleClose={handleCloseView}
        viewType="View"
      />
      <RenameDocument
        open={openRename}
        handleClose={handleCloseRename}
        editData={data}
        setRefetch={setRefetch}
      />
      <DeleteDocument
        open={openDelete}
        handleClose={handleCloseDelete}
        editData={data}
        setRefetch={setRefetch}
      />
    </Stack>
  );
}
