import React, { useContext, useEffect, useState } from "react";
import "../../Style/createstyle.scss";
import { useFormik, Form, FormikProvider } from "formik";
import * as Yup from "yup";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import { Grid, Stack, Box, TextField, Typography, Button } from "@mui/material";
import { useMutation } from "@apollo/client";
//Icons
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
//Src
import { AuthContext } from "../../Context/AuthContext";
import { CREATE_DEPARTMENT, UPDATE_DEPARTMENT } from "../../Schema/Department";

export default function CreateDepartment({
  open,
  handleClose,
  dialogTitle,
  editData,
  setRefetch,
}) {
  const { setAlert } = useContext(AuthContext);
  const [loading, setLoading] = useState(false);

  // console.log("teamAssign", teamAssign);

  const [createDepartment, { data, error }] = useMutation(CREATE_DEPARTMENT, {
    onCompleted: ({ createDepartment }) => {
      // console.log(createDepartment);
      setLoading(false);
      if (createDepartment?.success === true) {
        setAlert(true, "success", createDepartment?.message);
        handleClose();
        resetForm();
        setRefetch();
      } else {
        setAlert(true, "error", createDepartment?.message);
      }
    },
    onError: (error) => {
      setLoading(false);
      setAlert(true, "error", error?.message);
    },
  });

  const [updateDepartment] = useMutation(UPDATE_DEPARTMENT, {
    onCompleted: ({ updateDepartment }) => {
      // console.log(updateDepartment);
      setLoading(false);
      if (updateDepartment?.success === true) {
        setAlert(true, "success", updateDepartment?.message);
        handleClose();
        setRefetch();
      } else {
        setAlert(true, "error", updateDepartment?.message);
      }
    },
    onError: (error) => {
      setLoading(false);
      setAlert(true, "error", error?.message);
    },
  });

  //fromik
  const CreatePostions = Yup.object().shape({
    departmentName: Yup.string().required("require!"),
    remark: Yup.string(),
  });

  const formik = useFormik({
    initialValues: {
      departmentName: "",
      remark: "",
    },

    validationSchema: CreatePostions,
    onSubmit: async (values) => {
      // console.log("values:::", values);
      setLoading(true);
      if (dialogTitle !== "Create") {
        updateDepartment({
          variables: {
            id: editData?._id,
            input: {
              ...values,
            },
          },
        });
      } else {
        createDepartment({
          variables: {
            input: {
              ...values,
            },
          },
        });
      }
    },
  });

  const {
    errors,
    touched,
    handleSubmit,
    getFieldProps,
    setFieldValue,
    values,
    resetForm,
  } = formik;

  // console.log("editData", editData)

  useEffect(() => {
    if (editData) {
      setFieldValue("departmentName", editData?.departmentName);
      setFieldValue(
        "remark",
        editData?.remark === null ? "" : editData?.remark
      );
      // setDepartmentVal()
    }
  }, [editData]);

  return (
    <Dialog open={open} className="create-container">
      <DialogTitle sx={{ padding: "10px 20px" }}>
        <Stack direction="row" spacing={2}>
          <Stack direction="column" justifyContent="center">
            <Typography className="dialog-title">
              {dialogTitle} Department
            </Typography>
          </Stack>

          <Box sx={{ flexGrow: 1 }} />
          <IconButton onClick={handleClose}>
            <CloseIcon className="close-icon" />
          </IconButton>
        </Stack>
      </DialogTitle>

      <DialogContent dividers>
        <FormikProvider value={formik}>
          <Form noValidate autoComplete="off" onSubmit={handleSubmit}>
            <Grid container rowSpacing={2} columnSpacing={4}>
              <Grid item xs={12}>
                <Typography className="field-title">Department Name</Typography>
                <TextField
                  fullWidth
                  size="small"
                  className="text-field"
                  placeholder="postions"
                  {...getFieldProps("departmentName")}
                  error={Boolean(
                    touched.departmentName && errors.departmentName
                  )}
                  helperText={touched.departmentName && errors.departmentName}
                />
              </Grid>

              <Grid item xs={12}>
                <Typography className="field-title">Remark</Typography>
                <TextField
                  fullWidth
                  size="small"
                  className="text-field"
                  placeholder="remark"
                  {...getFieldProps("remark")}
                  error={Boolean(touched.remark && errors.remark)}
                  helperText={touched.remark && errors.remark}
                />
              </Grid>
            </Grid>
          </Form>
        </FormikProvider>
      </DialogContent>

      <DialogActions>
        <Stack direction="row" spacing={4} className="action-container">
          <Button className="btn-cencel" fullWidth onClick={handleClose}>
            Cencel
          </Button>

          {loading ? (
            <Button className="btn-create" fullWidth>
              Loading...
            </Button>
          ) : (
            <Button className="btn-create" fullWidth onClick={handleSubmit}>
              {dialogTitle}
            </Button>
          )}
        </Stack>
      </DialogActions>
    </Dialog>
  );
}
