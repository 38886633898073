import React, { useState } from "react";
import "../dashboard.scss";
// import "../../../Style/pagestyle.scss";
import {
  Box,
  TableContainer,
  Table,
  TableRow,
  TableCell,
  TableBody,
  TableHead,
} from "@mui/material";
import { useQuery } from "@apollo/client";
//Icons
//Srcs
import EmptyData from "../../Include/EmptyData";
import LoadingPage from "../../Include/LoadingPage";
import { GET_TAKE_LEAVE_PAYMENT_DASHBOARD } from "../../../Schema/Dashboard";
import { GET_TAKELEAVE_TYPE_BY_USERID } from "../../../Schema/TakeLeave";

export default function TakeLeavePaymentTable() {
  const [loading, setLoading] = useState(true);
  const [tableData, setTableData] = useState([]);

  //get userLoging from local
  const userLogin = JSON.parse(window.localStorage.getItem("userLogin"));

  const { data, refetch } = useQuery(GET_TAKE_LEAVE_PAYMENT_DASHBOARD, {
    onCompleted: ({ getTakeLeavePaymentDashboard }) => {
      // console.log("getTakeLeavePaymentDashboard::", getTakeLeavePaymentDashboard);
      setTableData(getTakeLeavePaymentDashboard)
      setLoading(false);
    },
    variables: {
      staffId: userLogin?._id,
    },
    onError: (error) => {
      console.log(error)
    },
  });
  //===================== Resize width Screen=====================
  const [width, setWidth] = React.useState(window.innerWidth);
  const updateDimensions = () => {
    setWidth(window.innerWidth - 50);
  };
  React.useEffect(() => {
    window.addEventListener("resize", updateDimensions);
    return () => window.removeEventListener("resize", updateDimensions);
  }, []);
  return (
    // <div className="page-container">
      <Box className="table-body-container">
        <TableContainer sx={{ maxWidth: `${width}px` }}>
          <Table className="table" aria-label="simple table">
            <TableHead className="header-row ">
              <TableCell className="header-title-start"> N°</TableCell>
              <TableCell className="header-title">Take leave type</TableCell>
              <TableCell className="header-title">Last year remain</TableCell>
              <TableCell className="header-title">Current year</TableCell>
              <TableCell className="header-title-end">Total used</TableCell>
              {/* <TableCell className="header-title-end header-sticky-right" align="right">
                {
                  userLogin?.systemAccessData?.role === "Admin" ? <Button
                    startIcon={<AddCircleIcon />}
                    className="btn-add-list"
                    onClick={handleOpen}
                  >
                    Add
                  </Button> : null
                }
                <CreateEducation
                  open={open}
                  handleClose={handleClose}
                  dialogTitle={"Create"}
                  staffId={staffId}
                  setRefetch={refetch}
                />
              </TableCell> */}
            </TableHead>
            {loading ? (
              <TableBody className="body">
                <TableRow className="body-row-gray">
                  <TableCell className="body-cell" colSpan={7}>
                    <LoadingPage />
                  </TableCell>
                </TableRow>
              </TableBody>
            ) : (
              <TableBody className="body">
                {tableData?.length === 0 ? (
                  <TableRow className="body-row-gray">
                    <TableCell className="body-cell" colSpan={7}>
                      <EmptyData />
                    </TableCell>
                  </TableRow>
                ) : (
                  <>
                    {tableData?.map((row, index) => {
                      return (
                        <TableRow
                          className={
                            (index + 1) % 2 === 0 ? "body-row" : "body-row-gray"
                          }
                          key={index}
                        >
                          <TableCell className="body-cell-start">
                            {index + 1}
                          </TableCell>
                          <TableCell className="body-cell">
                            {row?.takeLeaveType}
                          </TableCell>
                          <TableCell className="body-cell">
                            {row?.lastYearRemainQty} {row?.lastYearRemainQty !== null ? `${row?.lastYearRemainQty === 1?"day":"days"}`:""}
                          </TableCell>
                          <TableCell className="body-cell">
                            {row?.currentYearQty !== null ? `${row?.takeLeaveType === "Maternity Leave" ? "3 months" : row?.takeLeaveType === "Breastfeeding" ? "2 hours/per day" : `${row?.currentYearQty} ${row?.currentYearQty===1?"day":"days"}`}`:""}
                          </TableCell>
                          <TableCell className="body-cell">
                            {row?.UsedQty} {row?.UsedQty === 1 ? "day":"days"}
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </>
                )}
              </TableBody>
            )}
          </Table>
        </TableContainer>
      </Box>
    // </div>
  );
}
