import React, { useState } from "react";
import "../dashboard.scss";
import { Grid, Box, Stack, Typography } from "@mui/material";
import { useQuery } from "@apollo/client";
//Icons
import MoreTimeIcon from '@mui/icons-material/MoreTime';
import WorkHistoryIcon from '@mui/icons-material/WorkHistory';
import PendingActionsIcon from '@mui/icons-material/PendingActions';
import SickIcon from '@mui/icons-material/Sick';
//Srcs
import { GET_TAKE_LEAVE_PAYMENT_DASHBOARD } from "../../../Schema/Dashboard";

export default function TakeLeaveCard() {
  const [tableData, setTableData] = useState([]);

  const userLogin = JSON.parse(window.localStorage.getItem("userLogin"));

  const { data, refetch } = useQuery(GET_TAKE_LEAVE_PAYMENT_DASHBOARD, {
    onCompleted: ({ getTakeLeavePaymentDashboard }) => {
      // console.log("getTakeLeavePaymentDashboard::", getTakeLeavePaymentDashboard);
      setTableData(getTakeLeavePaymentDashboard)
      // setLoading(false);
    },
    variables: {
      staffId: userLogin?._id,
      takeLeaveType: ['Compensate Leave','Annual Leave', 'Sick Leave']
    },
    onError: (error) => {
      console.log(error)
    },
  });

  return (
    <Grid container spacing={3}>
      {/* =================================== Compensate Leave ================================ */}
      <Grid item xs={12} sm={6} lg={3}>
        <Box
          className="box-container"
          sx={{ borderBottom: "8px solid #082137" }}
        >
          <Stack direction="row" spacing={2} height={80}>
            <Stack
              className="box-icon-container"
              sx={{ backgroundColor: "#082137" }}
            >
              <MoreTimeIcon
                sx={{
                  color: "#ffff",
                  fontSize: "40px",
                }}
              />
            </Stack>
            <Stack direction="column" justifyContent="space-between">
              <Typography className="text-title">
                Compensate Leave
              </Typography>
              <Typography className="text-main" sx={{ color: "#082137" }}>
                {(tableData?.find(e => e.takeLeaveType==="Compensate Leave"))?.currentYearQty}
              </Typography>
            </Stack>
          </Stack>
        </Box>
      </Grid>

      {/* =================================== Annual Leave Remain ================================ */}
      <Grid item xs={12} sm={6} lg={3}>
        <Box
          className="box-container"
          sx={{ borderBottom: "8px solid #023f88" }}
        >
          <Stack direction="row" spacing={2} height={80}>
            <Stack
              className="box-icon-container"
              sx={{ backgroundColor: "#023f88" }}
            >
              <PendingActionsIcon
                sx={{
                  color: "#ffff",
                  fontSize: "40px",
                }}
              />
            </Stack>
            <Stack direction="column" justifyContent="space-between">
              <Typography className="text-title">
                Annual Leave Remain
              </Typography>
              <Typography className="text-main" sx={{ color: "#023f88" }}>
              {(tableData?.find(e => e.takeLeaveType==="Annual Leave"))?.lastYearRemainQty}
              </Typography>
            </Stack>
          </Stack>
        </Box>
      </Grid>

      {/* =================================== Current Annual Leave ================================ */}
      <Grid item xs={12} sm={6} lg={3}>
        <Box
          className="box-container"
          sx={{ borderBottom: "8px solid #198F4A" }}
        >
          <Stack direction="row" spacing={2} height={80}>
            <Stack
              className="box-icon-container"
              sx={{ backgroundColor: "#198F4A" }}
            >
              <WorkHistoryIcon
                sx={{
                  color: "#ffff",
                  fontSize: "40px",
                }}
              />
            </Stack>
            <Stack direction="column" justifyContent="space-between">
              <Typography className="text-title">Current Annual Leave</Typography>
              <Typography className="text-main" sx={{ color: "#198F4A" }}>
              {(tableData?.find(e => e.takeLeaveType==="Annual Leave"))?.currentYearQty}
              </Typography>
            </Stack>
          </Stack>
        </Box>
      </Grid>

      {/* =================================== Sick Leave ================================ */}
      <Grid item xs={12} sm={6} lg={3}>
        <Box
          className="box-container"
          sx={{ borderBottom: "8px solid #43425D" }}
        >
          <Stack direction="row" spacing={2} height={80}>
            <Stack
              className="box-icon-container"
              sx={{ backgroundColor: "#43425D" }}
            >
              <SickIcon
                sx={{
                  color: "#ffff",
                  fontSize: "40px",
                }}
              />
            </Stack>
            <Stack direction="column" justifyContent="space-between">
              <Typography className="text-title">Sick Leave</Typography>
              <Typography className="text-main" sx={{ color: "#43425D" }}>
              {(tableData?.find(e => e.takeLeaveType==="Sick Leave"))?.currentYearQty}
              </Typography>
            </Stack>
          </Stack>
        </Box>
      </Grid>
    </Grid>
  );
}
