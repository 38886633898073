import React, { useState, useContext } from "react";
import "../../Style/deletestyle.scss";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import { Stack, Box, TextField } from "@mui/material";
import { useMutation } from "@apollo/client";
import {
  ref,
  getDownloadURL,
  uploadBytesResumable,
  deleteObject,
} from "firebase/storage";
import { storage } from "../../firebase";
//Schema
import { AuthContext } from "../../Context/AuthContext";
import { DELETE_STAFFINFORMATION } from "../../Schema/StaffInformation";

export default function DeleteStaff({
  open,
  handleClose,
  editData,
  setRefetch,
}) {

  // console.log("editData", editData);

  const [loading, setLoading] = useState(false);
  const [confirmVal, setConfirmVal] = useState("");

  const [deleteStaffInfo] = useMutation(DELETE_STAFFINFORMATION, {
    onCompleted: ({ deleteStaffInfo }) => {
      // console.log(deleteStaffInfo);
      if (deleteStaffInfo?.success === true) {
        setAlert(true, "success", deleteStaffInfo?.message);
        handleClose();
        setLoading(false);
        setConfirmVal("");
      } else {
        handleClose();
        setAlert(true, "error", deleteStaffInfo?.message);
      }
    },
    onError: (error) => {
      console.log(error);
    },
  });

  const { setAlert } = useContext(AuthContext);

  const handleDelete = () => {
    setLoading(true);
    deleteImage()
    deleteStaffInfo({
      variables: {
        id: editData?._id,
      },
    });
  };

  const deleteImage = () => {
    if(editData?.staffProfile?.imageSrc){
      const deleteRef = ref(storage, editData?.staffProfile?.imageSrc);
      deleteObject(deleteRef).then(() => {
        console.log("delete this image successfully!")
      }).catch((err) => {
        console.log("can not delete this image!", err)
      })
    }
  }

  return (
    <Dialog open={open} className="delete-container">
      <DialogTitle className="dialog-title">
        <Stack direction="row" spacing={2}>
          <Stack direction="column" justifyContent="center">
            <Typography className="dialog-title">
              Delete Staff
            </Typography>
          </Stack>
          <Box sx={{ flexGrow: 1 }} />
          <IconButton onClick={handleClose}>
            <CloseIcon className="close-icon" />
          </IconButton>
        </Stack>
      </DialogTitle>
      <DialogContent 
        // dividers
      >
        <Stack direction="column" spacing={2}>
          <Typography className="delete-message">
            Are you sure to delete this data ?
          </Typography>
          {/* <Typography className="delete-message">
            Please input STAFF to delete!
          </Typography>
          <TextField
            fullWidth
            size="small"
            placeholder="input here"
            onChange={(e) => setConfirmVal(e.target.value)}
          /> */}
        </Stack>
      </DialogContent>

      <DialogActions>
        <Stack direction="row" spacing={4} className="action-container">
          <Button className="btn-cencel" fullWidth onClick={handleClose}>
            Cencel
          </Button>

          {loading ? (
            <Button className="btn-delete" fullWidth>
              Loading...
            </Button>
          ) : (
            <Button
              // disabled={confirmVal === "STAFF" ? false : true}
              className="btn-delete"
              fullWidth
              onClick={handleDelete}
            >
              Delete
            </Button>
          )}
        </Stack>
      </DialogActions>
    </Dialog>
  );
}
