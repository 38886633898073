import React, { useContext } from "react";
import "./topnavbar.scss";
import {
  Avatar,
  IconButton,
  Stack,
  Toolbar,
  Box,
  Typography,
  Menu,
  MenuItem,
  Divider,
  ListItemIcon,
  Drawer,
  Button,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import Logout from "@mui/icons-material/Logout";
import LockResetIcon from '@mui/icons-material/LockReset';
import MenuIcon from "@mui/icons-material/Menu";
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
//Src
import { AuthContext } from "../Context/AuthContext";
import MenuMobile from "./MenuMobile";

export default function TopNavbar({ handleDrawerOpen }) {
  const { setAlert } = useContext(AuthContext);
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const drawerWidth = 250;

  const [state, setState] = React.useState({ left: false });

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const openMenu = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  //get userLoging from local
  let userLogin = JSON.parse(window.localStorage.getItem("userLogin"));
  // console.log("userLogin::", userLogin)

  //My Account function
  const handleMyProfile = () => {
    setTimeout(() => {
      navigate(`/staff-info/staff-details?staffId=${userLogin?._id}`);
    }, 300);
  }

  //Reset password function
  const handleResetPassword = ()=>{
    setTimeout(() => {
      navigate(`/reset-password`);
    }, 300);
  }

  //Logout function
  const handleLogout = () => {
    window.localStorage.removeItem("token");
    window.localStorage.removeItem("userLogin");
    /* 
      Refresh the page to clear userLogin variable(useState) in routes.js file. 
      if we don't refresh page, some varibale or useState still have the old value 
      and make system work wrong for use's role then "page not found" appeared.
    */
    window.location.reload();
    // setTimeout(() => {
    //   navigate("/login");
    // }, 1000);
    setAlert(true, "success", "You are logged out!");
  };

  return (
    <Toolbar className="top-navbar">
      <Stack direction="row" sx={{ width: "100%" }}>
        <Stack direction="column" justifyContent="center">
          <IconButton
            onClick={toggleDrawer("left", true)}
            className="iconbtn-mobile"
          >
            <MenuIcon sx={{ color: "#082137" }} />
          </IconButton>
        </Stack>

        <Drawer
          anchor={"left"}
          open={state["left"]}
          onClose={toggleDrawer("left", false)}
        >
          <Box
            sx={{ width: drawerWidth }}
            role="presentation"
            onKeyDown={toggleDrawer("left", false)}
          >
            <MenuMobile toggleDrawer={toggleDrawer("left", false)} />
          </Box>
        </Drawer>

        <Box sx={{ flexGrow: 1 }} />
        <Button onClick={handleClick} className="user-container">
          <Stack direction="row" spacing={1} justifyContent="right">
            <Stack direction="column" justifyContent="center">
              <Avatar 
                alt={userLogin?.staffProfile?.englishData?.firstNameLatin}
                src={userLogin?.staffProfile?.imageSrc} 
               />
            </Stack>

            <Stack direction="column" justifyContent="center">
              <Typography className="user-name">{userLogin?.staffProfile?.englishData?.firstNameLatin + " " + userLogin?.staffProfile?.englishData?.lastNameLatin}</Typography>
            </Stack>

            <Stack direction="column" justifyContent="center">
              <IconButton>
                {openMenu ? <ExpandLess /> : <ExpandMore />}
              </IconButton>
            </Stack>
          </Stack>
        </Button>

      </Stack>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={openMenu}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            "&:before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <MenuItem onClick={handleMyProfile}>
          <Avatar /> My Profile
        </MenuItem>
        <MenuItem onClick={handleResetPassword}>
          <ListItemIcon>
            <LockResetIcon />
          </ListItemIcon>
          Reset Password
        </MenuItem>
        <Divider />
        <MenuItem onClick={handleLogout}>
          <ListItemIcon>
            <Logout fontSize="small" />
          </ListItemIcon>
          Logout
        </MenuItem>
      </Menu>
    </Toolbar>
  );
}
