import { gql } from "@apollo/client";

export const CREATE_TAKELEAVE = gql`
  mutation CreateTakeLeave($input: TakeLeaveInput) {
    createTakeLeave(input: $input) {
      message
      success
    }
  }
`;

export const GET_TAKELEAVE_TYPE_BY_USERID = gql`
  query Query($userId: ID!) {
    getTakeLeaveTypeByUserId(userId: $userId)
  }
`;

export const GET_PAYMENT_OF_TAKELEAVE_QTY = gql`
  query GetPaymentOfTakeLeaveQty($staffId: ID!, $takeLeaveType: [String!]!) {
    getPaymentOfTakeLeaveQty(staffId: $staffId, takeLeaveType: $takeLeaveType) {
      takeLeaveType
      fromDate
      validUntil
      paidMonths
      lastYearRemainQty
      currentYearQty
      UsedQty
    }
  }
`;

export const GET_TAKELEAVE_WITH_PAGINATION = gql`
  query GetTakeLeaveWithPagination(
    $page: Int
    $limit: Int
    $keyword: String
    $pagination: Boolean
    $userId: String
    $status: [String]
    $requestModifyStatus: [String]
    $adjust: Boolean
  ) {
    getTakeLeaveWithPagination(
      page: $page
      limit: $limit
      keyword: $keyword
      pagination: $pagination
      userId: $userId
      status: $status
      requestModifyStatus: $requestModifyStatus
      adjust: $adjust
    ) {
      data {
        _id
        isAdjust
        totalLeaveAsDay
        startDate
        endDate
        returnDate
        reason
        status
        takeLeaveTypeUsed {
          takeLeaveType
          quantityDayLeave
        }
        createdBy {
          _id
          staffProfile {
            imageName
            imageSrc
            englishData {
              firstNameLatin
              lastNameLatin
            }
            khmerData {
              firstNameKh
              lastNameKh
            }
          }
        }
        requestTo {
          staffProfile {
            imageName
            imageSrc
            englishData {
              firstNameLatin
              lastNameLatin
            }
            khmerData {
              firstNameKh
              lastNameKh
            }
          }
        }
        approvedOrDeniedBy {
          staffProfile {
            englishData {
              firstNameLatin
              lastNameLatin
            }
            khmerData {
              firstNameKh
              lastNameKh
            }
            imageName
            imageSrc
          }
        }
        approvedOrDeniedDate
        paymentOfTakeLeaveQuantity {
          takeLeaveType
          fromDate
          validUntil
          paidMonths
          lastYearRemainQty
          currentYearQty
          UsedQty
        }
        managerReply
        warningMessage
        requestModifyStatus
        requestModify {
          totalLeaveAsDay
          takeLeaveTypeUsed {
            takeLeaveType
            quantityDayLeave
          }
          startDate
          endDate
          returnDate
          reason
          emailMessage
        }
        createdAt
        updatedAt
      }
      paginator {
        slNo
        prev
        next
        perPage
        totalPosts
        totalPages
        currentPage
        hasPrevPage
        hasNextPage
        totalDocs
      }
    }
  }
`;

export const GET_ADJUSTMENT_TAKELEAVE_PAGINATION = gql`
  query GetTakeLeaveWithPagination(
    $page: Int
    $limit: Int
    $keyword: String
    $pagination: Boolean
    $userId: String
    $status: [String]
    $adjust: Boolean
  ) {
    getTakeLeaveWithPagination(
      page: $page
      limit: $limit
      keyword: $keyword
      pagination: $pagination
      userId: $userId
      status: $status
      adjust: $adjust
    ) {
      data {
        _id
        isAdjust
        totalLeaveAsDay
        startDate
        endDate
        returnDate
        reason
        status
        takeLeaveTypeUsed {
          takeLeaveType
          quantityDayLeave
        }
        createdBy {
          _id
          staffProfile {
            imageName
            imageSrc
            englishData {
              firstNameLatin
              lastNameLatin
            }
            khmerData {
              firstNameKh
              lastNameKh
            }
          }
        }
        requestTo {
          staffProfile {
            imageName
            imageSrc
            englishData {
              firstNameLatin
              lastNameLatin
            }
            khmerData {
              firstNameKh
              lastNameKh
            }
          }
        }
        approvedOrDeniedBy {
          staffProfile {
            englishData {
              firstNameLatin
              lastNameLatin
            }
            khmerData {
              firstNameKh
              lastNameKh
            }
            imageName
            imageSrc
          }
        }
        approvedOrDeniedDate
        paymentOfTakeLeaveQuantity {
          takeLeaveType
          fromDate
          validUntil
          paidMonths
          lastYearRemainQty
          currentYearQty
          UsedQty
        }
        managerReply
        warningMessage
        createdAt
        updatedAt
      }
      paginator {
        slNo
        prev
        next
        perPage
        totalPosts
        totalPages
        currentPage
        hasPrevPage
        hasNextPage
        totalDocs
      }
    }
  }
`;

export const UPDATE_TAKELEAVE = gql`
  mutation UpdateTakeLeave($id: ID!, $input: TakeLeaveInput) {
    updateTakeLeave(_id: $id, input: $input) {
      success
      message
    }
  }
`;

export const DELETE_TAKELEAVE = gql`
  mutation DeleteTakeLeave($timeOffId: ID) {
    deleteTakeLeave(timeOffId: $timeOffId) {
      success
      message
    }
  }
`;

export const GET_REMINDER_TAKE_LEAVE = gql`
  query GetReminderTakeLeave($staffId: ID!, $takeLeaveType: String) {
    getReminderTakeLeave(staffId: $staffId, takeLeaveType: $takeLeaveType) {
      remainingPreviousYear
      gotInCurrentYear
      remainingByMonth
      currentYearUsed
      totalRemaining
    }
  }
`;

export const APPROVE_TAKELEAVE = gql`
  mutation ApproveTakeLeave($takeLeaveId: ID!, $userId: ID!) {
    approveTakeLeave(takeLeaveId: $takeLeaveId, userId: $userId) {
      success
      message
    }
  }
`;

export const DENIE_TAKELEAVE = gql`
  mutation DenyTakeLeave($takeLeaveId: ID!, $userId: ID!) {
    denyTakeLeave(takeLeaveId: $takeLeaveId, userId: $userId) {
      success
      message
    }
  }
`;

export const ADJUST_TAKE_LEAVE = gql`
  mutation AdjustTakeLeave($input: TakeLeaveInput) {
  adjustTakeLeave(input: $input) {
    success
    message
  }
}
`;

export const REQUEST_MODIFY_TAKE_LEAVE = gql`
  mutation RequestModifyTakeLeave($id: ID!, $input: ModifyTakeLeaveInput) {
  requestModifyTakeLeave(_id: $id, input: $input) {
    success
    message
  }
}
`;

export const APPROVE_TAKELEAVE_MODIFY = gql`
  mutation ApproveModifyTakeLeave($takeLeaveId: ID!, $userId: ID!, $message: String) {
  approveModifyTakeLeave(takeLeaveId: $takeLeaveId, userId: $userId, message: $message) {
    success
    message
  }
}
`;

export const DENIE_TAKELEAVE_MODIFY = gql`
  mutation DenyModifyTakeLeave($takeLeaveId: ID!, $message: String, $userId: ID!) {
  denyModifyTakeLeave(takeLeaveId: $takeLeaveId, message: $message, userId: $userId) {
    success
    message
  }
}
`;
