import React, { createContext, useContext, useState } from "react";
import "./Style/App.css";
import Router from "./routes";
import { BrowserRouter as Routers } from "react-router-dom";
import { createTheme } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider } from "@mui/material/styles";
import AlertMessage from "./Components/AlertMessage/AlertMessage";
import { setContext } from "@apollo/client/link/context";
import { AuthContext } from "./Context/AuthContext";
import { onError } from "@apollo/client/link/error";
import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  createHttpLink,
  ApolloLink,
} from "@apollo/client";

export const MenuSizeContext = createContext();

function App() {
  const [menuSize, setMenuSize] = useState("large");
  const { setAlert } = useContext(AuthContext);

  const theme = React.useMemo(
    () =>
      createTheme({
        palette: {
          // mode: "light",
          background: {
            default: "#EBEDEF",
          },
        },

        typography: {
          fontFamily: ["Century Gothic", "Siemreap"].join(","),
        },
      }),

    []
  );

  const logoutLink = onError((networkError) => {
    // console.log("hello...", networkError?.response?.errors[0]?.extensions.code);
    if (
      networkError?.response?.errors[0]?.extensions.code ===
      "Invalid/Expired token"
      // || networkError?.response?.errors[0]?.message === "Error"
    ) {
      localStorage.clear();
      window.localStorage.removeItem("token");
    }
  });

  const httpLink = createHttpLink({
    uri: process.env.REACT_APP_END_POINT,
    // uri: "http://localhost:6001/",
  });

  const authLink = setContext((_, { headers }) => {
    const token = localStorage.getItem("token");
    return {
      headers: {
        ...headers,
        authorization: token,
      },
    };
  });

  const client = new ApolloClient({
    link: ApolloLink.from([logoutLink, authLink, httpLink]),
    cache: new InMemoryCache(),
  });

  return (
    <ApolloProvider client={client}>
      <MenuSizeContext.Provider value={[menuSize, setMenuSize]}>
        <ThemeProvider theme={theme}>
          <Routers>
            <Router />
          </Routers>
          <CssBaseline />
        </ThemeProvider>
      </MenuSizeContext.Provider>
      <AlertMessage />
    </ApolloProvider>
  );
}

export default App;
