import * as React from "react";
import "../../Style/actionstyle.scss";
import { IconButton, Typography, Stack } from "@mui/material";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import LockResetIcon from '@mui/icons-material/LockReset';
import BorderColorOutlinedIcon from "@mui/icons-material/BorderColorOutlined";
import DeleteForeverOutlinedIcon from "@mui/icons-material/DeleteForeverOutlined";
//create
import UpdateUser from "./UpdateUser";
import ResetPassword from "./ResetPassword";

export default function UserAction({ editData, setRefetch }) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  //Modal view
  const [openEdit, setOpenEdit] = React.useState(false);
  const handleOpenEdit = () => setOpenEdit(true);
  const handleCloseEdit = () => setOpenEdit(false);

  //Modal delete
  const [openDel, setOpenDel] = React.useState(false);
  const handleOpenDel = () => setOpenDel(true);
  const handleCloseDel = () => setOpenDel(false);

  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <IconButton onClick={handleClick}>
        <MoreVertIcon className="three-point" />
      </IconButton>
      <Menu
        id="basic-button"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
        className="action"
      >
        <MenuItem
          onClick={() => {
            handleOpenEdit();
            handleClose();
          }}
        >
          <Stack direction="row" spacing={1}>
            <BorderColorOutlinedIcon className="update-icon" />
            <Typography>Update</Typography>
          </Stack>
        </MenuItem>

        <MenuItem
          onClick={() => {
            handleOpenDel();
            handleClose();
          }}
        >
          <Stack direction="row" spacing={1}>
            <LockResetIcon className="update-icon"/>
            <Typography>Reset password</Typography>
          </Stack>
        </MenuItem>
      </Menu>

      <UpdateUser
        open={openEdit}
        handleClose={handleCloseEdit}
        editData={editData}
        dialogTitle={"Update"}
        setRefetch={setRefetch}
      />

      <ResetPassword
        open={openDel}
        handleClose={handleCloseDel}
        editData={editData}
        setRefetch={setRefetch}
      />
    </div>
  );
}
