import React, { Fragment, useContext, useEffect, useMemo, useState } from "react";
import "../../Style/createdrawerstyle.scss";
import * as Yup from "yup";
import {
  Grid,
  Stack,
  TextField,
  Typography,
  Button,
  IconButton,
  FormControl,
  Select,
  MenuItem,
} from "@mui/material";
import { useMutation, useQuery } from "@apollo/client";
import DeleteIcon from "@mui/icons-material/Delete";
import { GET_TAKELEAVE_TYPE_BY_USERID } from "../../Schema/TakeLeave";
export default function ListTakeLeaveTypeEntry({
  requiredField,
  setUpdateTakeLeaveType,
  setUpdateQuantityDayLeave,
  takeLeaveTypeEntryList,
  deleteTakeLeaveTypeEntryList,
  userLogin,
}) {
  //   console.log(takeLeaveTypeEntryList, "takeLeaveTypeEntryList");
  const [selectOptions, setSelectOptions] = useState([]);
  const [errorMessage, setErrorMessage] = useState(["Field is required"]);

  const [touched, setTouched] = useState(false);
  const handleTouch = () => setTouched(true);

  const { refetch } = useQuery(GET_TAKELEAVE_TYPE_BY_USERID, {
    variables: {
      userId: userLogin?._id,
    },
    onCompleted: ({ getTakeLeaveTypeByUserId }) => {
      //   console.log("getTakeLeaveTypeByUserId:", getTakeLeaveTypeByUserId);
      setSelectOptions(getTakeLeaveTypeByUserId);
    },
    onError: (error) => {
      console.log(error);
    },
  });

  const Listitems = takeLeaveTypeEntryList?.map((element) => {
    // console.log(element, "element");
    return (
      <Fragment key={element?.key}>
        <Grid item xs={7}>
          <FormControl size="small" className="text-field" fullWidth>
            <Typography className="field-title">Take leave type</Typography>
            <Select
              labelId="demo-select-small"
              id="demo-select-small"
              placeholder="select"
              value={element?.takeLeaveType}
              onChange={(event, val) => {
                setUpdateTakeLeaveType(val.props.value, element?.key);
              }}
              // error={Boolean(
              //     touched.takeLeaveType && errors.takeLeaveType
              // )}
              // helperText={touched.takeLeaveType && errors.takeLeaveType}
            >
              {selectOptions?.map((type) => {
                return (
                  <MenuItem key={type} value={type}>
                    {type}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={5}>
          <Typography className="field-title">Days leave</Typography>
          <Stack direction={"row"}>
            <TextField
              fullWidth
              size="small"
              type="number"
              className="text-field"    
              placeholder="days leave"
              value={element?.quantityDayLeave}
              onChange={(e) =>
                setUpdateQuantityDayLeave(e?.target?.value, element?.key)
              }
            />
            <IconButton
              onClick={() => deleteTakeLeaveTypeEntryList(element?.key)}
            >
              <DeleteIcon style={{ color: "red" }} />
            </IconButton>
          </Stack>
        </Grid>
      </Fragment>
    );
  });

  return (
    <Grid container rowSpacing={2} columnSpacing={3}>
      {Listitems}
    </Grid>
  );
}
