import React, { useState, useEffect } from "react";
import { useRoutes } from "react-router-dom";
import { Navigate } from "react-router-dom";
//layouts
import Layout from "./Layout/Layout";
//Pages
import Dashboard from "./Pages/Dashboard";
import StaffInformation from "./Pages/StaffInformation";
import ActivityLogs from "./Pages/ActivityLogs";
import AlertBirthday from "./Pages/AlertBirthday";
import TakeLeave from "./Pages/TakeLeave";
import OverTime from "./Pages/OverTime";
import ListData from "./Pages/ListData";
import GeneralSetting from "./Pages/GeneralSetting";
import AdjustmentTakeleave from "./Pages/AdjustmentTakeleave";
import Report from "./Pages/Report";
import Positions from "./Pages/Positions";
import Department from "./Pages/Department";
import OfficeBase from "./Pages/OfficeBase";
import WorkingBase from "./Pages/WorkingBase";
import WorkingPlace from "./Pages/WorkingPlace";
import Major from "./Pages/Major";
import Login from "./Pages/Login";
import Page404 from "./Pages/Page404";
import User from "./Pages/Users";
import OtherOperations from "./Pages/OtherOperations";
import StaffResignAndExitInterview from "./Pages/StaffResignAndExitInterview";
//Components
import CreateStaff from "./Components/StaffInformation/CreateStaff";
import UpdateStaff from "./Components/StaffInformation/UpdateStaff";
import StaffDetailsPage from "./Components/StaffInformation/StaffDetailsPage";
import GenerateOtp from "./Components/Login/GenerateOtp";
import VerifyOtp from "./Components/Login/VerifyOtp";
import ResetPassword from "./Components/Login/ResetPassword";
import StaffDetailsUserRole from "./Components/StaffInformation/StaffDetailsUserRole";
import TakeLeaveReport from "./Components/Report/TakeLeaveReport/TakeLeaveReport";
import OvertimeReport from "./Components/Report/OvertimeReport/OvertimeReport";


export default function Router({ prefersDarkMode, setPrefersDarkMode }) {
  //get userLoging from local
  const [userLogin, setUserLogin] = useState(null);
  // console.log("userLoginFirst::", userLogin);
  useEffect(() => {
    if (userLogin === null) {
      const getUserLogin = JSON.parse(window.localStorage.getItem("userLogin"));
      // console.log("getUserLogin::", getUserLogin);
      setUserLogin(getUserLogin);
    }
  });

  // console.log("userLogin::",userLogin);
  // console.log("userLoginRole::",userLogin?.systemAccessData?.role);

  const token = window.localStorage.getItem("token");

  // console.log("token::",token)
  // console.log(userLogin, "role");

  let LoginPage = useRoutes([
    { path: "/", element: <Login /> },
    { path: "/login", element: <Login /> },
    { path: "/forgot-password", element: <GenerateOtp /> },
    { path: "/verifyotp", element: <VerifyOtp /> },
    { path: "/reset-password", element: <ResetPassword /> },
    { path: "*", element: <Login /> },
  ]);

  let ContentAdmin = useRoutes([
    { path: "/reset-password", element: <ResetPassword /> },
    {
      path: "/",
      element: (
        <Layout
          to="/dashboard"
          prefersDarkMode={prefersDarkMode}
          setPrefersDarkMode={setPrefersDarkMode}
        />
      ),
      children: [
        { path: "/", element: <Navigate to="/dashboard" /> },
        { path: "dashboard", element: <Dashboard /> },
        { path: "/staff-info", element: <StaffInformation /> },
        { path: "staff-info-user", element: <StaffDetailsUserRole /> },
        { path: "/staff-info/create-staff", element: <CreateStaff /> },
        { path: "/staff-info/update-staff", element: <UpdateStaff /> },
        {
          path: "/staff-info/staff-details",
          element: <StaffDetailsPage />,
        },
        { path: "alert-birthday", element: <AlertBirthday /> },
        { path: "take-leave", element: <TakeLeave /> },
        { path: "over-time", element: <OverTime /> },
        { path: "/settings/activity-logs", element: <ActivityLogs /> },
        { path: "/settings/list-data", element: <ListData /> },
        { path: "/settings/list-data/positions", element: <Positions /> },
        {
          path: "/settings/list-data/department",
          element: <Department />,
        },
        {
          path: "/settings/list-data/office-base",
          element: <OfficeBase />,
        },
        {
          path: "/settings/list-data/working-base",
          element: <WorkingBase />,
        },
        {
          path: "/settings/list-data/working-place",
          element: <WorkingPlace />,
        },
        { path: "/settings/list-data/major", element: <Major /> },

        { path: "/settings/general", element: <GeneralSetting /> },
        {
          path: "/settings/general/adjust-take-leave",
          element: <AdjustmentTakeleave />,
        },
        { path: "/settings/general/user", element: <User /> },

        { path: "report", element: <Report /> },
        {
          path: "/report/takeleave-report",
          element: <TakeLeaveReport />,
        },
        {
          path: "/report/overtime-report",
          element: <OvertimeReport />,
        },
        {
          path: "/other-operations",
          element: <OtherOperations />
        },
        {
          path: "/other-operations/resign-and-exit-interview",
          element: <StaffResignAndExitInterview />
        },

        { path: "*", element: <Page404 /> },
      ],
    },
  ]);

  let ContentUser = useRoutes([
    { path: "/reset-password", element: <ResetPassword /> },
    {
      path: "/",
      element: (
        <Layout
          to="/dashboard"
          prefersDarkMode={prefersDarkMode}
          setPrefersDarkMode={setPrefersDarkMode}
        />
      ),
      children: [
        { path: "/", element: <Navigate to="/dashboard" /> },
        { path: "dashboard", element: <Dashboard /> },
        { path: "staff-info-user", element: <StaffDetailsUserRole /> },
        { path: "take-leave", element: <TakeLeave /> },
        { path: "over-time", element: <OverTime /> },
        { path: "report", element: <Report /> },
        {
          path: "/report/takeleave-report",
          element: <TakeLeaveReport />,
        },
        {
          path: "/report/overtime-report",
          element: <OvertimeReport />,
        },
        { path: "*", element: <Page404 /> },
      ],
    },
  ]);

  if (token) {
    if (userLogin?.systemAccessData?.role === "Admin") {
      return ContentAdmin;
    } else {
      return ContentUser;
    }
  } else {
    return LoginPage;
  }
}
