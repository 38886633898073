import React from "react";
import "./listdata.scss";
import { Typography, Stack, Box, Button, Grid } from "@mui/material";
import { useNavigate } from "react-router-dom";
//Icons
import { FcDepartment } from "react-icons/fc";
import { GiOfficeChair } from "react-icons/gi";
import { HiBuildingOffice } from "react-icons/hi2";
import { FaBookReader } from "react-icons/fa";

export default function ListData() {
  const navigate = useNavigate();

  return (
    <div className="setting-page">
      <Stack direction="row" sx={{ height: "50px" }}>
        <Stack direction="column" justifyContent="center">
          <Typography className="page-title">List Data</Typography>
        </Stack>
      </Stack>

      <Box className="body-container">
        <Grid container spacing={4}>
          <Grid item xs={12} sm={6} lg={4}>
            <Button
              className="btn-setting"
              onClick={() => navigate("/settings/list-data/department")}
            >
              <Stack direction="row" spacing={2}>
                <Stack direction="column" justifyContent="center">
                  <FcDepartment className="btn-icon" />
                </Stack>

                <Stack direction="column" spacing={1}>
                  <Typography className="btn-setting-title">
                    Department
                  </Typography>
                  <Typography className="btn-setting-des">
                    Create & edit department you want!
                  </Typography>
                </Stack>
              </Stack>
            </Button>
          </Grid>
          <Grid item xs={12} sm={6} lg={4}>
            <Button
              className="btn-setting"
              onClick={() => navigate("/settings/list-data/positions")}
            >
              <Stack direction="row" spacing={2}>
                <Stack direction="column" justifyContent="center">
                  <GiOfficeChair className="btn-icon" />
                </Stack>

                <Stack direction="column" spacing={1}>
                  <Typography className="btn-setting-title">
                    Positions
                  </Typography>
                  <Typography className="btn-setting-des">
                    Create & edit positions you want!
                  </Typography>
                </Stack>
              </Stack>
            </Button>
          </Grid>

          <Grid item xs={12} sm={6} lg={4}>
            <Button
              className="btn-setting"
              onClick={() => navigate("/settings/list-data/office-base")}
            >
              <Stack direction="row" spacing={2}>
                <Stack direction="column" justifyContent="center">
                  <HiBuildingOffice className="btn-icon" />
                </Stack>

                <Stack direction="column" spacing={1}>
                  <Typography className="btn-setting-title">
                    Office Base
                  </Typography>
                  <Typography className="btn-setting-des">
                    Create & edit office base you want!
                  </Typography>
                </Stack>
              </Stack>
            </Button>
          </Grid>
          <Grid item xs={12} sm={6} lg={4}>
            <Button
              className="btn-setting"
              onClick={() => navigate("/settings/list-data/working-base")}
            >
              <Stack direction="row" spacing={2}>
                <Stack direction="column" justifyContent="center">
                  <FcDepartment className="btn-icon" />
                </Stack>

                <Stack direction="column" spacing={1}>
                  <Typography className="btn-setting-title">
                    Working Base
                  </Typography>
                  <Typography className="btn-setting-des">
                    Create & edit working base you want!
                  </Typography>
                </Stack>
              </Stack>
            </Button>
          </Grid>
          <Grid item xs={12} sm={6} lg={4}>
            <Button
              className="btn-setting"
              onClick={() => navigate("/settings/list-data/working-place")}
            >
              <Stack direction="row" spacing={2}>
                <Stack direction="column" justifyContent="center">
                  <FcDepartment className="btn-icon" />
                </Stack>

                <Stack direction="column" spacing={1}>
                  <Typography className="btn-setting-title">
                    Working Place
                  </Typography>
                  <Typography className="btn-setting-des">
                    Create & edit working place you want!
                  </Typography>
                </Stack>
              </Stack>
            </Button>
          </Grid>
          <Grid item xs={12} sm={6} lg={4}>
            <Button
              className="btn-setting"
              onClick={() => navigate("/settings/list-data/major")}
            >
              <Stack direction="row" spacing={2}>
                <Stack direction="column" justifyContent="center">
                  <FaBookReader className="btn-icon" />
                </Stack>

                <Stack direction="column" spacing={1}>
                  <Typography className="btn-setting-title">Major</Typography>
                  <Typography className="btn-setting-des">
                    Create & edit major you want!
                  </Typography>
                </Stack>
              </Stack>
            </Button>
          </Grid>
        </Grid>
      </Box>
    </div>
  );
}
