import React, { useState } from "react";
import "./menumobile.scss";
import {
  List,
  ListItem,
  ListItemButton,
  Button,
  Collapse,
  Typography,
  Stack,
  Box,
} from "@mui/material";
import MuiDrawer from "@mui/material/Drawer";
import { styled } from "@mui/material/styles";
import { useLocation, useNavigate } from "react-router-dom";
//icons
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import { AiOutlineDashboard, AiOutlineSetting } from "react-icons/ai";
import { MdMoreTime } from "react-icons/md";
import { SlPeople } from "react-icons/sl";
import { TbCalendarTime, TbReportAnalytics } from "react-icons/tb";
import { MdOutlineAdminPanelSettings } from "react-icons/md";
import { BsCardList } from "react-icons/bs";
import { HiOutlineAdjustmentsHorizontal } from "react-icons/hi2";
//src
import pepyWhiteLogo from "../Assets/pypy-white-logo.svg";

const drawerWidth = 250;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  height: "100%",
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

export default function MenuMobile({ toggleDrawer }) {
  let location = useLocation();
  const navigate = useNavigate();

  const [openList, setOpenList] = useState(false);
  const handleOpenRequest = () => {
    setOpenList(!openList);
    setOpenSetting(false);
  };

  const [openSetting, setOpenSetting] = useState(false);
  const handleOpenSetting = () => {
    setOpenSetting(!openSetting);
    setOpenList(false);
  };

  const handleCloseSubMenu = () => {
    setOpenList(false);
    setOpenSetting(false);
    toggleDrawer("left", false);
  };

  const [userLogin, setUserLogin] = useState(
    JSON.parse(window.localStorage.getItem("userLogin"))
  );
  // console.log("userLogin::", userLogin)

  return (
    <Drawer variant="permanent" open={true} className="menu-mobile">
      <Stack direction="row" justifyContent="center" sx={{ m: 1 }}>
        <Button className="image-container" onClick={() => navigate("/")}>
          <img src={pepyWhiteLogo} className="logo" />
        </Button>
      </Stack>
      {userLogin?.systemAccessData?.role === "Admin" ? (
        <List className="list-menu">
          {/* ============================== page dashboard ========================== */}
          <ListItem
            className={
              location.pathname === "/dashboard"
                ? "list-item-active"
                : "list-item"
            }
            disablePadding
            onClick={() => {
              navigate("/");
              handleCloseSubMenu();
            }}
          >
            <ListItemButton className="list-item-button">
              <AiOutlineDashboard className="icon" />
              <Typography className="list-item-text">Dashboard</Typography>
            </ListItemButton>
          </ListItem>

          {/* ============================== page staff infromation ========================== */}
          <ListItem
            className={
              location.pathname === "/staff-info" ||
              location.pathname === "/staff-info/create-staff" ||
              location.pathname === "/staff-info/update-staff" ||
              location.pathname === "/staff-info/staff-details"
                ? "list-item-active"
                : "list-item"
            }
            disablePadding
            onClick={() => {
              navigate("/staff-info");
              handleCloseSubMenu();
            }}
          >
            <ListItemButton className="list-item-button">
              <SlPeople className="icon" />
              <Typography className="list-item-text">
                Staff Information
              </Typography>
            </ListItemButton>
          </ListItem>

          {/* ============================== page take leave ========================== */}
          <ListItem
            className={"list-item"}
            disablePadding
            onClick={handleOpenRequest}
          >
            <ListItemButton className="list-item-button">
              <TbCalendarTime className="icon" />
              <Typography className="list-item-text">Request</Typography>
              <Box sx={{ flexGrow: 1 }} />
              {openList ? (
                <ExpandLess sx={{ color: "#fff" }} />
              ) : (
                <ExpandMore sx={{ color: "#fff" }} />
              )}
            </ListItemButton>
          </ListItem>

          <Collapse in={openList} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <ListItem
                className={
                  location.pathname === "/take-leave"
                    ? "list-item-active"
                    : "list-item"
                }
                disablePadding
                onClick={() => {
                  navigate("/take-leave");
                  toggleDrawer("left", false);
                }}
              >
                <ListItemButton className="list-item-button">
                  <TbCalendarTime className="icon-submenu" />
                  <Typography className="list-item-text">Take Leave</Typography>
                </ListItemButton>
              </ListItem>
              <ListItem
                className={
                  location.pathname === "/over-time"
                    ? "list-item-active"
                    : "list-item"
                }
                disablePadding
                onClick={() => {
                  navigate("/over-time");
                  toggleDrawer("left", false);
                }}
              >
                <ListItemButton className="list-item-button">
                  <MdMoreTime className="icon-submenu" />
                  <Typography className="list-item-text">Over Time</Typography>
                </ListItemButton>
              </ListItem>
            </List>
          </Collapse>

          {/* ============================== page report ========================== */}
          <ListItem
            className={
              location.pathname === "/report" ||
              location.pathname === "/report/takeleave-report" ||
              location.pathname === "/report/overtime-report"
                ? "list-item-active"
                : "list-item"
            }
            disablePadding
            onClick={() => {
              navigate("/report");
              handleCloseSubMenu();
            }}
          >
            <ListItemButton className="list-item-button">
              <TbReportAnalytics className="icon" />
              <Typography className="list-item-text">Report</Typography>
            </ListItemButton>
          </ListItem>

          {/* ============================== page setting ========================== */}
          <ListItem
            className={"list-item"}
            disablePadding
            onClick={handleOpenSetting}
          >
            <ListItemButton className="list-item-button">
              <AiOutlineSetting className="icon" />
              <Typography className="list-item-text">Setting</Typography>
              <Box sx={{ flexGrow: 1 }} />
              {openSetting ? (
                <ExpandLess sx={{ color: "#fff" }} />
              ) : (
                <ExpandMore sx={{ color: "#fff" }} />
              )}
            </ListItemButton>
          </ListItem>

          <Collapse in={openSetting} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <ListItem
                className={
                  location.pathname === "/settings/list-data" ||
                  location.pathname === "/settings/list-data/positions" ||
                  location.pathname === "/settings/list-data/department" ||
                  location.pathname === "/settings/list-data/office-base" ||
                  location.pathname === "/settings/list-data/working-base" ||
                  location.pathname === "/settings/list-data/working-place" ||
                  location.pathname === "/settings/list-data/major"
                    ? "list-item-active"
                    : "list-item"
                }
                disablePadding
                onClick={() => {
                  navigate("/settings/list-data");
                  toggleDrawer("left", false);
                }}
              >
                <ListItemButton className="list-item-button">
                  <BsCardList className="icon-submenu" />
                  <Typography className="list-item-text">List Data</Typography>
                </ListItemButton>
              </ListItem>
              <ListItem
                className={
                  location.pathname === "/settings/general" ||
                  location.pathname === "/settings/general/adjust-take-leave" ||
                  location.pathname === "/settings/general/user"
                    ? "list-item-active"
                    : "list-item"
                }
                disablePadding
                onClick={() => {
                  navigate("/settings/general");
                  toggleDrawer("left", false);
                }}
              >
                <ListItemButton className="list-item-button">
                  <HiOutlineAdjustmentsHorizontal className="icon-submenu" />
                  <Typography className="list-item-text">General</Typography>
                </ListItemButton>
              </ListItem>
              <ListItem
                className={
                  location.pathname === "/settings/activity-logs"
                    ? "list-item-active"
                    : "list-item"
                }
                disablePadding
                onClick={() => {
                  navigate("/settings/activity-logs");
                  toggleDrawer("left", false);
                }}
              >
                <ListItemButton className="list-item-button">
                  <MdOutlineAdminPanelSettings className="icon-submenu" />
                  <Typography className="list-item-text">
                    Activity Logs
                  </Typography>
                </ListItemButton>
              </ListItem>
            </List>
          </Collapse>
        </List>
      ) : (
        <List className="list-menu">
          {/* ============================== page dashboard ========================== */}
          <ListItem
            className={
              location.pathname === "/dashboard"
                ? "list-item-active"
                : "list-item"
            }
            disablePadding
            onClick={() => {
              navigate("/");
              handleCloseSubMenu();
            }}
          >
            <ListItemButton className="list-item-button">
              <AiOutlineDashboard className="icon" />
              <Typography className="list-item-text">Dashboard</Typography>
            </ListItemButton>
          </ListItem>

          {/* ============================== page staff infromation ========================== */}
          <ListItem
            className={
              location.pathname === "/staff-info-user"
                ? "list-item-active"
                : "list-item"
            }
            disablePadding
            onClick={() => {
              navigate("/staff-info-user");
              handleCloseSubMenu();
            }}
          >
            <ListItemButton className="list-item-button">
              <SlPeople className="icon" />
              <Typography className="list-item-text">
                Staff Information
              </Typography>
            </ListItemButton>
          </ListItem>

          {/* ============================== page take leave ========================== */}
          <ListItem
            className={"list-item"}
            disablePadding
            onClick={handleOpenRequest}
          >
            <ListItemButton className="list-item-button">
              <TbCalendarTime className="icon" />
              <Typography className="list-item-text">Request</Typography>
              <Box sx={{ flexGrow: 1 }} />
              {openList ? (
                <ExpandLess sx={{ color: "#fff" }} />
              ) : (
                <ExpandMore sx={{ color: "#fff" }} />
              )}
            </ListItemButton>
          </ListItem>

          <Collapse in={openList} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <ListItem
                className={
                  location.pathname === "/take-leave"
                    ? "list-item-active"
                    : "list-item"
                }
                disablePadding
                onClick={() => {
                  navigate("/take-leave");
                  toggleDrawer("left", false);
                }}
              >
                <ListItemButton className="list-item-button">
                  <TbCalendarTime className="icon-submenu" />
                  <Typography className="list-item-text">Take Leave</Typography>
                </ListItemButton>
              </ListItem>
              <ListItem
                className={
                  location.pathname === "/over-time"
                    ? "list-item-active"
                    : "list-item"
                }
                disablePadding
                onClick={() => {
                  navigate("/over-time");
                  toggleDrawer("left", false);
                }}
              >
                <ListItemButton className="list-item-button">
                  <MdMoreTime className="icon-submenu" />
                  <Typography className="list-item-text">Over Time</Typography>
                </ListItemButton>
              </ListItem>
            </List>
          </Collapse>

          {/* ============================== page report ========================== */}
          <ListItem
            className={
              location.pathname === "/report" ||
              location.pathname === "/report/takeleave-report" ||
              location.pathname === "/report/overtime-report"
                ? "list-item-active"
                : "list-item"
            }
            disablePadding
            onClick={() => {
              navigate("/report");
              handleCloseSubMenu();
            }}
          >
            <ListItemButton className="list-item-button">
              <TbReportAnalytics className="icon" />
              <Typography className="list-item-text">Report</Typography>
            </ListItemButton>
          </ListItem>
        </List>
      )}

      <Box sx={{ flexGrow: 1 }}></Box>
      <Stack direction={"column"} spacing={1} style={{ paddingBottom: "15px" }}>
        <Typography className="powered-by">Powered By:</Typography>
        <Typography className="powered-by">Sipou & Chaktokrong </Typography>
      </Stack>
    </Drawer>
  );
}
