import React, { useContext, useEffect, useState } from "react";
import "../../Style/createstyle.scss";
import { useFormik, Form, FormikProvider } from "formik";
import * as Yup from "yup";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import {
  Grid,
  Stack,
  Box,
  TextField,
  Typography,
  Button,
  FormControl,
  Select,
  MenuItem,
} from "@mui/material";
import { useMutation } from "@apollo/client";
import moment from "moment";
//Icons
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
//Src
import { AuthContext } from "../../Context/AuthContext";
import { UPDATE_USER } from "../../Schema/Users";

export default function CreateWorkingBase({
  open,
  handleClose,
  dialogTitle,
  editData,
  setRefetch,
}) {
  const { setAlert } = useContext(AuthContext);
  const [loading, setLoading] = useState(false);

  const [updateUser] = useMutation(UPDATE_USER, {
    onCompleted: ({ updateUser }) => {
      console.log(updateUser, "updateUser");
      setLoading(false);
      if (updateUser?.success === true) {
        setAlert(true, "success", updateUser?.message);
        handleClose();
        setRefetch();
      } else {
        setAlert(true, "error", updateUser?.message);
      }
    },
    onError: (error) => {
      setLoading(false);
      setAlert(true, "error", error?.message);
    },
  });

  const CreateStaff = Yup.object().shape({
    emailAddress: Yup.string().email("Invalid email!").required("required!"),
    role: Yup.string().required("require!"),
    currentSituation: Yup.string().required("require!"),
  });

  const formik = useFormik({
    initialValues: {
      firstNameLatin: "",
      lastNameLatin: "",
      emailAddress: "",
      role: "User",
      currentSituation: "Working",
    },

    validationSchema: CreateStaff,
    onSubmit: async (values) => {
      // console.log("values:::", values);
      // console.log("dialogTitle:::", dialogTitle);
      setLoading(true);
      updateUser({
        variables: {
          id: editData?._id,
          input: {
            systemAccessData: {
              emailAddress: values?.emailAddress,
              role: values?.role,
              isActive: values?.isActive,
            },
            currentSituation: values?.currentSituation,
          },
        },
      });
    },
  });

  const {
    errors,
    touched,
    handleSubmit,
    getFieldProps,
    setFieldValue,
    values,
    resetForm,
  } = formik;

  // console.log("editData", editData?._id)

  useEffect(() => {
    if (editData) {
      setFieldValue(
        "firstNameLatin",
        editData?.staffProfile?.englishData?.firstNameLatin
      );
      setFieldValue(
        "lastNameLatin",
        editData?.staffProfile?.englishData?.lastNameLatin
      );
      setFieldValue("emailAddress", editData?.systemAccessData?.emailAddress);
      setFieldValue("role", editData?.systemAccessData?.role);
      setFieldValue("isActive", editData?.systemAccessData?.isActive);
      setFieldValue("currentSituation", editData?.currentSituation);
    }
  }, [editData]);

  return (
    <Dialog open={open} className="create-container">
      <DialogTitle sx={{ padding: "10px 20px" }}>
        <Stack direction="row" spacing={2}>
          <Stack direction="column" justifyContent="center">
            <Typography className="dialog-title">{dialogTitle} User</Typography>
          </Stack>

          <Box sx={{ flexGrow: 1 }} />
          <IconButton onClick={handleClose}>
            <CloseIcon className="close-icon" />
          </IconButton>
        </Stack>
      </DialogTitle>

      <DialogContent dividers>
        <FormikProvider value={formik}>
          <Form noValidate autoComplete="off" onSubmit={handleSubmit}>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <Typography className="field-title">First name</Typography>
                <TextField
                  disabled
                  fullWidth
                  size="small"
                  className="text-field"
                  placeholder="Email"
                  {...getFieldProps("firstNameLatin")}
                  error={Boolean(
                    touched.firstNameLatin && errors.firstNameLatin
                  )}
                  helperText={touched.firstNameLatin && errors.firstNameLatin}
                />
              </Grid>
              <Grid item xs={6}>
                <Typography className="field-title">Last name</Typography>
                <TextField
                  disabled
                  fullWidth
                  size="small"
                  className="text-field"
                  placeholder="Email"
                  {...getFieldProps("lastNameLatin")}
                  error={Boolean(touched.lastNameLatin && errors.lastNameLatin)}
                  helperText={touched.lastNameLatin && errors.lastNameLatin}
                />
              </Grid>
              <Grid item xs={12}>
                <Typography className="field-title">Email</Typography>
                <TextField
                  fullWidth
                  size="small"
                  className="text-field"
                  placeholder="Email"
                  {...getFieldProps("emailAddress")}
                  error={Boolean(touched.emailAddress && errors.emailAddress)}
                  helperText={touched.emailAddress && errors.emailAddress}
                />
              </Grid>

              <Grid item xs={6}>
                <Typography className="body-text">Role</Typography>
                <FormControl size="small" className="text-field" fullWidth>
                  <Select
                    labelId="demo-select-small"
                    id="demo-select-small"
                    value={values?.role}
                    onChange={(event) => {
                      setFieldValue("role", event.target.value);
                    }}
                    error={Boolean(touched.role && errors.role)}
                    helperText={touched.role && errors.role}
                  >
                    <MenuItem value={"User"}>User</MenuItem>
                    <MenuItem value={"Admin"}>Admin</MenuItem>
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={6}>
                <Typography className="body-text">Current Situation</Typography>
                <FormControl size="small" className="text-field" fullWidth>
                  <Select
                    labelId="demo-select-small"
                    id="demo-select-small"
                    value={values?.currentSituation}
                    onChange={(event) => {
                      setFieldValue("currentSituation", event.target.value);
                    }}
                    error={Boolean(
                      touched.currentSituation && errors.currentSituation
                    )}
                    helperText={
                      touched.currentSituation && errors.currentSituation
                    }
                  >
                    <MenuItem value={"Working"}>Working</MenuItem>
                    <MenuItem value={"Stop"}>Stop</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          </Form>
        </FormikProvider>
      </DialogContent>

      <DialogActions>
        <Stack direction="row" spacing={4} className="action-container">
          <Button className="btn-cencel" fullWidth onClick={handleClose}>
            Cencel
          </Button>

          {loading ? (
            <Button className="btn-create" fullWidth>
              Loading...
            </Button>
          ) : (
            <Button className="btn-create" fullWidth onClick={handleSubmit}>
              {dialogTitle}
            </Button>
          )}
        </Stack>
      </DialogActions>
    </Dialog>
  );
}
