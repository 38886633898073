import React, { useState, useContext, useEffect } from "react";
import "./staffdetailspage.scss";
import { Typography, Stack, Box, Button, Grid } from "@mui/material";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useQuery } from "@apollo/client";
//src
import LoadingPage from "../../Components/Include/LoadingPage";
import { AuthContext } from "../../Context/AuthContext";
import { GET_STAFFINFO_BY_ID } from "../../Schema/StaffInformation";
import StaffInformation from "./StaffInformation";
import Contract from "./Contract/Contract";
import Education from "./Education/Education";
import DriverLicense from "./DriverLicense/DriverLicense";
import CovidVaccination from "./CovidVaccination/CovidVaccination";
import Document from "./Document/Document";
import WorkHistory from "./WorkHistory/WorkHistory";
import PrintStaffInfo from "./PrintStaffInfo";

export default function StaffDetailsPage() {
  const { setAlert } = useContext(AuthContext);
  const [loading, setLoading] = useState(true);
  let navigate = useNavigate();

  const [openPrint, setOpenPrint] = useState(false);
  const handleOpenPrint = () => {
    setOpenPrint(true);
  };
  const handleClosePrint = () => {
    setOpenPrint(false);
  };

  let tabsNameFromLocal = window.localStorage.getItem("tabsName");
  const [tabsName, setTabsName] = useState(
    tabsNameFromLocal ? tabsNameFromLocal : "StaffInfo"
  );

  const [userLogin, setUserLogin] = useState(
    JSON.parse(window.localStorage.getItem("userLogin"))
  );

  //get Storage Room ID by Url
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const [staffId, setStaffId] = useState(params.get("staffId"));
  const [staffByIdData, setStaffByIdData] = useState({});

  useEffect(() => {
    setStaffId(params.get("staffId"));
  }, [location.search]);

  const { data, refetch } = useQuery(GET_STAFFINFO_BY_ID, {
    variables: {
      id: staffId,
    },
    onCompleted: ({ getStaffInfoById }) => {
      setStaffByIdData(getStaffInfoById);
      setLoading(false);
      // console.log(getStaffInfoById);
    },
    onError: (error) => {
      setAlert(true, "error", error?.message);
      // console.log(error)
    },
  });

  const handleStaffInfo = () => {
    setTabsName("StaffInfo");
    window.localStorage.setItem("tabsName", "StaffInfo");
  };

  const handleStaffContract = () => {
    setTabsName("StaffContract");
    window.localStorage.setItem("tabsName", "StaffContract");
  };

  const handleStaffEducation = () => {
    setTabsName("StaffEducation");
    window.localStorage.setItem("tabsName", "StaffEducation");
  };

  const handleStaffDriver = () => {
    setTabsName("StaffDriver");
    window.localStorage.setItem("tabsName", "StaffDriver");
  };

  const handleStaffCovid = () => {
    setTabsName("StaffCovid");
    window.localStorage.setItem("tabsName", "StaffCovid");
  };

  const handleWorkHistory = () => {
    setTabsName("WorkHistory");
    window.localStorage.setItem("tabsName", "WorkHistory");
  };

  const handleDocument = () => {
    setTabsName("Document");
    window.localStorage.setItem("tabsName", "Document");
  };

  return (
    <div className="staff-details">
      <Stack direction="row" spacing={2} sx={{ height: "50px" }}>
        <Stack direction="column" justifyContent="center">
          <Link className="link" to="/staff-info">
            Staff Information
          </Link>
        </Stack>
        <Stack direction="column" justifyContent="center">
          <Typography className="page-title">/</Typography>
        </Stack>
        <Stack direction="column" justifyContent="center">
          <Typography className="page-title">
            {staffByIdData?.staffProfile?.englishData?.firstNameLatin
              ? staffByIdData?.staffProfile?.englishData?.firstNameLatin +
                " " +
                staffByIdData?.staffProfile?.englishData?.lastNameLatin
              : "Loading..."}
          </Typography>
        </Stack>
        <Box sx={{ flexGrow: 1 }} />

        <Stack direction="row" spacing={1}>
          <Button className="btn-update" onClick={handleOpenPrint}>
            Print
          </Button>
          <PrintStaffInfo
            open={openPrint}
            handleClose={handleClosePrint}
            staffByIdData={staffByIdData}
          />
          <Button
            className="btn-update"
            onClick={() =>
              navigate(`/staff-info/update-staff?staffId=${staffId}`)
            }
          >
            Update
          </Button>
        </Stack>
      </Stack>

      <Box sx={{ marginTop: "20px" }}>
        <Grid container rowSpacing={1} columnSpacing={1}>
          <Grid item xs={6} lg={1.7} xl={1.7}>
            <Button
              className={
                tabsName === "StaffInfo" ? "btn-details-active" : "btn-details"
              }
              onClick={handleStaffInfo}
            >
              Staff Profile
            </Button>
          </Grid>
          <Grid item xs={6} lg={1.7} xl={1.7}>
            <Button
              className={
                tabsName === "StaffContract"
                  ? "btn-details-active"
                  : "btn-details"
              }
              onClick={handleStaffContract}
            >
              Contracts
            </Button>
          </Grid>
          <Grid item xs={6} lg={1.7} xl={1.7}>
            <Button
              className={
                tabsName === "StaffEducation"
                  ? "btn-details-active"
                  : "btn-details"
              }
              onClick={handleStaffEducation}
            >
              Educations
            </Button>
          </Grid>
          <Grid item xs={6} lg={1.7} xl={1.7}>
            <Button
              className={
                tabsName === "StaffDriver"
                  ? "btn-details-active"
                  : "btn-details"
              }
              onClick={handleStaffDriver}
            >
              Driver License
            </Button>
          </Grid>
          <Grid item xs={6} lg={1.7} xl={1.7}>
            <Button
              className={
                tabsName === "StaffCovid" ? "btn-details-active" : "btn-details"
              }
              onClick={handleStaffCovid}
            >
              Covid Vaccination
            </Button>
          </Grid>
          <Grid item xs={6} lg={1.7} xl={1.7}>
            <Button
              className={
                tabsName === "WorkHistory"
                  ? "btn-details-active"
                  : "btn-details"
              }
              onClick={handleWorkHistory}
            >
              Work History
            </Button>
          </Grid>
          <Grid item xs={6} lg={1.7} xl={1.7}>
            <Button
              className={
                tabsName === "Document" ? "btn-details-active" : "btn-details"
              }
              onClick={handleDocument}
            >
              Ref documents
            </Button>
          </Grid>
        </Grid>
      </Box>

      {loading ? (
        <Box sx={{ marginTop: "20px" }}>
          <LoadingPage />
        </Box>
      ) : (
        <Box sx={{ marginTop: "20px" }}>
          {tabsName === "StaffInfo" ? (
            <StaffInformation editData={staffByIdData} staffId={staffId} />
          ) : tabsName === "StaffContract" ? (
            <Contract userLogin={userLogin} staffId={staffId} />
          ) : tabsName === "StaffEducation" ? (
            <Education userLogin={userLogin} staffId={staffId} />
          ) : tabsName === "StaffDriver" ? (
            <DriverLicense userLogin={userLogin} staffId={staffId} />
          ) : tabsName === "StaffCovid" ? (
            <CovidVaccination userLogin={userLogin} staffId={staffId} />
          ) : tabsName === "WorkHistory" ? (
            <WorkHistory userLogin={userLogin} staffId={staffId} />
          ) : (
            <Document userLogin={userLogin} staffId={staffId} />
          )}
        </Box>
      )}
    </div>
  );
}
