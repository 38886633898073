import React, { useState, useContext, useEffect } from "react";
import "./menuweb.scss";
import {
  List,
  ListItem,
  ListItemButton,
  Typography,
  Box,
  Button,
  Collapse,
  Drawer,
  Stack,
  IconButton,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { useLocation, useNavigate } from "react-router-dom";
//icons
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import { AiOutlineDashboard, AiOutlineSetting } from "react-icons/ai";
import { MdMoreTime } from "react-icons/md";
import { SlPeople } from "react-icons/sl";
import { TbCalendarTime, TbReportAnalytics } from "react-icons/tb";
import { MdOutlineAdminPanelSettings } from "react-icons/md";
import { BsCardList } from "react-icons/bs";
import { HiOutlineAdjustmentsHorizontal } from "react-icons/hi2";
import ArrowCircleLeftOutlinedIcon from "@mui/icons-material/ArrowCircleLeftOutlined";
//src
import pepyWhiteLogo from "../Assets/pypy-white-logo.svg";
import { MenuSizeContext } from "../App";

const drawerWidth = 260;
const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: "flex-end",
}));
export default function MenuWeb() {
  //get userLoging from local
  const [userLogin, setUserLogin] = useState(null);
  useEffect(() => {
    if (userLogin === null) {
      const getUserLogin = JSON.parse(window.localStorage.getItem("userLogin"));
      setUserLogin(getUserLogin);
    }
  });
  // console.log("Role::",userLogin?.systemAccessData?.role);
  let location = useLocation();

  const navigate = useNavigate();

  const [menuSize, setMenuSize] = useContext(MenuSizeContext);
  const handleMenu = () => {
    setMenuSize("small");
    // console.log('large')
  };

  const [openList, setOpenList] = useState(false);
  const handleOpenRequest = () => {
    setOpenList(!openList);
    setOpenSetting(false);
  };

  const [openSetting, setOpenSetting] = useState(false);
  const handleOpenSetting = () => {
    setOpenSetting(!openSetting);
    setOpenList(false);
  };

  const handleCloseSubMenu = () => {
    setOpenSetting(false);
    setOpenList(false);
  };

  // const [userLogin, setUserLogin] = useState(
  //   JSON.parse(window.localStorage.getItem("userLogin"))
  // );
  // console.log("userLogin::", userLogin)

  return (
    <Drawer
      className="menu-web"
      sx={{
        width: drawerWidth,
        flexShrink: 0,
        "& .MuiDrawer-paper": {
          width: drawerWidth,
          boxSizing: "border-box",
        },
      }}
      variant="persistent"
      anchor="left"
      open={true}
    >
      <DrawerHeader className="drawer-header">
        <Button
          onClick={() => navigate("/dashboard")}
          className="image-container"
        >
          <img src={pepyWhiteLogo} className="logo" />
        </Button>
      </DrawerHeader>

      {userLogin?.systemAccessData?.role === "Admin" ? (
        <List className="list-menu">
          {/* ============================== page dashboard ========================== */}
          <ListItem
            className={
              location.pathname === "/dashboard"
                ? "list-item-active"
                : "list-item"
            }
            disablePadding
            onClick={() => {
              navigate("/");
              handleCloseSubMenu();
            }}
          >
            <ListItemButton className="list-item-button">
              <AiOutlineDashboard className="icon" />
              <Typography className="list-item-text">Dashboard</Typography>
            </ListItemButton>
          </ListItem>

          {/* ============================== page staff infromation ========================== */}
          <ListItem
            className={
              location.pathname === "/staff-info" ||
              location.pathname === "/staff-info/create-staff" ||
              location.pathname === "/staff-info/update-staff" ||
              location.pathname === "/staff-info/staff-details"
                ? "list-item-active"
                : "list-item"
            }
            disablePadding
            onClick={() => {
              navigate("/staff-info");
              handleCloseSubMenu();
            }}
          >
            <ListItemButton className="list-item-button">
              <SlPeople className="icon" />
              <Typography className="list-item-text">
                Staff Information
              </Typography>
            </ListItemButton>
          </ListItem>

          {/* ============================== page take leave ========================== */}
          <ListItem
            className={"list-item"}
            disablePadding
            onClick={handleOpenRequest}
          >
            <ListItemButton className="list-item-button">
              <TbCalendarTime className="icon" />
              <Typography className="list-item-text">Request</Typography>
              <Box sx={{ flexGrow: 1 }} />
              {openList ? (
                <ExpandLess sx={{ color: "#fff" }} />
              ) : (
                <ExpandMore sx={{ color: "#fff" }} />
              )}
            </ListItemButton>
          </ListItem>

          <Collapse in={openList} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <ListItem
                className={
                  location.pathname === "/take-leave"
                    ? "list-item-active"
                    : "list-item"
                }
                disablePadding
                onClick={() => navigate("/take-leave")}
              >
                <ListItemButton className="list-item-button">
                  <TbCalendarTime className="icon-submenu" />
                  <Typography className="list-item-text">Take Leave</Typography>
                </ListItemButton>
              </ListItem>
              <ListItem
                className={
                  location.pathname === "/over-time"
                    ? "list-item-active"
                    : "list-item"
                }
                disablePadding
                onClick={() => navigate("/over-time")}
              >
                <ListItemButton className="list-item-button">
                  <MdMoreTime className="icon-submenu" />
                  <Typography className="list-item-text">Over Time</Typography>
                </ListItemButton>
              </ListItem>
            </List>
          </Collapse>

          {/* ============================== page report ========================== */}
          <ListItem
            className={
              location.pathname === "/report" ||
              location.pathname === "/report/takeleave-report" ||
              location.pathname === "/report/overtime-report"
                ? "list-item-active"
                : "list-item"
            }
            disablePadding
            onClick={() => {
              navigate("/report");
              handleCloseSubMenu();
            }}
          >
            <ListItemButton className="list-item-button">
              <TbReportAnalytics className="icon" />
              <Typography className="list-item-text">Report</Typography>
            </ListItemButton>
          </ListItem>
          {/* ============================== Other Operations ========================== */}
          <ListItem
            className={
              location.pathname === "/other-operations" ||
              location.pathname === "/other-operations/resign-and-exit-interview" 
                ? "list-item-active"
                : "list-item"
            }
            disablePadding
            onClick={() => {
              navigate("/other-operations");
              handleCloseSubMenu();
            }}
          >
            <ListItemButton className="list-item-button">
              <TbReportAnalytics className="icon" />
              <Typography className="list-item-text">Other Operations</Typography>
            </ListItemButton>
          </ListItem>

          {/* ============================== page setting ========================== */}
          <ListItem
            className={"list-item"}
            disablePadding
            onClick={handleOpenSetting}
          >
            <ListItemButton className="list-item-button">
              <AiOutlineSetting className="icon" />
              <Typography className="list-item-text">Setting</Typography>
              <Box sx={{ flexGrow: 1 }} />
              {openSetting ? (
                <ExpandLess sx={{ color: "#fff" }} />
              ) : (
                <ExpandMore sx={{ color: "#fff" }} />
              )}
            </ListItemButton>
          </ListItem>

          <Collapse in={openSetting} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <ListItem
                className={
                  location.pathname === "/settings/list-data" ||
                  location.pathname === "/settings/list-data/positions" ||
                  location.pathname === "/settings/list-data/department" ||
                  location.pathname === "/settings/list-data/office-base" ||
                  location.pathname === "/settings/list-data/working-base" ||
                  location.pathname === "/settings/list-data/working-place" ||
                  location.pathname === "/settings/list-data/major"
                    ? "list-item-active"
                    : "list-item"
                }
                disablePadding
                onClick={() => navigate("/settings/list-data")}
              >
                <ListItemButton className="list-item-button">
                  <BsCardList className="icon-submenu" />
                  <Typography className="list-item-text">List Data</Typography>
                </ListItemButton>
              </ListItem>
              <ListItem
                className={
                  location.pathname === "/settings/general" ||
                  location.pathname === "/settings/general/adjust-take-leave" ||
                  location.pathname === "/settings/general/user"
                    ? "list-item-active"
                    : "list-item"
                }
                disablePadding
                onClick={() => navigate("/settings/general")}
              >
                <ListItemButton className="list-item-button">
                  <HiOutlineAdjustmentsHorizontal className="icon-submenu" />
                  <Typography className="list-item-text">General</Typography>
                </ListItemButton>
              </ListItem>
              <ListItem
                className={
                  location.pathname === "/settings/activity-logs"
                    ? "list-item-active"
                    : "list-item"
                }
                disablePadding
                onClick={() => {
                  navigate("/settings/activity-logs");
                }}
              >
                <ListItemButton className="list-item-button">
                  <MdOutlineAdminPanelSettings className="icon-submenu" />
                  <Typography className="list-item-text">
                    Activity Logs
                  </Typography>
                </ListItemButton>
              </ListItem>
            </List>
          </Collapse>
        </List>
      ) : (
        <List className="list-menu">
          {/* ============================== page dashboard ========================== */}
          <ListItem
            className={
              location.pathname === "/dashboard"
                ? "list-item-active"
                : "list-item"
            }
            disablePadding
            onClick={() => {
              navigate("/");
              handleCloseSubMenu();
            }}
          >
            <ListItemButton className="list-item-button">
              <AiOutlineDashboard className="icon" />
              <Typography className="list-item-text">Dashboard</Typography>
            </ListItemButton>
          </ListItem>

          {/* ============================== page staff infromation ========================== */}
          <ListItem
            className={
              location.pathname === "/staff-info-user"
                ? "list-item-active"
                : "list-item"
            }
            disablePadding
            onClick={() => {
              navigate("/staff-info-user");
              handleCloseSubMenu();
            }}
          >
            <ListItemButton className="list-item-button">
              <SlPeople className="icon" />
              <Typography className="list-item-text">
                Staff Information
              </Typography>
            </ListItemButton>
          </ListItem>

          {/* ============================== page take leave ========================== */}
          <ListItem
            className={"list-item"}
            disablePadding
            onClick={handleOpenRequest}
          >
            <ListItemButton className="list-item-button">
              <TbCalendarTime className="icon" />
              <Typography className="list-item-text">Request</Typography>
              <Box sx={{ flexGrow: 1 }} />
              {openList ? (
                <ExpandLess sx={{ color: "#fff" }} />
              ) : (
                <ExpandMore sx={{ color: "#fff" }} />
              )}
            </ListItemButton>
          </ListItem>

          <Collapse in={openList} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <ListItem
                className={
                  location.pathname === "/take-leave"
                    ? "list-item-active"
                    : "list-item"
                }
                disablePadding
                onClick={() => navigate("/take-leave")}
              >
                <ListItemButton className="list-item-button">
                  <TbCalendarTime className="icon-submenu" />
                  <Typography className="list-item-text">Take Leave</Typography>
                </ListItemButton>
              </ListItem>
              <ListItem
                className={
                  location.pathname === "/over-time"
                    ? "list-item-active"
                    : "list-item"
                }
                disablePadding
                onClick={() => navigate("/over-time")}
              >
                <ListItemButton className="list-item-button">
                  <MdMoreTime className="icon-submenu" />
                  <Typography className="list-item-text">Over Time</Typography>
                </ListItemButton>
              </ListItem>
            </List>
          </Collapse>

          {/* ============================== page report ========================== */}
          <ListItem
            className={
              location.pathname === "/report" ||
              location.pathname === "/report/takeleave-report" ||
              location.pathname === "/report/overtime-report"
                ? "list-item-active"
                : "list-item"
            }
            disablePadding
            onClick={() => {
              navigate("/report");
              handleCloseSubMenu();
            }}
          >
            <ListItemButton className="list-item-button">
              <TbReportAnalytics className="icon" />
              <Typography className="list-item-text">Report</Typography>
            </ListItemButton>
          </ListItem>
        </List>
      )}

      <Box sx={{ flexGrow: 1 }}></Box>
      <Stack direction="column" spacing={1}>
        <IconButton onClick={handleMenu}>
          <ArrowCircleLeftOutlinedIcon className="icon-footer" />
        </IconButton>
        <Stack
          direction={"column"}
          spacing={0.5}
          style={{ paddingBottom: "15px" }}
        >
          <Typography className="powered-by">Powered By:</Typography>
          <Typography className="powered-by">Sipou & Chaktokrong </Typography>
        </Stack>
      </Stack>
    </Drawer>
  );
}
