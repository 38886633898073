import React, { useState, useContext } from "react";
import "../../Style/deletestyle.scss";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import { Stack, Box } from "@mui/material";
import { useMutation } from "@apollo/client";
//Schema
import { AuthContext } from "../../Context/AuthContext";
import { DELETE_WORKINGBASE } from "../../Schema/WorkingBase";

export default function DeleteWorkingBase({
  open,
  handleClose,
  editData,
  setRefetch,
}) {
  const [loading, setLoading] = useState(false);

  const [deleteWorkingBase] = useMutation(DELETE_WORKINGBASE, {
    onCompleted: ({ deleteWorkingBase }) => {
      // console.log(deleteWorkingBase);
      setLoading(false);
      if (deleteWorkingBase?.success === true) {
        setAlert(true, "success", deleteWorkingBase?.message);
        handleClose();
        setRefetch();
      } else {
        setAlert(true, "error", deleteWorkingBase?.message);
      }
    },
    onError: (error) => {
      console.log(error);
    },
  });

  const { setAlert } = useContext(AuthContext);

  const handleDelete = () => {
    setLoading(true);
    deleteWorkingBase({
      variables: {
        id: editData?._id,
      },
    });
  };

  return (
    <Dialog open={open} onClose={handleClose} className="delete-container">
      <DialogTitle sx={{ padding: "10px 20px" }}>
        <Stack direction="row" spacing={2}>
          <Stack direction="column" justifyContent="center">
            <Typography className="dialog-title">
              Delete Working Base
            </Typography>
          </Stack>
          <Box sx={{ flexGrow: 1 }} />
          <IconButton onClick={handleClose}>
            <CloseIcon className="close-icon" />
          </IconButton>
        </Stack>
      </DialogTitle>

      <DialogContent>
        <Stack direction="row" spacing={1}>
          <Typography>Are you sure to delete this data ?</Typography>
        </Stack>
      </DialogContent>

      <DialogActions>
        <Stack direction="row" spacing={4} className="action-container">
          <Button className="btn-cencel" fullWidth onClick={handleClose}>
            Cencel
          </Button>

          {loading ? (
            <Button className="btn-delete" fullWidth>
              Loading...
            </Button>
          ) : (
            <Button className="btn-delete" fullWidth onClick={handleDelete}>
              Delete
            </Button>
          )}
        </Stack>
      </DialogActions>
    </Dialog>
  );
}
