import { Stack, Box, Typography } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
export default function LoadingPage() {
  return (
    <>
      <Stack direction="column" justifyContent="center" height="300px">
        <Stack direction="row" justifyContent="center">
          {/* <CircularProgress/> */}
          <img
            src={require('../../Assets/loading_icon.gif')}
            width='200px'
          />
        </Stack>
      </Stack>
    </>
  );
}
