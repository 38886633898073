import React, { useState, useContext } from "react";
import "../../Style/deletestyle.scss";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import CloseIcon from "@mui/icons-material/Close";
import { Stack, Box, TextField, Typography, IconButton } from "@mui/material";
import { useMutation } from "@apollo/client";
//Schema
import { AuthContext } from "../../Context/AuthContext";
import { APPROVE_OVERTIME, DENIE_OVERTIME } from "../../Schema/OverTime";

export default function ApproveAndDeny({
  open,
  handleClose,
  userLogin,
  editData,
  requestStatus,
  setRefetch,
}) {
  const [loading, setLoading] = useState(false);
  const [managerMessage, setManagerMessage] = useState("");

  const [approveOvertime] = useMutation(APPROVE_OVERTIME, {
    onCompleted: ({ approveOvertime }) => {
      // console.log("approveOvertime::", approveOvertime);
      setLoading(false);
      if (approveOvertime?.success === true) {
        setAlert(true, "success", approveOvertime?.message);
        handleClose();
        setRefetch();
      } else {
        setAlert(true, "error", approveOvertime?.message);
      }
    },
    onError: (error) => {
      console.log(error);
    },
  });

  const [denyOvertime] = useMutation(DENIE_OVERTIME, {
    onCompleted: ({ denyOvertime }) => {
      // console.log(denyOvertime);
      setLoading(false);
      if (denyOvertime?.success === true) {
        setAlert(true, "success", denyOvertime?.message);
        handleClose();
        setRefetch();
      } else {
        setAlert(true, "error", denyOvertime?.message);
      }
    },
    onError: (error) => {
      console.log(error);
    },
  });

  const { setAlert } = useContext(AuthContext);

  const handleSubmit = () => {
    setLoading(true);
    if (requestStatus === "Approve") {
      approveOvertime({
        variables: {
          overtimeId: editData?._id,
          userId: userLogin?._id,
          managerReply: managerMessage,
        },
      });
    } else {
      denyOvertime({
        variables: {
          overtimeId: editData?._id,
          userId: userLogin?._id,
          managerReply: managerMessage,
        },
      });
    }
  };

  return (
    <Dialog open={open} className="approve-container">
      <DialogTitle className="dialog-title">
        <Stack direction="row" spacing={2}>
          <Stack direction="column" justifyContent="center">
            <Typography className="dialog-title">{requestStatus === "Approve" ? "Approve" : "Deny"} Overtime</Typography>
          </Stack>
          <Box sx={{ flexGrow: 1 }} />
          <IconButton onClick={handleClose}>
            <CloseIcon className="close-icon" />
          </IconButton>
        </Stack>
      </DialogTitle>

      <DialogContent dividers>
        <Stack direction="column" spacing={2}>
          <Typography className="approve-body-text">
            Do you really want to{" "}
            {requestStatus === "Approve" ? "approve" : "deny"} this data?
          </Typography>
          <TextField
            fullWidth
            multiline
            rows={3}
            size="small"
            placeholder="input note"
            onChange={(e) => setManagerMessage(e.target.value)}
          />
        </Stack>
      </DialogContent>

      <DialogActions>
        <Stack direction="row" spacing={4} className="action-container">
          <Button className="btn-cencel" fullWidth onClick={handleClose}>
            Cencel
          </Button>

          {loading === true ? (
            <Button
              className={
                requestStatus === "Approve" ? "btn-approve" : "btn-delete"
              }
              fullWidth
            >
              Loading...
            </Button>
          ) : (
            <Button
              className={
                requestStatus === "Approve" ? "btn-approve" : "btn-delete"
              }
              fullWidth
              onClick={handleSubmit}
            >
              {requestStatus === "Approve" ? "Approve" : "Deny"}
            </Button>
          )}
        </Stack>
      </DialogActions>
    </Dialog>
  );
}
