import { gql } from "@apollo/client";

export const CREATE_STAFF_RESIGN = gql`
  mutation CreateStaffResignInterview($input: StaffResignInterviewCreateInput) {
  createStaffResignInterview(input: $input) {
    success
    message
  }
}
`;

export const GET_STAFF_RESIGN_WITH_PAGINATIONS = gql`
  query GetStaffResignInterviewWithPagination($page: Int, $limit: Int, $keyword: String, $pagination: Boolean) {
  getStaffResignInterviewWithPagination(page: $page, limit: $limit, keyword: $keyword, pagination: $pagination) {
    data {
      staffInfoId {
        _id
        staffProfile {
          imageName
          imageSrc
          englishData {
            firstNameLatin
            lastNameLatin
          }
        }
        currentContract {
          positions {
            positionName
          }
        }
      }
      process
      endDate
    }
    paginator {
      slNo
      prev
      next
      perPage
      totalPosts
      totalPages
      currentPage
      hasPrevPage
      hasNextPage
      totalDocs
    }
  }
}
`;