import { gql } from "@apollo/client";

export const CREATE_POSITIONS = gql`
  mutation CreatePositions($input: PositionsInput) {
    createPositions(input: $input) {
      message
      success
    }
  }
`;

export const GET_POSITIONS_WITH_PAGINATION = gql`
  query GetPositionsWithPagination(
    $page: Int
    $limit: Int
    $keyword: String
    $pagination: Boolean
    $departmentId: String
  ) {
    getPositionsWithPagination(
      page: $page
      limit: $limit
      keyword: $keyword
      pagination: $pagination
      departmentId: $departmentId
    ) {
      paginator {
        slNo
        prev
        next
        perPage
        totalPosts
        totalPages
        currentPage
        hasPrevPage
        hasNextPage
        totalDocs
      }
      data {
        _id
        positionName
        positionNameKhmer
        inDepartment {
          _id
          departmentName
          remark
          createdAt
          updatedAt
        }
        managerPositions {
          _id
          positionName
          positionNameKhmer
        }
        isDepartmentManager
        remark
      }
    }
  }
`;

export const UPDATE_POSITIONS = gql`
  mutation UpdatePositions($input: PositionsInput, $positionsId: ID) {
    updatePositions(input: $input, positionsId: $positionsId) {
      success
      message
    }
  }
`;

export const DELETE_POSITIONS = gql`
  mutation DeletePositions($positionsId: ID) {
    deletePositions(positionsId: $positionsId) {
      success
      message
    }
  }
`;
