import React, { useState, useContext, useEffect } from "react";
import {
  Box,
  Typography,
  Stack,
  TextField,
  Button,
  InputAdornment,
  Grid,
} from "@mui/material";
import { useFormik, Form, FormikProvider } from "formik";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import HttpsOutlinedIcon from "@mui/icons-material/HttpsOutlined";
import { useMutation } from "@apollo/client";
//Src
import "../../Style/loginStyle.scss";
import RightImage from "../../Assets/login-img.png";
import PepyLogo from "../../Assets/pepy-logo.png";
import { AuthContext } from "../../Context/AuthContext";
import { RESET_PASSWORD } from "../../Schema/Login";

export default function ResetPassword() {
  const navigate = useNavigate();
  const { setAlert } = useContext(AuthContext);
  const [loading, setLoading] = useState(false);

  // hide password hook
  const [show, setShow] = useState(false);
  const handleClick = () => setShow(!show);

  //For reset after login
  let resetPwEmail = JSON.parse(window.localStorage.getItem("userLogin"))?.systemAccessData?.emailAddress;
  // console.log(resetPwEmail, 'userLogin');

  //For first time reset password
  const emailVerify = JSON.parse(window.localStorage.getItem("resetPwEmail"));
  // console.log(emailVerify, "emailVerify");

  const [resetPassword, { data, error }] = useMutation(RESET_PASSWORD, {
    onCompleted: ({ resetPassword }) => {
      // console.log("resetPassword::", resetPassword);
      setLoading(false);
      if (resetPassword?.success === true) {
        setAlert(true, "success", resetPassword?.message);
        // window.localStorage.setItem("token", resetPassword?.token);
        window.localStorage.removeItem("token");
        window.localStorage.removeItem("userLogin");
        window.localStorage.removeItem("resetPwEmail");

        setTimeout(() => {
          navigate("/");
          window.location.reload();
        }, 1000);
      } else {
        setAlert(true, "error", resetPassword?.message);
      }
    },
    onError: (error) => {
      setLoading(false);
      setAlert(true, "error", error?.message);
    },
  });

  // console.log("emailAddress::", emailAddress);
  const ResetPasswordSchema = Yup.object().shape({
    // emailAddress: Yup.string().email("Invalid email!").required("Required"),
    password: Yup.string()
      .required("Required")
      .min(6, "Password must be 6 characters!"),
    confirm_Password: Yup.string()
      .required("Please retype your password!")
      .oneOf([Yup.ref("password")], "Your passwords do not match!"),
  });

  const formik = useFormik({
    initialValues: {
      emailAddress: "",
      password: "",
      confirm_Password: "",
    },

    validationSchema: ResetPasswordSchema,
    onSubmit: async (values) => {
      // console.log("values::", values);
      setLoading(true);
      resetPassword({
        variables: {
          emailAddress: values?.emailAddress,
          password: values?.password,
        },
      });
    },
  });

  const { errors, touched, handleSubmit, getFieldProps, setFieldValue } =
    formik;

  useEffect(() => {
    if (resetPwEmail !== undefined) {
      //For reset after login
      setFieldValue("emailAddress", resetPwEmail);
    }else{
      //For first time reset password
      setFieldValue("emailAddress", emailVerify);
    }
  }, [resetPwEmail]);

  let displayModalWeb = { xs: "none", sm: "flex" };
  let widthForm = { xs: "94%", sm: "90%", md: "60%" };

  return (
    <div className="login-page">
      <FormikProvider value={formik}>
        <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
          <Grid container>
            <Grid item xs={6} sx={{ display: displayModalWeb }}>
              <Box className="right-page-container">
                <Box className="vertical-center-leftSide">
                  <Typography className="company-title">
                    PEPY Empowering Youth
                  </Typography>
                  <Typography className="company-title">
                    Human Resource Management System
                  </Typography>
                  <img
                    src={`${RightImage}`}
                    alt="login image"
                    style={{
                      marginTop: "40px",
                      width: "30vw",
                    }}
                  />
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Box className="vertical-center-rightSide">
                <Box sx={{ width: widthForm }}>
                  <Stack
                    direction="column"
                    spacing={3}
                    sx={{ marginTop: "20px" }}
                  >
                    <Stack direction="row" justifyContent="center">
                      <img
                        src={`${PepyLogo}`}
                        alt="pepy logo"
                        style={{
                          width: "22%",
                        }}
                      />
                    </Stack>
                    <Typography className="login-des">
                      Please reset your password.
                    </Typography>
                    <TextField
                      // className="text-field"
                      disabled
                      placeholder="email address"
                      fullWidth
                      size="small"
                      {...getFieldProps("emailAddress")}
                      error={Boolean(
                        touched.emailAddress && errors.emailAddress
                      )}
                      helperText={touched.emailAddress && errors.emailAddress}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <EmailOutlinedIcon className="icon" />
                          </InputAdornment>
                        ),
                      }}
                    />

                    <TextField
                      // className="text-field"
                      type={show ? "text" : "password"}
                      size="small"
                      placeholder="password"
                      {...getFieldProps("password")}
                      error={Boolean(touched.password && errors.password)}
                      helperText={touched.password && errors.password}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <HttpsOutlinedIcon className="icon" />
                          </InputAdornment>
                        ),
                        endAdornment: (
                          <InputAdornment
                            position="start"
                            onClick={handleClick}
                          >
                            {show ? (
                              <VisibilityIcon sx={{ cursor: "pointer" }} />
                            ) : (
                              <VisibilityOffIcon sx={{ cursor: "pointer" }} />
                            )}
                          </InputAdornment>
                        ),
                      }}
                    />

                    <TextField
                      // className="text-field"
                      type={show ? "text" : "password"}
                      size="small"
                      placeholder="confirm password"
                      {...getFieldProps("confirm_Password")}
                      error={Boolean(
                        touched.confirm_Password && errors.confirm_Password
                      )}
                      helperText={
                        touched.confirm_Password && errors.confirm_Password
                      }
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <HttpsOutlinedIcon className="icon" />
                          </InputAdornment>
                        ),
                        endAdornment: (
                          <InputAdornment
                            position="start"
                            onClick={handleClick}
                          >
                            {show ? (
                              <VisibilityIcon sx={{ cursor: "pointer" }} />
                            ) : (
                              <VisibilityOffIcon sx={{ cursor: "pointer" }} />
                            )}
                          </InputAdornment>
                        ),
                      }}
                    />
                    {loading ? (
                      <Button className="btn-login">Loding...</Button>
                    ) : (
                      <Button className="btn-login" type="submit">
                        Login
                      </Button>
                    )}
                  </Stack>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Form>
      </FormikProvider>
    </div>
  );
}
