import React, { useState, useContext, useEffect } from "react";
import "../../Style/createstyle.scss";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import HttpsOutlinedIcon from "@mui/icons-material/HttpsOutlined";
import { Grid, Stack, Box, TextField, InputAdornment, } from "@mui/material";
import { useMutation } from "@apollo/client";
import * as Yup from "yup";
import { useFormik, Form, FormikProvider } from "formik";
//Schema
import { AuthContext } from "../../Context/AuthContext";
import { RESET_PASSWORD } from "../../Schema/Users";

export default function DeletePositions({
  open,
  handleClose,
  editData,
  setRefetch,
}) {
  const { setAlert } = useContext(AuthContext);
  const [loading, setLoading] = useState(false);

  // hide password hook
  const [show, setShow] = useState(false);
  const handleClick = () => setShow(!show);

  const [resetPassword, { data, error }] = useMutation(RESET_PASSWORD, {
    onCompleted: ({ resetPassword }) => {
      setLoading(false);
      if (resetPassword?.success === true) {
        setAlert(true, "success", resetPassword?.message);
        handleClose();
        resetForm();
        setRefetch();
      } else {
        setAlert(true, "error", resetPassword?.message);
      }
    },
    onError: (error) => {
      setLoading(false);
      setAlert(true, "error", error?.message);
    },
  });

  // console.log("emailAddress::", emailAddress);
  const ResetPasswordSchema = Yup.object().shape({
    emailAddress: Yup.string().email("Invalid email!").required("Required"),
    password: Yup.string()
      .required("Required")
      .min(6, "Password must be 6 characters!"),
    confirm_Password: Yup.string()
      .required("Please retype your password!")
      .oneOf([Yup.ref("password")], "Your passwords do not match!"),
  });

  const formik = useFormik({
    initialValues: {
      emailAddress: "",
      password: "",
      confirm_Password: "",
    },

    validationSchema: ResetPasswordSchema,
    onSubmit: async (values) => {
      console.log("values::", values);
      setLoading(true);
      resetPassword({
        variables: {
          emailAddress: editData?.systemAccessData?.emailAddress,
          password: values?.password,
        },
      });
    },
  });

  useEffect(() => {
    if (editData) {
      setFieldValue("emailAddress", editData?.systemAccessData?.emailAddress);
    }
  }, [editData]);

  const {
    errors,
    touched,
    handleSubmit,
    getFieldProps,
    setFieldValue,
    values,
    resetForm,
  } = formik;

  return (
    <Dialog open={open} className="create-container">
      <DialogTitle>
        <Stack direction="row" spacing={2}>
          <Stack direction="column" justifyContent="center">
            <Typography className="dialog-title">Reset Password</Typography>
          </Stack>
          <Box sx={{ flexGrow: 1 }} />
          <IconButton onClick={handleClose}>
            <CloseIcon className="close-icon" />
          </IconButton>
        </Stack>
      </DialogTitle>

      <DialogContent>
        <FormikProvider value={formik}>
          <Form noValidate autoComplete="off" onSubmit={handleSubmit}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography className="field-title">Email</Typography>
                <TextField
                  fullWidth
                  size="small"
                  className="text-field"
                  placeholder="email address"
                  disabled
                  {...getFieldProps("emailAddress")}
                  error={Boolean(
                    touched.emailAddress && errors.emailAddress
                  )}
                  helperText={touched.emailAddress && errors.emailAddress}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <EmailOutlinedIcon className="icon" />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>

             <Grid item xs={12}>
                <Typography className="field-title">New password</Typography>
                <TextField
                  fullWidth
                  size="small"
                  className="text-field"
                  type={show ? "text" : "password"}
                  placeholder="password"
                  {...getFieldProps("password")}
                  error={Boolean(touched.password && errors.password)}
                  helperText={touched.password && errors.password}
                  InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <HttpsOutlinedIcon className="icon" />
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <InputAdornment
                      position="start"
                      onClick={handleClick}
                    >
                      {show ? (
                        <VisibilityIcon sx={{ cursor: "pointer" }} />
                      ) : (
                        <VisibilityOffIcon sx={{ cursor: "pointer" }} />
                      )}
                    </InputAdornment>
                  ),
                }}
                />
              </Grid>

              <Grid item xs={12}>
                <Typography className="field-title">New confirm password</Typography>
                <TextField
                  fullWidth
                  size="small"
                  className="text-field"
                  type={show ? "text" : "password"}
                  placeholder="confirm password"
                  {...getFieldProps("confirm_Password")}
                  error={Boolean(
                    touched.confirm_Password && errors.confirm_Password
                  )}
                  helperText={
                    touched.confirm_Password && errors.confirm_Password
                  }
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <HttpsOutlinedIcon className="icon" />
                      </InputAdornment>
                    ),
                    endAdornment: (
                      <InputAdornment
                        position="start"
                        onClick={handleClick}
                      >
                        {show ? (
                          <VisibilityIcon sx={{ cursor: "pointer" }} />
                        ) : (
                          <VisibilityOffIcon sx={{ cursor: "pointer" }} />
                        )}
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>

            </Grid>
          </Form>
        </FormikProvider >
      </DialogContent>

      <DialogActions>
        <Stack direction="row" spacing={4} className="action-container">
          <Button className="btn-cencel" fullWidth onClick={handleClose}>
            Cencel
          </Button>

          {loading ? (
            <Button className="btn-create" fullWidth>
              Loading...
            </Button>
          ) : (
            <Button className="btn-create" fullWidth onClick={handleSubmit}>
              Save
            </Button>
          )}
        </Stack>
      </DialogActions>

    </Dialog>
  );
}
