import React from "react";
import { Stack, Box, Typography, Avatar } from "@mui/material";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { AuthContext } from "../../Context/AuthContext";
import { translateLauguage } from "../../Function/Translate";
import "./emptydata.scss";

export default function EmptyData() {
  // Change Language
  const { language } = React.useContext(AuthContext);
  const { t } = translateLauguage(language);

  return (
    <Stack direction="column" justifyContent="center" className="empty-data">
      <Stack direction="row" justifyContent="center">
        <Stack direction="column" justifyContent="center">
          <Stack direction="row" justifyContent="center" sx={{ mb: 1 }}>
            <ErrorOutlineIcon className="empty-icon" />
          </Stack>
          <Typography className="titleEn">No Data!</Typography>
          <Typography className="subEn">
            Your collection list is empty.
          </Typography>
        </Stack>
      </Stack>
    </Stack>
  );
}
