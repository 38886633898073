import React, { useState, useContext } from "react";
import "../../../Style/pagestyle.scss";
import {
  Box,
  Button,
  TableContainer,
  Table,
  TableRow,
  TableCell,
  TableBody,
  TableHead,
} from "@mui/material";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { useQuery } from "@apollo/client";
import moment from "moment";
//src
import { AuthContext } from "../../../Context/AuthContext";
import EmptyData from "../../Include/EmptyData";
import LoadingPage from "../../Include/LoadingPage";
import CreateCovidVaccination from "./CreateCovidVaccination";
import CovidVaccinationAction from "./CovidVaccinationAction";
import { GET_COVID_COVIDVACCINATION_BY_STAFF } from "../../../Schema/CovidVaccination";

export default function CovidVaccination({ userLogin, staffId }) {
  const [loading, setLoading] = useState(true);
  const { setAlert } = useContext(AuthContext);
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  //query
  const [tableData, setTableData] = useState([]);

  const { data, refetch } = useQuery(GET_COVID_COVIDVACCINATION_BY_STAFF, {
    variables: {
      staffId: staffId,
    },
    onCompleted: ({ getCovidVacinByStaffId }) => {
      // console.log(getCovidVacinByStaffId, "getCovidVacinByStaffId")
      setTableData(getCovidVacinByStaffId);
      setLoading(false);
    },
    onError: (error) => {
      setAlert(true, "error", error?.message);
      // console.log(error)
    },
  });

  //===================== Resize width Screen=====================
  const [width, setWidth] = React.useState(window.innerWidth);
  const updateDimensions = () => {
    setWidth(window.innerWidth - 50);
  };
  React.useEffect(() => {
    window.addEventListener("resize", updateDimensions);
    return () => window.removeEventListener("resize", updateDimensions);
  }, []);

  return (
    <div className="page-container">
      <Box className="body-container">
        <TableContainer sx={{ maxWidth: `${width}px` }}>
          <Table className="table" aria-label="simple table">
            <TableHead className="header-row ">
              <TableCell className="header-title-start"> N°</TableCell>
              <TableCell className="header-title">Vaccin Name</TableCell>
              <TableCell className="header-title">Doses Number</TableCell>
              <TableCell className="header-title">Date</TableCell>
              <TableCell className="header-title">
                Vaccination Location
              </TableCell>
              <TableCell
                className="header-title-end header-sticky-right"
                align="right"
              >
                {userLogin?.systemAccessData?.role === "Admin" ? (
                  <Button
                    startIcon={<AddCircleIcon />}
                    className="btn-add-list"
                    onClick={handleOpen}
                  >
                    Add
                  </Button>
                ) : null}
                <CreateCovidVaccination
                  open={open}
                  handleClose={handleClose}
                  dialogTitle={"Create"}
                  staffId={staffId}
                  setRefetch={refetch}
                />
              </TableCell>
            </TableHead>
            {loading ? (
              <TableBody className="body">
                <TableRow className="body-row-gray">
                  <TableCell className="body-cell" colSpan={7}>
                    <LoadingPage />
                  </TableCell>
                </TableRow>
              </TableBody>
            ) : (
              <TableBody className="body">
                {tableData?.length === 0 ? (
                  <TableRow className="body-row-gray">
                    <TableCell className="body-cell" colSpan={7}>
                      <EmptyData />
                    </TableCell>
                  </TableRow>
                ) : (
                  <>
                    {tableData?.map((row, index) => {
                      return (
                        <TableRow
                          className={
                            (index + 1) % 2 === 0 ? "body-row" : "body-row-gray"
                          }
                          key={index}
                        >
                          <TableCell className="body-cell-start">
                            {index + 1}
                          </TableCell>
                          <TableCell className="body-cell">
                            {row?.vaccinName}
                          </TableCell>
                          <TableCell className="body-cell">
                            {row?.dosesNumber}
                          </TableCell>
                          <TableCell className="body-cell">
                            {moment(row?.vaccinationDate).format("DD-MM-YYYY")}
                          </TableCell>
                          <TableCell className="body-cell">
                            {row?.vaccinationLocation}
                          </TableCell>
                          <TableCell
                            className="body-cell-end cell-sticky-right"
                            align="right"
                          >
                            <CovidVaccinationAction
                              editData={row}
                              setRefetch={refetch}
                              userRole={userLogin?.systemAccessData?.role}
                            />
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </>
                )}
              </TableBody>
            )}
          </Table>
        </TableContainer>
      </Box>
    </div>
  );
}
