import React, { useState, useContext } from "react";
import "../../Style/deletestyle.scss";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import CloseIcon from "@mui/icons-material/Close";
import { Stack, Box, TextField, Typography, IconButton } from "@mui/material";
import { useMutation } from "@apollo/client";
//Schema
import { AuthContext } from "../../Context/AuthContext";
import { APPROVE_TAKELEAVE, DENIE_TAKELEAVE, APPROVE_TAKELEAVE_MODIFY } from "../../Schema/TakeLeave";

export default function ApproveAndDeny({
  open,
  handleClose,
  userLogin,
  editData,
  requestStatus,
  setRefetch,
}) {
  const [loading, setLoading] = useState(false);
  const [managerMessage, setManagerMessage] = useState("");

  const [approveTakeLeave] = useMutation(APPROVE_TAKELEAVE, {
    onCompleted: ({ approveTakeLeave }) => {
      // console.log("approveTakeLeave::", approveTakeLeave);
      setLoading(false);
      if (approveTakeLeave?.success === true) {
        setAlert(true, "success", approveTakeLeave?.message);
        handleClose();
        setRefetch();
      } else {
        setAlert(true, "error", approveTakeLeave?.message);
      }
    },
    onError: (error) => {
      console.log(error);
    },
  });

  const [denyTakeLeave] = useMutation(DENIE_TAKELEAVE, {
    onCompleted: ({ denyTakeLeave }) => {
      // console.log(denyTakeLeave);
      setLoading(false);
      if (denyTakeLeave?.success === true) {
        setAlert(true, "success", denyTakeLeave?.message);
        handleClose();
        setRefetch();
      } else {
        setAlert(true, "error", denyTakeLeave?.message);
      }
    },
    onError: (error) => {
      console.log(error);
    },
  });

  const { setAlert } = useContext(AuthContext);

  const handleSubmit = () => {
    setLoading(true);
    if (requestStatus === "Approve") {
      approveTakeLeave({
        variables: {
          takeLeaveId: editData?._id,
          userId: userLogin?._id,
          managerReply: managerMessage,
        },
      });
    } else {
      denyTakeLeave({
        variables: {
          takeLeaveId: editData?._id,
          userId: userLogin?._id,
          managerReply: managerMessage,
        },
      });
    }
  };

  return (
    <Dialog open={open} className="approve-container">
      <DialogTitle className="dialog-title">
        <Stack direction="row" spacing={2}>
          <Stack direction="column" justifyContent="center">
            <Typography className="dialog-title">{requestStatus === "Approve" ? "Approve" : "Deny"} Take leave</Typography>
          </Stack>
          <Box sx={{ flexGrow: 1 }} />
          <IconButton onClick={handleClose}>
            <CloseIcon className="close-icon" />
          </IconButton>
        </Stack>
      </DialogTitle>

      <DialogContent dividers>
        <Stack direction="column" spacing={2}>
          <Typography className="approve-body-text">
            Do you really want to{" "}
            {requestStatus === "Approve" ? "approve" : "deny"} this data?
          </Typography>
          <TextField
            fullWidth
            multiline
            rows={3}
            size="small"
            placeholder="input note"
            onChange={(e) => setManagerMessage(e.target.value)}
          />
        </Stack>
      </DialogContent>

      <DialogActions>
        <Stack direction="row" spacing={4} className="action-container">
          <Button className="btn-cencel" fullWidth onClick={handleClose}>
            Cencel
          </Button>

          {loading === true ? (
            <Button
              className={
                requestStatus === "Approve" ? "btn-approve" : "btn-delete"
              }
              fullWidth
            >
              Loading...
            </Button>
          ) : (
            <Button
              className={
                requestStatus === "Approve" ? "btn-approve" : "btn-delete"
              }
              fullWidth
              onClick={handleSubmit}
            >
              {requestStatus === "Approve" ? "Approve" : "Deny"}
            </Button>
          )}
        </Stack>
      </DialogActions>
    </Dialog>
  );
}
