import React, { useContext, useState } from "react";
import "./smallmenu.scss";
import {
  List,
  ListItem,
  ListItemButton,
  Tooltip,
  Button,
  Collapse,
  Drawer,
  Stack,
  IconButton,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { useLocation, useNavigate } from "react-router-dom";
//icons
import { AiOutlineDashboard, AiOutlineSetting } from "react-icons/ai";
import { MdMoreTime } from "react-icons/md";
import { SlPeople } from "react-icons/sl";
import { TbCalendarTime, TbReportAnalytics } from "react-icons/tb";
import { MdOutlineAdminPanelSettings } from "react-icons/md";
import ArrowCircleRightOutlinedIcon from "@mui/icons-material/ArrowCircleRightOutlined";
import { BsCardList } from "react-icons/bs";
import { HiOutlineAdjustmentsHorizontal } from "react-icons/hi2";
//src
import pepyWhiteLogo from "../Assets/pypy-white-logo.svg";
import { MenuSizeContext } from "../App";
import { Box } from "@mui/system";

const drawerWidth = 80;

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  margin: "0px",
  alignItems: "center",
  justifyContent: "center",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

export default function MenuMobile({ open, handleDrawerClose }) {
  let location = useLocation();
  const navigate = useNavigate();

  const [openList, setOpenList] = useState(false);
  const handleOpenRequest = () => {
    setOpenList(!openList);
    setOpenSetting(false);
  };

  const [openSetting, setOpenSetting] = useState(false);
  const handleOpenSetting = () => {
    setOpenSetting(!openSetting);
    setOpenList(false);
  };

  const handleCloseSubMenu = () => {
    setOpenSetting(false);
    setOpenList(false);
  };

  const [menuSize, setMenuSize] = useContext(MenuSizeContext);
  const handleMenu = () => {
    setMenuSize("large");
    // console.log('large')
  };

  const [userLogin, setUserLogin] = useState(
    JSON.parse(window.localStorage.getItem("userLogin"))
  );
  // console.log("userLogin::", userLogin)

  return (
    <Drawer
      sx={{
        width: drawerWidth,
        flexShrink: 0,
        "& .MuiDrawer-paper": {
          width: drawerWidth,
          boxSizing: "border-box",
        },
      }}
      variant="persistent"
      anchor="left"
      open={true}
      className="small-menu"
    >
      <DrawerHeader sx={{ mb: 2 }}>
        <Button className="image-container" onClick={() => handleDrawerClose()}>
          <img src={pepyWhiteLogo} className="logo" />
        </Button>
      </DrawerHeader>

      {userLogin?.systemAccessData?.role === "Admin" ? (
        <List className="list-menu">
          {/* ============================== page dashboard ========================== */}
          <Tooltip title="Dashboard" placement="right">
            <ListItem
              className={
                location.pathname === "/dashboard"
                  ? "list-item-active"
                  : "list-item"
              }
              disablePadding
              onClick={() => {
                navigate("/");
                handleCloseSubMenu();
              }}
            >
              <ListItemButton className="list-item-button">
                <AiOutlineDashboard className="icon" />
              </ListItemButton>
            </ListItem>
          </Tooltip>

          {/* ============================== page staffinfo ========================== */}
          <Tooltip title="Staff Information" placement="right">
            <ListItem
              className={
                location.pathname === "/staff-info" ||
                location.pathname === "/staff-info/create-staff" ||
                location.pathname === "/staff-info/update-staff" ||
                location.pathname === "/staff-info/staff-details"
                  ? "list-item-active"
                  : "list-item"
              }
              disablePadding
              onClick={() => {
                navigate("/staff-info");
                handleCloseSubMenu();
              }}
            >
              <ListItemButton className="list-item-button">
                <SlPeople className="icon" />
              </ListItemButton>
            </ListItem>
          </Tooltip>

          {/* ============================== page take leave ========================== */}
          <Tooltip title="Request" placement="right">
            <ListItem
              className="list-item"
              disablePadding
              onClick={handleOpenRequest}
            >
              <ListItemButton className="list-item-button">
                <TbCalendarTime className="icon" />
              </ListItemButton>
            </ListItem>
          </Tooltip>

          <Collapse in={openList} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <Tooltip title="Take Leave" placement="right">
                <ListItem
                  className={
                    location.pathname === "/take-leave"
                      ? "list-item-active"
                      : "list-item"
                  }
                  disablePadding
                  onClick={() => navigate("/take-leave")}
                >
                  <ListItemButton className="list-item-button">
                    <TbCalendarTime className="icon" />
                  </ListItemButton>
                </ListItem>
              </Tooltip>

              <Tooltip title="Over Time" placement="right">
                <ListItem
                  className={
                    location.pathname === "/over-time"
                      ? "list-item-active"
                      : "list-item"
                  }
                  disablePadding
                  onClick={() => navigate("/over-time")}
                >
                  <ListItemButton className="list-item-button">
                    <MdMoreTime className="icon" />
                  </ListItemButton>
                </ListItem>
              </Tooltip>
            </List>
          </Collapse>

          {/* ============================== page report ========================== */}
          <Tooltip title="Report" placement="right">
            <ListItem
              className={
                location.pathname === "/report" ||
                location.pathname === "/report/takeleave-report" ||
                location.pathname === "/report/overtime-report"
                  ? "list-item-active"
                  : "list-item"
              }
              disablePadding
              onClick={() => {
                navigate("/report");
                handleCloseSubMenu();
              }}
            >
              <ListItemButton className="list-item-button">
                <TbReportAnalytics className="icon" />
              </ListItemButton>
            </ListItem>
          </Tooltip>

          {/* ============================== page activity logs ========================== */}
          {/* <Tooltip title="Activity Logs" placement="right">
            <ListItem
              className={
                location.pathname === "/activity-logs"
                  ? "list-item-active"
                  : "list-item"
              }
              disablePadding
              onClick={() => {
                navigate("/activity-logs");
                handleCloseSubMenu()
              }}
            >
              <ListItemButton className="list-item-button">
                <MdOutlineAdminPanelSettings className="icon" />
              </ListItemButton>
            </ListItem>
          </Tooltip> */}

          {/* ============================== page setting ========================== */}
          <Tooltip title="Setting" placement="right">
            <ListItem
              className="list-item"
              disablePadding
              onClick={handleOpenSetting}
            >
              <ListItemButton className="list-item-button">
                <AiOutlineSetting className="icon" />
              </ListItemButton>
            </ListItem>
          </Tooltip>

          <Collapse in={openSetting} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <Tooltip title="List data" placement="right">
                <ListItem
                  className={
                    location.pathname === "/settings/list-data" ||
                    location.pathname === "/settings/list-data/positions" ||
                    location.pathname === "/settings/list-data/department" ||
                    location.pathname === "/settings/list-data/office-base" ||
                    location.pathname === "/settings/list-data/working-base" ||
                    location.pathname === "/settings/list-data/working-place" ||
                    location.pathname === "/settings/list-data/major"
                      ? "list-item-active"
                      : "list-item"
                  }
                  disablePadding
                  onClick={() => navigate("/settings/list-data")}
                >
                  <ListItemButton className="list-item-button">
                    <BsCardList className="icon" />
                  </ListItemButton>
                </ListItem>
              </Tooltip>

              <Tooltip title="Adjustment" placement="right">
                <ListItem
                  className={
                    location.pathname === "/settings/general" ||
                    location.pathname ===
                      "/settings/general/adjust-take-leave" ||
                    location.pathname === "/settings/general/user"
                      ? "list-item-active"
                      : "list-item"
                  }
                  disablePadding
                  onClick={() => navigate("/settings/general")}
                >
                  <ListItemButton className="list-item-button">
                    <HiOutlineAdjustmentsHorizontal className="icon" />
                  </ListItemButton>
                </ListItem>
              </Tooltip>
              <Tooltip title="Activity Logs" placement="right">
                <ListItem
                  className={
                    location.pathname === "/settings/activity-logs"
                      ? "list-item-active"
                      : "list-item"
                  }
                  disablePadding
                  onClick={() => {
                    navigate("/settings/activity-logs");
                  }}
                >
                  <ListItemButton className="list-item-button">
                    <MdOutlineAdminPanelSettings className="icon" />
                  </ListItemButton>
                </ListItem>
              </Tooltip>
            </List>
          </Collapse>
        </List>
      ) : (
        <List className="list-menu">
          {/* ============================== page dashboard ========================== */}
          <Tooltip title="Dashboard" placement="right">
            <ListItem
              className={
                location.pathname === "/dashboard"
                  ? "list-item-active"
                  : "list-item"
              }
              disablePadding
              onClick={() => {
                navigate("/");
                handleCloseSubMenu();
              }}
            >
              <ListItemButton className="list-item-button">
                <AiOutlineDashboard className="icon" />
              </ListItemButton>
            </ListItem>
          </Tooltip>

          {/* ============================== page staffinfo ========================== */}
          <Tooltip title="Staff Information" placement="right">
            <ListItem
              className={
                location.pathname === "/staff-info-user"
                  ? "list-item-active"
                  : "list-item"
              }
              disablePadding
              onClick={() => {
                navigate("/staff-info-user");
                handleCloseSubMenu();
              }}
            >
              <ListItemButton className="list-item-button">
                <SlPeople className="icon" />
              </ListItemButton>
            </ListItem>
          </Tooltip>

          {/* ============================== page take leave ========================== */}
          <Tooltip title="Request" placement="right">
            <ListItem
              className="list-item"
              disablePadding
              onClick={handleOpenRequest}
            >
              <ListItemButton className="list-item-button">
                <TbCalendarTime className="icon" />
              </ListItemButton>
            </ListItem>
          </Tooltip>

          <Collapse in={openList} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <Tooltip title="Take Leave" placement="right">
                <ListItem
                  className={
                    location.pathname === "/take-leave"
                      ? "list-item-active"
                      : "list-item"
                  }
                  disablePadding
                  onClick={() => navigate("/take-leave")}
                >
                  <ListItemButton className="list-item-button">
                    <TbCalendarTime className="icon" />
                  </ListItemButton>
                </ListItem>
              </Tooltip>

              <Tooltip title="Over Time" placement="right">
                <ListItem
                  className={
                    location.pathname === "/over-time"
                      ? "list-item-active"
                      : "list-item"
                  }
                  disablePadding
                  onClick={() => navigate("/over-time")}
                >
                  <ListItemButton className="list-item-button">
                    <MdMoreTime className="icon" />
                  </ListItemButton>
                </ListItem>
              </Tooltip>
            </List>
          </Collapse>

          {/* ============================== page report ========================== */}
          <Tooltip title="Report" placement="right">
            <ListItem
              className={
                location.pathname === "/report" ||
                location.pathname === "/report/takeleave-report" ||
                location.pathname === "/report/overtime-report"
                  ? "list-item-active"
                  : "list-item"
              }
              disablePadding
              onClick={() => {
                navigate("/report");
                handleCloseSubMenu();
              }}
            >
              <ListItemButton className="list-item-button">
                <TbReportAnalytics className="icon" />
              </ListItemButton>
            </ListItem>
          </Tooltip>
        </List>
      )}
      <Box sx={{ flexGrow: 1 }}></Box>
      <Stack direction="row" justifyContent="center" className="stack-footer">
        <IconButton onClick={handleMenu}>
          <ArrowCircleRightOutlinedIcon className="icon-footer" />
        </IconButton>
      </Stack>
    </Drawer>
  );
}
