import React, { useState, useContext, useEffect } from "react";
import "../../../Style/pagestyle.scss";
import {
  Box,
  Button,
  TableContainer,
  Table,
  TableRow,
  TableCell,
  TableBody,
  TableHead,
  Switch,
  Checkbox,
} from "@mui/material";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { useQuery, useMutation } from "@apollo/client";
import moment from "moment";
//src
import { AuthContext } from "../../../Context/AuthContext";
import EmptyData from "../../Include/EmptyData";
import LoadingPage from "../../Include/LoadingPage";
import CreateCovidVaccination from "./CreateContract";
import CovidVaccinationAction from "./ContractAction";
import {
  GET_CONTRACT_BY_STAFF,
  EDIT_CONTRACT_STATUS,
} from "../../../Schema/StaffContract";

export default function CovidVaccination({ userLogin, staffId }) {
  const [loading, setLoading] = useState(true);
  const { setAlert } = useContext(AuthContext);
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [checkIsActive, setCheckIsActive] = useState(false);

  //query
  const [tableData, setTableData] = useState([]);

  const { refetch } = useQuery(GET_CONTRACT_BY_STAFF, {
    variables: {
      staffId: staffId,
    },
    onCompleted: ({ getContractByStaffId }) => {
      // console.log("getContractByStaffId::", getContractByStaffId);
      setTableData(getContractByStaffId);
      setLoading(false);
    },
    onError: (error) => {
      setAlert(true, "error", error?.message);
      // console.log(error)
    },
  });

  useEffect(() => {
    refetch();
  }, [staffId, checkIsActive]);

  const [editContractStatus] = useMutation(EDIT_CONTRACT_STATUS, {
    onCompleted: ({ editContractStatus }) => {
      console.log(editContractStatus, "editContractStatus");
      if (editContractStatus?.success === true) {
        setAlert(true, "success", editContractStatus?.message);
        // refetch();
        //window.location.reload(false);

        // window.location.reload(false);
      } else {
        setAlert(true, "error", editContractStatus?.message);
      }
    },
    onError: (error) => {
      setLoading(false);
      setAlert(true, "error", error?.message);
    },
  });

  const handleEditContractStatus = (value) => {
    // console.log(value, "value");
    setCheckIsActive(!checkIsActive);
    editContractStatus({
      variables: {
        id: value,
        isActive: true,
      },
    });
  };
  // console.log("tableData::", tableData);

  //===================== Resize width Screen=====================
  const [width, setWidth] = React.useState(window.innerWidth);
  const updateDimensions = () => {
    setWidth(window.innerWidth - 50);
  };
  React.useEffect(() => {
    window.addEventListener("resize", updateDimensions);
    return () => window.removeEventListener("resize", updateDimensions);
  }, []);

  return (
    <div className="page-container">
      <Box className="body-container">
        <TableContainer sx={{ maxWidth: `${width}px` }}>
          <Table className="table" aria-label="simple table">
            <TableHead className="header-row ">
              <TableCell className="header-title-start">Latest</TableCell>
              <TableCell
                className="header-title header-sticky-left"
                sx={{ minWidth: "200px" }}
              >
                Positions
              </TableCell>
              <TableCell className="header-title" sx={{ minWidth: "200px" }}>
                Positions Khmer
              </TableCell>
              <TableCell className="header-title" sx={{ minWidth: "150px" }}>
                Start Date
              </TableCell>
              <TableCell className="header-title" sx={{ minWidth: "150px" }}>
                End Date
              </TableCell>
              <TableCell className="header-title" sx={{ minWidth: "200px" }}>
                Department
              </TableCell>
              <TableCell className="header-title" sx={{ minWidth: "150px" }}>
                Office Base
              </TableCell>
              <TableCell className="header-title" sx={{ minWidth: "150px" }}>
                workingBase
              </TableCell>
              <TableCell className="header-title" sx={{ minWidth: "150px" }}>
                workingPlace
              </TableCell>
              <TableCell
                className="header-title-end header-sticky-right"
                align="right"
              >
                {userLogin?.systemAccessData?.role === "Admin" ? (
                  <Button
                    startIcon={<AddCircleIcon />}
                    className="btn-add-list"
                    onClick={handleOpen}
                  >
                    Add
                  </Button>
                ) : null}
                <CreateCovidVaccination
                  open={open}
                  handleClose={handleClose}
                  dialogTitle={"Create"}
                  staffId={staffId}
                  setRefetch={refetch}
                />
              </TableCell>
            </TableHead>
            {loading ? (
              <TableBody className="body">
                <TableRow className="body-row-gray">
                  <TableCell className="body-cell" colSpan={10}>
                    <LoadingPage />
                  </TableCell>
                </TableRow>
              </TableBody>
            ) : (
              <TableBody className="body">
                {tableData?.length === 0 ? (
                  <TableRow className="body-row-gray">
                    <TableCell className="body-cell" colSpan={10}>
                      <EmptyData />
                    </TableCell>
                  </TableRow>
                ) : (
                  <>
                    {tableData?.map((row, index) => {
                      return (
                        <TableRow
                          className={
                            (index + 1) % 2 === 0 ? "body-row" : "body-row-gray"
                          }
                          key={index}
                        >
                          <TableCell className="body-cell-start">
                            {/* {index + 1} */}
                            <Checkbox
                              checked={row?.isActive}
                              disabled={row?.isActive}
                              onChange={(e) =>
                                handleEditContractStatus(row?._id)
                              }
                            />
                          </TableCell>
                          <TableCell className="body-cell cell-sticky-left">
                            {row?.positions?.positionName}
                          </TableCell>
                          <TableCell className="body-cell">
                            {row?.positions?.positionNameKhmer}
                          </TableCell>
                          <TableCell className="body-cell">
                            {moment(row?.startDate).format("DD-MMM-YYYY")}
                          </TableCell>
                          <TableCell className="body-cell">
                            {moment(row?.endDate).format("DD-MMM-YYYY")}
                          </TableCell>
                          <TableCell className="body-cell">
                            {row?.positions?.inDepartment?.departmentName}
                          </TableCell>
                          <TableCell className="body-cell">
                            {row?.officeBase?.officeBaseName}
                          </TableCell>
                          <TableCell className="body-cell">
                            {row?.workingBase?.workingBaseName}
                          </TableCell>
                          <TableCell className="body-cell">
                            {row?.workingPlace?.workingPlaceName}
                          </TableCell>
                          <TableCell
                            className="body-cell-end cell-sticky-right"
                            align="right"
                          >
                            <CovidVaccinationAction
                              editData={row}
                              setRefetch={refetch}
                              userRole={userLogin?.systemAccessData?.role}
                            />
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </>
                )}
              </TableBody>
            )}
          </Table>
        </TableContainer>
      </Box>
    </div>
  );
}
