import React, { useState } from "react";
import {
  Box,
  Stack,
  Avatar,
  Grid,
  Button,
  Divider,
  Typography,
} from "@mui/material";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import CheckIcon from "@mui/icons-material/Check";
import PriorityHighIcon from "@mui/icons-material/PriorityHigh";
import moment from "moment";
//Srcs
import "../../Style/requestStyle.scss";
import LoadingPage from "../Include/LoadingPage";
import EmptyData from "../Include/EmptyData";
import OvertimeAction from "./OvertimeAction";
import ApproveAndDeny from "./ApproveAndDeny";

export default function OvertimeCard({
  loading,
  setRefetch,
  userLogin,
  overtimeDetails,
  tableData
}) {
  const [open, setOpen] = useState(false);
  const handleClose = () => setOpen(false);
  const [requestStatus, setRequestStatus] = useState("");

  const handleApprove = () => {
    setOpen(true);
    setRequestStatus("Approve");
  };

  const handleDeny = () => {
    setOpen(true);
    setRequestStatus("Deny");
  };

  return (
    <Box key={overtimeDetails?.userName} className="request-card-container">
      {
        overtimeDetails === undefined ? <Stack direction="row" justifyContent="center" width="100%">
          <EmptyData />
        </Stack> : <Stack direction="row" justifyContent="center" width="100%">
          {loading ? (
            <LoadingPage />
          ) : (
            <Box sx={{ width: { xs: "90%", md: "100%", lg: "80%", xl: "70%" } }}>
              <Grid item container spacing={1}>
                <Grid item xs={12} lg={1.5}>
                  <Stack direction="row" justifyContent="right">
                    <Avatar
                      alt="User"
                      src={overtimeDetails?.createdBy?.staffProfile?.imageSrc}
                      sx={{ width: 50, height: 50 }}
                    />
                  </Stack>
                </Grid>
                <Grid item xs={12} lg={10.5}>
                  <Stack
                    direction="column"
                    spacing={2}
                    className="card-container"
                  >
                    <Stack direction="row" justifyContent="space-between">
                      <Stack direction="column" justifyContent="center">
                        <Typography className="request-card-request-type">
                          {overtimeDetails?.createdBy?.staffProfile?.englishData
                            ?.firstNameLatin +
                            " " +
                            overtimeDetails?.createdBy?.staffProfile?.englishData
                              ?.lastNameLatin}
                        </Typography>
                      </Stack>
                      <Stack direction="column" justifyContent="center">
                        <Typography className="request-card-reason">
                          {moment(overtimeDetails?.createdAt)
                            .utc()
                            .format("DD, MMM YY")}
                        </Typography>
                      </Stack>
                      <Box
                        sx={{
                          display:
                            userLogin?._id === overtimeDetails?.createdBy?._id &&
                              overtimeDetails?.status === "Pending"
                              ? "block"
                              : "none",
                        }}
                      >
                        <OvertimeAction
                          userLogin={userLogin}
                          editData={overtimeDetails}
                          setRefetch={setRefetch}
                        />
                      </Box>
                    </Stack>

                    <Box className="request-card-header">
                      <Box sx={{ padding: "4px" }}>
                        <Typography className="request-card-request-type">
                          Total hours: {overtimeDetails?.totalOvertimeAsHours}
                        </Typography>
                        <Stack direction='column'>
                          <Typography className="request-card-item">
                            From : {moment(overtimeDetails?.startDate).format('DD-MMM-YYYY hh:mm A')}
                          </Typography>
                          <Typography className="request-card-item">
                            To : {moment(overtimeDetails?.endDate).format('DD-MMM-YYYY hh:mm A')}
                          </Typography>
                        </Stack>
                      </Box>
                    </Box>

                    <Stack direction="column">
                      <Typography className="request-card-reason">
                        Description:
                      </Typography>
                      <Typography className="request-card-reason">
                        {overtimeDetails?.reason}
                      </Typography>
                    </Stack>

                    <Divider />

                    <Grid container>
                      <Grid
                        item
                        xs={
                          userLogin?._id !== overtimeDetails?.createdBy?._id &&
                            overtimeDetails?.status === "Pending"
                            ? 6
                            : 12
                        }
                      >
                        <Stack direction="column" spacing={1}>
                          <Typography className="request-card-reason">
                            Manager respone
                          </Typography>

                          {overtimeDetails?.status === "Pending" ? (
                            <Stack direction="row" spacing={1}>
                              <HelpOutlineIcon
                                sx={{
                                  fontSize: "18px",
                                  color: "orange",
                                }}
                              />
                              <Stack
                                direction="column"
                                justifyContent="center"
                                className="request-card-item"
                              >
                                {overtimeDetails?.status}
                              </Stack>
                            </Stack>
                          ) : overtimeDetails?.status === "Denied" ? (
                            <Stack direction="row" spacing={2}>
                              <Avatar
                                alt="Manager"
                                src={
                                  overtimeDetails?.approvedOrDeniedBy
                                    ?.staffProfile?.imageSrc
                                }
                                sx={{ width: "40px", height: "40px" }}
                              />
                              <Stack direction="column" spacing={1}>
                                <Stack direction="row" spacing={1}>
                                  <Typography className="request-card-request-type">
                                    {overtimeDetails?.approvedOrDeniedBy
                                      ?.staffProfile?.englishData
                                      ?.firstNameLatin +
                                      " " +
                                      overtimeDetails?.approvedOrDeniedBy
                                        ?.staffProfile?.englishData
                                        ?.lastNameLatin}{" "}
                                    :
                                  </Typography>
                                  <Typography className="request-card-item">
                                    {moment(overtimeDetails?.statusDate)
                                      .utc()
                                      .format("DD, MMM YY")}
                                  </Typography>
                                </Stack>
                                <Stack direction="row">
                                  <PriorityHighIcon
                                    sx={{ color: "red", fontSize: "16px" }}
                                  />
                                  <Stack
                                    direction="column"
                                    justifyContent="center"
                                    className="request-card-item"
                                  >
                                    {overtimeDetails?.status}
                                  </Stack>
                                </Stack>
                                <Stack
                                  direction="column"
                                  sx={{
                                    display:
                                      overtimeDetails?.managerReply === ""
                                        ? "none"
                                        : "flex",
                                  }}
                                >
                                  <Typography className="request-card-reason">
                                    Note
                                  </Typography>
                                  <Typography className="request-card-reason">
                                    {overtimeDetails?.managerReply}
                                  </Typography>
                                </Stack>
                              </Stack>
                            </Stack>
                          ) : (
                            <Stack direction="row" spacing={2}>
                              <Avatar
                                alt="Manager"
                                src={
                                  overtimeDetails?.approvedOrDeniedBy
                                    ?.staffProfile?.imageSrc
                                }
                                sx={{ width: "40px", height: "40px" }}
                              />
                              <Stack direction="column" spacing={1}>
                                <Stack direction="row" spacing={1}>
                                  <Typography className="request-card-request-type">
                                    {overtimeDetails?.approvedOrDeniedBy
                                      ?.staffProfile?.englishData
                                      ?.firstNameLatin +
                                      " " +
                                      overtimeDetails?.approvedOrDeniedBy
                                        ?.staffProfile?.englishData
                                        ?.lastNameLatin}{" "}
                                    :
                                  </Typography>
                                  <Typography className="request-card-item">
                                    {moment(overtimeDetails?.statusDate)
                                      .utc()
                                      .format("DD, MMM YY")}
                                  </Typography>
                                </Stack>
                                <Stack direction="row" spacing={1}>
                                  <CheckIcon
                                    sx={{ color: "#198f4a", fontSize: "16px" }}
                                  />
                                  <Stack
                                    direction="column"
                                    justifyContent="center"
                                    className="request-card-item"
                                  >
                                    {overtimeDetails?.status}
                                  </Stack>
                                </Stack>
                                <Stack
                                  direction="column"
                                  sx={{
                                    display:
                                      overtimeDetails?.managerReply === ""
                                        ? "none"
                                        : "flex",
                                  }}
                                >
                                  <Typography className="request-card-reason">
                                    Note
                                  </Typography>
                                  <Typography className="request-card-reason">
                                    {overtimeDetails?.managerReply}
                                  </Typography>
                                </Stack>
                              </Stack>
                            </Stack>
                          )}
                        </Stack>
                      </Grid>
                      <Grid item xs={6}>
                        <Stack
                          direction="row"
                          spacing={2}
                          justifyContent="right"
                          sx={{
                            display:
                              userLogin?._id !==
                                overtimeDetails?.createdBy?._id &&
                                overtimeDetails?.status === "Pending"
                                ? "flex"
                                : "none",
                          }}
                        >
                          <Button className="btn-dennie" onClick={handleDeny}>
                            Denie
                          </Button>
                          <Button className="btn-approve" onClick={handleApprove}>
                            Approve
                          </Button>
                          <ApproveAndDeny
                            open={open}
                            handleClose={handleClose}
                            userLogin={userLogin}
                            editData={overtimeDetails}
                            requestStatus={requestStatus}
                            setRefetch={setRefetch}
                          />
                        </Stack>
                      </Grid>
                    </Grid>
                  </Stack>
                </Grid>
              </Grid>
            </Box>
          )}
        </Stack>
      }
    </Box>
  );
}
