import React, { useState, useContext } from "react";
import {
  Box,
  Typography,
  Stack,
  TextField,
  Button,
  InputAdornment,
  Grid,
} from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import { useMutation } from "@apollo/client";
//Src
import "../../Style/loginStyle.scss";
import RightImage from "../../Assets/login-img.png";
import PepyLogo from "../../Assets/pepy-logo.png";
import { AuthContext } from "../../Context/AuthContext";
import { VERIFY_OTP_CODE, GENERATE_OPT } from "../../Schema/Login";

export default function VerifyOtp() {
  const navigate = useNavigate();
  const { setAlert } = useContext(AuthContext);
  const [loading, setLoading] = useState(false);
  const [otpCode, setOtpCode] = useState("");
  const emailVerify = JSON.parse(window.localStorage.getItem("resetPwEmail"));
  // console.log(emailVerify, "emailVerify");

  const [generateOTP, { error }] = useMutation(GENERATE_OPT, {
    onCompleted: ({ generateOTP }) => {
      // console.log("generateOTP::", generateOTP);
      if (generateOTP?.success === true) {
        setAlert(true, "success", generateOTP?.message);
      } 
      setLoading(false);
    },
    onError: (error) => {
      setAlert(true, "error", error?.message);
    },
  });

  const handleResend = () => {
    setLoading(true);
    generateOTP({
      variables: {
        emailAddress: emailVerify,
      },
    });
  };

  const [verifyOTP, { data }] = useMutation(VERIFY_OTP_CODE, {
    onCompleted: ({ verifyOTP }) => {
      // console.log("verifyOTP::", verifyOTP);
      setLoading(false);
      if (verifyOTP?.success === true) {
        setAlert(true, "success", verifyOTP?.message);
        setTimeout(() => {
          navigate("/reset-password");
        }, 1000);
      } else {
        setAlert(true, "error", verifyOTP?.message);
      }
    },
    onError: (error) => {
      setAlert(true, "error", error?.message);
    },
  });

  const handleSubmit = () => {
    setLoading(true);
    verifyOTP({
      variables: {
        otp: otpCode,
      },
    });
  };

  let displayModalWeb = { xs: "none", sm: "flex" };
  let widthForm = { xs: "94%", sm: "90%", md: "60%" };

  return (
    <div className="login-page">
      <Grid container>
        <Grid item xs={6} sx={{ display: displayModalWeb }}>
          <Box className="right-page-container">
            <Box className="vertical-center-leftSide">
              <Typography className="company-title">
                PEPY Empowering Youth
              </Typography>
              <Typography className="company-title">
                Human Resource Management System
              </Typography>
              <img
                src={`${RightImage}`}
                alt="login image"
                style={{
                  marginTop: "40px",
                  width: "30vw",
                }}
              />
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Box className="vertical-center-rightSide">
            <Box sx={{ width: widthForm }}>
              <Stack direction="column" spacing={3} sx={{ marginTop: "20px" }}>
                <Stack direction="row" justifyContent="center">
                  <img
                    src={`${PepyLogo}`}
                    alt="pepy logo"
                    style={{
                      width: "22%",
                    }}
                  />
                </Stack>
                <Typography className="login-des">
                  Please check your email to get OTP Code. The OTP will expire after 180 seconds!
                </Typography>
                <TextField
                  placeholder="OTP Code"
                  fullWidth
                  size="small"
                  onChange={(e) => setOtpCode(e?.target?.value)}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <EmailOutlinedIcon className="icon" />
                      </InputAdornment>
                    ),
                  }}
                />
                <Stack direction="row" justifyContent="right">
                  {
                    loading ? (
                      <Typography className="link-forgot">
                        Sending...
                      </Typography>
                    ):(
                      <Button className="link-forgot" onClick={handleResend} sx={{textDecoration: 'underline', textTransform: 'initial'}}>
                        If do not recieve, Resend
                      </Button>
                    )
                  }
                 
                </Stack>

                {loading ? (
                  <Button className="btn-login">Loding...</Button>
                ) : (
                  <Button className="btn-login" onClick={handleSubmit}>
                    Verify Code
                  </Button>
                )}
              </Stack>

            </Box>
          </Box>
        </Grid>
      </Grid>
    </div>
  );
}
