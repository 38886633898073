import React, { useState } from "react";
import {
  Box,
  Stack,
  Avatar,
  Grid,
  Button,
  Divider,
  Typography,
  IconButton,
} from "@mui/material";
import moment from "moment";
//icons
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import WarningRounded from "@mui/icons-material/WarningRounded";
import CheckIcon from "@mui/icons-material/Check";
import PriorityHighIcon from "@mui/icons-material/PriorityHigh";
//Srcs
import "../../Style/createdrawerstyle.scss";
import TakeLeaveAction from "./TakeleaveAction";
import ApproveAndDeny from "./ApproveAndDeny";

export default function TakeleaveCardMobile({
  handleClose,
  setRefetch,
  takeLeaveDetails,
  userLogin,
}) {
  const [open, setOpen] = useState(false);
  const handleCloseApproveDeny = () => setOpen(false);
  const [requestStatus, setRequestStatus] = useState("");

  const handleApprove = () => {
    setOpen(true);
    setRequestStatus("Approve");
  };

  const handleDeny = () => {
    setOpen(true);
    setRequestStatus("Deny");
  };

  // console.log("takeLeaveDetails::", takeLeaveDetails);

  return (
    <Stack direction="column" className="drawer-container">
      <Stack
        direction="row"
        justifyContent="space-between"
        sx={{ marginBottom: "20px" }}
      >
        <IconButton onClick={handleClose} className="close-icon-container">
          <ArrowBackIcon className="close-icon" />
        </IconButton>

        <Stack direction="column" justifyContent="center">
          <Typography className="dialog-title">Take Leave Details</Typography>
        </Stack>
      </Stack>
      <Stack direction="column" spacing={1.5} className="drawer-card-container">
        <Stack direction="row" justifyContent="space-between">
          <Stack direction="column" justifyContent="center">
            <Typography className="request-card-request-type">
              {takeLeaveDetails?.createdBy?.staffProfile?.englishData
                ?.firstNameLatin +
                " " +
                takeLeaveDetails?.createdBy?.staffProfile?.englishData
                  ?.lastNameLatin}
            </Typography>
          </Stack>
          <Stack direction="column" justifyContent="center">
            <Typography className="request-card-reason">
              {moment(takeLeaveDetails?.createdAt).format("DD, MMM YY")}
            </Typography>
          </Stack>
          <Box
            sx={{
              display:
                userLogin?._id === takeLeaveDetails?.createdBy?._id &&
                  takeLeaveDetails?.status === "Pending"
                  ? "block"
                  : "none",
            }}
          >
            <TakeLeaveAction
              userLogin={userLogin}
              editData={takeLeaveDetails}
              setRefetch={setRefetch}
            />
          </Box>
        </Stack>

        <Box className="request-card-header">
          <Box sx={{ padding: "4px" }}>
            <Typography className="request-card-request-type">
              {takeLeaveDetails?.takeLeaveType}
            </Typography>
            <Stack direction="column">
              <Typography className="request-card-request-type">
                Total leave : {takeLeaveDetails?.totalLeaveAsDay}{" "}
                {takeLeaveDetails?.totalLeaveAsDay <= 1
                  ? "day"
                  : "days"}
              </Typography>
              <Typography className="request-card-request-type">
                Take leave type used :
              </Typography>
              {
                takeLeaveDetails?.takeLeaveTypeUsed?.map(e => {
                  return (
                    <Typography className="request-card-item">
                      - {e.takeLeaveType} : {e.quantityDayLeave}{" "}{e.quantityDayLeave <= 1 ? "day" : "days"}
                    </Typography>
                  )
                })
              }
              <br />
              <Typography className="request-card-item">
                From date :{" "}
                {moment(takeLeaveDetails?.startDate).format(
                  "DD-MMM-YYYY hh:mm A"
                )}
              </Typography>
              <Typography className="request-card-item">
                To date :{" "}
                {moment(takeLeaveDetails?.endDate).format(
                  "DD-MMM-YYYY hh:mm A"
                )}
              </Typography>
              <Typography className="request-card-item">
                Return date :{" "}
                {moment(takeLeaveDetails?.returnDate).format(
                  "DD-MMM-YYYY hh:mm A"
                )}
              </Typography>
            </Stack>
            <br />
            <Stack direction="column">
              <Typography className="request-card-request-type">
                Reasons :
              </Typography>
              <Typography className="request-card-reason">
                {takeLeaveDetails?.reason}
              </Typography>
            </Stack>
          </Box>
        </Box>

        {/* Warning message*/}
        {
          takeLeaveDetails?.warningMessage?.length > 0 ? (
            <Box className="request-card-warning">
              <Box sx={{ padding: "4px" }}>
                <Stack direction="row" alignItems={"center"}>
                  <WarningRounded
                    sx={{ color: "#ddd200", }}
                  />
                  <Typography className="request-card-request-type">
                    Warning :
                  </Typography>
                </Stack>
                <Stack direction="column">
                  {
                    takeLeaveDetails?.warningMessage?.map(element => {
                      return (
                        <Typography className="request-card-item">
                          - {element}
                        </Typography>
                      )
                    })
                  }
                </Stack>
              </Box>
            </Box>

          ) : (
            ""
          )
        }

        {/* Take leave record*/}
        {
          takeLeaveDetails?.paymentOfTakeLeaveQuantity?.length > 0 ? (
            <Box className="request-card-take-leave-record">
              <Box sx={{ padding: "4px" }}>
                <Typography className="request-card-request-type">
                  Take Leave record :
                </Typography>
                <Stack direction="column">
                  {
                    takeLeaveDetails?.paymentOfTakeLeaveQuantity?.map(element => {
                      return (
                        <>
                          <Typography className="request-card-item">
                            <ul>
                              <li>{element.takeLeaveType}(day/days) :
                                <ul>
                                  {element.takeLeaveType === "Annual Leave" ? <li>Last year remain = {element.lastYearRemainQty}</li> : ""}
                                  {element.takeLeaveType === "Annual Leave" ? <li>Current year = {element.currentYearQty}</li> : <li>Available quantity = {element.currentYearQty}</li>}
                                  <li>Used quantity = {element.UsedQty}</li>
                                </ul>
                              </li>
                            </ul>
                          </Typography>
                        </>
                      )
                    })
                  }
                </Stack>
              </Box>
            </Box>

          ) : (
            ""
          )
        }

        <Divider />

        <Grid container>
          <Grid
            item
            xs={
              userLogin?._id !== takeLeaveDetails?.createdBy?._id &&
                takeLeaveDetails?.status === "Pending"
                ? 6
                : 12
            }
          >
            <Stack direction="column" spacing={1}>
              <Typography className="request-card-reason">
                Manager respone
              </Typography>

              {takeLeaveDetails?.status === "Pending" ? (
                <Stack direction="row" spacing={1}>
                  <HelpOutlineIcon
                    sx={{
                      fontSize: "18px",
                      color: "orange",
                    }}
                  />
                  <Stack
                    direction="column"
                    justifyContent="center"
                    className="request-card-item"
                  >
                    {takeLeaveDetails?.status}
                  </Stack>
                </Stack>
              ) : takeLeaveDetails?.status === "Denied" ? (
                <Stack direction="row" spacing={2}>
                  <Avatar
                    alt="Manager"
                    src={
                      takeLeaveDetails?.approvedOrDeniedBy?.staffProfile
                        ?.imageSrc
                    }
                    sx={{ width: "40px", height: "40px" }}
                  />
                  <Stack direction="column" spacing={1}>
                    <Stack direction="row" spacing={1}>
                      <Typography className="request-card-request-type">
                        {takeLeaveDetails?.approvedOrDeniedBy?.staffProfile
                          ?.englishData?.firstNameLatin +
                          " " +
                          takeLeaveDetails?.approvedOrDeniedBy?.staffProfile
                            ?.englishData?.lastNameLatin}{" "}
                        :
                      </Typography>
                      <Typography className="request-card-item">
                        {moment(takeLeaveDetails?.statusDate).format(
                          "DD, MMM YY"
                        )}
                      </Typography>
                    </Stack>
                    <Stack direction="row">
                      <PriorityHighIcon
                        sx={{ color: "red", fontSize: "16px" }}
                      />
                      <Stack
                        direction="column"
                        justifyContent="center"
                        className="request-card-item"
                      >
                        {takeLeaveDetails?.status}
                      </Stack>
                    </Stack>
                    <Stack
                      direction="column"
                      sx={{
                        display:
                          takeLeaveDetails?.managerReply === ""
                            ? "none"
                            : "flex",
                      }}
                    >
                      <Typography className="request-card-reason">
                        Note
                      </Typography>
                      <Typography className="request-card-reason">
                        {takeLeaveDetails?.managerReply}
                      </Typography>
                    </Stack>
                  </Stack>
                </Stack>
              ) : (
                <Stack direction="row" spacing={2}>
                  <Avatar
                    alt="Manager"
                    src={
                      takeLeaveDetails?.approvedOrDeniedBy?.staffProfile
                        ?.imageSrc
                    }
                    sx={{ width: "40px", height: "40px" }}
                  />
                  <Stack direction="column" spacing={1}>
                    <Stack direction="row" spacing={1}>
                      <Typography className="request-card-request-type">
                        {takeLeaveDetails?.approvedOrDeniedBy?.staffProfile
                          ?.englishData?.firstNameLatin +
                          " " +
                          takeLeaveDetails?.approvedOrDeniedBy?.staffProfile
                            ?.englishData?.lastNameLatin}{" "}
                        :
                      </Typography>
                      <Typography className="request-card-item">
                        {moment(takeLeaveDetails?.statusDate).format(
                          "DD, MMM YY"
                        )}
                      </Typography>
                    </Stack>
                    <Stack direction="row" spacing={1}>
                      <CheckIcon sx={{ color: "#198f4a", fontSize: "16px" }} />
                      <Stack
                        direction="column"
                        justifyContent="center"
                        className="request-card-item"
                      >
                        {takeLeaveDetails?.status}
                      </Stack>
                    </Stack>
                    <Stack
                      direction="column"
                      sx={{
                        display:
                          takeLeaveDetails?.managerReply === ""
                            ? "none"
                            : "flex",
                      }}
                    >
                      <Typography className="request-card-reason">
                        Note
                      </Typography>
                      <Typography className="request-card-reason">
                        {takeLeaveDetails?.managerReply}
                      </Typography>
                    </Stack>
                  </Stack>
                </Stack>
              )}
            </Stack>
          </Grid>
          <Grid item xs={6}>
            <Stack
              direction="row"
              spacing={2}
              justifyContent="right"
              sx={{
                display:
                  userLogin?._id !== takeLeaveDetails?.createdBy?._id &&
                    takeLeaveDetails?.status === "Pending"
                    ? "flex"
                    : "none",
              }}
            >
              <Button className="btn-dennie" onClick={handleDeny}>
                Denie
              </Button>
              <Button className="btn-approve" onClick={handleApprove}>
                Approve
              </Button>
              <ApproveAndDeny
                open={open}
                handleClose={handleCloseApproveDeny}
                userLogin={userLogin}
                editData={takeLeaveDetails}
                requestStatus={requestStatus}
                setRefetch={setRefetch}
              />
            </Stack>
          </Grid>
        </Grid>
      </Stack>
    </Stack>
  );
}
