import React, { useState, useContext, useEffect } from "react";
import "./createstaff.scss";
import Button from "@mui/material/Button";
import {
  Grid,
  Stack,
  Box,
  TextField,
  Typography,
  Select,
  FormControl,
  MenuItem,
  Avatar,
  IconButton,
  Divider,
  Switch,
} from "@mui/material";
import PropTypes from 'prop-types';
import LinearProgress from '@mui/material/LinearProgress';
import { useNavigate, Link, useLocation } from "react-router-dom";
import { useFormik, Form, FormikProvider } from "formik";
import * as Yup from "yup";
import moment from "moment";
//date picker
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { useMutation, useQuery } from "@apollo/client";
//Srcs
import {
  UPDATE_STAFF_INFORMATION,
  GET_STAFFINFO_BY_ID,
} from "../../Schema/StaffInformation";
import { AuthContext } from "../../Context/AuthContext";
import CropImageFile from "../CropImage/CropImageFile";


function LinearProgressWithLabel(props) {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Box sx={{ width: '100%', mr: 1 }}>
        <LinearProgress variant="determinate" {...props} />
      </Box>
      <Box sx={{ minWidth: 35 }}>
        <Typography variant="body2" color="text.secondary">{`${Math.round(
          props.value,
        )}%`}</Typography>
      </Box>
    </Box>
  );
}
LinearProgressWithLabel.propTypes = {
  /**
   * The value of the progress indicator for the determinate and buffer variants.
   * Value between 0 and 100.
   */
  value: PropTypes.number.isRequired,
};

export default function UpdateStaff() {
  const { setAlert } = useContext(AuthContext);
  let navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [editData, setEditData] = useState({});
  //get Storage Room ID by Url
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const [staffId, setStaffId] = useState(params.get("staffId"));
  

  useEffect(() => {
    setStaffId(params.get("staffId"));
  }, [location.search]);
  // console.log("staffId::", staffId)

  //----Progress-----
  const [statusProgress, setStatusProgress] = React.useState(false)
  const [progress, setProgress] = React.useState(10);
  
  React.useEffect(() => {
    const timer = setInterval(() => {
      setProgress((prevProgress) => (prevProgress >= 100 ? 100 : prevProgress + 10));
    }, 300);
    return () => {
      clearInterval(timer);
    };
  }, []);
  //-----------------

  // upload Image
  const [profileHook, setProfileHook] = useState("");
  const [imageFile, setImageFile] = useState(null);
  const [photoURL, setPhotoURL] = useState(null);
  const [openCrop, setOpenCrop] = useState(false);

  // console.log( "imageFile:", imageFile);
  // console.log("photoURL:", photoURL);

  const handleUploadImage = (e) => {
    const imageFile = e.target.files[0];
    if (imageFile) {
      setImageFile(imageFile);
      setPhotoURL(URL.createObjectURL(imageFile));
      setOpenCrop(true);
    }
  };

  // =========================== get staffinfo by id ================================
  const { data, refetch } = useQuery(GET_STAFFINFO_BY_ID, {
    variables: {
      id: staffId,
    },
    onCompleted: ({ getStaffInfoById }) => {
      setEditData(getStaffInfoById);
    },
    onError: (error) => {
      setAlert(true, "error", error?.message);
    },
  });

  // console.log("editData::", editData)

  // ================================= Update user ==========================================
  const [updateStaffInfo] = useMutation(UPDATE_STAFF_INFORMATION, {
    onCompleted: ({ updateStaffInfo }) => {
      // console.log("updateStaffInfo::", updateStaffInfo)
      setLoading(false);
      if (updateStaffInfo?.success === true) {
        setAlert(true, "success", updateStaffInfo?.message);
        resetForm();
        setTimeout(() => {
          navigate("/staff-info");
        }, 1000);
      } else {
        setAlert(true, "error", updateStaffInfo?.message);
      }
    },
    onError: (error) => {
      setAlert(true, "error", error?.message);
      setLoading(false);
      console.log("error", error?.message);
    },
  });
  //end

  //declear formik values
  const phoneRegex = RegExp(
    /^(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/
  );
  const CreateStaff = Yup.object().shape({
    startedDate: Yup.date(),
    currentSituation: Yup.string().required("require!"),
    //Ststem Access data
    emailAddress: Yup.string().email("Invalid email!").required("required!"),
    role: Yup.string().required("require!"),
    isActive: Yup.string().required("require!"),
    //English data
    firstNameLatin: Yup.string().required("require!"),
    lastNameLatin: Yup.string().required("require!"),
    dateOfBirthEng: Yup.date().required("require!"),
    maritalStatusEng: Yup.string().required("require!"),
    nationalityEng: Yup.string(),
    identityCardEng: Yup.string(),
    passportsNumerEng: Yup.string(),
    phoneNumberEng: Yup.string(),
    gender: Yup.string().required("require!"),
    placeOfBirthcityEng: Yup.string(),
    placeOfBirthcommuneEng: Yup.string(),
    placeOfBirthdistrictEng: Yup.string(),
    placeOfBirthvillageEng: Yup.string(),
    permanancecityEng: Yup.string(),
    permanancecommuneEng: Yup.string(),
    permanancedistrictEng: Yup.string(),
    permanancevillageEng: Yup.string(),
    temporarycityEng: Yup.string(),
    temporarycommuneEng: Yup.string(),
    temporarydistrictEng: Yup.string(),
    temporaryvillageEng: Yup.string(),
    //Khmer data
    firstNameKh: Yup.string(),
    lastNameKh: Yup.string(),
    placeOfBirthcityKh: Yup.string(),
    placeOfBirthcommuneKh: Yup.string(),
    placeOfBirthdistrictKh: Yup.string(),
    placeOfBirthvillageKh: Yup.string(),
    permanancecityKh: Yup.string(),
    permanancecommuneKh: Yup.string(),
    permanancedistrictKh: Yup.string(),
    permanancevillageKh: Yup.string(),
    temporarycityKh: Yup.string(),
    temporarycommuneKh: Yup.string(),
    temporarydistrictKh: Yup.string(),
    temporaryvillageKh: Yup.string(),
  });

  const formik = useFormik({
    initialValues: {
      startedDate: moment(),
      currentSituation: "Working",
      //============Ststem Access data=========
      emailAddress: "",
      role: "User",
      isActive: true,
      //=========English data========
      firstNameLatin: "",
      lastNameLatin: "",
      dateOfBirthEng: moment(),
      maritalStatusEng: "Single",
      nationalityEng: "",
      identityCardEng: "",
      passportsNumerEng: "",
      phoneNumberEng: "",
      gender: "Male",
      placeOfBirthvillageEng: "",
      placeOfBirthcommuneEng: "",
      placeOfBirthdistrictEng: "",
      placeOfBirthcityEng: "",
      permanancevillageEng: "",
      permanancecommuneEng: "",
      permanancedistrictEng: "",
      permanancecityEng: "",
      temporaryvillageEng: "",
      temporarycommuneEng: "",
      temporarydistrictEng: "",
      temporarycityEng: "",
      //=========Khmer data========
      firstNameKh: "",
      lastNameKh: "",
      placeOfBirthvillageKh: "",
      placeOfBirthcommuneKh: "",
      placeOfBirthdistrictKh: "",
      placeOfBirthcityKh: "",
      permanancevillageKh: "",
      permanancecommuneKh: "",
      permanancecityKh: "",
      permanancedistrictKh: "",
      temporaryvillageKh: "",
      temporarycommuneKh: "",
      temporarydistrictKh: "",
      temporarycityKh: "",
    },

    validationSchema: CreateStaff,
    onSubmit: async (values) => {
      // console.log("values", values);
      setLoading(true);
      localStorage.removeItem("staffImageCroped");
      // console.log("value:::=>", profileHook);
      updateStaffInfo({
        variables: {
          id: staffId,
          input: {
            startedDate: values?.startedDate,
            currentSituation: values?.currentSituation,
            //Ststem Access data
            systemAccessData: {
              emailAddress: values?.emailAddress,
              role: values?.role,
              isActive: values?.isActive,
            },
            staffProfile: {
              imageSrc: imageFile
                ? profileHook
                : editData?.staffProfile?.imageSrc,
              imageName: "Profile Image",
              englishData: {
                firstNameLatin: values?.firstNameLatin,
                lastNameLatin: values?.lastNameLatin,
                dateOfBirthEng: values?.dateOfBirthEng,
                maritalStatusEng: values?.maritalStatusEng,
                nationalityEng: values?.nationalityEng,
                identityCardEng: values?.identityCardEng,
                passportsNumerEng: values?.passportsNumerEng,
                phoneNumberEng: values?.phoneNumberEng,
                // currentSituation: values?.currentSituation,
                gender: values?.gender,
                placeOfBirthEng: {
                  villageEng: values?.placeOfBirthvillageEng,
                  communeEng: values?.placeOfBirthcommuneEng,
                  districtEng: values?.placeOfBirthdistrictEng,
                  cityEng: values?.placeOfBirthcityEng,
                },
                permananceAddressEng: {
                  villageEng: values?.permanancevillageEng,
                  communeEng: values?.permanancecommuneEng,
                  districtEng: values?.permanancedistrictEng,
                  cityEng: values?.permanancecityEng,
                },
                temporaryAddressEng: {
                  villageEng: values?.temporaryvillageEng,
                  communeEng: values?.temporarycommuneEng,
                  districtEng: values?.temporarydistrictEng,
                  cityEng: values?.temporarycityEng,
                },
              },
              khmerData: {
                //Khmer address
                firstNameKh: values?.firstNameKh,
                lastNameKh: values?.lastNameKh,
                placeOfBirthKh: {
                  villageKh: values?.placeOfBirthvillageKh,
                  communeKh: values?.placeOfBirthcommuneKh,
                  districtKh: values?.placeOfBirthdistrictKh,
                  cityKh: values?.placeOfBirthcityKh,
                },
                permananceAddressKh: {
                  villageKh: values?.permanancevillageKh,
                  communeKh: values?.permanancecommuneKh,
                  districtKh: values?.permanancedistrictKh,
                  cityKh: values?.permanancecityKh,
                },
                temporaryAddressKh: {
                  villageKh: values?.temporaryvillageKh,
                  communeKh: values?.temporarycommuneKh,
                  districtKh: values?.temporarydistrictKh,
                  cityKh: values?.temporarycityKh,
                },
              },
            },
          },
        },
      });
    },
  });

  const {
    errors,
    touched,
    handleSubmit,
    getFieldProps,
    setFieldValue,
    values,
    resetForm,
  } = formik;

  // console.log("hello::", editData?.staffProfile?.imageSrc);

  useEffect(() => {
    if (editData) {
      setFieldValue("startedDate", editData?.startedDate);
      setFieldValue("currentSituation", editData?.currentSituation);
      // ================================= System access data ===================================
      setFieldValue("emailAddress", editData?.systemAccessData?.emailAddress);
      setFieldValue("role", editData?.systemAccessData?.role);
      setFieldValue("isActive", editData?.systemAccessData?.isActive);
      // ================================= Image data ===================================
      // setProfileHook(editData?.staffProfile?.imageSrc);
      // ================================= English data ===================================
      setFieldValue(
        "firstNameLatin",
        editData?.staffProfile?.englishData?.firstNameLatin
      );
      setFieldValue(
        "lastNameLatin",
        editData?.staffProfile?.englishData?.lastNameLatin
      );
      setFieldValue(
        "dateOfBirthEng",
        editData?.staffProfile?.englishData?.dateOfBirthEng
      );
      setFieldValue(
        "maritalStatusEng",
        editData?.staffProfile?.englishData?.maritalStatusEng
      );
      setFieldValue(
        "nationalityEng",
        editData?.staffProfile?.englishData?.nationalityEng
      );
      setFieldValue(
        "identityCardEng",
        editData?.staffProfile?.englishData?.identityCardEng
      );
      setFieldValue(
        "passportsNumerEng",
        editData?.staffProfile?.englishData?.passportsNumerEng
      );
      setFieldValue(
        "phoneNumberEng",
        editData?.staffProfile?.englishData?.phoneNumberEng
      );
      setFieldValue("gender", editData?.staffProfile?.englishData?.gender);
      //================================= Address English data ===================================
      setFieldValue(
        "placeOfBirthvillageEng",
        editData?.staffProfile?.englishData?.placeOfBirthEng?.villageEng
      );
      setFieldValue(
        "placeOfBirthcommuneEng",
        editData?.staffProfile?.englishData?.placeOfBirthEng?.communeEng
      );
      setFieldValue(
        "placeOfBirthdistrictEng",
        editData?.staffProfile?.englishData?.placeOfBirthEng?.districtEng
      );
      setFieldValue(
        "placeOfBirthcityEng",
        editData?.staffProfile?.englishData?.placeOfBirthEng?.cityEng
      );
      setFieldValue(
        "permanancevillageEng",
        editData?.staffProfile?.englishData?.permananceAddressEng?.villageEng
      );
      setFieldValue(
        "permanancecommuneEng",
        editData?.staffProfile?.englishData?.permananceAddressEng?.communeEng
      );
      setFieldValue(
        "permanancedistrictEng",
        editData?.staffProfile?.englishData?.permananceAddressEng?.districtEng
      );
      setFieldValue(
        "permanancecityEng",
        editData?.staffProfile?.englishData?.permananceAddressEng?.cityEng
      );
      setFieldValue(
        "temporaryvillageEng",
        editData?.staffProfile?.englishData?.temporaryAddressEng?.villageEng
      );
      setFieldValue(
        "temporarycommuneEng",
        editData?.staffProfile?.englishData?.temporaryAddressEng?.communeEng
      );
      setFieldValue(
        "temporarydistrictEng",
        editData?.staffProfile?.englishData?.temporaryAddressEng?.districtEng
      );
      setFieldValue(
        "temporarycityEng",
        editData?.staffProfile?.englishData?.temporaryAddressEng?.cityEng
      );
      // ================================= Khmer data ===================================
      setFieldValue(
        "firstNameKh",
        editData?.staffProfile?.khmerData?.firstNameKh
      );
      setFieldValue(
        "lastNameKh",
        editData?.staffProfile?.khmerData?.lastNameKh
      );
      //================================= Address Khmer data ===================================
      setFieldValue(
        "placeOfBirthvillageKh",
        editData?.staffProfile?.khmerData?.placeOfBirthKh?.villageKh
      );
      setFieldValue(
        "placeOfBirthcommuneKh",
        editData?.staffProfile?.khmerData?.placeOfBirthKh?.communeKh
      );
      setFieldValue(
        "placeOfBirthdistrictKh",
        editData?.staffProfile?.khmerData?.placeOfBirthKh?.districtKh
      );
      setFieldValue(
        "placeOfBirthcityKh",
        editData?.staffProfile?.khmerData?.placeOfBirthKh?.cityKh
      );
      setFieldValue(
        "permanancevillageKh",
        editData?.staffProfile?.khmerData?.permananceAddressKh?.villageKh
      );
      setFieldValue(
        "permanancecommuneKh",
        editData?.staffProfile?.khmerData?.permananceAddressKh?.communeKh
      );
      setFieldValue(
        "permanancecityKh",
        editData?.staffProfile?.khmerData?.permananceAddressKh?.districtKh
      );
      setFieldValue(
        "permanancedistrictKh",
        editData?.staffProfile?.khmerData?.permananceAddressKh?.cityKh
      );
      setFieldValue(
        "temporaryvillageKh",
        editData?.staffProfile?.khmerData?.temporaryAddressKh?.villageKh
      );
      setFieldValue(
        "temporarycommuneKh",
        editData?.staffProfile?.khmerData?.temporaryAddressKh?.communeKh
      );
      setFieldValue(
        "temporarydistrictKh",
        editData?.staffProfile?.khmerData?.temporaryAddressKh?.districtKh
      );
      setFieldValue(
        "temporarycityKh",
        editData?.staffProfile?.khmerData?.temporaryAddressKh?.cityKh
      );
    }
  }, [editData]);

  // console.log("editData::", editData)
  // console.log("profileHook::", profileHook)

  return (
    <div className="create-staff">
      <Stack direction="row" spacing={2} className="header-container">
        <Stack direction="row" spacing={1}>
          <Stack direction="column" justifyContent="center">
            <Link className="link" to="/staff-info">
              Staff Information
            </Link>
          </Stack>
          <Stack direction="column" justifyContent="center">
            <Typography className="page-title">/</Typography>
          </Stack>
          <Stack direction="column" justifyContent="center">
            <Typography className="page-title">Update Staff</Typography>
          </Stack>
        </Stack>

        <Box sx={{ flexGrow: 1 }} />

        <Stack direction="row">
          {loading || (statusProgress && progress < 100) ? (
            <Button className="btn-create" fullWidth>
              Loading...
            </Button>
          ) : (
            <Button
              // disabled
              className="btn-create"
              fullWidth
              onClick={() => {
                // setCheckListRequried(true);
                handleSubmit();
              }}
            >
              Update
            </Button>
          )}
        </Stack>
      </Stack>

      <FormikProvider value={formik}>
        <Form noValidate autoComplete="off" onSubmit={handleSubmit}>
          <Box className="body-container">
            <Grid container rowSpacing={1.5} columnSpacing={6}>
              <Grid item xs={12}>
                <Grid container spacing={4}>
                  <Grid item xs={12} lg={6}>
                    <Stack direction="row" justifyContent="center">
                      {!openCrop ? (
                        <Box className="title-add">
                          <Stack
                            className="profile"
                            direction="row"
                            justifycontent="center"
                          >
                            <IconButton component="label">
                              <TextField
                                type="file"
                                id="image"
                                sx={{ display: "none" }}
                                onChange={handleUploadImage}
                              />
                              <Avatar
                                className="avater-image"
                                src={
                                  imageFile
                                    ? URL.createObjectURL(imageFile)
                                    : editData?.staffProfile?.imageSrc === ""
                                    ? null
                                    : editData?.staffProfile?.imageSrc
                                }
                              />
                              <input type="file" hidden />
                            </IconButton>
                            
                          </Stack>

                          <Stack
                            direction="row"
                            justifyContent="center"
                            spacing={2}
                            sx={{ mt: 1 }}
                          >
                            {
                              statusProgress && progress < 100 ? (
                                <Box sx={{ width: '100%', marginLeft: '20px' }}>
                                  <LinearProgressWithLabel value={progress} />
                                </Box>
                              ):(
                                <Typography className="text-En">Profile</Typography>
                              )
                            }
                            
            
                          </Stack>
                        </Box>
                      ) : (
                        <CropImageFile
                          setProgress={setProgress}
                          setStatusProgress={setStatusProgress}
                          setImageFile={setImageFile}
                          photoURL={photoURL}
                          openCrop={openCrop}
                          setOpenCrop={setOpenCrop}
                          setPhotoURL={setPhotoURL}
                          setUploadHook={setProfileHook}
                          srcFromBackend={editData?.staffProfile?.imageSrc}
                        />
                      )}
                    </Stack>
                  </Grid>

                  <Grid item xs={12} lg={6}>
                    <Grid container rowSpacing={1.5} columnSpacing={4}>
                      <Grid item xs={12}>
                        <Stack direction="row">
                          <Stack direction="column" justifyContent="center">
                            <Typography className="body-text">
                              Role :
                            </Typography>
                          </Stack>
                          <Box sx={{ flexGrow: 1 }} />
                          <FormControl size="small" className="text-field">
                            <Select
                              labelId="demo-select-small"
                              id="demo-select-small"
                              value={values?.role}
                              onChange={(event) => {
                                setFieldValue("role", event.target.value);
                              }}
                              error={Boolean(touched.role && errors.role)}
                              helperText={touched.role && errors.role}
                            >
                              <MenuItem value={"User"}>User</MenuItem>
                              <MenuItem value={"Admin"}>Admin</MenuItem>
                            </Select>
                          </FormControl>
                        </Stack>
                      </Grid>
                      <Grid item xs={12}>
                        <Stack direction="row">
                          <Stack direction="column" justifyContent="center">
                            <Typography className="body-text">
                              Is Active :
                            </Typography>
                          </Stack>
                          <Box sx={{ flexGrow: 1 }} />
                          <Switch
                            checked={values?.isActive}
                            onChange={(e) =>
                              setFieldValue("isActive", e.target.checked)
                            }
                            inputProps={{ "aria-label": "controlled" }}
                          />
                        </Stack>
                      </Grid>
                      <Grid item xs={12}>
                        <Stack direction="row">
                          <Stack direction="column" justifyContent="center">
                            <Typography className="body-text">
                              Email :
                            </Typography>
                          </Stack>
                          <Box sx={{ flexGrow: 1 }} />
                          <TextField
                            fullWidth
                            size="small"
                            className="text-field"
                            placeholder="Email"
                            {...getFieldProps("emailAddress")}
                            error={Boolean(
                              touched.emailAddress && errors.emailAddress
                            )}
                            helperText={
                              touched.emailAddress && errors.emailAddress
                            }
                          />
                        </Stack>
                      </Grid>

                      <Grid item xs={12}>
                        <Stack direction="row">
                          <Stack direction="column" justifyContent="center">
                            <Typography className="body-text">
                              Start Date :
                            </Typography>
                          </Stack>
                          <Box sx={{ flexGrow: 1 }} />
                          <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <DatePicker
                              value={values?.startedDate}
                              onChange={(e) => setFieldValue("startedDate", e)}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  className="text-field"
                                  size="small"
                                  fullWidth
                                />
                              )}
                            />
                          </LocalizationProvider>
                        </Stack>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={12}>
                <Typography className="data-title">English Data</Typography>
                <Divider sx={{ marginBottom: "20px" }} />
              </Grid>
              <Grid item xs={12} lg={6}>
                {/* ========================================== English Data =========================================== */}

                <Stack direction="row">
                  <Stack direction="column" justifyContent="center">
                    <Typography className="body-text">First name :</Typography>
                  </Stack>
                  <Box sx={{ flexGrow: 1 }} />
                  <TextField
                    fullWidth
                    size="small"
                    className="text-field"
                    placeholder="first name"
                    {...getFieldProps("firstNameLatin")}
                    error={Boolean(
                      touched.firstNameLatin && errors.firstNameLatin
                    )}
                    helperText={touched.firstNameLatin && errors.firstNameLatin}
                  />
                </Stack>
              </Grid>
              <Grid item xs={12} lg={6}>
                <Stack direction="row">
                  <Stack direction="column" justifyContent="center">
                    <Typography className="body-text">Last name :</Typography>
                  </Stack>
                  <Box sx={{ flexGrow: 1 }} />
                  <TextField
                    fullWidth
                    size="small"
                    className="text-field"
                    placeholder="last name"
                    {...getFieldProps("lastNameLatin")}
                    error={Boolean(
                      touched.lastNameLatin && errors.lastNameLatin
                    )}
                    helperText={touched.lastNameLatin && errors.lastNameLatin}
                  />
                </Stack>
              </Grid>
              <Grid item xs={12} lg={6}>
                <Stack direction="row">
                  <Stack direction="column" justifyContent="center">
                    <Typography className="body-text">
                      Date Of birth :
                    </Typography>
                  </Stack>
                  <Box sx={{ flexGrow: 1 }} />
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                      value={values?.dateOfBirthEng}
                      onChange={(e) => setFieldValue("dateOfBirthEng", e)}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          className="text-field"
                          size="small"
                          fullWidth
                        />
                      )}
                    />
                  </LocalizationProvider>
                </Stack>
              </Grid>

              <Grid item xs={12} lg={6}>
                <Stack direction="row">
                  <Stack direction="column" justifyContent="center">
                    <Typography className="body-text">
                      Current Situation :
                    </Typography>
                  </Stack>
                  <Box sx={{ flexGrow: 1 }} />
                  <FormControl size="small" className="text-field">
                    <Select
                      labelId="demo-select-small"
                      id="demo-select-small"
                      value={values?.currentSituation}
                      onChange={(event) => {
                        setFieldValue("currentSituation", event.target.value);
                      }}
                      error={Boolean(
                        touched.currentSituation && errors.currentSituation
                      )}
                      helperText={
                        touched.currentSituation && errors.currentSituation
                      }
                    >
                      <MenuItem value={"Working"}>Working</MenuItem>
                      <MenuItem value={"Stop"}>Stop</MenuItem>
                    </Select>
                  </FormControl>
                </Stack>
              </Grid>

              <Grid item xs={12} lg={6}>
                <Stack direction="row">
                  <Stack direction="column" justifyContent="center">
                    <Typography className="body-text">Gender :</Typography>
                  </Stack>
                  <Box sx={{ flexGrow: 1 }} />
                  <FormControl size="small" className="text-field">
                    <Select
                      labelId="demo-select-small"
                      id="demo-select-small"
                      value={values?.gender}
                      onChange={(event) => {
                        setFieldValue("gender", event.target.value);
                      }}
                      error={Boolean(touched.gender && errors.gender)}
                      helperText={touched.gender && errors.gender}
                    >
                      <MenuItem value={"Male"}>Male</MenuItem>
                      <MenuItem value={"Female"}>Female</MenuItem>
                    </Select>
                  </FormControl>
                </Stack>
              </Grid>
              <Grid item xs={12} lg={6}>
                <Stack direction="row">
                  <Stack direction="column" justifyContent="center">
                    <Typography className="body-text">
                      Marital Status :
                    </Typography>
                  </Stack>
                  <Box sx={{ flexGrow: 1 }} />

                  <FormControl size="small" className="text-field">
                    <Select
                      labelId="demo-select-small"
                      id="demo-select-small"
                      value={values?.maritalStatusEng}
                      onChange={(event) => {
                        setFieldValue("maritalStatusEng", event.target.value);
                      }}
                      error={Boolean(
                        touched.maritalStatusEng && errors.maritalStatusEng
                      )}
                      helperText={
                        touched.maritalStatusEng && errors.maritalStatusEng
                      }
                    >
                      <MenuItem value={"Single"}>Single</MenuItem>
                      <MenuItem value={"Married"}>Married</MenuItem>
                    </Select>
                  </FormControl>
                </Stack>
              </Grid>
              <Grid item xs={12} lg={6}>
                <Stack direction="row">
                  <Stack direction="column" justifyContent="center">
                    <Typography className="body-text">Nationality :</Typography>
                  </Stack>
                  <Box sx={{ flexGrow: 1 }} />
                  <TextField
                    fullWidth
                    size="small"
                    className="text-field"
                    placeholder="nationality"
                    {...getFieldProps("nationalityEng")}
                    error={Boolean(
                      touched.nationalityEng && errors.nationalityEng
                    )}
                    helperText={touched.nationalityEng && errors.nationalityEng}
                  />
                </Stack>
              </Grid>
              <Grid item xs={12} lg={6}>
                <Stack direction="row">
                  <Stack direction="column" justifyContent="center">
                    <Typography className="body-text">
                      Identity Card :
                    </Typography>
                  </Stack>
                  <Box sx={{ flexGrow: 1 }} />
                  <TextField
                    fullWidth
                    size="small"
                    className="text-field"
                    placeholder="identity card"
                    {...getFieldProps("identityCardEng")}
                    error={Boolean(
                      touched.identityCardEng && errors.identityCardEng
                    )}
                    helperText={
                      touched.identityCardEng && errors.identityCardEng
                    }
                  />
                </Stack>
              </Grid>
              <Grid item xs={12} lg={6}>
                <Stack direction="row">
                  <Stack direction="column" justifyContent="center">
                    <Typography className="body-text">
                      Passport Number :
                    </Typography>
                  </Stack>
                  <Box sx={{ flexGrow: 1 }} />
                  <TextField
                    fullWidth
                    size="small"
                    className="text-field"
                    placeholder="passport number"
                    {...getFieldProps("passportsNumerEng")}
                    error={Boolean(
                      touched.passportsNumerEng && errors.passportsNumerEng
                    )}
                    helperText={
                      touched.passportsNumerEng && errors.passportsNumerEng
                    }
                  />
                </Stack>
              </Grid>
              <Grid item xs={12} lg={6}>
                <Stack direction="row">
                  <Stack direction="column" justifyContent="center">
                    <Typography className="body-text">
                      Phone Number :
                    </Typography>
                  </Stack>
                  <Box sx={{ flexGrow: 1 }} />
                  <TextField
                    fullWidth
                    size="small"
                    className="text-field"
                    placeholder="phone number"
                    {...getFieldProps("phoneNumberEng")}
                    error={Boolean(
                      touched.phoneNumberEng && errors.phoneNumberEng
                    )}
                    helperText={touched.phoneNumberEng && errors.phoneNumberEng}
                  />
                </Stack>
              </Grid>

              {/* ============================================ place of birth ========================================= */}
              <Grid item xs={12} lg={6}>
                <Stack direction="row">
                  <Stack direction="column" justifyContent="center">
                    <Typography className="body-text">
                      Place of birth :
                    </Typography>
                  </Stack>
                  <Box sx={{ flexGrow: 1 }} />
                  <TextField
                    fullWidth
                    size="small"
                    className="text-field"
                    placeholder="village"
                    {...getFieldProps("placeOfBirthvillageEng")}
                    error={Boolean(
                      touched.placeOfBirthvillageEng &&
                        errors.placeOfBirthvillageEng
                    )}
                    helperText={
                      touched.placeOfBirthvillageEng &&
                      errors.placeOfBirthvillageEng
                    }
                  />
                </Stack>
              </Grid>
              <Grid item xs={12} lg={6}>
                <Stack direction="row">
                  <Stack direction="column" justifyContent="center">
                    <Typography className="body-text">
                      Place of birth :
                    </Typography>
                  </Stack>
                  <Box sx={{ flexGrow: 1 }} />
                  <TextField
                    fullWidth
                    size="small"
                    className="text-field"
                    placeholder="commune"
                    {...getFieldProps("placeOfBirthcommuneEng")}
                    error={Boolean(
                      touched.placeOfBirthcommuneEng &&
                        errors.placeOfBirthcommuneEng
                    )}
                    helperText={
                      touched.placeOfBirthcommuneEng &&
                      errors.placeOfBirthcommuneEng
                    }
                  />
                </Stack>
              </Grid>
              <Grid item xs={12} lg={6}>
                <Stack direction="row">
                  <Stack direction="column" justifyContent="center">
                    <Typography className="body-text">
                      Place of birth :
                    </Typography>
                  </Stack>
                  <Box sx={{ flexGrow: 1 }} />
                  <TextField
                    fullWidth
                    size="small"
                    className="text-field"
                    placeholder="district"
                    {...getFieldProps("placeOfBirthdistrictEng")}
                    error={Boolean(
                      touched.placeOfBirthdistrictEng &&
                        errors.placeOfBirthdistrictEng
                    )}
                    helperText={
                      touched.placeOfBirthdistrictEng &&
                      errors.placeOfBirthdistrictEng
                    }
                  />
                </Stack>
              </Grid>
              <Grid item xs={12} lg={6}>
                <Stack direction="row">
                  <Stack direction="column" justifyContent="center">
                    <Typography className="body-text">
                      Place of birth :
                    </Typography>
                  </Stack>
                  <Box sx={{ flexGrow: 1 }} />
                  <TextField
                    fullWidth
                    size="small"
                    className="text-field"
                    placeholder="province/city"
                    {...getFieldProps("placeOfBirthcityEng")}
                    error={Boolean(
                      touched.placeOfBirthcityEng && errors.placeOfBirthcityEng
                    )}
                    helperText={
                      touched.placeOfBirthcityEng && errors.placeOfBirthcityEng
                    }
                  />
                </Stack>
              </Grid>

              {/* ========================================== Permanance address ======================================== */}
              <Grid item xs={12} lg={6}>
                <Stack direction="row">
                  <Stack direction="column" justifyContent="center">
                    <Typography className="body-text">
                      Permanance address :
                    </Typography>
                  </Stack>
                  <Box sx={{ flexGrow: 1 }} />
                  <TextField
                    fullWidth
                    size="small"
                    className="text-field"
                    placeholder="village"
                    {...getFieldProps("permanancevillageEng")}
                    error={Boolean(
                      touched.permanancevillageEng &&
                        errors.permanancevillageEng
                    )}
                    helperText={
                      touched.permanancevillageEng &&
                      errors.permanancevillageEng
                    }
                  />
                </Stack>
              </Grid>
              <Grid item xs={12} lg={6}>
                <Stack direction="row">
                  <Stack direction="column" justifyContent="center">
                    <Typography className="body-text">
                      Permanance address :
                    </Typography>
                  </Stack>
                  <Box sx={{ flexGrow: 1 }} />
                  <TextField
                    fullWidth
                    size="small"
                    className="text-field"
                    placeholder="commune"
                    {...getFieldProps("permanancecommuneEng")}
                    error={Boolean(
                      touched.permanancecommuneEng &&
                        errors.permanancecommuneEng
                    )}
                    helperText={
                      touched.permanancecommuneEng &&
                      errors.permanancecommuneEng
                    }
                  />
                </Stack>
              </Grid>
              <Grid item xs={12} lg={6}>
                <Stack direction="row">
                  <Stack direction="column" justifyContent="center">
                    <Typography className="body-text">
                      Permanance address :
                    </Typography>
                  </Stack>
                  <Box sx={{ flexGrow: 1 }} />
                  <TextField
                    fullWidth
                    size="small"
                    className="text-field"
                    placeholder="district"
                    {...getFieldProps("permanancedistrictEng")}
                    error={Boolean(
                      touched.permanancedistrictEng &&
                        errors.permanancedistrictEng
                    )}
                    helperText={
                      touched.permanancedistrictEng &&
                      errors.permanancedistrictEng
                    }
                  />
                </Stack>
              </Grid>
              <Grid item xs={12} lg={6}>
                <Stack direction="row">
                  <Stack direction="column" justifyContent="center">
                    <Typography className="body-text">
                      Permanance address :
                    </Typography>
                  </Stack>
                  <Box sx={{ flexGrow: 1 }} />
                  <TextField
                    fullWidth
                    size="small"
                    className="text-field"
                    placeholder="province/city"
                    {...getFieldProps("permanancecityEng")}
                    error={Boolean(
                      touched.permanancecityEng && errors.permanancecityEng
                    )}
                    helperText={
                      touched.permanancecityEng && errors.permanancecityEng
                    }
                  />
                </Stack>
              </Grid>

              {/* ========================================== Temporary address ======================================== */}
              <Grid item xs={12} lg={6}>
                <Stack direction="row">
                  <Stack direction="column" justifyContent="center">
                    <Typography className="body-text">
                      Temporary address :
                    </Typography>
                  </Stack>
                  <Box sx={{ flexGrow: 1 }} />
                  <TextField
                    fullWidth
                    size="small"
                    className="text-field"
                    placeholder="village"
                    {...getFieldProps("temporaryvillageEng")}
                  />
                </Stack>
              </Grid>
              <Grid item xs={12} lg={6}>
                <Stack direction="row">
                  <Stack direction="column" justifyContent="center">
                    <Typography className="body-text">
                      Temporary address :
                    </Typography>
                  </Stack>
                  <Box sx={{ flexGrow: 1 }} />
                  <TextField
                    fullWidth
                    size="small"
                    className="text-field"
                    placeholder="commune"
                    {...getFieldProps("temporarycommuneEng")}
                  />
                </Stack>
              </Grid>
              <Grid item xs={12} lg={6}>
                <Stack direction="row">
                  <Stack direction="column" justifyContent="center">
                    <Typography className="body-text">
                      Temporary address :
                    </Typography>
                  </Stack>
                  <Box sx={{ flexGrow: 1 }} />
                  <TextField
                    fullWidth
                    size="small"
                    className="text-field"
                    placeholder="district"
                    {...getFieldProps("temporarydistrictEng")}
                  />
                </Stack>
              </Grid>
              <Grid item xs={12} lg={6}>
                <Stack direction="row">
                  <Stack direction="column" justifyContent="center">
                    <Typography className="body-text">
                      Temporary address :
                    </Typography>
                  </Stack>
                  <Box sx={{ flexGrow: 1 }} />
                  <TextField
                    fullWidth
                    size="small"
                    className="text-field"
                    placeholder="province/city"
                    {...getFieldProps("temporarycityEng")}
                  />
                </Stack>
              </Grid>

              {/* ====================================== Khmer Data ================================= */}
              <Grid item xs={12}>
                <Typography className="data-title">Khmer Data</Typography>
                <Divider sx={{ marginBottom: "20px" }} />
              </Grid>
              <Grid item xs={12} lg={6}>
                <Stack direction="row">
                  <Stack direction="column" justifyContent="center">
                    <Typography className="body-text">First name :</Typography>
                  </Stack>
                  <Box sx={{ flexGrow: 1 }} />
                  <TextField
                    fullWidth
                    size="small"
                    className="text-field"
                    placeholder="first name"
                    {...getFieldProps("firstNameKh")}
                    error={Boolean(touched.firstNameKh && errors.firstNameKh)}
                    helperText={touched.firstNameKh && errors.firstNameKh}
                  />
                </Stack>
              </Grid>
              <Grid item xs={12} lg={6}>
                <Stack direction="row">
                  <Stack direction="column" justifyContent="center">
                    <Typography className="body-text">Last name :</Typography>
                  </Stack>
                  <Box sx={{ flexGrow: 1 }} />
                  <TextField
                    fullWidth
                    size="small"
                    className="text-field"
                    placeholder="last name"
                    {...getFieldProps("lastNameKh")}
                    error={Boolean(touched.lastNameKh && errors.lastNameKh)}
                    helperText={touched.lastNameKh && errors.lastNameKh}
                  />
                </Stack>
              </Grid>

              {/* ============================================ place of birth khmer ========================================= */}
              <Grid item xs={12} lg={6}>
                <Stack direction="row">
                  <Stack direction="column" justifyContent="center">
                    <Typography className="body-text">
                      Place of birth village :
                    </Typography>
                  </Stack>
                  <Box sx={{ flexGrow: 1 }} />
                  <TextField
                    fullWidth
                    size="small"
                    className="text-field"
                    placeholder="village"
                    {...getFieldProps("placeOfBirthvillageKh")}
                    error={Boolean(
                      touched.placeOfBirthvillageKh &&
                        errors.placeOfBirthvillageKh
                    )}
                    helperText={
                      touched.placeOfBirthvillageKh &&
                      errors.placeOfBirthvillageKh
                    }
                  />
                </Stack>
              </Grid>
              <Grid item xs={12} lg={6}>
                <Stack direction="row">
                  <Stack direction="column" justifyContent="center">
                    <Typography className="body-text">
                      Place of birth commune :
                    </Typography>
                  </Stack>
                  <Box sx={{ flexGrow: 1 }} />
                  <TextField
                    fullWidth
                    size="small"
                    className="text-field"
                    placeholder="commune"
                    {...getFieldProps("placeOfBirthcommuneKh")}
                    error={Boolean(
                      touched.placeOfBirthcommuneKh &&
                        errors.placeOfBirthcommuneKh
                    )}
                    helperText={
                      touched.placeOfBirthcommuneKh &&
                      errors.placeOfBirthcommuneKh
                    }
                  />
                </Stack>
              </Grid>
              <Grid item xs={12} lg={6}>
                <Stack direction="row">
                  <Stack direction="column" justifyContent="center">
                    <Typography className="body-text">
                      Place of birth district :
                    </Typography>
                  </Stack>
                  <Box sx={{ flexGrow: 1 }} />
                  <TextField
                    fullWidth
                    size="small"
                    className="text-field"
                    placeholder="district"
                    {...getFieldProps("placeOfBirthdistrictKh")}
                    error={Boolean(
                      touched.placeOfBirthdistrictKh &&
                        errors.placeOfBirthdistrictKh
                    )}
                    helperText={
                      touched.placeOfBirthdistrictKh &&
                      errors.placeOfBirthdistrictKh
                    }
                  />
                </Stack>
              </Grid>
              <Grid item xs={12} lg={6}>
                <Stack direction="row">
                  <Stack direction="column" justifyContent="center">
                    <Typography className="body-text">
                      Place of birth province :
                    </Typography>
                  </Stack>
                  <Box sx={{ flexGrow: 1 }} />
                  <TextField
                    fullWidth
                    size="small"
                    className="text-field"
                    placeholder="province/city"
                    {...getFieldProps("placeOfBirthcityKh")}
                    error={Boolean(
                      touched.placeOfBirthcityKh && errors.placeOfBirthcityKh
                    )}
                    helperText={
                      touched.placeOfBirthcityKh && errors.placeOfBirthcityKh
                    }
                  />
                </Stack>
              </Grid>

              {/* ========================================== Permanance address khmer ======================================== */}
              <Grid item xs={12} lg={6}>
                <Stack direction="row">
                  <Stack direction="column" justifyContent="center">
                    <Typography className="body-text">
                      Permanance address village :
                    </Typography>
                  </Stack>
                  <Box sx={{ flexGrow: 1 }} />
                  <TextField
                    fullWidth
                    size="small"
                    className="text-field"
                    placeholder="village"
                    {...getFieldProps("permanancevillageKh")}
                    error={Boolean(
                      touched.permanancevillageKh && errors.permanancevillageKh
                    )}
                    helperText={
                      touched.permanancevillageKh && errors.permanancevillageKh
                    }
                  />
                </Stack>
              </Grid>
              <Grid item xs={12} lg={6}>
                <Stack direction="row">
                  <Stack direction="column" justifyContent="center">
                    <Typography className="body-text">
                      Permanance address commune :
                    </Typography>
                  </Stack>
                  <Box sx={{ flexGrow: 1 }} />
                  <TextField
                    fullWidth
                    size="small"
                    className="text-field"
                    placeholder="commune"
                    {...getFieldProps("permanancecommuneKh")}
                    error={Boolean(
                      touched.permanancecommuneKh && errors.permanancecommuneKh
                    )}
                    helperText={
                      touched.permanancecommuneKh && errors.permanancecommuneKh
                    }
                  />
                </Stack>
              </Grid>
              <Grid item xs={12} lg={6}>
                <Stack direction="row">
                  <Stack direction="column" justifyContent="center">
                    <Typography className="body-text">
                      Permanance address district :
                    </Typography>
                  </Stack>
                  <Box sx={{ flexGrow: 1 }} />
                  <TextField
                    fullWidth
                    size="small"
                    className="text-field"
                    placeholder="district"
                    {...getFieldProps("permanancedistrictKh")}
                    error={Boolean(
                      touched.permanancedistrictKh &&
                        errors.permanancedistrictKh
                    )}
                    helperText={
                      touched.permanancedistrictKh &&
                      errors.permanancedistrictKh
                    }
                  />
                </Stack>
              </Grid>
              <Grid item xs={12} lg={6}>
                <Stack direction="row">
                  <Stack direction="column" justifyContent="center">
                    <Typography className="body-text">
                      Permanance address province :
                    </Typography>
                  </Stack>
                  <Box sx={{ flexGrow: 1 }} />
                  <TextField
                    fullWidth
                    size="small"
                    className="text-field"
                    placeholder="province/city"
                    {...getFieldProps("permanancecityKh")}
                    error={Boolean(
                      touched.permanancecityKh && errors.permanancecityKh
                    )}
                    helperText={
                      touched.permanancecityKh && errors.permanancecityKh
                    }
                  />
                </Stack>
              </Grid>

              {/* ========================================== Temporary address khmer ======================================== */}
              <Grid item xs={12} lg={6}>
                <Stack direction="row">
                  <Stack direction="column" justifyContent="center">
                    <Typography className="body-text">
                      Temporary address village :
                    </Typography>
                  </Stack>
                  <Box sx={{ flexGrow: 1 }} />
                  <TextField
                    fullWidth
                    size="small"
                    className="text-field"
                    placeholder="village"
                    {...getFieldProps("temporaryvillageKh")}
                  />
                </Stack>
              </Grid>
              <Grid item xs={12} lg={6}>
                <Stack direction="row">
                  <Stack direction="column" justifyContent="center">
                    <Typography className="body-text">
                      Temporary address commune :
                    </Typography>
                  </Stack>
                  <Box sx={{ flexGrow: 1 }} />
                  <TextField
                    fullWidth
                    size="small"
                    className="text-field"
                    placeholder="commune"
                    {...getFieldProps("temporarycommuneKh")}
                  />
                </Stack>
              </Grid>
              <Grid item xs={12} lg={6}>
                <Stack direction="row">
                  <Stack direction="column" justifyContent="center">
                    <Typography className="body-text">
                      Temporary address district :
                    </Typography>
                  </Stack>
                  <Box sx={{ flexGrow: 1 }} />
                  <TextField
                    fullWidth
                    size="small"
                    className="text-field"
                    placeholder="district"
                    {...getFieldProps("temporarydistrictKh")}
                  />
                </Stack>
              </Grid>
              <Grid item xs={12} lg={6}>
                <Stack direction="row">
                  <Stack direction="column" justifyContent="center">
                    <Typography className="body-text">
                      Temporary address province :
                    </Typography>
                  </Stack>
                  <Box sx={{ flexGrow: 1 }} />
                  <TextField
                    fullWidth
                    size="small"
                    className="text-field"
                    placeholder="province/city"
                    {...getFieldProps("temporarycityKh")}
                  />
                </Stack>
              </Grid>
            </Grid>
          </Box>
        </Form>
      </FormikProvider>
    </div>
  );
}
