import { gql } from "@apollo/client";

export const CREATE_WORKING_HISTORY = gql`
  mutation CreateWorkingHistory(
    $staffInfoId: ID!
    $input: WorkingHistoryInput
  ) {
    createWorkingHistory(staffInfoId: $staffInfoId, input: $input) {
      success
      message
    }
  }
`;

export const GET_WORKING_HISTORY_BY_STAFFID = gql`
  query GetWorkingHistoryPagination(
    $page: Int
    $limit: Int
    $keyword: String
    $pagination: Boolean
    $staffInfoId: String
  ) {
    getWorkingHistoryPagination(
      page: $page
      limit: $limit
      keyword: $keyword
      pagination: $pagination
      staffInfoId: $staffInfoId
    ) {
      data {
        _id
        workingPlace
        positions
        startDate
        endDate
        fileUpload
        responsibilities
        remark
        createdAt
        updatedAt
      }
      paginator {
        slNo
        prev
        next
        perPage
        totalPosts
        totalPages
        currentPage
        hasPrevPage
        hasNextPage
        totalDocs
      }
    }
  }
`;

export const UPDATE_WORKING_HISTORY = gql`
  mutation UpdateWorkingHistory($input: WorkingHistoryInput, $id: ID) {
    updateWorkingHistory(input: $input, _id: $id) {
      success
      message
    }
  }
`;

export const DELETE_WORKING_HISTORY = gql`
  mutation DeleteWorkingHistory($id: ID) {
    deleteWorkingHistory(_id: $id) {
      success
      message
    }
  }
`;
