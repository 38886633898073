import React, { useState, useContext } from "react";
import {
  Box,
  Typography,
  Stack,
  TextField,
  Button,
  InputAdornment,
  Grid,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import { useLazyQuery, useQuery, useMutation } from "@apollo/client";
//Src
import "../../Style/loginStyle.scss";
import RightImage from "../../Assets/login-img.png";
import PepyLogo from "../../Assets/pepy-logo.png";
import { AuthContext } from "../../Context/AuthContext";
import { GENERATE_OPT } from "../../Schema/Login";

export default function GenerateOTP() {
  const navigate = useNavigate();
  const { setAlert } = useContext(AuthContext);
  const [loading, setLoading] = useState(false);
  const [emailAddress, setEmailAddress] = useState("");

  const [generateOTP, { data, error }] = useMutation(GENERATE_OPT, {
    onCompleted: ({ generateOTP }) => {
      // console.log("generateOTP::", generateOTP);
      if (generateOTP?.success === true) {
        setAlert(true, "success", generateOTP?.message);
        window.localStorage.setItem(
          "resetPwEmail",
          JSON.stringify(emailAddress)
        );
        setTimeout(() => {
          navigate("/verifyotp");
        }, 1000);
      } else {
        setAlert(true, "error", generateOTP?.message);
      }
      setLoading(false);
    },
    onError: (error) => {
      setAlert(true, "error", error?.message);
    },
  });

  const handleSubmit = () => {
    setLoading(true);
    generateOTP({
      variables: {
        emailAddress: emailAddress,
      },
    });
  };

  // console.log("emailAddress::", emailAddress);

  let displayModalWeb = { xs: "none", sm: "flex" };
  let widthForm = { xs: "94%", sm: "90%", md: "60%" };

  return (
    <div className="login-page">
      <Grid container>
        <Grid item xs={6} sx={{ display: displayModalWeb }}>
          <Box className="right-page-container">
            <Box className="vertical-center-leftSide">
              <Typography className="company-title">
                PEPY Empowering Youth
              </Typography>
              <Typography className="company-title">
                Human Resource Management System
              </Typography>
              <img
                src={`${RightImage}`}
                alt="login image"
                style={{
                  marginTop: "40px",
                  width: "30vw",
                }}
              />
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Box className="vertical-center-rightSide">
            <Box sx={{ width: widthForm }}>
              <Stack direction="column" spacing={3} sx={{ marginTop: "20px" }}>
                <Stack direction="row" justifyContent="center">
                  <img
                    src={`${PepyLogo}`}
                    alt="pepy logo"
                    style={{
                      width: "22%",
                    }}
                  />
                </Stack>
                <Typography className="login-des">
                  Please input your email to receive your reset password's code!
                </Typography>
                <TextField
                  placeholder="email address"
                  fullWidth
                  size="small"
                  onChange={(e) => setEmailAddress(e?.target?.value)}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <EmailOutlinedIcon className="icon" />
                      </InputAdornment>
                    ),
                  }}
                />
                {loading ? (
                  <Button className="btn-login">Loding...</Button>
                ) : (
                  <Button className="btn-login" onClick={handleSubmit}>
                    Send
                  </Button>
                )}
              </Stack>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </div>
  );
}
