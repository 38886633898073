import React, { useState } from "react";
import { TextField, IconButton, Stack } from "@mui/material";
//Icons
import DeleteIcon from "@mui/icons-material/Delete";
//Srcs

export default function ListJournalEntry({
  responsibilityList,
  deleteResponsibilityList,
  setUpdateResponsibility,
  requireField,
}) {
  const [touched, setTouched] = useState(false);
  const handleTouch = () => setTouched(true);
  let requireMessage = "require!";

  const Listitems = responsibilityList?.map((item, index) => {
    // console.log("item", item);
    return (
      <Stack direction="row" justifyContent="space-between" key={item?.key}>
        <TextField
          sx={{ width: "85%" }}
          size="small"
          placeholder="input responsibility"
          value={item?.responsibility_name}
          onChange={(e) => setUpdateResponsibility(e?.target?.value, item?.key)}
          onFocus={handleTouch}
          error={
            (touched && item?.responsibility_name === "") ||
            (requireField && item?.responsibility_name === "")
          }
          helperText={
            (touched && item?.responsibility_name === "" && requireMessage) ||
            (requireField && item?.responsibility_name === "" && requireMessage)
          }
        />

        <IconButton onClick={() => deleteResponsibilityList(item?.key)}>
          <DeleteIcon style={{ color: "red" }} />
        </IconButton>
      </Stack>
    );
  });
  return Listitems;
}
