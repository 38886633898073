import React, { useState, useEffect } from "react";
import generatePdfThumbnails from "pdf-thumbnails-generator";
import { Stack } from "@mui/material";

export default function FileThumnail({ pdfFile }) {
  const [thumnail, setThumnail] = useState("");

  // console.log("pdfFile::", pdfFile);

  async function generateThumbnails(pdf) {
    try {
      // const file = await urlToObject(pdfFile)
      let thumbnails = await generatePdfThumbnails(pdf, 200);
      // console.log("thumbnails::", thumbnails);
      setThumnail(thumbnails[0].thumbnail);
    } catch (err) {
      console.error(err, "err document");
    }
  }

  useEffect(() => {
    if (pdfFile) {
      generateThumbnails(pdfFile);
    }
  }, [pdfFile]);

  // const [thumbnails, setThumbnails] = useState(null);

  // useEffect(() => {
  //   async function generateThumbnails() {
  //     try {

  //       const thumbnailsResult = await generatePdfThumbnails('./sample.pdf', 150);
  //       setThumbnails(thumbnailsResult);
  //     } catch (err) {
  //       console.error(err);
  //     }
  //   }
  //   generateThumbnails();
  // }, []);

  return (
    <Stack direction="row" justifyContent="center" sx={{ width: "100%" }}>
      {thumnail ? (
        <img
          src={thumnail}
          alt="document"
          style={{ width: "100%" }}
          className="thumbnail-img"
        />
      ) : (
        <span>Loading...</span>
      )}
    </Stack>
  );
}
