import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import CloseIcon from "@mui/icons-material/Close";
import { Box, IconButton, Stack, Typography } from "@mui/material";
//upload image
import { v4 as uuidv4 } from "uuid";
import imageCompression from "browser-image-compression";
import moment from "moment";
import {
  ref,
  getDownloadURL,
  uploadBytesResumable,
  deleteObject,
} from "firebase/storage";
// Import the main component
import { Worker, Viewer } from "@react-pdf-viewer/core";
import { defaultLayoutPlugin } from "@react-pdf-viewer/default-layout";
import { thumbnailPlugin } from "@react-pdf-viewer/thumbnail";
import { zoomPlugin } from "@react-pdf-viewer/zoom";
// Import styles
import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/thumbnail/lib/styles/index.css";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";
import "@react-pdf-viewer/zoom/lib/styles/index.css";
//Srcs
import "./viewdocument.scss";
import { storage } from "../../../firebase";

export default function ViewDocument({
  setProgress,
  setStatusProgress,
  open,
  setOpenView,
  handleClose,
  pdfFile,
  uploadFile,
  setUploadFile,
  setDocumentSrc,
  viewType,
}) {
  const defaultLayoutPluginInstance = defaultLayoutPlugin();
  const thumbnailPluginInstance = thumbnailPlugin();

  const [loading, setLoading] = useState(false);
  var staffDocumentSrc = localStorage.getItem("staffDocumentSrc");

  const handleUpload = async () => {
    setLoading(true);
    try {
      setTimeout(() => {
        setStatusProgress(true);
        setProgress(10);
        uploadImage(uploadFile);
        setLoading(false);
        setOpenView(false);
      }, 200);
    } catch (error) {
      console.log(error);
    }
  };

  const newDate = moment(new Date()).format("MMdYYYY");

  //compress image in customer
  const uploadImage = async (file) => {
    // console.log("file::", file);
    if (!file) return;
    // const options = {
    //   maxSizeMB: 1,
    //   maxWidthOrHeight: 1920,
    //   useWebWorker: true,
    // };

    // const compressedFile = await imageCompression(file, options);
    let newName = `${uuidv4()}${newDate}${file.name.split(" ").pop()}`;
    var newFile = new File([file], `${newName}.pdf`, {
      type: "file/pdf",
    });

    // to firbase
    const storageRef = ref(storage, `documents/${newFile.name}`);
    const uploadTask = uploadBytesResumable(storageRef, file);

    uploadTask.on(
      "state_changed",
      (snapshot) => {
        const progress =
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        // console.log("progress::", progress)
      },
      (err) => console.log("error::", err),
      () => {
        getDownloadURL(uploadTask.snapshot.ref).then((url) => {
          // console.log("url::", url);
          setDocumentSrc(url);
          window.localStorage.setItem("staffDocumentSrc", url);
        });
      }
    );
  };

  const deleteImage = () => {
    if (staffDocumentSrc) {
      const deleteDocumentSrc = ref(storage, staffDocumentSrc);
      deleteObject(deleteDocumentSrc)
        .then(() => {
          localStorage.removeItem("staffDocumentSrc");
          console.log("delete this draft image successfully!");
        })
        .catch((err) => {
          console.log("can not delete this draft image!", err);
        });
    }
  };

  const handleCloseUpload = () => {
    handleClose();
    setUploadFile(null);
  };

  return (
    <Dialog open={open} className="preview-pdf-dialog" fullScreen>
      <DialogTitle sx={{ padding: "4px 10px !important" }}>
        <Stack direction="row" justifyContent="center" spacing={2}>
          <Stack direction="column" justifyContent="center">
            <Typography
              sx={{ fontWeight: "bold", fontFamily: "KhmerOsSiemreap" }}
            >
              View Document
            </Typography>
          </Stack>
          <Box sx={{ flexGrow: 1 }}></Box>
          <IconButton onClick={handleCloseUpload} sx={{ color: "red" }}>
            <CloseIcon />
          </IconButton>
        </Stack>
      </DialogTitle>

      <DialogContent>
        <Box>
          {pdfFile ? (
            <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js">
              <div
                style={{
                  border: "1px solid rgba(0, 0, 0, 0.3)",
                  height: "750px",
                }}
              >
                <Viewer
                  defaultScale={1.5}
                  fileUrl={pdfFile}
                  plugins={[
                    defaultLayoutPluginInstance,
                    thumbnailPluginInstance,
                  ]}
                />
              </div>
            </Worker>
          ) : (
            "No PDF"
          )}
        </Box>
      </DialogContent>

      {viewType ? null : (
        <DialogActions>
          <Stack direction="row" justifyContent="right">
            {loading ? (
              <Button className="btn-upload" fullWidth>
                ...Loading
              </Button>
            ) : (
              <Button className="btn-upload" fullWidth onClick={handleUpload}>
                Upload
              </Button>
            )}
          </Stack>
        </DialogActions>
      )}
    </Dialog>
  );
}
