import React, { useContext, useEffect, useState } from "react";
import "../../../Style/createstyle.scss";
import { useFormik, Form, FormikProvider } from "formik";
import * as Yup from "yup";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import {
  Grid,
  Stack,
  Box,
  TextField,
  Typography,
  Button,
  FormControl,
  Select,
  MenuItem,
} from "@mui/material";
import LinearProgress from "@mui/material/LinearProgress";
import PropTypes from "prop-types";
import { useMutation } from "@apollo/client";
import { ref, deleteObject } from "firebase/storage";
import { storage } from "../../../firebase";
//Icons
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
//date picker
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import moment from "moment";
//Src
import { AuthContext } from "../../../Context/AuthContext";
import {
  CREATE_STAFF_DRIVERLICENSE,
  UPDATE_STAFF_DRIVERLICENSE,
} from "../../../Schema/StaffDriverLicense";
import EmptyPdfImage from "../../../Assets/empty-image.png";
import CropImageDriverLicense from "../../CropImage/CropImageDriverLicense";

function LinearProgressWithLabel(props) {
  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <Box sx={{ width: "100%", mr: 1 }}>
        <LinearProgress variant="determinate" {...props} />
      </Box>
      <Box sx={{ minWidth: 35 }}>
        <Typography variant="body2" color="text.secondary">{`${Math.round(
          props.value
        )}%`}</Typography>
      </Box>
    </Box>
  );
}
LinearProgressWithLabel.propTypes = {
  value: PropTypes.number.isRequired,
};

export default function CreateDriverLicense({
  open,
  handleClose,
  dialogTitle,
  staffId,
  editData,
  setRefetch,
}) {
  const { setAlert } = useContext(AuthContext);
  const [loading, setLoading] = useState(false);

  let driverLicenseImageFromLocal = localStorage.getItem(
    "driverLicenseImageFromLocal"
  );

  const deleteImageFromLocal = () => {
    if (driverLicenseImageFromLocal) {
      setImageFile(null)
      const deleteImageCrop = ref(storage, driverLicenseImageFromLocal);
      deleteObject(deleteImageCrop)
        .then(() => {
          localStorage.removeItem("driverLicenseImageFromLocal");
          console.log("delete this draft image successfully!");
        })
        .catch((err) => {
          console.log("can not delete this draft image!", err);
        });
    }
  };

  // upload Image
  const [profileHook, setProfileHook] = useState("");
  const [imageFile, setImageFile] = useState(null);
  const [photoURL, setPhotoURL] = useState(null);
  const [openCrop, setOpenCrop] = useState(false);

  //----Progress------
  const [statusProgress, setStatusProgress] = React.useState(false);
  const [progress, setProgress] = React.useState(10);
  // console.log("statusProgress", statusProgress);
  // console.log("progress", progress);
  React.useEffect(() => {
    const timer = setInterval(() => {
      setProgress((prevProgress) =>
        prevProgress >= 100 ? 100 : prevProgress + 10
      );
    }, 500);
    return () => {
      clearInterval(timer);
    };
  }, []);
  //-----------------

  const handleUploadImage = (e) => {
    const imageFile = e.target.files[0];
    if (imageFile) {
      setImageFile(imageFile);
      setPhotoURL(URL.createObjectURL(imageFile));
      setOpenCrop(true);
    }
  };

  const [createStaffDrivingLicense, { data, error }] = useMutation(
    CREATE_STAFF_DRIVERLICENSE,
    {
      onCompleted: ({ createStaffDrivingLicense }) => {
        setLoading(false);
        if (createStaffDrivingLicense?.success === true) {
          setAlert(true, "success", createStaffDrivingLicense?.message);
          handleClose();
          resetForm();
          setRefetch();
          setImageFile("");
          setProfileHook("");
        } else {
          setAlert(true, "error", createStaffDrivingLicense?.message);
        }
      },
      onError: (error) => {
        setLoading(false);
        setAlert(true, "error", error?.message);
      },
    }
  );

  const [updateStaffDrivingLicense] = useMutation(UPDATE_STAFF_DRIVERLICENSE, {
    onCompleted: ({ updateStaffDrivingLicense }) => {
      // console.log(updateStaffDrivingLicense);
      setLoading(false);
      if (updateStaffDrivingLicense?.success === true) {
        setAlert(true, "success", updateStaffDrivingLicense?.message);
        handleClose();
        setRefetch();
      } else {
        setAlert(true, "error", updateStaffDrivingLicense?.message);
      }
    },
    onError: (error) => {
      setLoading(false);
      setAlert(true, "error", error?.message);
    },
  });

  //fromik
  const CreateStaffDrivingLicense = Yup.object().shape({
    drivingId: Yup.string().required("require!"),
    drivingType: Yup.string().required("require!"),
    issueDate: Yup.date().required("require!"),
    expiryDate: Yup.date().required("require!"),
    manualOrAuto: Yup.string().required("require!"),
    remark: Yup.string(),
  });

  const formik = useFormik({
    initialValues: {
      drivingId: "",
      drivingType: "A",
      issueDate: moment(),
      expiryDate: moment(),
      manualOrAuto: "Manual",
      remark: "",
    },

    validationSchema: CreateStaffDrivingLicense,
    onSubmit: async (values) => {
      // console.log("values:::", values, staffId);
      if (dialogTitle !== "Create") {
        setLoading(true);
        updateStaffDrivingLicense({
          variables: {
            id: editData?._id,
            input: {
              ...values,
              imageName: "Driver license",
              imageSrc: profileHook,
            },
          },
        });
      } else {
        setLoading(true);
        createStaffDrivingLicense({
          variables: {
            staffInfoId: staffId,
            input: {
              ...values,
              imageName: imageFile ? "Driver license" : "",
              imageSrc: imageFile ? profileHook : "",
            },
          },
        });
      }
    },
  });

  const {
    errors,
    touched,
    handleSubmit,
    getFieldProps,
    setFieldValue,
    values,
    resetForm,
  } = formik;

  // console.log("editData::", editData);

  useEffect(() => {
    if (editData) {
      setProfileHook(editData?.imageSrc);
      setFieldValue("drivingId", editData?.drivingId);
      setFieldValue("drivingType", editData?.drivingType);
      setFieldValue("expiryDate", editData?.expiryDate);
      setFieldValue("issueDate", editData?.issueDate);
      setFieldValue("manualOrAuto", editData?.manualOrAuto);
      setFieldValue(
        "remark",
        editData?.remark === null ? "" : editData?.remark
      );
    }
  }, [editData]);

  // useEffect(() => {
  //   if (majorVal?.id !== undefined) {
  //     setFieldValue("major", majorVal?.id);
  //   } else {
  //     setFieldValue("major", "");
  //   }
  // }, [majorVal?.id]);

  return (
    <Dialog open={open} className="create-container">
      <DialogTitle sx={{ padding: "10px 20px" }}>
        <Stack direction="row" spacing={2}>
          <Stack direction="column" justifyContent="center">
            <Typography className="dialog-title">
              {dialogTitle} Staff's Driver License
            </Typography>
          </Stack>

          <Box sx={{ flexGrow: 1 }} />
          <IconButton
            onClick={() => {
              handleClose();
              deleteImageFromLocal();
            }}
          >
            <CloseIcon className="close-icon" />
          </IconButton>
        </Stack>
      </DialogTitle>

      <DialogContent dividers>
        <FormikProvider value={formik}>
          <Form noValidate autoComplete="off" onSubmit={handleSubmit}>
            <Grid container rowSpacing={2} columnSpacing={4}>
              <Grid item xs={12}>
                <Stack direction="row" justifyContent="center">
                  {!openCrop ? (
                    <Box className="title-add">
                      <Stack
                        className="profile"
                        direction="row"
                        justifycontent="center"
                      >
                        <Button component="label">
                          <TextField
                            type="file"
                            id="image"
                            sx={{ display: "none" }}
                            onChange={handleUploadImage}
                          />
                          <img
                            className="button-image"
                            src={
                              imageFile
                                ? URL.createObjectURL(imageFile)
                                : editData?.imageSrc
                                ? editData?.imageSrc
                                : EmptyPdfImage
                            }
                          />
                          <input type="file" hidden />
                        </Button>
                      </Stack>

                      <Stack
                        direction="row"
                        justifyContent="center"
                        spacing={2}
                        sx={{ mt: 1 }}
                      >
                        {statusProgress && progress < 100 ? (
                          <Box sx={{ width: "100%", marginLeft: "20px" }}>
                            <LinearProgressWithLabel value={progress} />
                          </Box>
                        ) : (
                          <Typography className="text-En">
                            License image
                          </Typography>
                        )}
                      </Stack>
                    </Box>
                  ) : (
                    <CropImageDriverLicense
                      setProgress={setProgress}
                      setStatusProgress={setStatusProgress}
                      setImageFile={setImageFile}
                      photoURL={photoURL}
                      openCrop={openCrop}
                      setOpenCrop={setOpenCrop}
                      setPhotoURL={setPhotoURL}
                      setUploadHook={setProfileHook}
                      srcFromBackend={
                        editData?.imageSrc ? editData?.imageSrc : ""
                      }
                    />
                  )}
                </Stack>
              </Grid>
              <Grid item xs={6}>
                <Typography className="field-title">Driving id</Typography>
                <TextField
                  fullWidth
                  size="small"
                  className="text-field"
                  placeholder="input driving id"
                  {...getFieldProps("drivingId")}
                  error={Boolean(touched.drivingId && errors.drivingId)}
                  helperText={touched.drivingId && errors.drivingId}
                />
              </Grid>

              <Grid item xs={6}>
                <Typography className="field-title">Driving type</Typography>

                <FormControl size="small" fullWidth className="text-field">
                  <Select
                    labelId="demo-select-small"
                    id="demo-select-small"
                    value={values?.drivingType}
                    onChange={(event) => {
                      setFieldValue("drivingType", event.target.value);
                    }}
                    error={Boolean(touched.drivingType && errors.drivingType)}
                    helperText={touched.drivingType && errors.drivingType}
                  >
                    <MenuItem value={"A"}>A</MenuItem>
                    <MenuItem value={"B"}>B</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={6}>
                <Typography className="field-title">Issue date</Typography>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                    value={values?.issueDate}
                    onChange={(e) => setFieldValue("issueDate", e)}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        className="text-field"
                        size="small"
                        fullWidth
                      />
                    )}
                  />
                </LocalizationProvider>
              </Grid>
              <Grid item xs={6}>
                <Typography className="field-title">Expiry date</Typography>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                    value={values?.expiryDate}
                    onChange={(e) => setFieldValue("expiryDate", e)}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        className="text-field"
                        size="small"
                        fullWidth
                      />
                    )}
                  />
                </LocalizationProvider>
              </Grid>
              <Grid item xs={12}>
                <Typography className="field-title">Manual/Auto</Typography>
                <FormControl size="small" fullWidth className="text-field">
                  <Select
                    labelId="demo-select-small"
                    id="demo-select-small"
                    value={values?.manualOrAuto}
                    onChange={(event) => {
                      setFieldValue("manualOrAuto", event.target.value);
                    }}
                    error={Boolean(touched.manualOrAuto && errors.manualOrAuto)}
                    helperText={touched.manualOrAuto && errors.manualOrAuto}
                  >
                    <MenuItem value={"Manual"}>Manual</MenuItem>
                    <MenuItem value={"Auto"}>Auto</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <Typography className="field-title">Remark</Typography>
                <TextField
                  fullWidth
                  size="small"
                  className="text-field"
                  multiline
                  placeholder="remark"
                  {...getFieldProps("remark")}
                  error={Boolean(touched.remark && errors.remark)}
                  helperText={touched.remark && errors.remark}
                />
              </Grid>
            </Grid>
          </Form>
        </FormikProvider>
      </DialogContent>

      <DialogActions>
        <Stack direction="row" spacing={4} className="action-container">
          <Button
            className="btn-cencel"
            fullWidth
            onClick={() => {
              handleClose();
              deleteImageFromLocal();
            }}
          >
            Cencel
          </Button>

          {loading ? (
            <Button className="btn-create" fullWidth>
              Loading...
            </Button>
          ) : (
            <Button className="btn-create" fullWidth onClick={handleSubmit}>
              {dialogTitle}
            </Button>
          )}
        </Stack>
      </DialogActions>
    </Dialog>
  );
}
