import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import CloseIcon from "@mui/icons-material/Close";
import { Box, IconButton, Stack, Typography } from "@mui/material";
// Import the main component
import { Worker, Viewer } from "@react-pdf-viewer/core";
import { defaultLayoutPlugin } from "@react-pdf-viewer/default-layout";
import { thumbnailPlugin } from "@react-pdf-viewer/thumbnail";
// Import styles
import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/thumbnail/lib/styles/index.css";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";
import "@react-pdf-viewer/zoom/lib/styles/index.css";
//Srcs
import "./viewdocument.scss";

export default function ViewDocument({ open, handleClose, pdfFile }) {
  const defaultLayoutPluginInstance = defaultLayoutPlugin();
  const thumbnailPluginInstance = thumbnailPlugin();

  return (
    <Dialog open={open} className="preview-pdf-dialog" fullScreen>
      <DialogTitle sx={{ padding: "4px 10px !important" }}>
        <Stack direction="row" justifyContent="center" spacing={2}>
          <Stack direction="column" justifyContent="center">
            <Typography
              sx={{ fontWeight: "bold", fontFamily: "KhmerOsSiemreap" }}
            >
              View Document
            </Typography>
          </Stack>
          <Box sx={{ flexGrow: 1 }}></Box>
          <IconButton onClick={handleClose} sx={{ color: "red" }}>
            <CloseIcon />
          </IconButton>
        </Stack>
      </DialogTitle>

      <DialogContent>
        <Box>
          {pdfFile ? (
            <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js">
              <div
                style={{
                  border: "1px solid rgba(0, 0, 0, 0.3)",
                  height: "750px",
                }}
              >
                <Viewer
                  defaultScale={1.5}
                  fileUrl={pdfFile}
                  plugins={[
                    defaultLayoutPluginInstance,
                    thumbnailPluginInstance,
                  ]}
                />
              </div>
            </Worker>
          ) : (
            "No PDF"
          )}
        </Box>
      </DialogContent>
    </Dialog>
  );
}
