import React from "react";
import "./listdata.scss";
import { Typography, Stack, Box, Button, Grid } from "@mui/material";
import { useNavigate } from "react-router-dom";
//Icons
import { RiFolderSettingsFill } from "react-icons/ri";
import {FaUserCog} from "react-icons/fa";

export default function GeneralSetting() {
  const navigate = useNavigate();

  return (
    <div className="setting-page">
      <Stack direction="row" sx={{ height: "50px" }}>
        <Stack direction="column" justifyContent="center">
          <Typography className="page-title">General Setting</Typography>
        </Stack>
      </Stack>

      <Box className="body-container">
        <Grid container spacing={4}>
          <Grid item xs={12} sm={6} lg={4}>
            <Button
              className="btn-setting"
              onClick={() => navigate("/settings/general/adjust-take-leave")}
            >
              <Stack direction="row" spacing={2}>
                <Stack direction="column" justifyContent="center">
                  <RiFolderSettingsFill className="btn-icon" />
                </Stack>

                <Stack direction="column" spacing={1}>
                  <Typography className="btn-setting-title">
                    Take leave adjustment
                  </Typography>
                  <Typography className="btn-setting-des">
                    Adjust staff's take leave.
                  </Typography>
                </Stack>
              </Stack>
            </Button>
          </Grid>
          <Grid item xs={12} sm={6} lg={4}>
            <Button
              className="btn-setting"
              onClick={() => navigate("/settings/general/user")}
            >
              <Stack direction="row" spacing={2}>
                <Stack direction="column" justifyContent="center">
                  <FaUserCog className="btn-icon" />
                </Stack>

                <Stack direction="column" spacing={1}>
                  <Typography className="btn-setting-title">
                    User
                  </Typography>
                  <Typography className="btn-setting-des">
                    Manage user, reset user's password.
                  </Typography>
                </Stack>
              </Stack>
            </Button>
          </Grid>
        </Grid>
      </Box>
    </div>
  );
}
