import { gql } from "@apollo/client";

export const CREATE_OVER_TIME = gql`
  mutation CreateOvertimeWork($input: OvertimeWorkInput) {
    createOvertimeWork(input: $input) {
      success
      message
    }
  }
`;

export const GET_OVERTIME_WITH_PAGINATION = gql`
  query GetOvertimeWorkPagination(
    $page: Int
    $limit: Int
    $keyword: String
    $pagination: Boolean
    $userId: ID
    $status: [String]
  ) {
    getOvertimeWorkPagination(
      page: $page
      limit: $limit
      keyword: $keyword
      pagination: $pagination
      userId: $userId
      status: $status
    ) {
      data {
        _id
        timeType
        totalOvertimeAsDay
        totalOvertimeAsHours
        startDate
        endDate
        startTime
        endTime
        reason
        status
        requestTo {
          _id
          staffProfile {
            imageName
            imageSrc
            englishData {
              firstNameLatin
              lastNameLatin
            }
            khmerData {
              firstNameKh
              lastNameKh
            }
          }
        }
        createdBy {
          _id
          staffProfile {
            imageName
            imageSrc
            englishData {
              firstNameLatin
              lastNameLatin
            }
            khmerData {
              firstNameKh
              lastNameKh
            }
          }
        }
        approvedOrDeniedBy {
          _id
          staffProfile {
            imageName
            imageSrc
            englishData {
              firstNameLatin
              lastNameLatin
              gender
            }
          }
        }
        reply {
          characterType
          text
        }
        updatedAt
        createdAt
        managerReply
      }
      paginator {
        slNo
        prev
        next
        perPage
        totalPosts
        totalPages
        currentPage
        hasPrevPage
        hasNextPage
        totalDocs
      }
    }
  }
`;

export const UPDAATE_OVERTIME = gql`
  mutation UpdateOvertimeWork($input: OvertimeWorkInput, $id: ID) {
    updateOvertimeWork(input: $input, _id: $id) {
      success
      message
    }
  }
`;

export const DELETE_OVERTIME = gql`
  mutation DeleteOvertimeWork($id: ID) {
    deleteOvertimeWork(_id: $id) {
      message
      success
    }
  }
`;

export const APPROVE_OVERTIME = gql`
  mutation ApproveOvertime(
    $overtimeId: ID!
    $userId: ID!
    $managerReply: String
  ) {
    approveOvertime(
      overtimeId: $overtimeId
      userId: $userId
      managerReply: $managerReply
    ) {
      success
      message
    }
  }
`;

export const DENIE_OVERTIME = gql`
  mutation DenyOvertime($overtimeId: ID!, $userId: ID!, $managerReply: String) {
    denyOvertime(
      overtimeId: $overtimeId
      userId: $userId
      managerReply: $managerReply
    ) {
      success
      message
    }
  }
`;
