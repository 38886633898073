import React, { useContext, useEffect, useState } from "react";
import "../../Style/createdrawerstyle.scss";
import { useFormik, Form, FormikProvider } from "formik";
import * as Yup from "yup";
import {
  Grid,
  Stack,
  TextField,
  Typography,
  Button,
  IconButton,
  Box
} from "@mui/material";
import { useMutation, useQuery } from "@apollo/client";
//date picker
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import moment from "moment";
//icons
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
//Src
import { AuthContext } from "../../Context/AuthContext";
import {
  UPDATE_TAKELEAVE,
  GET_PAYMENT_OF_TAKELEAVE_QTY,
} from "../../Schema/TakeLeave";
import ListTakeLeaveTypeEntry from "./ListTakeLeaveTypeEntry";

export default function UpdateTakeleave({
  handleClose,
  dialogTitle,
  editData,
  setRefetch,
  userLogin,
}) {
  const { setAlert } = useContext(AuthContext);
  const [loading, setLoading] = useState(false);
  const [paymentOfTakeLeaveQuantity, setPaymentOfTakeLeaveQuantity] = useState(
    []
  );
  const [requiredField, setRequiredField] = useState(false);

  // ========================================= List Take leave Type =========================================
  const [currentTakeLeaveType, setCurrentTakeLeaveType] = useState({
    key: "",
    takeLeaveType: "",
    quantityDayLeave: "",
  });

  const [takeLeaveTypeEntryList, setTakeLeaveTypeEntryList] = useState([]);

  const addTakeLeaveTypeEntry = () => {
    const newItem = currentTakeLeaveType;

    if (newItem !== "") {
      const items = [...takeLeaveTypeEntryList, newItem];
      setTakeLeaveTypeEntryList([...items]);
      setCurrentTakeLeaveType({
        key: "",
        takeLeaveType: "",
        quantityDayLeave: "",
      });
    }
  };
  const handleAddTakeLeaveTypeEntryList = () => {
    setCurrentTakeLeaveType({
      takeLeaveType: "",
      quantityDayLeave: 0,
      key: Date.now(),
    });
  };

  useEffect(() => {
    if (currentTakeLeaveType?.key !== "") {
      addTakeLeaveTypeEntry();
    }
  }, [currentTakeLeaveType]);

  //delete function
  const deleteTakeLeaveTypeEntryList = (key) => {
    const filtereditems = takeLeaveTypeEntryList?.filter((t) => t.key !== key);
    setTakeLeaveTypeEntryList(filtereditems);
  };

  const setUpdateTakeLeaveType = (takeLeaveType, key) => {
    const items = takeLeaveTypeEntryList;
    items.map((i) => {
      if (i.key == key) {
        i.takeLeaveType = takeLeaveType;
      }
    });
    setTakeLeaveTypeEntryList([...items]);
    if (takeLeaveType !== undefined && takeLeaveType !== "") {
      setRequiredField(false);
    } else {
      setRequiredField(true);
    }
  };

  const setUpdateQuantityDayLeave = (quantityDayLeave, key) => {
    const items = takeLeaveTypeEntryList;
    items.map((i) => {
      if (i.key == key) {
        i.quantityDayLeave = parseFloat(quantityDayLeave);
      }
    });
    setTakeLeaveTypeEntryList([...items]);
    if (
      quantityDayLeave !== undefined &&
      quantityDayLeave !== "" &&
      quantityDayLeave !== 0
    ) {
      setRequiredField(false);
    } else {
      setRequiredField(true);
    }
  };

  const { refetch: refetchQty } = useQuery(GET_PAYMENT_OF_TAKELEAVE_QTY, {
    variables: {
      staffId: userLogin?._id,
      takeLeaveType:
        takeLeaveTypeEntryList?.length !== 0
          ? takeLeaveTypeEntryList?.map((d) => d["takeLeaveType"])
          : [],
    },
    onCompleted: ({ getPaymentOfTakeLeaveQty }) => {
      setPaymentOfTakeLeaveQuantity(getPaymentOfTakeLeaveQty);
    },
    onError: (error) => {
      console.log(error);
    },
  });

  //   console.log(paymentOfTakeLeaveQuantity, "paymentOfTakeLeaveQuantity");

  const [updateTakeLeave] = useMutation(UPDATE_TAKELEAVE, {
    onCompleted: ({ updateTakeLeave }) => {
      setLoading(false);
      if (updateTakeLeave?.success === true) {
        setAlert(true, "success", updateTakeLeave?.message);
        handleClose();
        setRefetch();
      } else {
        setAlert(true, "error", updateTakeLeave?.message);
      }
    },
    onError: (error) => {
      setLoading(false);
      setAlert(true, "error", error?.message);
    },
  });

  //fromik
  const CreateTakeLeave = Yup.object().shape({
    startDate: Yup.date().required("require!"),
    endDate: Yup.date().required("require!"),
    returnDate: Yup.date().required("require!"),
    reason: Yup.string().required("require!"),
    status: Yup.string(),
  });

  const formik = useFormik({
    initialValues: {
      startDate: moment(),
      endDate: moment(),
      returnDate: moment(),
      reason: "",
      status: "Pending",
    },

    validationSchema: CreateTakeLeave,
    onSubmit: async (values) => {
      let createStatus = false;
      const BreakException = {};
      try {
        if (takeLeaveTypeEntryList?.length !== 0) {
          takeLeaveTypeEntryList?.forEach(function (elem) {
            if (
              elem?.takeLeaveType !== undefined &&
              elem?.takeLeaveType !== "" &&
              elem?.quantityDayLeave !== "" &&
              elem?.quantityDayLeave !== undefined
            ) {
              createStatus = true;
            } else {
              setLoading(false);
              setRequiredField(true);
              createStatus = false;
              throw BreakException;
            }
          });
        } else {
          setLoading(false);
          setRequiredField(true);
        }
      } catch (e) {
        if (e !== BreakException) throw e;
      }

      // console.log("createStatus::", takeLeaveTypeEntryList, createStatus);

      if (createStatus) {
        setLoading(true);
        let takeLeaveTypeUsedEntry = [];
        takeLeaveTypeEntryList?.map((e) => {
          const element = {
            takeLeaveType: e?.takeLeaveType,
            quantityDayLeave: e?.quantityDayLeave,
          };
          takeLeaveTypeUsedEntry?.push(element);
        });

        // console.log("values::", values);
        let paymentOfTakeLeaveQuantityToSubmit = [];
        paymentOfTakeLeaveQuantity?.map((d) => {
          const allQuantity = {
            UsedQty: d?.UsedQty,
            currentYearQty: d?.currentYearQty,
            fromDate: d?.fromDate,
            lastYearRemainQty: d?.lastYearRemainQty,
            paidMonths: d?.paidMonths,
            takeLeaveType: d?.takeLeaveType,
            validUntil: d?.validUntil,
          };
          paymentOfTakeLeaveQuantityToSubmit?.push(allQuantity);
        });
        setLoading(true);
        let totalLeaveAsDay = takeLeaveTypeEntryList
          ?.map((i) => i.quantityDayLeave)
          .reduce((a, b) => a + b);
        // console.log("newTakeLeave::", newTakeLeave);

        updateTakeLeave({
          variables: {
            id: editData?._id,
            input: {
              createdBy: userLogin?._id,
              ...values,
              isAdjust: false,
              totalLeaveAsDay: totalLeaveAsDay,
              takeLeaveTypeUsed: takeLeaveTypeUsedEntry,
              paymentOfTakeLeaveQuantity: paymentOfTakeLeaveQuantityToSubmit,
            },
          },
        });
      }
    },
  });

  const {
    errors,
    touched,
    handleSubmit,
    getFieldProps,
    setFieldValue,
    values,
    resetForm,
  } = formik;

  //   console.log("editData::", editData);

  useEffect(() => {
    if (editData) {
      setFieldValue("startDate", editData?.startDate);
      setFieldValue("endDate", editData?.endDate);
      setFieldValue("returnDate", editData?.returnDate);
      setFieldValue("reason", editData?.reason);
      setFieldValue("status", editData?.status);
    }

    if (editData?.takeLeaveTypeUsed?.length !== 0) {
      let rows = [];
      editData?.takeLeaveTypeUsed?.map((d, index) => {
        const allRows = {
          key: index + 1 + new Date(),
          takeLeaveType: d?.takeLeaveType,
          quantityDayLeave: d?.quantityDayLeave,
        };
        rows?.push(allRows);
      });
      setTakeLeaveTypeEntryList([...rows]);
    }
  }, [editData]);

  useEffect(() => {
    refetchQty();
  }, [takeLeaveTypeEntryList]);

  return (
    <Stack
      direction="column"
      justifyContent="space-between"
      className="drawer-container"
    >
      <div>
        <Stack
          direction="row"
          justifyContent="space-between"
          sx={{ marginBottom: "20px" }}
        >
          <IconButton onClick={handleClose} className="close-icon-container">
            <ArrowBackIcon className="close-icon" />
          </IconButton>

          <Stack direction="column" justifyContent="center">
            <Typography className="dialog-title">
              {dialogTitle} Take Leave
            </Typography>
          </Stack>
        </Stack>
        <FormikProvider value={formik}>
          <Form noValidate autoComplete="off" onSubmit={handleSubmit}>
            <Stack direction={"row-reverse"}>
              <IconButton onClick={handleAddTakeLeaveTypeEntryList}>
                <AddCircleOutlineIcon style={{ color: "green" }} />
              </IconButton>
            </Stack>
            <Grid container rowSpacing={2} columnSpacing={3}>
              <Grid item xs={12}>
                <ListTakeLeaveTypeEntry
                  requiredField={requiredField}
                  setUpdateTakeLeaveType={setUpdateTakeLeaveType}
                  setUpdateQuantityDayLeave={setUpdateQuantityDayLeave}
                  takeLeaveTypeEntryList={takeLeaveTypeEntryList}
                  deleteTakeLeaveTypeEntryList={deleteTakeLeaveTypeEntryList}
                  userLogin={userLogin}
                />
              </Grid>
              <Grid item xs={6}>
                <Typography className="field-title">Start date</Typography>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DateTimePicker
                    value={values?.startDate}
                    onChange={(e) => setFieldValue("startDate", e)}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        className="text-field"
                        size="small"
                        fullWidth
                      />
                    )}
                  />
                </LocalizationProvider>
              </Grid>

              <Grid item xs={6}>
                <Typography className="field-title">End date</Typography>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DateTimePicker
                    value={values?.endDate}
                    onChange={(e) => setFieldValue("endDate", e)}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        className="text-field"
                        size="small"
                        fullWidth
                      />
                    )}
                  />
                </LocalizationProvider>
              </Grid>

              <Grid item xs={12}>
                <Typography className="field-title">Return date</Typography>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                    value={values?.returnDate}
                    onChange={(e) => setFieldValue("returnDate", e)}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        className="text-field"
                        size="small"
                        fullWidth
                      />
                    )}
                  />
                </LocalizationProvider>
              </Grid>

              <Grid item xs={12}>
                <Typography className="field-title">Reason</Typography>
                <TextField
                  fullWidth
                  size="small"
                  multiline
                  rows={2}
                  className="text-field"
                  placeholder="reason"
                  {...getFieldProps("reason")}
                  error={Boolean(touched.reason && errors.reason)}
                  helperText={touched.reason && errors.reason}
                />
              </Grid>
              <Grid item xs={12}>
                {/* Take leave record*/}
                {
                  paymentOfTakeLeaveQuantity?.length > 0 ? (
                    <Box className="request-card-take-leave-record">
                      <Box sx={{ padding: "4px" }}>
                        <Typography className="request-card-request-type" sx={{ fontWeight: 'bold' }}>
                          Take Leave record :
                        </Typography>
                        <Stack direction="column">
                          {
                            paymentOfTakeLeaveQuantity?.map(element => {
                              return (
                                <>
                                  <Typography className="request-card-item">
                                    <ul>
                                      <li>{element.takeLeaveType}(day/days) :
                                        <ul>
                                          {element.takeLeaveType === "Annual Leave" ? <li>Last year remain = {element.lastYearRemainQty}</li> : ""}
                                          {element.takeLeaveType === "Annual Leave" ? <li>Current year = {element.currentYearQty}</li> : <li>Available quantity = {element.currentYearQty}</li>}
                                          <li>Used quantity = {element.UsedQty}</li>
                                        </ul>
                                      </li>
                                    </ul>
                                  </Typography>
                                </>
                              )
                            })
                          }
                        </Stack>
                      </Box>
                    </Box>

                  ) : (
                    ""
                  )
                }
              </Grid>
            </Grid>
          </Form>
        </FormikProvider>
      </div>

      <Grid container sx={{ paddingBottom: "40px" }}>
        <Grid item xs={12}>
          <Stack direction="row" spacing={4} className="action-container">
            <Button className="btn-cencel" fullWidth onClick={handleClose}>
              Cencel
            </Button>

            {loading ? (
              <Button className="btn-create" fullWidth>
                Loading...
              </Button>
            ) : (
              <Button className="btn-create" fullWidth onClick={handleSubmit}>
                {dialogTitle}
              </Button>
            )}
          </Stack>
        </Grid>
      </Grid>
    </Stack>
  );
}
