import { gql } from "@apollo/client";

export const RESET_PASSWORD = gql`
  mutation ResetPassword($emailAddress: String!, $password: String!) {
    resetPassword(emailAddress: $emailAddress, password: $password) {
      success
      message
    }
  }
`;

export const UPDATE_USER = gql`
mutation UpdateUser($id: ID, $input: StaffInfoInput) {
  updateUser(_id: $id, input: $input) {
    success
    message
  }
}
`;

