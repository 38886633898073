import React, { useContext, useEffect, useState } from "react";
import "../../../Style/createstyle.scss";
import { useFormik, Form, FormikProvider } from "formik";
import * as Yup from "yup";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import { Grid, Stack, Box, TextField, Typography, Button } from "@mui/material";
import { useMutation } from "@apollo/client";
import PropTypes from "prop-types";
import LinearProgress from "@mui/material/LinearProgress";
//Icons
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
//Src
import FileThumnail from "../../../DynamicComp/FileThumnail";
import ViewDocument from "./ViewDocument";
import EmptyPdfImage from "../../../Assets/empty-image.png";
import { AuthContext } from "../../../Context/AuthContext";
import { UPLOAD_STAFF_DOCUMENT } from "../../../Schema/Document";

function LinearProgressWithLabel(props) {
  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <Box sx={{ width: "100%", mr: 1 }}>
        <LinearProgress variant="determinate" {...props} />
      </Box>
      <Box sx={{ minWidth: 35 }}>
        <Typography variant="body2" color="text.secondary">{`${Math.round(
          props.value
        )}%`}</Typography>
      </Box>
    </Box>
  );
}
LinearProgressWithLabel.propTypes = {
  value: PropTypes.number.isRequired,
};

export default function UploadDocument({
  open,
  handleClose,
  staffId,
  setRefetch,
}) {
  const { setAlert } = useContext(AuthContext);
  const [loading, setLoading] = useState(false);

  //---------------Progress------------------
  const [statusProgress, setStatusProgress] = React.useState(false);
  const [progress, setProgress] = React.useState(10);
  // console.log("statusProgress", statusProgress);
  // console.log("progress", progress);
  React.useEffect(() => {
    const timer = setInterval(() => {
      setProgress((prevProgress) =>
        prevProgress >= 100 ? 100 : prevProgress + 10
      );
    }, 500);
    return () => {
      clearInterval(timer);
    };
  }, []);
  //-----------------

  // upload Image
  const [pdfFile, setPdfFile] = useState(null);
  const [uploadFile, setUploadFile] = useState(null);
  const [documentSrc, setDocumentSrc] = useState("");
  const [openView, setOpenView] = useState(false);
  const handleCloseView = () => {
    setOpenView(false);
    setPdfFile(null);
  };
  const fileType = ["application/pdf"];
  const handleUploadImage = (e) => {
    let fileSelected = e.target.files[0];
    setUploadFile(fileSelected);
    //For thumbnails
    if (fileSelected) {
      if (fileSelected && fileType.includes(fileSelected.type)) {
        let reader = new FileReader();
        reader.readAsDataURL(fileSelected);
        reader.onload = (e) => {
          setPdfFile(e.target.result);
        };
      } else {
        setPdfFile(null);
      }
    } else {
      console.log("Please select file!");
    }
    setOpenView(true);
  };

  const [uploadStaffDocument] = useMutation(UPLOAD_STAFF_DOCUMENT, {
    onCompleted: ({ uploadStaffDocument }) => {
      // console.log(uploadStaffDocument);
      setLoading(false);
      if (uploadStaffDocument?.success === true) {
        setAlert(true, "success", uploadStaffDocument?.message);
        handleClose();
        setRefetch();
        resetForm();
        setPdfFile(null);
        setUploadFile(null);
      } else {
        setAlert(true, "error", uploadStaffDocument?.message);
      }
    },
    onError: (error) => {
      setLoading(false);
      setAlert(true, "error", error?.message);
    },
  });

  //fromik
  const CreateStaffEducation = Yup.object().shape({
    documentName: Yup.string().required("require!"),
  });

  const formik = useFormik({
    initialValues: {
      documentName: "",
    },

    validationSchema: CreateStaffEducation,
    onSubmit: async (values) => {
      setLoading(true);
      // console.log("values:::", values, staffId);
      // let documents = [{documentName: values?.documentName, documentSrc: }];

      uploadStaffDocument({
        variables: {
          input: {
            documentName: values?.documentName,
            documentURL: documentSrc,
            staffInfoId: staffId,
          },
        },
      });
    },
  });

  const {
    errors,
    touched,
    handleSubmit,
    getFieldProps,
    setFieldValue,
    values,
    resetForm,
  } = formik;

  return (
    <Dialog open={open} className="create-container" fullWidth maxWidth="sm">
      <DialogTitle sx={{ padding: "10px 20px" }}>
        <Stack direction="row" spacing={2}>
          <Stack direction="column" justifyContent="center">
            <Typography className="dialog-title">Upload document</Typography>
          </Stack>
          <Box sx={{ flexGrow: 1 }} />
          <IconButton onClick={handleClose}>
            <CloseIcon className="close-icon" />
          </IconButton>
        </Stack>
      </DialogTitle>

      <DialogContent dividers>
        <FormikProvider value={formik}>
          <Form noValidate autoComplete="off" onSubmit={handleSubmit}>
            <Grid container rowSpacing={2} columnSpacing={4}>
              <Grid item xs={12}>
                <Stack direction="column" spacing={1}>
                  <Stack direction="row" justifyContent="center">
                    {/* =============================== For view outside​ =================================  */}
                    {!openView ? (
                      <Button component="label">
                        <TextField
                          type="file"
                          id="image"
                          sx={{ display: "none" }}
                          onChange={handleUploadImage}
                        />
                        {uploadFile ? (
                          <FileThumnail pdfFile={pdfFile} />
                        ) : (
                          <img
                            style={{ width: "200px", height: "200px" }}
                            src={EmptyPdfImage}
                          />
                        )}

                        <input type="file" hidden />
                      </Button>
                    ) : (
                      <ViewDocument
                        setProgress={setProgress}
                        setStatusProgress={setStatusProgress}
                        open={openView}
                        setOpenView={setOpenView}
                        handleClose={handleCloseView}
                        pdfFile={pdfFile}
                        uploadFile={uploadFile}
                        setUploadFile={setUploadFile}
                        setDocumentSrc={setDocumentSrc}
                      />
                    )}
                  </Stack>

                  <Stack
                    direction="row"
                    justifyContent="center"
                    spacing={2}
                    sx={{ mt: 1 }}
                  >
                    {statusProgress && progress < 100 ? (
                      <Box sx={{ width: "100%", marginLeft: "20px" }}>
                        <LinearProgressWithLabel value={progress} />
                      </Box>
                    ) : (
                      <Typography className="text-En">Upload pdf</Typography>
                    )}
                  </Stack>
                </Stack>
              </Grid>
              <Grid item xs={12}>
                <Typography className="field-title">Document name</Typography>
                <TextField
                  fullWidth
                  size="small"
                  className="text-field"
                  multiline
                  placeholder="document name"
                  {...getFieldProps("documentName")}
                  error={Boolean(touched.documentName && errors.documentName)}
                  helperText={touched.documentName && errors.documentName}
                />
              </Grid>
            </Grid>
          </Form>
        </FormikProvider>
      </DialogContent>

      <DialogActions>
        <Stack direction="row" spacing={4} className="action-container">
          <Button className="btn-cencel" fullWidth onClick={handleClose}>
            Cencel
          </Button>

          {loading ? (
            <Button className="btn-create" fullWidth>
              Loading...
            </Button>
          ) : (
            <Button className="btn-create" fullWidth onClick={handleSubmit}>
              Upload
            </Button>
          )}
        </Stack>
      </DialogActions>
    </Dialog>
  );
}
