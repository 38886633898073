import React, { useState } from "react";
import ReactApexChart from "react-apexcharts";
import "../dashboard.scss";
import {
    Box,
  } from "@mui/material";
  import { useQuery } from "@apollo/client";
import { GET_STAFF_STATISTICS } from "../../../Schema/Dashboard";

function BarChart() {
    // const [staffStatistic, setstaffStatistic] = useState(null);
    const [totalBoard, setTotalBoard] = useState(null);
    const [totalStaff, setTotalStaff] = useState(null);
    const [fullTimeStaff, setFullTimeStaff] = useState(null);
    const [partTimeStaff, setPartTimeStaff] = useState(null);
    const [probationStaff, setProbationStaff] = useState(null);
    const [volunteersStaff, setVolunteersStaff] = useState(null);
    const [internshipStaff, setInternshipStaff] = useState(null);

    const { data, refetch } = useQuery(GET_STAFF_STATISTICS, {
      onCompleted: ({ staffStatistic }) => {
        // setstaffStatistic(staffStatistic?.series);
        setTotalBoard(staffStatistic?.series[5]);
        setTotalStaff(staffStatistic?.series[0] + staffStatistic?.series[1] + staffStatistic?.series[2])
        setFullTimeStaff(staffStatistic?.series[0])
        setPartTimeStaff(staffStatistic?.series[1])
        setProbationStaff(staffStatistic?.series[2])
        setVolunteersStaff(staffStatistic?.series[4])
        setInternshipStaff(staffStatistic?.series[3])
      },
    });
    
    const state = {
        series: [{
            data: [totalBoard, totalStaff, fullTimeStaff, partTimeStaff, probationStaff, volunteersStaff, internshipStaff]
          }, 
        ],
          options: {
            chart: {
              type: 'bar',
              height: 300
            },
            plotOptions: {
              bar: {
                horizontal: true,
                dataLabels: {
                  position: 'top',
                },
              }
            },
            dataLabels: {
              enabled: true,
              offsetX: -6,
              style: {
                fontSize: '12px',
                colors: ['#fff']
              }
            },
            stroke: {
              show: true,
              width: 1,
              colors: ['#fff']
            },
            tooltip: {
              shared: true,
              intersect: false
            },
            xaxis: {
              categories: ['Board of director', "Total staff", "Full-time staff", "Part-time staff", "Probation staff", "Volunteer staff", "Internship staff"],
            },
            colors: ['#198F4A'],
        },
    };

return (
    <Box
        className="box-container"
        sx={{
        padding: "20px",
        opacity: "93%",
        }}
    >
        <Box className="card-title-center">Members statistic</Box>
        <div>
            <ReactApexChart options={state.options} series={state.series} type="bar" height={200} />
        </div>
    </Box>
)
}

export default BarChart