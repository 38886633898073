import React, { useState, useContext } from "react";
import {
  Box,
  Typography,
  Stack,
  TextField,
  Button,
  InputAdornment,
  Grid,
} from "@mui/material";
import { useFormik, Form, FormikProvider } from "formik";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import * as Yup from "yup";
import { Link, useNavigate } from "react-router-dom";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import HttpsOutlinedIcon from "@mui/icons-material/HttpsOutlined";
import { useMutation } from "@apollo/client";
//Src
import "../Style/loginStyle.scss";
import RightImage from "../Assets/login-img.png";
import PepyLogo from "../Assets/pepy-logo.png";
import { AuthContext } from "../Context/AuthContext";
import { LOGIN_USER } from "../Schema/Login";

export default function Login() {
  const navigate = useNavigate();
  const { setAlert } = useContext(AuthContext);
  const [loading, setLoading] = useState(false);

  // hide password hook
  const [show, setShow] = useState(false);
  const handleClick = () => setShow(!show);

  const [loginUser, { data, error }] = useMutation(LOGIN_USER, {
    onCompleted: ({ loginUser }) => {
      // console.log("loginUser::", loginUser);
      setLoading(false);
      if (loginUser?.success === true) {
        setAlert(true, "success", loginUser?.message);
        window.localStorage.setItem(
          "userLogin",
          JSON.stringify(loginUser?.user) 
        );
        window.localStorage.setItem("token", loginUser?.token);
        //Check default password, if default need to show reset password page
        if (
          loginUser?.message ===
          "You're Welcome, Please reset your new password."
        ) {
          setTimeout(() => {
            navigate("/reset-password");
          }, 1000);
        } else {
          navigate("/");
        }
      } else {
        setAlert(true, "error", loginUser?.message);
      }
    },
    onError: (error) => {
      setAlert(true, "error", error?.message);
    },
  });

  const LoginSchema = Yup.object().shape({
    emailAddress: Yup.string().email("Invalid email!").required("Required"),
    password: Yup.string()
      .required("Required")
      .min(6, "Password must be 6 characters!"),
  });

  const formik = useFormik({
    initialValues: {
      emailAddress: "",
      password: "",
    },

    validationSchema: LoginSchema,
    onSubmit: async (values) => {
      // console.log("values::", values);
      setLoading(true);
      loginUser({
        variables: {
          emailAddress: values?.emailAddress,
          password: values?.password,
        },
      });
    },
  });

  const { errors, touched, handleSubmit, getFieldProps } = formik;

  let displayModalWeb = { xs: "none", sm: "flex" };
  let widthForm = { xs: "94%", sm: "90%", md: "50%" };

  return (
    <div className="login-page">
      <FormikProvider value={formik}>
        <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
          <Grid container>
            <Grid item xs={6} sx={{ display: displayModalWeb }}>
              <Box className="right-page-container">
                <Box className="vertical-center-leftSide">
                  <Typography className="company-title">
                    PEPY Empowering Youth
                  </Typography>
                  <Typography className="company-title">
                    Human Resource Management System
                  </Typography>
                  <img
                    src={`${RightImage}`}
                    alt="login image"
                    style={{
                      marginTop: "40px",
                      width: "30vw",
                    }}
                  />
                  <Box className="powered-by-container">
                    <Typography className="powered-by">Powered By:</Typography>
                    <Typography className="powered-by">Sipou & Chaktokrong </Typography>
                  </Box>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Box className="vertical-center-rightSide">
                <Box sx={{ width: widthForm }}>
                  <Stack
                    direction="column"
                    spacing={3}
                    sx={{ marginTop: "20px" }}
                  >
                    <Stack direction="row" justifyContent="center">
                      <img
                        src={`${PepyLogo}`}
                        alt="pepy logo"
                        style={{
                          width: "22%",
                          borderRadius: "10px",
                          overflow: "hidden",
                        }}
                      />
                    </Stack>
                    <Typography className="login-text">LOGIN</Typography>
                    <Typography className="login-des">
                      Welcome back! Please login to your account.
                    </Typography>
                    <TextField
                      // className="text-field"
                      placeholder="email address"
                      fullWidth
                      size="small"
                      {...getFieldProps("emailAddress")}
                      error={Boolean(
                        touched.emailAddress && errors.emailAddress
                      )}
                      helperText={touched.emailAddress && errors.emailAddress}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <EmailOutlinedIcon className="icon" />
                          </InputAdornment>
                        ),
                      }}
                    />

                    <TextField
                      // className="text-field"
                      type={show ? "text" : "password"}
                      size="small"
                      placeholder="password"
                      {...getFieldProps("password")}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <HttpsOutlinedIcon className="icon" />
                          </InputAdornment>
                        ),
                        endAdornment: (
                          <InputAdornment
                            position="start"
                            onClick={handleClick}
                          >
                            {show ? (
                              <VisibilityIcon sx={{ cursor: "pointer" }} />
                            ) : (
                              <VisibilityOffIcon sx={{ cursor: "pointer" }} />
                            )}
                          </InputAdornment>
                        ),
                      }}
                    />
                    <Stack direction="row" justifyContent="right">
                      <Link to="/forgot-password" className="link-forgot">
                        Forgot password?
                      </Link>
                    </Stack>
                    {loading ? (
                      <Button size="large" className="btn-login">
                        Loading...
                      </Button>
                    ) : (
                      <Button type="submit" size="large" className="btn-login">
                        Login
                      </Button>
                    )}
                  </Stack>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Form>
      </FormikProvider>
    </div>
  );
}
