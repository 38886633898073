import * as React from "react";
import "../../Style/actionstyle.scss";
import { IconButton, Typography, Stack, Drawer } from "@mui/material";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import BorderColorOutlinedIcon from "@mui/icons-material/BorderColorOutlined";
import DeleteForeverOutlinedIcon from "@mui/icons-material/DeleteForeverOutlined";
//create
import CreateOvertime from "./CreateOverTime";
import DeleteOverTime from "./DeleteOverTime";

export default function OvertimeAction({ userLogin, editData, setRefetch }) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  //Modal view
  const [openEdit, setOpenEdit] = React.useState(false);
  const handleOpenEdit = () => setOpenEdit(true);
  const handleCloseEdit = () => setOpenEdit(false);

  //Modal delete
  const [openDel, setOpenDel] = React.useState(false);
  const handleOpenDel = () => setOpenDel(true);
  const handleCloseDel = () => setOpenDel(false);

  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <IconButton onClick={handleClick}>
        <MoreVertIcon className="more-icon" />
      </IconButton>
      <Menu
        id="basic-button"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <MenuItem
          onClick={() => {
            handleOpenEdit();
            handleClose();
          }}
        >
          <Stack direction="row" spacing={1}>
            <BorderColorOutlinedIcon className="update-icon" />
            <Typography className="update-title">Update</Typography>
          </Stack>
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleOpenDel();
            handleClose();
          }}
        >
          <Stack direction="row" spacing={1}>
            <DeleteForeverOutlinedIcon className="delete-icon" />
            <Typography className="update-title">Delete</Typography>
          </Stack>
        </MenuItem>
      </Menu>

      <Drawer
        sx={{
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: { xs: "100%", sm: 420 },
          },
        }}
        anchor="right"
        open={openEdit}
      >
        <CreateOvertime
          handleClose={handleCloseEdit}
          userLogin={userLogin}
          editData={editData}
          dialogTitle={"Update"}
          setRefetch={setRefetch}
        />
      </Drawer>

      <DeleteOverTime
        open={openDel}
        handleClose={handleCloseDel}
        userLogin={userLogin}
        editData={editData}
        setRefetch={setRefetch}
      />
    </div>
  );
}
