import { gql } from "@apollo/client";

export const CREATE_DEPARTMENT = gql`
  mutation CreateDepartment($input: DepartmentInput) {
    createDepartment(input: $input) {
      success
      message
    }
  }
`;

export const GET_DEPARTMENTS_WITH_PAGINATION = gql`
  query GetDepartmentsPagination(
    $page: Int
    $limit: Int
    $keyword: String
    $pagination: Boolean
  ) {
    getDepartmentsPagination(
      page: $page
      limit: $limit
      keyword: $keyword
      pagination: $pagination
    ) {
      data {
        _id
        departmentName
        remark
        createdAt
        updatedAt
      }
      paginator {
        slNo
        prev
        next
        perPage
        totalPosts
        totalPages
        currentPage
        hasPrevPage
        hasNextPage
        totalDocs
      }
    }
  }
`;

export const GET_DEPARTMENT_BY_ID = gql`
  query GetDepartmentById($id: ID) {
    getDepartmentById(_id: $id) {
      _id
      departmentName
      remark
      createdAt
      updatedAt
    }
  }
`;

export const UPDATE_DEPARTMENT = gql`
  mutation UpdateDepartment($input: DepartmentInput, $id: ID) {
    updateDepartment(input: $input, _id: $id) {
      success
      message
    }
  }
`;

export const DELETE_DEPARTMENT = gql`
  mutation DeleteDepartment($id: ID) {
    deleteDepartment(_id: $id) {
      success
      message
    }
  }
`;
