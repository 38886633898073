import React, { useContext, useEffect, useState } from "react";
import "../../../Style/createstyle.scss";
import { useFormik, Form, FormikProvider } from "formik";
import * as Yup from "yup";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import { Grid, Stack, Box, TextField, Typography, Button } from "@mui/material";
import { useMutation } from "@apollo/client";
import PropTypes from "prop-types";
import LinearProgress from "@mui/material/LinearProgress";
//Icons
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
//Src
import FileThumnail from "../../../DynamicComp/FileThumnail";
import ViewDocument from "./ViewDocument";
import EmptyPdfImage from "../../../Assets/empty-image.png";
import { AuthContext } from "../../../Context/AuthContext";
import { RENAME_DOCUMENT } from "../../../Schema/Document";

export default function UploadDocument({
  open,
  handleClose,
  editData,
  setRefetch,
}) {
  const { setAlert } = useContext(AuthContext);
  const [loading, setLoading] = useState(false);

  const [renameDocument] = useMutation(RENAME_DOCUMENT, {
    onCompleted: ({ renameDocument }) => {
      // console.log(renameDocument);
      setLoading(false);
      if (renameDocument?.success === true) {
        setAlert(true, "success", renameDocument?.message);
        handleClose();
        setRefetch();
        resetForm();
      } else {
        setAlert(true, "error", renameDocument?.message);
      }
    },
    onError: (error) => {
      setLoading(false);
      setAlert(true, "error", error?.message);
    },
  });

  //fromik
  const CreateStaffEducation = Yup.object().shape({
    documentName: Yup.string().required("require!"),
  });

  const formik = useFormik({
    initialValues: {
      documentName: "",
    },

    validationSchema: CreateStaffEducation,
    onSubmit: async (values) => {
      setLoading(true);

      renameDocument({
        variables: {
          id: editData?._id,
          documentName: values?.documentName,
        },
      });
    },
  });

  const {
    errors,
    touched,
    handleSubmit,
    getFieldProps,
    setFieldValue,
    values,
    resetForm,
  } = formik;

  useEffect(() => {
    if (editData) {
      setFieldValue("documentName", editData?.documentName);
    }
  }, [editData]);

  return (
    <Dialog open={open} className="create-container" fullWidth maxWidth="xs">
      <DialogTitle sx={{ padding: "10px 20px" }}>
        <Stack direction="row" spacing={2}>
          <Stack direction="column" justifyContent="center">
            <Typography className="dialog-title">Rename document</Typography>
          </Stack>
          <Box sx={{ flexGrow: 1 }} />
          <IconButton onClick={handleClose}>
            <CloseIcon className="close-icon" />
          </IconButton>
        </Stack>
      </DialogTitle>

      <DialogContent dividers>
        <FormikProvider value={formik}>
          <Form noValidate autoComplete="off" onSubmit={handleSubmit}>
            <Grid container rowSpacing={2} columnSpacing={4}>
              <Grid item xs={12}>
                <Typography className="field-title">Document name</Typography>
                <TextField
                  fullWidth
                  size="small"
                  className="text-field"
                  multiline
                  placeholder="document name"
                  {...getFieldProps("documentName")}
                  error={Boolean(touched.documentName && errors.documentName)}
                  helperText={touched.documentName && errors.documentName}
                />
              </Grid>
            </Grid>
          </Form>
        </FormikProvider>
      </DialogContent>

      <DialogActions>
        <Stack direction="row" spacing={4} className="action-container">
          <Button className="btn-cencel" fullWidth onClick={handleClose}>
            Cencel
          </Button>

          {loading ? (
            <Button className="btn-create" fullWidth>
              Loading...
            </Button>
          ) : (
            <Button className="btn-create" fullWidth onClick={handleSubmit}>
              Update
            </Button>
          )}
        </Stack>
      </DialogActions>
    </Dialog>
  );
}
