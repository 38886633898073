import { gql } from "@apollo/client";

export const CREATE_STAFF_EDUCATION = gql`
  mutation CreateStaffEducation($input: EducationInput, $staffInfoId: ID) {
    createStaffEducation(input: $input, staffInfoId: $staffInfoId) {
      success
      message
    }
  }
`;

export const GET_EDUCATION_BY_STAFFID = gql`
  query GetEducationByStaffId($staffId: ID!) {
    getEducationByStaffId(staffId: $staffId) {
      _id
      level
      fileUpload
      major {
        _id
        majorName
        remark
      }
      universityName
      graduactionDate
      remark
    }
  }
`;

export const DELETE_STAFF_EDUCATION = gql`
  mutation DeleteStaffEducation($id: ID) {
    deleteStaffEducation(_id: $id) {
      success
      message
    }
  }
`;

export const UPDATE_STAFF_EDUCATION = gql`
  mutation UpdateStaffEducation($input: EducationInput, $id: ID) {
    updateStaffEducation(input: $input, _id: $id) {
      success
      message
    }
  }
`;
