import React from "react";
import { Box, Grid, Stack, Typography } from "@mui/material";
//Srcs
import LastOvertime from "./AdminDashboard/LastOvertime.jsx";
import LastTakeleave from "./AdminDashboard/LastTakeleave.jsx";
import TakeLeavePaymentTableAdm from "./AdminDashboard/TakeLeavePaymentTableAdm"
import BarChart from "./AdminDashboard/BarChart"
import Card from "./AdminDashboard/Card"

function AdminDashboard() {
  return (
    <Box sx={{ flexGrow: 1, marginTop: "20px" }}>
      <Grid container spacing={3}>
        <Grid item xs={12} md={12} xl={12}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
              <Card />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
              <BarChart />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <TakeLeavePaymentTableAdm />
        </Grid>
        <Grid item xs={12} md={6} lg={6} xl={6}>
          <LastTakeleave />
        </Grid>
        <Grid item xs={12} md={6} xl={6}>
          <LastOvertime />
        </Grid>
      </Grid>
    </Box>
  );
}

export default AdminDashboard;
