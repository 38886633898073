import React, { useEffect, useState } from "react";
import "../dashboard.scss";
import "../../../Style/pagestyle.scss";
import {
  Box,
  TableContainer,
  Table,
  TableRow,
  TableCell,
  TableBody,
  TableHead,
  Typography,
  Stack,
  Grid,
} from "@mui/material";
import { useQuery } from "@apollo/client";
//Icons
//Srcs
import EmptyData from "../../Include/EmptyData";
import LoadingPage from "../../Include/LoadingPage";
import { GET_TAKE_LEAVE_PAYMENT_DASHBOARD } from "../../../Schema/Dashboard";
import { SelectStaff } from "../../../DynamicComp/DynamicSelect";

export default function TakeLeavePaymentTableAdm() {
  const [loading, setLoading] = useState(true);
  const [tableData, setTableData] = useState([]);
  const [staffVal, setStaffVal] = useState({ id: "", title: "" });

  useEffect(()=>{
    //get userLoging from local
    const userLogin = JSON.parse(window.localStorage.getItem("userLogin"));
    if(userLogin){
      setStaffVal({
        id: userLogin?._id, 
        title: userLogin.staffProfile?.englishData?.firstNameLatin + " " + userLogin?.staffProfile?.englishData?.lastNameLatin
      })
    }
  }, [])

  const { data, refetch } = useQuery(GET_TAKE_LEAVE_PAYMENT_DASHBOARD, {
    onCompleted: ({ getTakeLeavePaymentDashboard }) => {
      // console.log("getTakeLeavePaymentDashboard::", getTakeLeavePaymentDashboard);
      setTableData(getTakeLeavePaymentDashboard)
      setLoading(false);
    },
    variables: {
      staffId: staffVal?.id,
    },
    onError: (error) => {
      console.log(error)
    },
  });
  //===================== Resize width Screen=====================
  const [width, setWidth] = React.useState(window.innerWidth);
  const updateDimensions = () => {
    setWidth(window.innerWidth - 50);
  };
  React.useEffect(() => {
    window.addEventListener("resize", updateDimensions);
    return () => window.removeEventListener("resize", updateDimensions);
  }, []);
  return (
    <div className="page-container">
      <Box style={{backgroundColor:"#ffff", borderRadius: "10px", paddingTop:"10px"}}>
        <Grid container>
          <Grid item xs={6} md={7} lg={8} xl={8.6}>
            <Typography style={{  
              fontSize: "16px",
              fontWeight: "bold",
              opacity: "70%",
              paddingLeft: "20px",
            }}>Take Leave Payment</Typography>
          </Grid>
          <Grid item xs={6} md={5} lg={4} xl={3.4}>
            <Stack 
              direction={"row"} 
              justifyContent={"center"} 
              alignItems={'center'} 
              spacing={1}
            >
              <Typography>Selected: </Typography>
              <SelectStaff
                selectedVal={staffVal}
                setSelectedVal={setStaffVal}
                className={"text-field"}
              />
            </Stack>
          </Grid>
        </Grid>
        <Box className="table-body-container">
          <TableContainer sx={{ maxWidth: `${width}px` }}>
            <Table className="table" aria-label="simple table">
              <TableHead className="header-row ">
                <TableCell className="header-title-start"> N°</TableCell>
                <TableCell className="header-title">Take leave type</TableCell>
                <TableCell className="header-title">Last year remain</TableCell>
                <TableCell className="header-title">Current year</TableCell>
                <TableCell className="header-title-end">Total used</TableCell>
              </TableHead>
              {loading ? (
                <TableBody className="body">
                  <TableRow className="body-row-gray">
                    <TableCell className="body-cell" colSpan={7}>
                      <LoadingPage />
                    </TableCell>
                  </TableRow>
                </TableBody>
              ) : (
                <TableBody className="body">
                  {tableData?.length === 0 ? (
                    <TableRow className="body-row-gray">
                      <TableCell className="body-cell" colSpan={7}>
                        <EmptyData />
                      </TableCell>
                    </TableRow>
                  ) : (
                    <>
                      {tableData?.map((row, index) => {
                        return (
                          <TableRow
                            className={
                              (index + 1) % 2 === 0 ? "body-row" : "body-row-gray"
                            }
                            key={index}
                          >
                            <TableCell className="body-cell-start">
                              {index + 1}
                            </TableCell>
                            <TableCell className="body-cell">
                              {row?.takeLeaveType}
                            </TableCell>
                            <TableCell className="body-cell">
                              {row?.lastYearRemainQty} {row?.lastYearRemainQty !== null ? `${row?.lastYearRemainQty === 1?"day":"days"}`:""}
                            </TableCell>
                            <TableCell className="body-cell">
                              {row?.currentYearQty !== null ? `${row?.takeLeaveType === "Maternity Leave" ? "3 months" : row?.takeLeaveType === "Breastfeeding" ? "2 hours/per day" : `${row?.currentYearQty} ${row?.currentYearQty===1?"day":"days"}`}`:""}
                            </TableCell>
                            <TableCell className="body-cell">
                              {row?.UsedQty} {row?.UsedQty === 1 ? "day":"days"}
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    </>
                  )}
                </TableBody>
              )}
            </Table>
          </TableContainer>
        </Box>
      </Box>
    </div>
  );
}
