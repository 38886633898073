import React, { useState, useEffect, useContext } from "react";
import { styled } from "@mui/material/styles";
import { Box, AppBar } from "@mui/material";
import CssBaseline from "@mui/material/CssBaseline";
import { Outlet } from "react-router-dom";
//Src
import TopNavbar from "../Menu/TopNavbar";
import MenuWeb from "../Menu/MenuWeb";
import MenuMobile from "../Menu/MenuMobile";
import SmallMenu from "../Menu/SmallMenu";
import { MenuSizeContext } from "../App";

// const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })(
//   ({ theme, open }) => ({
//     flexGrow: 1,
//     padding: theme.spacing(3),
//     transition: theme.transitions.create("margin", {
//       easing: theme.transitions.easing.sharp,
//       duration: theme.transitions.duration.leavingScreen,
//     }),
//     marginLeft: `-${drawerWidth}px`,
//     ...(open && {
//       transition: theme.transitions.create("margin", {
//         easing: theme.transitions.easing.easeOut,
//         duration: theme.transitions.duration.enteringScreen,
//       }),
//       marginLeft: 0,
//     }),
//   })
// );

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: "flex-end",
}));

export default function PersistentDrawerLeft() {
  const [menuSize] = useContext(MenuSizeContext);
  const drawerWidth = menuSize === "large" ? 260 : 80;
  const [open, setOpen] = React.useState(false);
  const handleDrawerOpen = () => {
    setOpen(true);
  };
  const handleDrawerClose = () => {
    setOpen(false);
  };

  // useEffect(() => {
  //   console.log(menuSize, "menuSize");
  // });

  // Resize width
  const [width, setWidth] = useState(window.innerWidth);
  const updateDimensions = () => {
    setWidth(window.innerWidth);
  };
  useEffect(() => {
    window.addEventListener("resize", updateDimensions);
    return () => window.removeEventListener("resize", updateDimensions);
  }, []);

  let displayModalWeb = { xs: "none", sm: "flex" };
  let displayModalMobile = { xs: "flex", sm: "none" };

  return (
    <>
      <Box sx={{ display: displayModalWeb }}>
        <CssBaseline />
        <AppBar
          sx={{ boxShadow: "none", backgroundColor: "#fff" }}
          position="fixed"
        >
          <TopNavbar handleDrawerOpen={handleDrawerOpen} />
        </AppBar>
        {menuSize === "large" ? <MenuWeb /> : <SmallMenu />}
        <Box
          sx={{
            padding: "80px 30px 20px 30px",
            width: `calc(100% - ${drawerWidth}px)`,
          }}
        >
          <Outlet />
        </Box>
      </Box>

      <Box sx={{ display: displayModalMobile }}>
        <CssBaseline />
        <AppBar
          sx={{ boxShadow: "none", backgroundColor: "#fff" }}
          position="fixed"
        >
          <TopNavbar handleDrawerOpen={handleDrawerOpen} />
        </AppBar>
        <Box sx={{ marginTop: "54px", width: "100%" }}>
          <Outlet />
        </Box>
      </Box>
    </>
  );
}
