import React, { useState, useContext } from "react";
import "../dashboard.scss";
import { Grid, Box, Stack, Typography } from "@mui/material";
import { useQuery } from "@apollo/client";
import { AuthContext } from "../../../Context/AuthContext";
//Icons
import VaccinesIcon from "@mui/icons-material/Vaccines";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import HomeWorkIcon from "@mui/icons-material/HomeWork";
import DriveEtaIcon from "@mui/icons-material/DriveEta";
//Srcs
import {
    GET_TOTAL_DEPARTMENT,
    GET_TOTAL_STAFF,
    GET_TOTAL_COVID_VACCINATION,
    GET_TOTAL_DRIVERLICENCE,
    GET_STAFF_STATISTICS
} from "../../../Schema/Dashboard";


export default function Card() {
    const [staffStatisticData, setStaffStatisticData] = useState(null);
    const [totalDepartment, setTotalDepartment] = useState(0);
    const [totalStaff, setTotalStaff] = useState(0);
    const [totalCovidVaccination, setTotalCovidVaccination] = useState(0);
    const [totalDriverlicence, setTotalDriverlicence] = useState(0);

    const { data } = useQuery(GET_TOTAL_DEPARTMENT, {
        onCompleted: ({ totalDepartment }) => {
            setTotalDepartment(totalDepartment);
        },
        onError: (error) => {
            console.log("total department", error);
            setAlert(true, "error", error?.message);
        },
    });

    const { data: staffData } = useQuery(GET_TOTAL_STAFF, {
        onCompleted: ({ totalStaff }) => {
            setTotalStaff(totalStaff);
        },
        onError: (error) => {
            console.log(error);
        },
    });

    const { data: covidData } = useQuery(GET_TOTAL_COVID_VACCINATION, {
        onCompleted: ({ totalStaffCovidVaccinated }) => {
            setTotalCovidVaccination(totalStaffCovidVaccinated);
        },
        onError: (error) => {
            console.log(error);
        },
    });

    const { data: driverData } = useQuery(GET_TOTAL_DRIVERLICENCE, {
        onCompleted: ({ totalStaffHaveDrivingLicense }) => {
            setTotalDriverlicence(totalStaffHaveDrivingLicense);
        },
        onError: (error) => {
            console.log("error::", error);
        },
    });

    const { data: staffStatistic } = useQuery(GET_STAFF_STATISTICS, {
        onCompleted: ({ staffStatistic }) => {
            setStaffStatisticData(staffStatistic?.series);
        },
        onError: (error) => {
            console.log(error);
        },
    });
    const { setAlert } = useContext(AuthContext);

    return (
        <Grid container spacing={3}>
            {/* =================================== Total Members ================================ */}
            <Grid item xs={12} sm={6} lg={6}>
                <Box
                    className="box-container"
                    sx={{ borderBottom: "5px solid #198F4A" }}
                >
                    <Stack direction="row" spacing={2} height={80}>
                        <Stack
                            className="box-icon-container"
                            sx={{ backgroundColor: "#198F4A" }}
                        >
                        <PeopleAltIcon
                            sx={{
                                color: "#ffff",
                                fontSize: "40px",
                            }}
                        />
                        </Stack>
                        <Stack direction="column" justifyContent="space-between">
                            <Typography className="text-title">Total Members</Typography>
                            <Typography className="text-main" sx={{ color: "#198F4A" }}>
                                {staffStatisticData?.reduce((a, b) => a + b, 0)}
                            </Typography>
                        </Stack>
                    </Stack>
                </Box>
            </Grid>
            {/* =================================== Total Department ================================ */}
            <Grid item xs={12} sm={6} lg={6}>
                <Box
                    className="box-container"
                    sx={{ borderBottom: "5px solid #082137" }}
                >
                    <Stack direction="row" spacing={2} height={80}>
                        <Stack
                            className="box-icon-container"
                            sx={{ backgroundColor: "#082137" }}
                        >
                            <HomeWorkIcon
                                sx={{
                                    color: "#ffff",
                                    fontSize: "40px",
                                }}
                            />
                        </Stack>
                        <Stack direction="column" justifyContent="space-between">
                            <Typography className="text-title">Departments</Typography>
                            <Typography className="text-main" sx={{ color: "#082137" }}>
                                {totalDepartment}
                            </Typography>
                        </Stack>
                    </Stack>
                </Box>
            </Grid>

            {/* =================================== Total Driver licence ================================ */}
            <Grid item xs={12} sm={6} lg={6}>
                <Box
                    className="box-container"
                    sx={{ borderBottom: "5px solid #43425D" }}
                >
                    <Stack direction="row" spacing={2} height={80}>
                        <Stack
                            className="box-icon-container"
                            sx={{ backgroundColor: "#43425D" }}
                        >
                            <DriveEtaIcon
                                sx={{
                                    color: "#ffff",
                                    fontSize: "40px",
                                }}
                            />
                        </Stack>
                        <Stack direction="column" justifyContent="space-between">
                            <Typography className="text-title">Driver Licence</Typography>
                            <Typography className="text-main" sx={{ color: "#43425D" }}>
                                {totalDriverlicence}
                            </Typography>
                        </Stack>
                    </Stack>
                </Box>
            </Grid>

            {/* =================================== Total Covid Vaccination ================================ */}
            <Grid item xs={12} sm={6} lg={6}>
                <Box
                    className="box-container"
                    sx={{ borderBottom: "5px solid #81ab48"}}
                >
                    <Stack direction="row" spacing={2} height={80}>
                        <Stack
                            className="box-icon-container"
                            sx={{ backgroundColor: "#81ab48" }}
                        >
                            <VaccinesIcon
                                sx={{
                                    color: "#ffff",
                                    fontSize: "40px",
                                }}
                            />
                        </Stack>
                        <Stack direction="column" justifyContent="space-between">
                            <Typography className="text-title">Covid Vaccinated</Typography>
                            <Typography className="text-main" sx={{ color: "#81ab48" }}>
                                {totalCovidVaccination}
                            </Typography>
                        </Stack>
                    </Stack>
                </Box>
            </Grid>
        </Grid>
    );
}
