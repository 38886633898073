import { gql } from "@apollo/client";

export const GET_ACTION_LOG_PAGINATION = gql`
  query GetActionLogsWithPagination(
    $page: Int
    $limit: Int
    $keyword: String
    $pagination: Boolean
    $fromDate: Date
    $toDate: Date
  ) {
    getActionLogsWithPagination(
      page: $page
      limit: $limit
      keyword: $keyword
      pagination: $pagination
      fromDate: $fromDate
      toDate: $toDate
    ) {
      paginator {
        slNo
        prev
        next
        perPage
        totalPosts
        totalPages
        currentPage
        hasPrevPage
        hasNextPage
        totalDocs
      }
      data {
        user {
          _id
          staffProfile {
            englishData {
              firstNameLatin
              lastNameLatin
            }
            imageSrc
            imageName
          }
        }
        actionOn
        actionType
        docId
        createdAt
        updatedAt
      }
    }
  }
`;
