import React, { useContext, useEffect, useState, useMemo } from "react";
import "../../../Style/createstyle.scss";
import { useFormik, Form, FormikProvider } from "formik";
import * as Yup from "yup";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import { Grid, Stack, Box, TextField, Typography, Button } from "@mui/material";
import { useMutation } from "@apollo/client";
import PropTypes from "prop-types";
import LinearProgress from "@mui/material/LinearProgress";
//Icons
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import AddCircleIcon from "@mui/icons-material/AddCircle";
//date picker
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import moment from "moment";
//Src
import { AuthContext } from "../../../Context/AuthContext";
import { CREATE_WORKING_HISTORY } from "../../../Schema/WorkHistory";
import FileThumnail from "../../../DynamicComp/FileThumnail";
import ViewDocument from "../Document/ViewDocument";
import EmptyPdfImage from "../../../Assets/empty-image.png";
import ListResponsibility from "./ListResponsibility";

function LinearProgressWithLabel(props) {
  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <Box sx={{ width: "100%", mr: 1 }}>
        <LinearProgress variant="determinate" {...props} />
      </Box>
      <Box sx={{ minWidth: 35 }}>
        <Typography variant="body2" color="text.secondary">{`${Math.round(
          props.value
        )}%`}</Typography>
      </Box>
    </Box>
  );
}
LinearProgressWithLabel.propTypes = {
  value: PropTypes.number.isRequired,
};

export default function CreateWorkHistory({
  open,
  handleClose,
  staffId,
  setRefetch,
}) {
  const { setAlert } = useContext(AuthContext);
  const [loading, setLoading] = useState(false);

  //---------------Progress------------------
  const [statusProgress, setStatusProgress] = React.useState(false);
  const [progress, setProgress] = React.useState(10);
  // console.log("statusProgress", statusProgress);
  // console.log("progress", progress);
  React.useEffect(() => {
    const timer = setInterval(() => {
      setProgress((prevProgress) =>
        prevProgress >= 100 ? 100 : prevProgress + 10
      );
    }, 500);
    return () => {
      clearInterval(timer);
    };
  }, []);

  // upload Image
  const [pdfFile, setPdfFile] = useState(null);
  const [uploadFile, setUploadFile] = useState(null);
  const [documentSrc, setDocumentSrc] = useState("");
  const [openView, setOpenView] = useState(false);
  const handleCloseView = () => {
    setOpenView(false);
    setPdfFile(null);
  };
  const fileType = ["application/pdf"];
  const handleUploadImage = (e) => {
    let fileSelected = e.target.files[0];
    setUploadFile(fileSelected);
    //For thumbnails
    if (fileSelected) {
      if (fileSelected && fileType.includes(fileSelected.type)) {
        let reader = new FileReader();
        reader.readAsDataURL(fileSelected);
        reader.onload = (e) => {
          setPdfFile(e.target.result);
        };
      } else {
        setPdfFile(null);
      }
    } else {
      console.log("Please select file!");
    }
    setOpenView(true);
  };
  //----------------- End -------------------------

  // ========================================= List Responsibility =========================================
  const [requireField, setRequireField] = useState(false);
  const [currentResponsibility, setCurrentResponsibility] = useState({
    key: "",
    responsibility_name: "",
  });

  const [responsibilityList, setResponsibilityList] = useState([]);

  const addJournalEntry = () => {
    const newItem = currentResponsibility;
    if (newItem !== "") {
      const items = [...responsibilityList, newItem];
      setResponsibilityList([...items]);
      setCurrentResponsibility({
        key: "",
        responsibility_name: "",
      });
    }
  };

  const handleAddResponsibilityList = () => {
    setCurrentResponsibility({
      responsibility_name: "",
      key: Date.now(),
    });
  };

  useMemo(async () => {
    await handleAddResponsibilityList();
    await addJournalEntry();
  }, []);

  useEffect(() => {
    if (currentResponsibility?.key !== "") {
      addJournalEntry();
    }
  }, [currentResponsibility]);

  //delete function
  const deleteResponsibilityList = (key) => {
    const filtereditems = responsibilityList?.filter((t) => t.key !== key);
    setResponsibilityList(filtereditems);
  };

  const setUpdateResponsibility = (responsibility_name, key) => {
    const items = responsibilityList;
    items.map((i) => {
      if (i.key == key) {
        i.responsibility_name = responsibility_name;
      }
    });
    setResponsibilityList([...items]);
    if (responsibility_name !== "") {
      setRequireField(false);
    } else {
      setRequireField(true);
    }
  };
  // ============================ End list responsible ==============================

  const [createWorkingHistory] = useMutation(CREATE_WORKING_HISTORY, {
    onCompleted: ({ createWorkingHistory }) => {
      setLoading(false);
      if (createWorkingHistory?.success === true) {
        setAlert(true, "success", createWorkingHistory?.message);
        handleClose();
        resetForm();
        setRefetch();
        setDocumentSrc("");
        setUploadFile(null)
        setPdfFile(null)
        setResponsibilityList([]);
      } else {
        setAlert(true, "error", createWorkingHistory?.message);
      }
    },
    onError: (error) => {
      setLoading(false);
      setAlert(true, "error", error?.message);
    },
  });

  //fromik
  const CheckValidation = Yup.object().shape({
    startDate: Yup.string(),
    endDate: Yup.string(),
    positions: Yup.string().required("require!"),
    workingPlace: Yup.string().required("require!"),
    remark: Yup.string(),
  });

  const formik = useFormik({
    initialValues: {
      startDate: moment(),
      endDate: moment(),
      positions: "",
      workingPlace: "",
      remark: "",
    },

    validationSchema: CheckValidation,
    onSubmit: async (values) => {
      setLoading(true);
      // console.log("values:::", values, staffId);
      let createStatus = false;
      const BreakException = {};
      try {
        if (responsibilityList?.length !== 0) {
          responsibilityList?.forEach(function (elem) {
            if (elem?.responsibility_name !== "") {
              createStatus = true;
            } else {
              setLoading(false);
              setRequireField(true);
              createStatus = false;
              throw BreakException;
            }
          });
        } else {
          setLoading(false);
          setRequireField(true);
        }
      } catch (e) {
        if (e !== BreakException) throw e;
      }

      if (createStatus) {
        let responsibilities = [];
        responsibilityList?.map((d) => {
          const allRes = d?.responsibility_name;
          responsibilities?.push(allRes);
        });

        setLoading(true);
        // console.log("documentSrc::", documentSrc);
        createWorkingHistory({
          variables: {
            staffInfoId: staffId,
            input: {
              fileUpload: documentSrc ? documentSrc : "",
              responsibilities: responsibilities,
              ...values,
            },
          },
        });
      }
    },
  });

  const {
    errors,
    touched,
    handleSubmit,
    getFieldProps,
    setFieldValue,
    values,
    resetForm,
  } = formik;

  return (
    <Dialog open={open} className="create-container">
      <DialogTitle sx={{ padding: "10px 20px" }}>
        <Stack direction="row" spacing={2}>
          <Stack direction="column" justifyContent="center">
            <Typography className="dialog-title">
              Create working history
            </Typography>
          </Stack>

          <Box sx={{ flexGrow: 1 }} />
          <IconButton onClick={handleClose}>
            <CloseIcon className="close-icon" />
          </IconButton>
        </Stack>
      </DialogTitle>

      <DialogContent dividers>
        <FormikProvider value={formik}>
          <Form noValidate autoComplete="off" onSubmit={handleSubmit}>
            <Grid container rowSpacing={2} columnSpacing={4}>
              <Grid item xs={12}>
                <Stack direction="column" spacing={1}>
                  <Stack direction="row" justifyContent="center">
                    {/* =============================== For view outside​ =================================  */}
                    {!openView ? (
                      <Button component="label">
                        <TextField
                          type="file"
                          id="image"
                          sx={{ display: "none" }}
                          onChange={handleUploadImage}
                        />
                        {uploadFile ? (
                          <FileThumnail pdfFile={pdfFile} />
                        ) : (
                          <img
                            style={{ width: "200px", height: "200px" }}
                            src={EmptyPdfImage}
                          />
                        )}
                        <input type="file" hidden />
                      </Button>
                    ) : (
                      <ViewDocument
                        setProgress={setProgress}
                        setStatusProgress={setStatusProgress}
                        open={openView}
                        setOpenView={setOpenView}
                        handleClose={handleCloseView}
                        pdfFile={pdfFile}
                        uploadFile={uploadFile}
                        setUploadFile={setUploadFile}
                        setDocumentSrc={setDocumentSrc}
                      />
                    )}
                  </Stack>

                  <Stack
                    direction="row"
                    justifyContent="center"
                    spacing={2}
                    sx={{ mt: 1 }}
                  >
                    {statusProgress && progress < 100 ? (
                      <Box sx={{ width: "100%", marginLeft: "20px" }}>
                        <LinearProgressWithLabel value={progress} />
                      </Box>
                    ) : (
                      <Typography className="text-En">Upload pdf</Typography>
                    )}
                  </Stack>
                </Stack>
              </Grid>

              <Grid item xs={6}>
                <Typography className="field-title">Start date</Typography>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                    value={values?.startDate}
                    onChange={(e) => setFieldValue("startDate", e)}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        className="text-field"
                        size="small"
                        fullWidth
                      />
                    )}
                  />
                </LocalizationProvider>
              </Grid>

              <Grid item xs={6}>
                <Typography className="field-title">End date</Typography>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                    value={values?.endDate}
                    onChange={(e) => setFieldValue("endDate", e)}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        className="text-field"
                        size="small"
                        fullWidth
                      />
                    )}
                  />
                </LocalizationProvider>
              </Grid>

              <Grid item xs={6}>
                <Typography className="field-title">Positions</Typography>
                <TextField
                  fullWidth
                  size="small"
                  className="text-field"
                  multiline
                  placeholder="positions"
                  {...getFieldProps("positions")}
                  error={Boolean(touched.positions && errors.positions)}
                  helperText={touched.positions && errors.positions}
                />
              </Grid>

              <Grid item xs={6}>
                <Typography className="field-title">Working place</Typography>
                <TextField
                  fullWidth
                  size="small"
                  className="text-field"
                  multiline
                  placeholder="working place"
                  {...getFieldProps("workingPlace")}
                  error={Boolean(touched.workingPlace && errors.workingPlace)}
                  helperText={touched.workingPlace && errors.workingPlace}
                />
              </Grid>

              <Grid item xs={12}>
                <Typography className="field-title">Remark</Typography>
                <TextField
                  fullWidth
                  size="small"
                  className="text-field"
                  multiline
                  placeholder="remark"
                  {...getFieldProps("remark")}
                  error={Boolean(touched.remark && errors.remark)}
                  helperText={touched.remark && errors.remark}
                />
              </Grid>

              <Grid item xs={12}>
                <Stack direction="column" spacing={2}>
                  <Stack direction="row" justifyContent="space-between">
                    <Typography className="dialog-title">
                      Responsibilities
                    </Typography>
                    <IconButton onClick={handleAddResponsibilityList}>
                      <AddCircleIcon sx={{ color: "#082137" }} />
                    </IconButton>
                  </Stack>
                  <ListResponsibility
                    responsibilityList={responsibilityList}
                    deleteResponsibilityList={deleteResponsibilityList}
                    setUpdateResponsibility={setUpdateResponsibility}
                    requireField={requireField}
                  />
                </Stack>
              </Grid>
            </Grid>
          </Form>
        </FormikProvider>
      </DialogContent>

      <DialogActions>
        <Stack direction="row" spacing={4} className="action-container">
          <Button className="btn-cencel" fullWidth onClick={handleClose}>
            Cencel
          </Button>

          {loading ? (
            <Button className="btn-create" fullWidth>
              Loading...
            </Button>
          ) : (
            <Button
              className="btn-create"
              fullWidth
              onClick={handleSubmit}
              disabled={responsibilityList?.length === 0 ? true : false}
            >
              Create
            </Button>
          )}
        </Stack>
      </DialogActions>
    </Dialog>
  );
}
