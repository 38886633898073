import { gql } from "@apollo/client";

export const CREATE_WORKINGPLACE = gql`
mutation CreateWorkingPlace($input: WorkingPlaceInput) {
  createWorkingPlace(input: $input) {
    success
    message
  }
}
`;

export const GET_WORKINGPLACE_WITH_PAGINATION = gql`
  query GetWorkingPlacePagination($page: Int, $limit: Int, $keyword: String, $pagination: Boolean) {
  getWorkingPlacePagination(page: $page, limit: $limit, keyword: $keyword, pagination: $pagination) {
    data {
      _id
      workingPlaceName
      remark
      createdAt
      updatedAt
    }
    paginator {
      slNo
      prev
      next
      perPage
      totalPosts
      totalPages
      currentPage
      hasPrevPage
      hasNextPage
      totalDocs
    }
  }
}
`;

export const UPDATE_WORKINGPLACE = gql`
mutation UpdateWorkingPlace($input: WorkingPlaceInput, $id: ID) {
  updateWorkingPlace(input: $input, _id: $id) {
    success
    message
  }
}
`;

export const DELETE_WORKINGPLACE = gql`
mutation DeleteWorkingPlace($id: ID) {
  deleteWorkingPlace(_id: $id) {
    success
    message
  }
}
`;
