import React, { useState } from "react";
import {
  Box,
  Stack,
  Avatar,
  Grid,
  Button,
  Divider,
  Typography,
  IconButton,
} from "@mui/material";
import moment from "moment";
//icons
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import CheckIcon from "@mui/icons-material/Check";
import PriorityHighIcon from "@mui/icons-material/PriorityHigh";
//Srcs
import "../../Style/createdrawerstyle.scss";
import OvertimeAction from "./OvertimeAction";
import ApproveAndDeny from "./ApproveAndDeny";

export default function OvertimeCardMobile({
  handleClose,
  setRefetch,
  overtimeDetails,
  userLogin,
}) {
  const [open, setOpen] = useState(false);
  const handleCloseApproveDeny = () => setOpen(false);
  const [requestStatus, setRequestStatus] = useState("");

  const handleApprove = () => {
    setOpen(true);
    setRequestStatus("Approve");
  };

  const handleDeny = () => {
    setOpen(true);
    setRequestStatus("Deny");
  };

  // console.log("overtimeDetails::", overtimeDetails);

  return (
    <Stack direction="column" className="drawer-container">
      <Stack
        direction="row"
        justifyContent="space-between"
        sx={{ marginBottom: "20px" }}
      >
        <IconButton onClick={handleClose} className="close-icon-container">
          <ArrowBackIcon className="close-icon" />
        </IconButton>

        <Stack direction="column" justifyContent="center">
          <Typography className="dialog-title">Overtime Work Details</Typography>
        </Stack>
      </Stack>
      <Stack direction="column" spacing={2} className="drawer-card-container">
        <Stack direction="row" justifyContent="space-between">
          <Stack direction="column" justifyContent="center">
            <Typography className="request-card-request-type">
              {overtimeDetails?.createdBy?.staffProfile?.englishData
                ?.firstNameLatin +
                " " +
                overtimeDetails?.createdBy?.staffProfile?.englishData
                  ?.lastNameLatin}
            </Typography>
          </Stack>
          <Stack direction="column" justifyContent="center">
            <Typography className="request-card-reason">
              {moment(overtimeDetails?.createdAt).format("DD, MMM YY")}
            </Typography>
          </Stack>
          <Box
            sx={{
              display:
                userLogin?._id === overtimeDetails?.createdBy?._id &&
                  overtimeDetails?.status === "Pending"
                  ? "block"
                  : "none",
            }}
          >
            <OvertimeAction
              userLogin={userLogin}
              editData={overtimeDetails}
              setRefetch={setRefetch}
            />
          </Box>
        </Stack>

        <Box className="request-card-header">
          <Box sx={{ padding: "4px" }}>
            <Typography className="request-card-request-type">
              Total hours: {overtimeDetails?.totalOvertimeAsHours}
            </Typography>
            {/* <Typography className="request-card-item">
              {` ${moment(overtimeDetails?.startDate)
                .utc()
                .format("DD-MMM-YYYY")}  ${moment(overtimeDetails?.startDate)
                .utcOffset(0, true)
                .format("hh:mm A")} - ${moment(overtimeDetails?.endDate)
                .utc()
                .format("DD-MMM-YYYY")}  ${moment(overtimeDetails?.endDate)
                .utcOffset(0, true)
                .format("hh:mm A")}`}
            </Typography> */}
            <Stack direction='column'>

              <Typography className="request-card-item">
                From : {moment(overtimeDetails?.startDate).format('DD-MMM-YYYY hh:mm A')}
              </Typography>
              <Typography className="request-card-item">
                To : {moment(overtimeDetails?.endDate).format('DD-MMM-YYYY hh:mm A')}
              </Typography>
            </Stack>
          </Box>
        </Box>

        <Stack direction="column">
          <Typography className="request-card-reason">Description</Typography>
          <Typography className="request-card-reason">
            {overtimeDetails?.reason}
          </Typography>
        </Stack>

        <Divider />

        <Grid container>
          <Grid
            item
            xs={
              userLogin?._id !== overtimeDetails?.createdBy?._id &&
                overtimeDetails?.status === "Pending"
                ? 6
                : 12
            }
          >
            <Stack direction="column" spacing={1}>
              <Typography className="request-card-reason">
                Manager respone
              </Typography>

              {overtimeDetails?.status === "Pending" ? (
                <Stack direction="row" spacing={1}>
                  <HelpOutlineIcon
                    sx={{
                      fontSize: "18px",
                      color: "orange",
                    }}
                  />
                  <Stack
                    direction="column"
                    justifyContent="center"
                    className="request-card-item"
                  >
                    {overtimeDetails?.status}
                  </Stack>
                </Stack>
              ) : overtimeDetails?.status === "Denied" ? (
                <Stack direction="row" spacing={2}>
                  <Avatar
                    alt="Manager"
                    src={
                      overtimeDetails?.approvedOrDeniedBy?.staffProfile
                        ?.imageSrc
                    }
                    sx={{ width: "40px", height: "40px" }}
                  />
                  <Stack direction="column" spacing={1}>
                    <Stack direction="row" spacing={1}>
                      <Typography className="request-card-request-type">
                        {overtimeDetails?.approvedOrDeniedBy?.staffProfile
                          ?.englishData?.firstNameLatin +
                          " " +
                          overtimeDetails?.approvedOrDeniedBy?.staffProfile
                            ?.englishData?.lastNameLatin}{" "}
                        :
                      </Typography>
                      <Typography className="request-card-item">
                        {moment(overtimeDetails?.statusDate).format(
                          "DD, MMM YY"
                        )}
                      </Typography>
                    </Stack>
                    <Stack direction="row">
                      <PriorityHighIcon
                        sx={{ color: "red", fontSize: "16px" }}
                      />
                      <Stack
                        direction="column"
                        justifyContent="center"
                        className="request-card-item"
                      >
                        {overtimeDetails?.status}
                      </Stack>
                    </Stack>
                    <Stack
                      direction="column"
                      sx={{
                        display:
                          overtimeDetails?.managerReply === ""
                            ? "none"
                            : "flex",
                      }}
                    >
                      <Typography className="request-card-reason">
                        Note
                      </Typography>
                      <Typography className="request-card-reason">
                        {overtimeDetails?.managerReply}
                      </Typography>
                    </Stack>
                  </Stack>
                </Stack>
              ) : (
                <Stack direction="row" spacing={2}>
                  <Avatar
                    alt="Manager"
                    src={
                      overtimeDetails?.approvedOrDeniedBy?.staffProfile
                        ?.imageSrc
                    }
                    sx={{ width: "40px", height: "40px" }}
                  />
                  <Stack direction="column" spacing={1}>
                    <Stack direction="row" spacing={1}>
                      <Typography className="request-card-request-type">
                        {overtimeDetails?.approvedOrDeniedBy?.staffProfile
                          ?.englishData?.firstNameLatin +
                          " " +
                          overtimeDetails?.approvedOrDeniedBy?.staffProfile
                            ?.englishData?.lastNameLatin}{" "}
                        :
                      </Typography>
                      <Typography className="request-card-item">
                        {moment(overtimeDetails?.statusDate).format(
                          "DD, MMM YY"
                        )}
                      </Typography>
                    </Stack>
                    <Stack direction="row" spacing={1}>
                      <CheckIcon sx={{ color: "#198f4a", fontSize: "18px" }} />
                      <Stack
                        direction="column"
                        justifyContent="center"
                        className="request-card-item"
                      >
                        {overtimeDetails?.status}
                      </Stack>
                    </Stack>
                    <Stack
                      direction="column"
                      sx={{
                        display:
                          overtimeDetails?.managerReply === ""
                            ? "none"
                            : "flex",
                      }}
                    >
                      <Typography className="request-card-reason">
                        Note
                      </Typography>
                      <Typography className="request-card-reason">
                        {overtimeDetails?.managerReply}
                      </Typography>
                    </Stack>
                  </Stack>
                </Stack>
              )}
            </Stack>
          </Grid>
          <Grid item xs={6}>
            <Stack
              direction="row"
              spacing={2}
              justifyContent="right"
              sx={{
                display:
                  userLogin?._id !== overtimeDetails?.createdBy?._id &&
                    overtimeDetails?.status === "Pending"
                    ? "flex"
                    : "none",
              }}
            >
              <Button className="btn-dennie" onClick={handleDeny}>
                Denie
              </Button>
              <Button className="btn-approve" onClick={handleApprove}>
                Approve
              </Button>
              <ApproveAndDeny
                open={open}
                handleClose={handleCloseApproveDeny}
                userLogin={userLogin}
                editData={overtimeDetails}
                requestStatus={requestStatus}
                setRefetch={setRefetch}
              />
            </Stack>
          </Grid>
        </Grid>
      </Stack>
    </Stack>
  );
}
