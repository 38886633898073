import React from "react";
import { Grid, Box, Stack, Typography } from "@mui/material";
import moment from "moment";
//Srcs
import "../../../Style/createstyle.scss";
import ImageLogo from "../../../Assets/PEPY-transparent.png";

export default function PrintOvertime({
  componentRef,
  fromDate,
  toDate,
  tableData,
}) {
  // console.log("tableData::", tableData);
  return (
    <div className="setup-report-container" ref={componentRef}>
      <Grid container spacing={1}>
        <Grid item xs={4}>
          <img src={ImageLogo} className="logo-company" />
        </Grid>
        <Grid item xs={4}>
          <Typography className="report-top-title-head">
            Overtime Report
          </Typography>
          <Typography className="report-top-subtitle-head">
            From {moment(fromDate).format("DD-MMM-YYYY")} to{" "}
            {moment(toDate).format("DD-MMM-YYYY")}
          </Typography>
        </Grid>
        <Grid item xs={4}></Grid>
      </Grid>
      <Stack direction="row" spacing={1} sx={{ marginTop: "10px" }}>
        <Stack direction="column">
          <Typography className="createdby-name">Full name</Typography>
          <Typography className="createdby-name">Positions</Typography>
        </Stack>
        <Stack direction="column">
          <Typography className="createdby-name">
            :{" "}
            {tableData[0]?.createdBy?.staffProfile?.englishData
              ?.firstNameLatin +
              " " +
              tableData[0]?.createdBy?.staffProfile?.englishData?.lastNameLatin}
          </Typography>
          <Typography className="createdby-name">
            :{" "}
            {tableData[0]?.createdBy?.currentContract?.positions?.positionName}
          </Typography>
        </Stack>
      </Stack>

      <div className="table-report-container" style={{ marginTop: "10px" }}>
        <table className="summary-table-report">
          <thead>
            <tr>
              <th style={{ width: "3%" }}>N°</th>
              <th style={{ width: "15%" }}>Create by</th>
              <th style={{ width: "11%" }}>Request date</th>
              <th style={{ width: "12%" }}>Duration</th>
              <th style={{ width: "22%" }}>Period</th>
              <th style={{ width: "30%" }}>Reason</th>
              <th style={{ width: "7%" }}>Status</th>
            </tr>
          </thead>

          <tbody>
            {tableData?.map((row, index) => {
              return (
                <tr key={index}>
                  <td>{index + 1}</td>
                  <td>
                    {row?.createdBy?.staffProfile?.englishData?.firstNameLatin +
                      " " +
                      row?.createdBy?.staffProfile?.englishData?.lastNameLatin}
                  </td>
                  <td>{moment(row?.updatedAt).format("DD-MMM-YYYY")}</td>
                  <td>
                    {row?.totalOvertimeAsHours} h ({row?.totalOvertimeAsDay} d)
                  </td>
                  <td>
                    {moment(row?.startDate).format("DD MMM")},{" "}
                    {moment(row?.startTime).format("HH:MM")} -{" "}
                    {moment(row?.endDate).format("DD MMM")},{" "}
                    {moment(row?.endTime).format("HH:MM")}
                  </td>
                  <td>{row?.reason}</td>
                  <td>{row?.status}</td>
                </tr>
              );
            })}
            {/* <tr>
              <td>1</td>
              <td>1</td>
              <td>1</td>
              <td>1</td>
              <td>1</td>
              <td>1</td>
            </tr>
            <tr>
              <td>1</td>
              <td>1</td>
              <td>1</td>
              <td>1</td>
              <td>1</td>
              <td>1</td>
            </tr> */}
          </tbody>
        </table>

        <Typography className="createdby-name" sx={{ marginTop: "10px" }}>
          Singnature:
        </Typography>
        <Grid container sx={{ marginTop: "6px" }}>
          <Grid item xs={4}>
            <Stack
              direction="column"
              spacing={10}
              sx={{ border: "1px solid gray", padding: "0px 6px" }}
            >
              <Typography className="createdby-name">Employee:</Typography>
              <Stack direction="column" spacing={1}>
                <Typography className="createdby-name">
                  Name:{" "}
                  {/* {tableData[0]?.createdBy?.staffProfile?.englishData
                    ?.firstNameLatin +
                    " " +
                    tableData[0]?.createdBy?.staffProfile?.englishData
                      ?.lastNameLatin} */}
                </Typography>
                <Typography className="createdby-name">
                  Positions:{" "}
                  {/* {
                    tableData[0]?.createdBy?.currentContract?.positions
                      ?.positionName
                  } */}
                </Typography>
                <Typography className="createdby-name">Date:</Typography>
              </Stack>
            </Stack>
          </Grid>

          <Grid item xs={4}>
            <Stack
              direction="column"
              spacing={10}
              sx={{ border: "1px solid gray", padding: "0px 6px" }}
            >
              <Typography className="createdby-name">Manager:</Typography>

              <Stack direction="column" spacing={1}>
                <Typography className="createdby-name">Name:</Typography>
                <Typography className="createdby-name">Positions:</Typography>
                <Typography className="createdby-name">Date:</Typography>
              </Stack>
            </Stack>
          </Grid>

          <Grid item xs={4}>
            <Stack
              direction="column"
              spacing={10}
              sx={{ border: "1px solid gray", padding: "0px 6px" }}
            >
              <Typography className="createdby-name">HR Manager:</Typography>

              <Stack direction="column" spacing={1}>
                <Typography className="createdby-name">Name:</Typography>
                <Typography className="createdby-name">Positions:</Typography>
                <Typography className="createdby-name">Date:</Typography>
              </Stack>
            </Stack>
          </Grid>
        </Grid>
      </div>
    </div>
  );
}
