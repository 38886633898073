import React, { useState, useContext, useEffect } from "react";
import "../../../Style/pagestyle.scss";
import {
  Button,
  Grid,
  Stack,
  TextField,
  InputAdornment,
  Box,
  Pagination,
  Select,
  MenuItem,
  Skeleton,
  Card,
  CardHeader,
  CardContent,
} from "@mui/material";
import LibraryAddOutlinedIcon from "@mui/icons-material/LibraryAddOutlined";
import SearchIcon from "@mui/icons-material/Search";
import { useQuery } from "@apollo/client";
//src
import { AuthContext } from "../../../Context/AuthContext";
import EmptyData from "../../Include/EmptyData";
import LoadingPage from "../../Include/LoadingPage";
import UploadDocument from "./UploadDocument";
import { GET_DOCUMENT_WITH_PAGINATION } from "../../../Schema/Document";
import PdfCard from "./PdfCard";

export default function Document({ userLogin, staffId }) {
  const [loading, setLoading] = useState(true);
  const { setAlert } = useContext(AuthContext);

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  //query
  const [tableData, setTableData] = useState([]);
  const [paginator, setPaginator] = useState([]);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(6);
  const [keyword, setKeyword] = useState("");

  const { data, refetch } = useQuery(GET_DOCUMENT_WITH_PAGINATION, {
    variables: {
      page: page,
      limit: limit,
      keyword: keyword,
      pagination: true,
      staffId: staffId,
    },
    onCompleted: ({ getDocumentPagination }) => {
      // console.log("getDocumentPagination::", getDocumentPagination);
      setTableData(getDocumentPagination?.data);
      setPaginator(getDocumentPagination?.paginator);
      setLoading(false);
    },
    onError: (error) => {
      setAlert(true, "error", error?.message);
    },
  });

  const handleLimit = (e) => {
    setLimit(e.target.value);
  };

  useEffect(() => {
    refetch();
  }, []);

  return (
    <div className="page-container">
      <Box sx={{ marginTop: "20px" }}>
        <Grid item container spacing={3}>
          <Grid item xs={6} md={4} lg={3}>
            <TextField
              placeholder="search"
              size="small"
              className="text-field"
              onChange={(e) => setKeyword(e.target.value)}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start" sx={{ mr: 1 }}>
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={6} md={8} lg={9}>
            <Stack direction="row" justifyContent="right">
              {userLogin?.systemAccessData?.role === "Admin" ? (
                <Button
                  onClick={handleOpen}
                  className="btn-add"
                  startIcon={<LibraryAddOutlinedIcon className="icon-add" />}
                >
                  Upload
                </Button>
              ) : null}

              <UploadDocument
                open={open}
                handleClose={handleClose}
                staffId={staffId}
                setRefetch={refetch}
              />
            </Stack>
          </Grid>
        </Grid>
      </Box>

      <Box sx={{ marginTop: "20px" }}>
        {loading ? (
          <Grid container spacing={3}>
            {[1, 2, 3, 4]?.map((data, index) => {
              return (
                <Grid item xs={12} sm={6} md={4} lg={3} xl={3} key={data?._id}>
                  <Card>
                    <CardHeader
                      subheader={
                        <Skeleton animation="wave" height={10} width="40%" />
                      }
                    />
                    <Skeleton
                      sx={{ height: 400 }}
                      animation="wave"
                      variant="rectangular"
                    />

                    <CardContent>
                      <Skeleton animation="wave" height={10} width="80%" />
                    </CardContent>
                  </Card>
                  {/* <Stack
                    direction="column"
                    sx={{
                      bgcolor: "#fff",
                      borderRadius: "10px",
                      padding: "20px 0px",
                    }}
                  >
                    <Skeleton
                      width={200}
                      animation="wave"
                      sx={{ padding: "0px 20px" }}
                    />
                    <Skeleton width={"100%"} height={300} animation="wave" />
                    <Skeleton
                      width={200}
                      animation="wave"
                      sx={{ padding: "0px 20px" }}
                    />
                  </Stack> */}
                </Grid>
              );
            })}
          </Grid>
        ) : (
          <>
            {tableData?.length === 0 ? (
              <EmptyData />
            ) : (
              <Grid container spacing={3}>
                {tableData?.map((data, index) => {
                  return (
                    <Grid
                      item
                      xs={12}
                      sm={6}
                      md={4}
                      lg={3}
                      xl={3}
                      key={data?._id}
                    >
                      <PdfCard
                        data={data}
                        setRefetch={refetch}
                        userRole={userLogin?.systemAccessData?.role}
                      />
                    </Grid>
                  );
                })}
              </Grid>
            )}
          </>
        )}

        <Stack
          direction="row"
          justifyContent="right"
          spacing={2}
          sx={{ margin: "20px 30px 0px 0px" }}
        >
          <Stack direction="column" justifyContent="center">
            <Pagination
              hideNextButton={false}
              hidePrevButton={false}
              count={paginator?.totalPages}
              color="primary"
              variant="outlined"
              onChange={(event, pageNumber) => setPage(parseInt(pageNumber))}
            />
          </Stack>
          <Select
            size="small"
            value={limit}
            onChange={handleLimit}
            defaultValue="8/Page"
          >
            <MenuItem value={6}>6/Page</MenuItem>
            <MenuItem value={8}>8/Page</MenuItem>
            <MenuItem value={10}>10/Page</MenuItem>
            <MenuItem value={paginator?.totalDocs}>All/Page</MenuItem>
          </Select>
        </Stack>
      </Box>
    </div>
  );
}
