import React, { useContext, useEffect, useState } from "react";
import "../../Style/createdrawerstyle.scss";
import { useFormik, Form, FormikProvider } from "formik";
import * as Yup from "yup";
import {
  Grid,
  Stack,
  TextField,
  Typography,
  Button,
  IconButton,
  FormHelperText
} from "@mui/material";
import { useMutation } from "@apollo/client";
//date picker
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import moment from "moment";
//icons
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
//Src
import { AuthContext } from "../../Context/AuthContext";
import { CREATE_OVER_TIME, UPDAATE_OVERTIME } from "../../Schema/OverTime";

export default function CreateOverTime({
  handleClose,
  dialogTitle,
  editData,
  setRefetch,
  userLogin,
}) {
  const { setAlert } = useContext(AuthContext);
  const [loading, setLoading] = useState(false);

  const [createOvertimeWork, { data, error }] = useMutation(CREATE_OVER_TIME, {
    onCompleted: ({ createOvertimeWork }) => {
      // console.log(createOvertimeWork);
      setLoading(false);
      if (createOvertimeWork?.success === true) {
        setAlert(true, "success", createOvertimeWork?.message);
        handleClose();
        resetForm();
        setRefetch();
      } else {
        setAlert(true, "error", createOvertimeWork?.message);
      }
    },
    onError: (error) => {
      setLoading(false);
      setAlert(true, "error", error?.message);
    },
  });

  const [updateOvertimeWork] = useMutation(UPDAATE_OVERTIME, {
    onCompleted: ({ updateOvertimeWork }) => {
      setLoading(false);
      if (updateOvertimeWork?.success === true) {
        setAlert(true, "success", updateOvertimeWork?.message);
        handleClose();
        setRefetch();
      } else {
        setAlert(true, "error", updateOvertimeWork?.message);
      }
    },
    onError: (error) => {
      setLoading(false);
      setAlert(true, "error", error?.message);
    },
  });

  //fromik
  const CreatePostions = Yup.object().shape({
    startDate: Yup.date(),
    endDate: Yup.date(),
    startTime: Yup.date(),
    endTime: Yup.date(),
    reason: Yup.string().required("require!"),
    status: Yup.string().required("require!"),
    timeType: Yup.string().required("require!"),
    totalOvertimeAsDay: Yup.number().required("require!"),
    totalOvertimeAsHours: Yup.number().required("require!"),
  });

  const formik = useFormik({
    initialValues: {
      startDate: moment(),
      endDate: moment(),
      startTime: moment(),
      endTime: moment(),
      reason: "",
      status: "Pending",
      timeType: "hours",
      totalOvertimeAsDay: 1,
      totalOvertimeAsHours: 1,
    },

    validationSchema: CreatePostions,
    onSubmit: async (values) => {
      // console.log("values:::", values);
      setLoading(true);
      let newOverTime = {
        createdBy: userLogin?._id,
        ...values,
      };
      if (dialogTitle !== "Create") {
        updateOvertimeWork({
          variables: {
            id: editData?._id,
            input: {
              ...values,
              totalOvertimeAsDay: values.totalOvertimeAsHours / 8,
            },
          },
        });
      } else {
        createOvertimeWork({
          variables: {
            input: {
              ...newOverTime,
              totalOvertimeAsDay: values.totalOvertimeAsHours / 8,
            },
          },
        });
      }
    },
  });

  const {
    errors,
    touched,
    handleSubmit,
    getFieldProps,
    setFieldValue,
    values,
    resetForm,
  } = formik;

  // console.log("editData::", editData);

  useEffect(() => {
    if (editData) {
      setFieldValue("startDate", editData?.startDate);
      setFieldValue("endDate", editData?.endDate);
      setFieldValue("startTime", editData?.startTime);
      setFieldValue("endTime", editData?.endTime);
      setFieldValue("reason", editData?.reason);
      setFieldValue("status", editData?.status);
      setFieldValue("totalOvertimeAsHours", editData?.totalOvertimeAsHours);
      setFieldValue("totalOvertimeAsDay", editData?.totalOvertimeAsDay);
    }
  }, [editData]);

  return (
    <Stack
      direction="column"
      justifyContent="space-between"
      className="drawer-container"
    >
      <div>
        <Stack
          direction="row"
          justifyContent="space-between"
          sx={{ marginBottom: "20px" }}
        >
          <IconButton onClick={handleClose} className="close-icon-container">
            <ArrowBackIcon className="close-icon" />
          </IconButton>
          <Stack direction="column" justifyContent="center">
            <Typography className="dialog-title">
              {dialogTitle} Overtime
            </Typography>
          </Stack>
        </Stack>
        <FormikProvider value={formik}>
          <Form noValidate autoComplete="off" onSubmit={handleSubmit}>
            <Grid container rowSpacing={2} columnSpacing={3}>
              <Grid item xs={12}>
                <Typography className="field-title">Total hours</Typography>
                <TextField
                  fullWidth
                  size="small"
                  type="number"
                  className="text-field"
                  placeholder="total hours"
                  {...getFieldProps("totalOvertimeAsHours")}
                  error={Boolean(
                    touched.totalOvertimeAsHours && errors.totalOvertimeAsHours
                  )}
                  helperText={
                    touched.totalOvertimeAsHours && errors.totalOvertimeAsHours
                  }
                />
              </Grid>

              <Grid item xs={12}>
                <Typography className="field-title">Start date</Typography>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DateTimePicker
                    value={values?.startDate}
                    onChange={(e) => setFieldValue("startDate", e)}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        className="text-field"
                        size="small"
                        fullWidth
                      />
                    )}
                  />
                </LocalizationProvider>
              </Grid>

              <Grid item xs={12}>
                <Typography className="field-title">End date</Typography>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DateTimePicker
                    value={values?.endDate}
                    onChange={(e) => setFieldValue("endDate", e)}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        className="text-field"
                        size="small"
                        fullWidth
                      />
                    )}
                  />
                </LocalizationProvider>
              </Grid>

              <Grid item xs={12}>
                <Typography className="field-title">Reason</Typography>
                <TextField
                  fullWidth
                  size="small"
                  multiline
                  rows={3}
                  className="text-field"
                  placeholder="reason"
                  {...getFieldProps("reason")}
                  error={Boolean(touched.reason && errors.reason)}
                  helperText={touched.reason && errors.reason}
                />
              </Grid>
            </Grid>
          </Form>
        </FormikProvider>
      </div>

      <Grid container sx={{ paddingBottom: "40px" }}>
        <Grid item xs={12}>
          <Stack direction="row" spacing={4} className="action-container">
            <Button className="btn-cencel" fullWidth onClick={handleClose}>
              Cencel
            </Button>

            {loading ? (
              <Button className="btn-create" fullWidth>
                Loading...
              </Button>
            ) : (
              <Button className="btn-create" fullWidth onClick={handleSubmit}>
                {dialogTitle}
              </Button>
            )}
          </Stack>
        </Grid>
      </Grid>
    </Stack>
  );
}
