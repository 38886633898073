import { gql } from "@apollo/client";


export const CREATE_OFFICEBASE = gql`
  mutation CreateOfficeBase($input: OfficeBaseInput) {
    createOfficeBase(input: $input) {
      success
      message
    }
  }
`;

export const GET_OFFICE_BASE_WITH_PAGINATION = gql`
  query GetOfficeBasesPagination($page: Int, $limit: Int, $keyword: String, $pagination: Boolean) {
  getOfficeBasesPagination(page: $page, limit: $limit, keyword: $keyword, pagination: $pagination) {
    data {
      _id
      officeBaseName
      remark
      createdAt
      updatedAt
    }
    paginator {
      slNo
      prev
      next
      perPage
      totalPosts
      totalPages
      currentPage
      hasPrevPage
      hasNextPage
      totalDocs
    }
  }
}
`;

export const UPDATE_OFFICEBASE = gql`
  mutation UpdateOfficeBase($id: ID, $input: OfficeBaseInput) {
  updateOfficeBase(_id: $id, input: $input) {
    success
    message
  }
}
`;

export const DELETE_OFFICEBASE = gql`
  mutation DeleteOfficeBase($id: ID) {
    deleteOfficeBase(_id: $id) {
      success
      message
    }
  }
`;
