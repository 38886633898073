import React, { useEffect, useState } from "react";
import "./staffinformation.scss";
import {
  Grid,
  Stack,
  Box,
  Typography,
  Avatar,
  Divider,
  Switch,
} from "@mui/material";
import moment from "moment";
//Srcs
import { getKhmerNumber, getFormattedPhoneNum, getKhmerDay, getKhmerMonth } from "../../Function/GetKhmerNumber"

export default function UpdateStaff({ editData }) {

  const [fieldValue, setFieldValue] = useState()

  // console.log("editData::", editData?.currentSituation);

  useEffect(() => {
    if (editData) {
      // ================================= Khmer data ===================================

      //================================= Address Khmer data ===================================
      setFieldValue(
        "placeOfBirthvillageKh",
        editData?.staffProfile?.khmerData?.placeOfBirthKh?.villageKh
      );
      setFieldValue(
        "placeOfBirthcommuneKh",
        editData?.staffProfile?.khmerData?.placeOfBirthKh?.communeKh
      );
      setFieldValue(
        "placeOfBirthdistrictKh",
        editData?.staffProfile?.khmerData?.placeOfBirthKh?.districtKh
      );
      setFieldValue(
        "placeOfBirthcityKh",
        editData?.staffProfile?.khmerData?.placeOfBirthKh?.cityKh
      );
      setFieldValue(
        "permanancevillageKh",
        editData?.staffProfile?.khmerData?.permananceAddressKh?.villageKh
      );
      setFieldValue(
        "permanancecommuneKh",
        editData?.staffProfile?.khmerData?.permananceAddressKh?.communeKh
      );
      setFieldValue(
        "permanancecityKh",
        editData?.staffProfile?.khmerData?.permananceAddressKh?.districtKh
      );
      setFieldValue(
        "permanancedistrictKh",
        editData?.staffProfile?.khmerData?.permananceAddressKh?.cityKh
      );
      setFieldValue(
        "temporaryvillageKh",
        editData?.staffProfile?.khmerData?.temporaryAddressKh?.villageKh
      );
      setFieldValue(
        "temporarycommuneKh",
        editData?.staffProfile?.khmerData?.temporaryAddressKh?.communeKh
      );
      setFieldValue(
        "temporarydistrictKh",
        editData?.staffProfile?.khmerData?.temporaryAddressKh?.districtKh
      );
      setFieldValue(
        "temporarycityKh",
        editData?.staffProfile?.khmerData?.temporaryAddressKh?.cityKh
      );
    }
  }, [editData]);

  // console.log(editData?.currentSituation, "editData")
  // console.log(values, "values")

  return (
    <div className="staff-info">
      <Box className="body-container">
        <Grid container rowSpacing={1.5} columnSpacing={10}>
          <Grid item xs={12}>
            <Grid container spacing={4} columnSpacing={10}>
              <Grid item xs={12} lg={6}>
                <Stack direction="row" justifyContent="center">
                  <Box className="title-add">
                    <Stack
                      className="profile"
                      direction="row"
                      justifycontent="center"
                    >
                      <Avatar
                        className="avater-image"
                        src={editData?.staffProfile?.imageSrc}
                      />
                    </Stack>
                    <Stack
                      direction="row"
                      justifyContent="center"
                      spacing={2}
                      sx={{ mt: 1 }}
                    >
                      <Typography className="text-En">Profile image</Typography>
                    </Stack>
                  </Box>
                </Stack>
              </Grid>

              <Grid item xs={12} lg={6}>
                <Grid container rowSpacing={1.5}>
                  <Grid item xs={12}>
                    <Stack className="header-info" direction="row" spacing={2}>
                      <Typography className="body-text">Role</Typography>
                      <Typography className="body-text">: {editData?.systemAccessData?.role}</Typography>
                    </Stack>
                  </Grid>

                  <Grid item xs={12}>
                    <Stack className="header-info" direction="row" spacing={2}>
                      <Typography className="body-text">Email</Typography>
                      <Typography className="body-text">: {editData?.systemAccessData?.emailAddress}</Typography>
                    </Stack>
                  </Grid>

                  <Grid item xs={12}>
                    <Stack className="header-info" direction="row" spacing={2}>
                      <Typography className="body-text">Start date</Typography>
                      <Typography className="body-text">: {moment(editData?.startedDate).format("DD-MMM-YYYY")}</Typography>
                    </Stack>
                  </Grid>

                  <Grid item xs={12}>
                    <Stack className="header-info" direction="row" spacing={2}>
                      <Typography className="body-text">Is active?</Typography>
                      <Typography className="body-text">:   <Switch
                          checked={editData?.systemAccessData?.isActive}
                          inputProps={{ "aria-label": "controlled" }}
                        /> 
                        {/* {editData?.systemAccessData?.isActive === true ? "Working" : "Stop"} */}
                      
                      </Typography>
                    </Stack>
                  </Grid>

                </Grid>
              </Grid>
            </Grid>
          </Grid>


          {/* ____________________New Layout Test____________________ */}

          {/* --------------------English Data----------------------- */}
          <Grid item xs={12} lg={6} rowSpacing={1.5}>
            <Grid item xs={12}>
              <Typography className="data-title">English Data</Typography>
              <Divider sx={{ marginBottom: "20px" }} />
            </Grid>

            <Grid container rowSpacing={1.5} columnSpacing={4}>
              <Grid item xs={12}>
                <Stack className="header-info" direction="row" spacing={2}>
                  <Typography className="body-text">First name</Typography>
                  <Typography className="body-text">: {editData?.staffProfile?.englishData?.firstNameLatin}</Typography>
                </Stack>
              </Grid>

              <Grid item xs={12}>
                <Stack className="header-info" direction="row" spacing={2}>
                  <Typography className="body-text">Last name</Typography>
                  <Typography className="body-text">: {editData?.staffProfile?.englishData?.lastNameLatin}</Typography>
                </Stack>
              </Grid>

              <Grid item xs={12}>
                <Stack className="header-info" direction="row" spacing={2}>
                  <Typography className="body-text">Date of birth</Typography>
                  <Typography className="body-text">: {moment(editData?.staffProfile?.englishData?.dateOfBirthEng).format("DD-MMM-YYYY")}</Typography>
                </Stack>
              </Grid>

              <Grid item xs={12}>
                <Stack className="header-info" direction="row" spacing={2}>
                  <Typography className="body-text">Current situation</Typography>
                  <Typography className="body-text">: {editData?.currentSituation}</Typography>
                </Stack>
              </Grid>

              <Grid item xs={12}>
                <Stack className="header-info" direction="row" spacing={2}>
                  <Typography className="body-text">Gender</Typography>
                  <Typography className="body-text">: {editData?.staffProfile?.englishData?.gender}</Typography>
                </Stack>
              </Grid>

              <Grid item xs={12}>
                <Stack className="header-info" direction="row" spacing={2}>
                  <Typography className="body-text">Marital status</Typography>
                  <Typography className="body-text">: {editData?.staffProfile?.englishData?.maritalStatusEng}</Typography>
                </Stack>
              </Grid>

              <Grid item xs={12}>
                <Stack className="header-info" direction="row" spacing={2}>
                  <Typography className="body-text">Nationality</Typography>
                  <Typography className="body-text">: {editData?.staffProfile?.englishData?.nationalityEng}</Typography>
                </Stack>
              </Grid>

              <Grid item xs={12}>
                <Stack className="header-info" direction="row" spacing={2}>
                  <Typography className="body-text">Identity card</Typography>
                  <Typography className="body-text">: {editData?.staffProfile?.englishData?.identityCardEng}</Typography>
                </Stack>
              </Grid>

              <Grid item xs={12}>
                <Stack className="header-info" direction="row" spacing={2}>
                  <Typography className="body-text">Passport number</Typography>
                  <Typography className="body-text">: {editData?.staffProfile?.englishData?.passportsNumerEng}</Typography>
                </Stack>
              </Grid>

              <Grid item xs={12}>
                <Stack className="header-info" direction="row" spacing={2}>
                  <Typography className="body-text">Phone number</Typography>
                  <Typography className="body-text">: {editData?.staffProfile?.englishData?.phoneNumberEng}</Typography>
                </Stack>
              </Grid>

              {/* ============================================ place of birth ========================================= */}
              <Grid item xs={12}>
                <Stack className="header-info" direction="row" spacing={2}>
                  <Typography className="body-text" sx={{ fontWeight: 'bold' }}>Place of birth</Typography>
                  {/* <Typography className="body-text">: {editData?.staffProfile?.englishData?.placeOfBirthEng?.villageEng}</Typography> */}
                </Stack>
              </Grid>

              <Grid item xs={12}>
                <Stack className="header-info" direction="row" spacing={2}>
                  <Typography className="body-text">Village</Typography>
                  <Typography className="body-text">: {editData?.staffProfile?.englishData?.placeOfBirthEng?.villageEng}</Typography>
                </Stack>
              </Grid>

              <Grid item xs={12}>
                <Stack className="header-info" direction="row" spacing={2}>
                  <Typography className="body-text">Commune</Typography>
                  <Typography className="body-text">: {editData?.staffProfile?.englishData?.placeOfBirthEng?.communeEng}</Typography>
                </Stack>
              </Grid>

              <Grid item xs={12}>
                <Stack className="header-info" direction="row" spacing={2}>
                  <Typography className="body-text">District</Typography>
                  <Typography className="body-text">: {editData?.staffProfile?.englishData?.placeOfBirthEng?.districtEng}</Typography>
                </Stack>
              </Grid>

              <Grid item xs={12}>
                <Stack className="header-info" direction="row" spacing={2}>
                  <Typography className="body-text">city</Typography>
                  <Typography className="body-text">: {editData?.staffProfile?.englishData?.placeOfBirthEng?.cityEng}</Typography>
                </Stack>
              </Grid>

              {/* ========================================== Permanance address ======================================== */}
              <Grid item xs={12}>
                <Stack className="header-info" direction="row" spacing={2}>
                  <Typography className="body-text" sx={{ fontWeight: 'bold' }}>Permanance address</Typography>
                  {/* <Typography className="body-text">: {editData?.staffProfile?.englishData?.permananceAddressEng?.villageEng}</Typography> */}
                </Stack>
              </Grid>

              <Grid item xs={12}>
                <Stack className="header-info" direction="row" spacing={2}>
                  <Typography className="body-text">Village</Typography>
                  <Typography className="body-text">: {editData?.staffProfile?.englishData?.permananceAddressEng?.villageEng}</Typography>
                </Stack>
              </Grid>

              <Grid item xs={12}>
                <Stack className="header-info" direction="row" spacing={2}>
                  <Typography className="body-text">Commune</Typography>
                  <Typography className="body-text">: {editData?.staffProfile?.englishData?.permananceAddressEng?.communeEng}</Typography>
                </Stack>
              </Grid>

              <Grid item xs={12}>
                <Stack className="header-info" direction="row" spacing={2}>
                  <Typography className="body-text">District</Typography>
                  <Typography className="body-text">: {editData?.staffProfile?.englishData?.permananceAddressEng?.districtEng}</Typography>
                </Stack>
              </Grid>

              <Grid item xs={12}>
                <Stack className="header-info" direction="row" spacing={2}>
                  <Typography className="body-text">City</Typography>
                  <Typography className="body-text">: {editData?.staffProfile?.englishData?.permananceAddressEng?.cityEng}</Typography>
                </Stack>
              </Grid>

              {/* ========================================== Temporary address ======================================== */}
              <Grid item xs={12}>
                <Stack className="header-info" direction="row" spacing={2}>
                  <Typography className="body-text" sx={{ fontWeight: 'bold' }}>Temporary address</Typography>
                  {/* <Typography className="body-text">: {editData?.staffProfile?.englishData?.temporaryAddressEng?.villageEng}</Typography> */}
                </Stack>
              </Grid>

              <Grid item xs={12}>
                <Stack className="header-info" direction="row" spacing={2}>
                  <Typography className="body-text">Village</Typography>
                  <Typography className="body-text">: {editData?.staffProfile?.englishData?.temporaryAddressEng?.villageEng}</Typography>
                </Stack>
              </Grid>

              <Grid item xs={12}>
                <Stack className="header-info" direction="row" spacing={2}>
                  <Typography className="body-text">Commune</Typography>
                  <Typography className="body-text">: {editData?.staffProfile?.englishData?.temporaryAddressEng?.communeEng}</Typography>
                </Stack>
              </Grid>

              <Grid item xs={12}>
                <Stack className="header-info" direction="row" spacing={2}>
                  <Typography className="body-text">District</Typography>
                  <Typography className="body-text">: {editData?.staffProfile?.englishData?.temporaryAddressEng?.districtEng}</Typography>
                </Stack>
              </Grid>

              <Grid item xs={12}>
                <Stack className="header-info" direction="row" spacing={2}>
                  <Typography className="body-text">City</Typography>
                  <Typography className="body-text">: {editData?.staffProfile?.englishData?.temporaryAddressEng?.cityEng}</Typography>
                </Stack>
              </Grid>
            </Grid>
          </Grid>


          {/* --------------------Khmer Data----------------------- */}
          <Grid item xs={12} lg={6}>
            <Grid item xs={12}>
              <Typography className="data-title">Khmer Data</Typography>
              <Divider sx={{ marginBottom: "20px" }} />
            </Grid>
            <Grid container rowSpacing={1.5} columnSpacing={4}>
              <Grid item xs={12}>
                <Stack className="header-info" direction="row" spacing={2}>
                  <Typography className="body-text">នាមខ្លួន</Typography>
                  <Typography className="body-text">: {editData?.staffProfile?.khmerData?.firstNameKh}</Typography>
                </Stack>
              </Grid>

              <Grid item xs={12}>
                <Stack className="header-info" direction="row" spacing={2}>
                  <Typography className="body-text">នាមត្រកូល</Typography>
                  <Typography className="body-text">: {editData?.staffProfile?.khmerData?.lastNameKh}</Typography>
                </Stack>
              </Grid>

              <Grid item xs={12}>
                <Stack className="header-info" direction="row" spacing={2}>
                  <Typography className="body-text">ថ្ងៃ ខែ ឆ្នាំកំណើត</Typography>
                  <Typography className="body-text">: {`${getKhmerNumber(moment(editData?.staffProfile?.englishData?.dateOfBirthEng).format("DD"))} - ${getKhmerMonth(moment(editData?.staffProfile?.englishData?.dateOfBirthEng).format("M"))} - ${getKhmerNumber(moment(editData?.staffProfile?.englishData?.dateOfBirthEng).format("YYYY"))}`} </Typography>
                </Stack>
              </Grid>

              <Grid item xs={12}>
                <Stack className="header-info" direction="row" spacing={2}>
                  <Typography className="body-text">ស្ថានភាពបច្ចុប្បន្ន</Typography>
                  <Typography className="body-text">: {editData?.currentSituation === "Working" ? "កំពុងបំពេញការងារ" : "បានលាឈប់"}</Typography>
                </Stack>
              </Grid>

              <Grid item xs={12}>
                <Stack className="header-info" direction="row" spacing={2}>
                  <Typography className="body-text">ភេទ</Typography>
                  <Typography className="body-text">: {editData?.staffProfile?.englishData?.gender === "Male" ? "ប្រុស" : "ស្រី"}</Typography>
                </Stack>
              </Grid>

              <Grid item xs={12}>
                <Stack className="header-info" direction="row" spacing={2}>
                  <Typography className="body-text">ស្ថានភាពអាពាហ៍ពិពាហ៍</Typography>
                  <Typography className="body-text">: {editData?.staffProfile?.englishData?.maritalStatusEng === "Married" ? "រៀបការ" : "នៅលីវ"}</Typography>
                </Stack>
              </Grid>

              <Grid item xs={12}>
                <Stack className="header-info" direction="row" spacing={2}>
                  <Typography className="body-text">សញ្ជាតិ</Typography>
                  <Typography className="body-text">: {editData?.staffProfile?.englishData?.nationalityEng}</Typography>
                </Stack>
              </Grid>

              <Grid item xs={12}>
                <Stack className="header-info" direction="row" spacing={2}>
                  <Typography className="body-text">លេខអត្តសញ្ញាណប័ណ្ណ</Typography>
                  <Typography className="body-text">: {getKhmerNumber(editData?.staffProfile?.englishData?.identityCardEng)}</Typography>
                </Stack>
              </Grid>

              <Grid item xs={12}>
                <Stack className="header-info" direction="row" spacing={2}>
                  <Typography className="body-text">លេខ​លិខិតឆ្លងដែន</Typography>
                  <Typography className="body-text">: {getKhmerNumber(editData?.staffProfile?.englishData?.passportsNumerEng)}</Typography>
                </Stack>
              </Grid>

              <Grid item xs={12}>
                <Stack className="header-info" direction="row" spacing={2}>
                  <Typography className="body-text">លេខទូរស័ព្ទ</Typography>
                  <Typography className="body-text">: {getKhmerNumber(getFormattedPhoneNum(editData?.staffProfile?.englishData?.phoneNumberEng))}</Typography>
                </Stack>
              </Grid>

              {/* ============================================ place of birth khmer ========================================= */}
              <Grid item xs={12}>
                <Stack className="header-info" direction="row" spacing={2}>
                  <Typography className="body-text" sx={{ fontWeight: 'bold' }}>ទីកន្លែងកំណើត</Typography>
                  {/* <Typography className="body-text">: {editData?.staffProfile?.khmerData?.placeOfBirthKh?.villageKh}</Typography> */}
                </Stack>
              </Grid>

              <Grid item xs={12}>
                <Stack className="header-info" direction="row" spacing={2}>
                  <Typography className="body-text">ភូមិ</Typography>
                  <Typography className="body-text">: {editData?.staffProfile?.khmerData?.placeOfBirthKh?.villageKh}</Typography>
                </Stack>
              </Grid>

              <Grid item xs={12}>
                <Stack className="header-info" direction="row" spacing={2}>
                  <Typography className="body-text">ឃុំ​/សង្ដាត់</Typography>
                  <Typography className="body-text">: {editData?.staffProfile?.khmerData?.placeOfBirthKh?.communeKh}</Typography>
                </Stack>
              </Grid>

              <Grid item xs={12}>
                <Stack className="header-info" direction="row" spacing={2}>
                  <Typography className="body-text">ស្រុក/ខណ្ឌ</Typography>
                  <Typography className="body-text">: {editData?.staffProfile?.khmerData?.placeOfBirthKh?.districtKh}</Typography>
                </Stack>
              </Grid>

              <Grid item xs={12}>
                <Stack className="header-info" direction="row" spacing={2}>
                  <Typography className="body-text">ខេត្ត/ក្រុង</Typography>
                  <Typography className="body-text">: {editData?.staffProfile?.khmerData?.placeOfBirthKh?.cityKh}</Typography>
                </Stack>
              </Grid>

              {/* ========================================== Permanance address khmer ======================================== */}
              <Grid item xs={12}>
                <Stack className="header-info" direction="row" spacing={2}>
                  <Typography className="body-text" sx={{ fontWeight: 'bold' }}>អាសយដ្ឋានអចិន្ត្រៃយ៍</Typography>
                  {/* <Typography className="body-text">: {editData?.staffProfile?.khmerData?.permananceAddressKh?.villageKh}</Typography> */}
                </Stack>
              </Grid>

              <Grid item xs={12}>
                <Stack className="header-info" direction="row" spacing={2}>
                  <Typography className="body-text">ភូមិ</Typography>
                  <Typography className="body-text">: {editData?.staffProfile?.khmerData?.permananceAddressKh?.villageKh}</Typography>
                </Stack>
              </Grid>

              <Grid item xs={12}>
                <Stack className="header-info" direction="row" spacing={2}>
                  <Typography className="body-text">ឃុំ​/សង្ដាត់</Typography>
                  <Typography className="body-text">: {editData?.staffProfile?.khmerData?.permananceAddressKh?.communeKh}</Typography>
                </Stack>
              </Grid>

              <Grid item xs={12}>
                <Stack className="header-info" direction="row" spacing={2}>
                  <Typography className="body-text">ស្រុក/ខណ្ឌ</Typography>
                  <Typography className="body-text">: {editData?.staffProfile?.khmerData?.permananceAddressKh?.districtKh}</Typography>
                </Stack>
              </Grid>

              <Grid item xs={12}>
                <Stack className="header-info" direction="row" spacing={2}>
                  <Typography className="body-text">ខេត្ត/ក្រុង</Typography>
                  <Typography className="body-text">: {editData?.staffProfile?.khmerData?.permananceAddressKh?.cityKh}</Typography>
                </Stack>
              </Grid>

              {/* ========================================== Temporary address khmer ======================================== */}
              <Grid item xs={12}>
                <Stack className="header-info" direction="row" spacing={2}>
                  <Typography className="body-text" sx={{ fontWeight: 'bold' }}>អាសយដ្ឋានបណ្តោះអាសន្ន</Typography>
                  <Typography className="body-text">: {editData?.staffProfile?.khmerData?.temporaryAddressKh?.villageKh}</Typography>
                </Stack>
              </Grid>

              <Grid item xs={12}>
                <Stack className="header-info" direction="row" spacing={2}>
                  <Typography className="body-text">ភូមិ</Typography>
                  <Typography className="body-text">: {editData?.staffProfile?.khmerData?.temporaryAddressKh?.villageKh}</Typography>
                </Stack>
              </Grid>

              <Grid item xs={12}>
                <Stack className="header-info" direction="row" spacing={2}>
                  <Typography className="body-text">ឃុំ​/សង្ដាត់</Typography>
                  <Typography className="body-text">: {editData?.staffProfile?.khmerData?.temporaryAddressKh?.communeKh}</Typography>
                </Stack>
              </Grid>

              <Grid item xs={12}>
                <Stack className="header-info" direction="row" spacing={2}>
                  <Typography className="body-text">ស្រុក/ខណ្ឌ​</Typography>
                  <Typography className="body-text">: {editData?.staffProfile?.khmerData?.temporaryAddressKh?.districtKh}</Typography>
                </Stack>
              </Grid>

              <Grid item xs={12}>
                <Stack className="header-info" direction="row" spacing={2}>
                  <Typography className="body-text">ខេត្ត/ក្រុង</Typography>
                  <Typography className="body-text">: {editData?.staffProfile?.khmerData?.temporaryAddressKh?.cityKh}</Typography>
                </Stack>
              </Grid>
            </Grid>

          </Grid>
        </Grid>
      </Box>
    </div>
  );
}
