import { gql } from "@apollo/client";

export const LOGIN_USER = gql`
  mutation LoginUser($emailAddress: String!, $password: String!) {
    loginUser(emailAddress: $emailAddress, password: $password) {
      success
      message
      token
      user {
        _id
        staffProfile {
          imageName
          imageSrc
          englishData {
            firstNameLatin
            lastNameLatin
          }
          khmerData {
            firstNameKh
            lastNameKh
          }
        }
        systemAccessData {
          emailAddress
          role
          isActive
        }
      }
    }
  }
`;

export const GENERATE_OPT = gql`
  mutation GenerateOTP($emailAddress: String!) {
    generateOTP(emailAddress: $emailAddress) {
      success
      message
    }
  }
`;

export const VERIFY_OTP_CODE = gql`
  mutation VerifyOTP($otp: String!) {
    verifyOTP(otp: $otp) {
      success
      message
    }
  }
`;

export const RESET_PASSWORD = gql`
  mutation ResetPassword($emailAddress: String!, $password: String!) {
    resetPassword(emailAddress: $emailAddress, password: $password) {
      success
      message
    }
  }
`;
