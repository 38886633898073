import React, { useState, useContext, useEffect, useRef } from "react";
import "../../../Style/pagestyle.scss";
import {
  Typography,
  Stack,
  Box,
  Grid,
  TableContainer,
  Table,
  TableRow,
  TableCell,
  TableBody,
  Pagination,
  TableHead,
  TextField,
  Select,
  MenuItem,
  Button,
} from "@mui/material";
import { useQuery } from "@apollo/client";
import { Link } from "react-router-dom";
import PrintOutlinedIcon from "@mui/icons-material/PrintOutlined";
import { useReactToPrint } from "react-to-print";
//date picker
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import moment from "moment";
//src
import { AuthContext } from "../../../Context/AuthContext";
import EmptyData from "../../../Components/Include/EmptyData";
import LoadingPage from "../../../Components/Include/LoadingPage";
import {
  SelectStaff,
  SelectStatus,
  SelectTakeLeaveType,
} from "../../../DynamicComp/DynamicSelect";
import { GET_TAKELEAVE_REPORT } from "../../../Schema/Report";
import PrintTakeLeave from "./PrintTakeLeave";
import SummaryTakeLeave from "./SummaryTakeLeave";

export default function TakeLeaveReport() {
  const [loading, setLoading] = useState(true);
  const { setAlert } = useContext(AuthContext);

  let userLogin = JSON.parse(window.localStorage.getItem("userLogin"));
  // console.log("userLogin::", userLogin);
  const [staffVal, setStaffVal] = useState({ id: "", title: "" });

  const [fromDate, setFromDate] = useState(moment().format("YYYY-MM-01"));
  const [toDate, setToDate] = useState(moment());
  const [printTakeLeaveData, setPrintTakeLeaveData] = useState();
  const [takeLeaveType, setTakeLeaveType] = useState("");
  const [status, setStatus] = useState("");
  //for print
  const componentRef = useRef();
  const handalePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const [open, setOpen] = React.useState(false);
  const handleOpen = (row) => {
    setOpen(true);
    setPrintTakeLeaveData(row);
  };
  const handleClose = () => setOpen(false);

  //===================== Resize width Screen=====================
  const [width, setWidth] = React.useState(window.innerWidth);
  const updateDimensions = () => {
    setWidth(window.innerWidth - 50);
  };
  React.useEffect(() => {
    window.addEventListener("resize", updateDimensions);
    return () => window.removeEventListener("resize", updateDimensions);
  }, []);

  //query
  const [tableData, setTableData] = useState([]);
  const [paginator, setPaginator] = useState([]);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(6);

  const { refetch } = useQuery(GET_TAKELEAVE_REPORT, {
    variables: {
      page: page,
      limit: limit,
      staffId:
        userLogin?.systemAccessData?.role === "Admin"
          ? staffVal?.id
          : userLogin?._id,
      fromDate: moment(fromDate).format("YYYY-MM-DD"),
      toDate: moment(toDate).format("YYYY-MM-DD"),
      status: status,
      takeLeaveType: takeLeaveType,
    },
    onCompleted: ({ getTakeLeaveReport }) => {
      // console.log(getTakeLeaveReport, "getTakeLeaveReport");
      setTableData(getTakeLeaveReport?.data);
      setPaginator(getTakeLeaveReport?.paginator);
      setLoading(false);
    },
    onError: (error) => {
      setAlert(true, "error", error?.message);
      console.log("error::", error);
    },
  });
  // console.log(tableData?.length, "tableData");
  const handleLimit = (e) => {
    setLimit(e.target.value);
  };

  useEffect(() => {
    refetch();
  }, [staffVal?.id, fromDate, toDate, takeLeaveType]);

  const allTakeLeaveType = [
    "Compensate Leave",
    "Annual Leave",
    "Sick Leave",
    "Maternity Leave",
    "Paternity Leave",
    "Breastfeeding",
    "Compassionate(wedding) Leave",
    "Compassionate(death) Leave",
    "Unpaid Leave",
  ];

  return (
    <div className="page-container">
      <PrintTakeLeave
        open={open}
        handleClose={handleClose}
        printTakeLeaveData={printTakeLeaveData}
      />

      <Stack direction="row" sx={{ height: "50px" }}>
        <Stack direction="row" spacing={1}>
          <Stack direction="column" justifyContent="center">
            <Link className="link" to="/report">
              Report
            </Link>
          </Stack>
          <Stack direction="column" justifyContent="center">
            <Typography className="page-title">/</Typography>
          </Stack>
          <Stack direction="column" justifyContent="center">
            <Typography className="page-title">TakeLeave report</Typography>
          </Stack>
        </Stack>
        <Box sx={{ flexGrow: 1 }} />
        <Stack direction="column" justifyContent="center" spacing={2}>
          {/* <Button
            onClick={handleOpen}
            className="btn-add"
            startIcon={<LibraryAddOutlinedIcon className="icon-add" />}
          >
            Create
          </Button> */}
        </Stack>
      </Stack>

      <Box sx={{ marginTop: "20px" }}>
        {userLogin?.systemAccessData?.role === "Admin" ? (
          <Grid item container spacing={2}>
            <Grid item xs={6} sm={6} md={3} lg={2.2}>
              <Stack direction="row" spacing={1}>
                <Stack direction="column" justifyContent="center">
                  <Typography>From</Typography>
                </Stack>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                    value={fromDate}
                    onChange={(e) => setFromDate(e)}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        className="text-field"
                        size="small"
                        fullWidth
                      />
                    )}
                  />
                </LocalizationProvider>
              </Stack>
            </Grid>
            <Grid item xs={6} sm={6} md={3} lg={2}>
              <Stack direction="row" spacing={1}>
                <Stack direction="column" justifyContent="center">
                  <Typography>to</Typography>
                </Stack>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                    value={toDate}
                    onChange={(e) => setToDate(e)}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        className="text-field"
                        size="small"
                        fullWidth
                      />
                    )}
                  />
                </LocalizationProvider>
              </Stack>
            </Grid>

            <Grid item xs={6} sm={6} md={3} lg={2}>
              <SelectStaff
                selectedVal={staffVal}
                setSelectedVal={setStaffVal}
                className={"text-field"}
              />
            </Grid>

            <Grid item xs={6} sm={6} md={3} lg={1.6}>
              <SelectStatus
                selectedVal={status}
                setSelectedVal={setStatus}
                className={"text-field"}
              />
            </Grid>

            <Grid item xs={6} sm={6} md={3} lg={2.4}>
              <SelectTakeLeaveType
                selectedVal={takeLeaveType}
                setSelectedVal={setTakeLeaveType}
                className={"text-field"}
              />
            </Grid>
            <Grid item xs={6} sm={6} md={3} lg={1.8}>
              <Stack
                direction="row"
                justifyContent="right"
                sx={{
                  display:
                    staffVal?.id && tableData?.length > 0 ? "flex" : "none",
                }}
              >
                <Button
                  className="btn-print"
                  startIcon={<PrintOutlinedIcon />}
                  onClick={handalePrint}
                >
                  Print List
                </Button>
                <Box sx={{ display: "none" }}>
                  <SummaryTakeLeave
                    componentRef={componentRef}
                    fromDate={fromDate}
                    toDate={toDate}
                    tableData={tableData}
                  />
                </Box>
              </Stack>
            </Grid>
          </Grid>
        ) : (
          <Grid item container spacing={2}>
            <Grid item xs={6} sm={6} md={3} lg={2.4}>
              <Stack direction="row" spacing={1}>
                <Stack direction="column" justifyContent="center">
                  <Typography>From</Typography>
                </Stack>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                    value={fromDate}
                    onChange={(e) => setFromDate(e)}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        className="text-field"
                        size="small"
                        fullWidth
                      />
                    )}
                  />
                </LocalizationProvider>
              </Stack>
            </Grid>
            <Grid item xs={6} sm={6} md={3} lg={2.4}>
              <Stack direction="row" spacing={1}>
                <Stack direction="column" justifyContent="center">
                  <Typography>to</Typography>
                </Stack>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                    value={toDate}
                    onChange={(e) => setToDate(e)}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        className="text-field"
                        size="small"
                        fullWidth
                      />
                    )}
                  />
                </LocalizationProvider>
              </Stack>
            </Grid>

            <Grid item xs={6} sm={6} md={3} lg={2.4}>
              <SelectStatus
                selectedVal={status}
                setSelectedVal={setStatus}
                className={"text-field"}
              />
            </Grid>

            <Grid item xs={6} sm={6} md={3} lg={2.4}>
              <SelectTakeLeaveType
                selectedVal={takeLeaveType}
                setSelectedVal={setTakeLeaveType}
                className={"text-field"}
              />
            </Grid>

            <Grid item xs={12} sm={12} md={12} lg={2.4}>
              <Stack direction="row" justifyContent="right">
                <Button
                  className="btn-print"
                  startIcon={<PrintOutlinedIcon />}
                  onClick={handalePrint}
                >
                  Print List
                </Button>
                <Box sx={{ display: "none" }}>
                  <SummaryTakeLeave
                    componentRef={componentRef}
                    fromDate={fromDate}
                    toDate={toDate}
                    tableData={tableData}
                  />
                </Box>
              </Stack>
            </Grid>
          </Grid>
        )}
      </Box>

      {/* <Box>
        <SummaryTakeLeave
          componentRef={componentRef} 
          fromDate={fromDate}
          toDate={toDate}
          tableData={tableData}
        />
      </Box> */}

      <Box className="body-container">
        <TableContainer sx={{ maxWidth: `${width}px` }}>
          <Table className="table" aria-label="simple table">
            <TableHead className="header-row ">
              <TableCell className="header-title-start">N°</TableCell>
              <TableCell className="header-title" sx={{ minWidth: "180px" }}>Create by</TableCell>
              <TableCell className="header-title" sx={{ minWidth: "150px" }}>Create at</TableCell>
              <TableCell className="header-title" sx={{ minWidth: "250px" }}>Take leave type</TableCell>
              <TableCell className="header-title" sx={{ minWidth: "130px" }}>Total leave</TableCell>
              <TableCell className="header-title" sx={{ minWidth: "180px" }}>From Date</TableCell>
              <TableCell className="header-title" sx={{ minWidth: "180px" }}>To date</TableCell>
              <TableCell className="header-title" sx={{ minWidth: "180px" }}>Return date</TableCell>
              <TableCell className="header-title" sx={{ minWidth: "130px" }}>Status</TableCell>
              <TableCell
                className="header-title-end header-sticky-right"
                align="right"
              ></TableCell>
            </TableHead>
            {loading ? (
              <TableBody className="body">
                <TableRow className="body-row-gray">
                  <TableCell className="body-cell" colSpan={7}>
                    <LoadingPage />
                  </TableCell>
                </TableRow>
              </TableBody>
            ) : (
              <TableBody className="body">
                {tableData?.length === 0 ? (
                  <TableRow className="body-row-gray">
                    <TableCell className="body-cell" colSpan={7}>
                      <EmptyData />
                    </TableCell>
                  </TableRow>
                ) : (
                  <>
                    {tableData?.map((row, index) => {
                      return (
                        <TableRow
                          className={
                            (index + 1) % 2 === 0 ? "body-row" : "body-row-gray"
                          }
                          key={index}
                        >
                          <TableCell className="body-cell-start">
                            {index + paginator?.slNo}
                          </TableCell>
                          <TableCell className="body-cell">
                            {row?.createdBy?.staffProfile?.englishData
                              ?.firstNameLatin +
                              " " +
                              row?.createdBy?.staffProfile?.englishData
                                ?.lastNameLatin}
                          </TableCell>
                          <TableCell className="body-cell">
                            {moment(row?.createdAt).format("DD-MMM-YYYY")}
                          </TableCell>
                          <TableCell className="body-cell">
                            <Stack direction="column">
                              {row?.takeLeaveTypeUsed?.map((d, index) => {
                                return (
                                  <Typography key={index}>
                                    - {d?.takeLeaveType}
                                  </Typography>
                                );
                              })}
                            </Stack>
                          </TableCell>
                          <TableCell className="body-cell">
                            {row?.totalLeaveAsDay}
                          </TableCell>
                          <TableCell className="body-cell">
                            {moment(row?.startDate).format("DD-MMM-YYYY HH:mm")}
                          </TableCell>
                          <TableCell className="body-cell">
                            {moment(row?.endDate).format("DD-MMM-YYYY HH:mm")}
                          </TableCell>
                          <TableCell className="body-cell">
                            {moment(row?.returnDate).format("DD-MMM-YYYY HH:mm")}
                          </TableCell>
                          <TableCell className="body-cell">
                            {row?.status}
                          </TableCell>
                          <TableCell
                            className="body-cell-end cell-sticky-right"
                            align="right"
                          >
                            <Button
                              className="body-cell-print"
                              onClick={() => handleOpen(row)}
                            >
                              Print
                            </Button>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </>
                )}
              </TableBody>
            )}
          </Table>
        </TableContainer>

        <Stack
          direction="row"
          justifyContent="right"
          spacing={2}
          sx={{ margin: "20px 30px 0px 0px" }}
        >
          <Stack direction="column" justifyContent="center">
            <Pagination
              hideNextButton={false}
              hidePrevButton={false}
              count={paginator?.totalPages}
              color="primary"
              variant="outlined"
              onChange={(event, pageNumber) => setPage(parseInt(pageNumber))}
            />
          </Stack>
          <Select
            size="small"
            value={limit}
            onChange={handleLimit}
            defaultValue="8/Page"
          >
            <MenuItem value={6}>6/Page</MenuItem>
            <MenuItem value={8}>8/Page</MenuItem>
            <MenuItem value={10}>10/Page</MenuItem>
            <MenuItem value={paginator?.totalDocs}>All/Page</MenuItem>
          </Select>
        </Stack>
      </Box>
    </div>
  );
}
