import React, { useState, useContext } from "react";
import "../Style/pagestyle.scss";
import {
  Typography,
  Stack,
  Avatar,
  Box,
  Button,
  Grid,
  TableContainer,
  Table,
  TableRow,
  TableCell,
  TableBody,
  Pagination,
  TableHead,
  TextField,
  InputAdornment,
  Select,
  MenuItem,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import LibraryAddOutlinedIcon from "@mui/icons-material/LibraryAddOutlined";
import { useQuery } from "@apollo/client";
import { Link } from "react-router-dom";
import moment from "moment"
//src
import { AuthContext } from "../Context/AuthContext";
import EmptyData from "../Components/Include/EmptyData";
import LoadingPage from "../Components/Include/LoadingPage";
import CreateAdjustmentTakeleave from "../Components/AdjustmentTakeleave/CreateAdjustmentTakeleave";
import AdjustmentTakeleaveAction from "../Components/AdjustmentTakeleave/AdjustmentTakeleaveAction";
import { GET_ADJUSTMENT_TAKELEAVE_PAGINATION } from "../Schema/TakeLeave";

export default function AdjustmentTakeleave() {
  const [loading, setLoading] = useState(true);
  const { setAlert } = useContext(AuthContext);

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  //query
  const [tableData, setTableData] = useState([]);
  const [paginator, setPaginator] = useState([]);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(6);
  const [keyword, setKeyword] = useState("");

  const { data, refetch } = useQuery(GET_ADJUSTMENT_TAKELEAVE_PAGINATION, {
    variables: {
      page: page,
      limit: limit,
      keyword: keyword,
      pagination: true,
      adjust: true,
    },
    onCompleted: ({ getTakeLeaveWithPagination }) => {
      setTableData(getTakeLeaveWithPagination?.data);
      setPaginator(getTakeLeaveWithPagination?.paginator);
      setLoading(false);
    },
    onError: (error) => {
      setAlert(true, "error", error?.message);
      // console.log(error)
    },
  });

  const handleLimit = (e) => {
    setLimit(e.target.value);
  };

  console.log("tableData::", tableData);

  //===================== Resize width Screen=====================
  const [width, setWidth] = React.useState(window.innerWidth);
  const updateDimensions = () => {
    setWidth(window.innerWidth - 50);
  };
  React.useEffect(() => {
    window.addEventListener("resize", updateDimensions);
    return () => window.removeEventListener("resize", updateDimensions);
  }, []);

  return (
    <div className="page-container">
      <Stack direction="row" sx={{ height: "50px" }}>
        <Stack direction="row" spacing={1}>
          <Stack direction="column" justifyContent="center">
            <Link className="link" to="/settings/general">
              General Setting
            </Link>
          </Stack>
          <Stack direction="column" justifyContent="center">
            <Typography className="page-title">/</Typography>
          </Stack>
          <Stack direction="column" justifyContent="center">
            <Typography className="page-title">Take Leave Adjustment</Typography>
          </Stack>
        </Stack>
        <Box sx={{ flexGrow: 1 }} />
        <Stack direction="column" justifyContent="center" spacing={2}>
          <Button
            onClick={handleOpen}
            className="btn-add"
            startIcon={<LibraryAddOutlinedIcon className="icon-add" />}
          >
            Create
          </Button>
        </Stack>

        <CreateAdjustmentTakeleave
          open={open}
          handleClose={handleClose}
          setRefetch={refetch}
          dialogTitle={"Create"}
        />
      </Stack>

      <Grid item container spacing={3} sx={{ marginTop: "10px" }}>
        <Grid item xs={6} md={4} lg={3}>
          <TextField
            placeholder="search"
            size="small"
            className="text-field"
            onChange={(e) => setKeyword(e.target.value)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start" sx={{ mr: 1 }}>
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />
        </Grid>
        <Grid item xs={6} md={4} lg={3}></Grid>
        <Grid item xs={3}></Grid>
        <Grid item xs={3}></Grid>
      </Grid>

      <Box className="body-container">
        <TableContainer sx={{ maxWidth: `${width}px` }}>
          <Table className="table" aria-label="simple table">
            <TableHead className="header-row ">
              <TableCell className="header-title-start">N°</TableCell>
              <TableCell className="header-title header-sticky-left">
                Staff name
              </TableCell>
              <TableCell className="header-title" sx={{ minWidth: "140px" }}>Adjust at</TableCell>
              <TableCell className="header-title">Adjust on</TableCell>
              <TableCell className="header-title">Last year remain</TableCell>
              <TableCell className="header-title">Current year</TableCell>
              <TableCell className="header-title">Total used</TableCell>
              <TableCell className="header-title" sx={{ minWidth: "140px" }}>Valid until</TableCell>
              <TableCell
                className="header-title-end header-sticky-right"
                align="right"
              >
                Action
              </TableCell>
            </TableHead>
            {loading ? (
              <TableBody className="body">
                <TableRow className="body-row-gray">
                  <TableCell className="body-cell" colSpan={8}>
                    <LoadingPage />
                  </TableCell>
                </TableRow>
              </TableBody>
            ) : (
              <TableBody className="body">
                {tableData?.length === 0 ? (
                  <TableRow className="body-row-gray">
                    <TableCell className="body-cell" colSpan={8}>
                      <EmptyData />
                    </TableCell>
                  </TableRow>
                ) : (
                  <>
                    {tableData?.map((row, index) => {
                      // console.log("row", row?.paymentOfTakeLeaveQuantity[0].takeLeaveType);
                      return (
                        <TableRow
                          className={
                            (index + 1) % 2 === 0 ? "body-row" : "body-row-gray"
                          }
                          key={index}
                        >
                          <TableCell className="body-cell-start">
                            {index + paginator?.slNo}
                          </TableCell>
                          <TableCell className="body-cell cell-sticky-left">
                            <Stack direction="row" spacing={1}>
                              <Avatar
                                alt="Staff"
                                src={row?.createdBy?.staffProfile?.imageSrc}
                              />
                              <Stack direction="column" justifyContent="center">
                                <Typography>
                                  {row?.createdBy?.staffProfile?.englishData
                                    ?.firstNameLatin +
                                    " " +
                                    row?.createdBy?.staffProfile?.englishData
                                      ?.lastNameLatin}
                                </Typography>
                              </Stack>
                            </Stack>
                          </TableCell>
                          <TableCell className="body-cell">
                            {moment(row?.createdAt).format("DD-MMM-YYYY")}
                          </TableCell>
                          <TableCell className="body-cell">
                            {row?.paymentOfTakeLeaveQuantity[0]?.takeLeaveType}
                          </TableCell>
                          <TableCell className="body-cell">
                            {row?.paymentOfTakeLeaveQuantity[0]?.lastYearRemainQty}
                          </TableCell>
                          <TableCell className="body-cell">
                            {row?.paymentOfTakeLeaveQuantity[0]?.currentYearQty}
                          </TableCell>
                          <TableCell className="body-cell">
                            {row?.paymentOfTakeLeaveQuantity[0]?.UsedQty}
                          </TableCell>
                          <TableCell className="body-cell">
                            {moment(row?.paymentOfTakeLeaveQuantity[0]?.validUntil).format("DD-MMM-YYYY")}
                          </TableCell>
                          <TableCell
                            className="body-cell-end cell-sticky-right"
                            align="right"
                          >
                            <AdjustmentTakeleaveAction
                              editData={row}
                              setRefetch={refetch}
                            />
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </>
                )}
              </TableBody>
            )}
          </Table>
        </TableContainer>

        <Stack
          direction="row"
          justifyContent="right"
          spacing={2}
          sx={{ margin: "20px 30px 0px 0px" }}
        >
          <Stack direction="column" justifyContent="center">
            <Pagination
              hideNextButton={false}
              hidePrevButton={false}
              count={paginator?.totalPages}
              color="primary"
              variant="outlined"
              onChange={(event, pageNumber) => setPage(parseInt(pageNumber))}
            />
          </Stack>
          <Select
            size="small"
            value={limit}
            onChange={handleLimit}
            defaultValue="8/Page"
          >
            <MenuItem value={6}>6/Page</MenuItem>
            <MenuItem value={8}>8/Page</MenuItem>
            <MenuItem value={10}>10/Page</MenuItem>
            <MenuItem value={paginator?.totalDocs}>All/Page</MenuItem>
          </Select>
        </Stack>
      </Box>
    </div>
  );
}
