import React from "react";
import { Box, Grid, Paper, Stack, Typography } from "@mui/material";
// import { styled } from "@mui/material/styles";
import "../Style/pagestyle.scss";
//dashboard
import AdminDashboard from "../Components/Dashboard/AdminDashboard";
import UserDashboard from "../Components/Dashboard/UserDashboard";

export default function Dashboard() {
  // let widthScreen = window.innerWidth;y
  // let widthCondit = false;
  // if(widthScreen<1536 && widthScreen>1199){
  //   widthCondit = true
  // }

  let loginUser = JSON.parse(localStorage.getItem("userLogin"))

  // console.log("loginUser::", loginUser)

  return (
    <div className="page-container">
      <Stack direction="row" sx={{ height: "50px" }}>
        <Stack direction="column" justifyContent="center">
          <Typography className="page-title">Dashboard</Typography>
        </Stack>
      </Stack>
      {loginUser?.systemAccessData?.role === "Admin" ? (
        <AdminDashboard />
      ) : (
        <UserDashboard />
      )}
    </div>
  );
}
