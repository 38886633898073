import React, { useState, useContext } from "react";
import "../../../Style/pagestyle.scss";
import {
  Box,
  Button,
  TableContainer,
  Table,
  TableRow,
  TableCell,
  TableBody,
  TableHead,
  Stack,
  Typography,
} from "@mui/material";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { useQuery } from "@apollo/client";
import moment from "moment";
//src
import { AuthContext } from "../../../Context/AuthContext";
import EmptyData from "../../Include/EmptyData";
import LoadingPage from "../../Include/LoadingPage";
import CreateWorkHistory from "./CreateWorkHistory";
import WorkHistoryAction from "./WorkHistoryAction";
import { GET_WORKING_HISTORY_BY_STAFFID } from "../../../Schema/WorkHistory";

export default function WorkHistory({ userLogin, staffId }) {
  const [loading, setLoading] = useState(true);
  const { setAlert } = useContext(AuthContext);
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [tableData, setTableData] = useState([]);

  const { refetch } = useQuery(GET_WORKING_HISTORY_BY_STAFFID, {
    variables: {
      page: 1,
      limit: 100,
      keyword: "",
      pagination: false,
      staffInfoId: staffId,
    },
    onCompleted: ({ getWorkingHistoryPagination }) => {
      // console.log("getWorkingHistoryPagination::", getWorkingHistoryPagination);
      setTableData(getWorkingHistoryPagination?.data);
      setLoading(false);
    },
    onError: (error) => {
      setAlert(true, "error", error?.message);
      // console.log(error)
    },
  });

  //===================== Resize width Screen=====================
  const [width, setWidth] = React.useState(window.innerWidth);
  const updateDimensions = () => {
    setWidth(window.innerWidth - 50);
  };
  React.useEffect(() => {
    window.addEventListener("resize", updateDimensions);
    return () => window.removeEventListener("resize", updateDimensions);
  }, []);

  return (
    <div className="page-container">
      <Box className="body-container">
        <TableContainer sx={{ maxWidth: `${width}px` }}>
          <Table className="table" aria-label="simple table">
            <TableHead className="header-row ">
              <TableCell className="header-title-start"> N°</TableCell>
              <TableCell className="header-title">Positions</TableCell>
              <TableCell className="header-title">Start date</TableCell>
              <TableCell className="header-title">End date</TableCell>
              <TableCell className="header-title">Responsibilities</TableCell>
              <TableCell className="header-title">Remark</TableCell>
              <TableCell
                className="header-title-end header-sticky-right"
                align="right"
              >
                {userLogin?.systemAccessData?.role === "Admin" ? (
                  <Button
                    startIcon={<AddCircleIcon />}
                    className="btn-add-list"
                    onClick={handleOpen}
                  >
                    Add
                  </Button>
                ) : null}
                <CreateWorkHistory
                  open={open}
                  handleClose={handleClose}
                  staffId={staffId}
                  setRefetch={refetch}
                />
              </TableCell>
            </TableHead>
            {loading ? (
              <TableBody className="body">
                <TableRow className="body-row-gray">
                  <TableCell className="body-cell" colSpan={7}>
                    <LoadingPage />
                  </TableCell>
                </TableRow>
              </TableBody>
            ) : (
              <TableBody className="body">
                {tableData?.length === 0 ? (
                  <TableRow className="body-row-gray">
                    <TableCell className="body-cell" colSpan={7}>
                      <EmptyData />
                    </TableCell>
                  </TableRow>
                ) : (
                  <>
                    {tableData?.map((row, index) => {
                      return (
                        <TableRow
                          className={
                            (index + 1) % 2 === 0 ? "body-row" : "body-row-gray"
                          }
                          key={index}
                        >
                          <TableCell className="body-cell-start">
                            {index + 1}
                          </TableCell>
                          <TableCell className="body-cell">
                            {row?.positions}
                          </TableCell>
                          <TableCell className="body-cell">
                            {moment(row?.startDate).format("DD-MM-YYYY")}
                          </TableCell>
                          <TableCell className="body-cell">
                            {moment(row?.endDate).format("DD-MM-YYYY")}
                          </TableCell>
                          <TableCell className="body-cell">
                            <Stack direction="column">
                              {row?.responsibilities.map((d, index1) => {
                                return (
                                  <Typography key={d} className="res-name">
                                    {index1 + 1}. {d}
                                  </Typography>
                                );
                              })}
                            </Stack>
                          </TableCell>
                          <TableCell className="body-cell">
                            {row?.remark}
                          </TableCell>
                          <TableCell
                            className="body-cell-end cell-sticky-right"
                            align="right"
                          >
                            <WorkHistoryAction
                              editData={row}
                              setRefetch={refetch}
                              userRole={userLogin?.systemAccessData?.role}
                            />
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </>
                )}
              </TableBody>
            )}
          </Table>
        </TableContainer>
      </Box>
    </div>
  );
}
