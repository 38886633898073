import React, { useState, useContext } from "react";
import "../../Style/requestStyle.scss";
import {
  Box,
  List,
  IconButton,
  Stack,
  ListItemButton,
  Typography,
  Avatar,
  Grid,
  Button,
  Drawer,
} from "@mui/material";
import { useQuery } from "@apollo/client";
import AddIcon from "@mui/icons-material/Add";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import moment from "moment";
//components
import { AuthContext } from "../../Context/AuthContext";
import LoadingPage from "../Include/LoadingPage";
import EmptyData from "../Include/EmptyData";
import { GET_TAKELEAVE_WITH_PAGINATION } from "../../Schema/TakeLeave";
import CreateTakeleave from "./CreateTakeleave";
import TakeleaveCardMobile from "./TakeleaveCardMobile";

export default function TakeleaveMobile() {
  //Tabs panel
  const { setAlert } = useContext(AuthContext);
  const [tabsName, setTabsName] = useState("Inprogress");
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  //View overtime mobile
  const [openView, setOpenView] = useState(false);
  const handleOpenView = () => setOpenView(true);
  const handleCloseView = () => setOpenView(false);

  let userLogin = JSON.parse(window.localStorage.getItem("userLogin"));

  //function get data
  //query
  const [loading, setLoading] = useState(true);
  const [tableData, setTableData] = useState([]);
  const [paginator, setPaginator] = useState([]);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(5);
  const [keyword, setKeyword] = useState("");

  const { data, refetch } = useQuery(GET_TAKELEAVE_WITH_PAGINATION, {
    variables: {
      page: page,
      limit: limit,
      keyword: keyword,
      pagination: true,
      userId: userLogin?._id,
      status: tabsName === "Inprogress" ? ["Pending"] : ["Approved", "Denied"],
    },
    onCompleted: ({ getTakeLeaveWithPagination }) => {
      setTableData(getTakeLeaveWithPagination?.data);
      setPaginator(getTakeLeaveWithPagination?.paginator);
      setLoading(false);
    },
    onError: (error) => {
      setAlert(true, "error", error?.message);
      // console.log(error)
    },
  });

  const handleSeeMore = () => {
    setLimit(limit + 5);
  };

  // function select
  const [takeLeaveDetails, setTakeLeaveDetails] = useState();
  const [selectedIndex, setSelectedIndex] = useState();
  const handleListItemClick = (event, index, element) => {
    setTakeLeaveDetails(element);
    setSelectedIndex(index);
    handleOpenView();
  };

  return (
    <div className="page-request-mobile">
      <Grid container sx={{ position: "sticky", top: 0 }}>
        <Grid item xs={6}>
          <Button
            className={
              tabsName === "Inprogress" ? "tabs-btn-active" : "tabs-btn"
            }
            onClick={() => setTabsName("Inprogress")}
          >
            In Progress
          </Button>
        </Grid>
        <Grid item xs={6}>
          <Button
            className={
              tabsName === "Completed" ? "tabs-btn-active" : "tabs-btn"
            }
            onClick={() => setTabsName("Completed")}
          >
            Completed
          </Button>
        </Grid>
      </Grid>
      {loading ? (
        <Box sx={{ width: "100%" }}>
          <LoadingPage />
        </Box>
      ) : (
        <Box sx={{ width: "100%" }}>
          {tableData?.length === 0 ? (
            <EmptyData />
          ) : (
            <List component="nav" aria-label="main mailbox folders">
              {tableData?.map((element, index) => {
                return (
                  <ListItemButton
                    sx={{ height: "60px" }}
                    key={index}
                    selected={selectedIndex === index}
                    onClick={(event) =>
                      handleListItemClick(event, index, element)
                    }
                  >
                    {element?.status === "Pending" ? (
                      <Stack direction="row" spacing={1}>
                        <Avatar
                          alt="User"
                          sx={{ width: 40, height: 40 }}
                          src={element?.createdBy?.staffProfile?.imageSrc}
                        />
                        <Stack
                          direction="column"
                          justifyContent="space-between"
                          className="stack-user-container"
                        >
                          <Typography className="user-request">
                            {element?.createdBy?.staffProfile?.englishData
                              ?.firstNameLatin +
                              " " +
                              element?.createdBy?.staffProfile?.englishData
                                ?.lastNameLatin +
                              " requested overtime"}
                          </Typography>
                          <Typography className="date-request">
                            {moment(element?.createdAt).utc().format("MMM DD, YYYY")}
                          </Typography>
                        </Stack>
                      </Stack>
                    ) : (
                      <Stack direction="row" spacing={1}>
                        <Avatar
                          alt="User"
                          sx={{ width: 40, height: 40 }}
                          src={
                            element?.approvedOrDeniedBy?.staffProfile?.imageSrc
                          }
                        />
                        <Stack
                          direction="column"
                          justifyContent="space-between"
                          className="stack-user-container"
                        >
                          {element?.status === "Denied" ? (
                            <Typography className="user-request">
                              {element?.approvedOrDeniedBy?.staffProfile
                                ?.englishData?.firstNameLatin +
                                " " +
                                element?.approvedOrDeniedBy?.staffProfile
                                  ?.englishData?.lastNameLatin +
                                " denied overtime"}
                            </Typography>
                          ) : (
                            <Typography className="user-request">
                              {element?.approvedOrDeniedBy?.staffProfile
                                ?.englishData?.firstNameLatin +
                                " " +
                                element?.approvedOrDeniedBy?.staffProfile
                                  ?.englishData?.lastNameLatin +
                                " approved overtime"}
                            </Typography>
                          )}
                          <Typography className="date-request">
                            {moment(element?.createdAt).utc().format("MMM DD, YYYY")}
                          </Typography>
                        </Stack>
                      </Stack>
                    )}
                  </ListItemButton>
                );
              })}
              <ListItemButton sx={{ height: "50px" }} onClick={handleSeeMore}>
                <Stack direction="row" spacing={1}>
                  <IconButton sx={{ width: 35, height: 35 }}>
                    <ExpandMoreIcon />
                  </IconButton>

                  <Stack direction="column" justifyContent="center">
                    <Typography className="seemore-text">See More</Typography>
                  </Stack>
                </Stack>
              </ListItemButton>
              <Drawer
                sx={{
                  flexShrink: 0,
                  "& .MuiDrawer-paper": {
                    width: "100%",
                  },
                }}
                anchor="right"
                open={openView}
              >
                <TakeleaveCardMobile
                  open={openView}
                  handleClose={handleCloseView}
                  setRefetch={refetch}
                  takeLeaveDetails={takeLeaveDetails}
                  userLogin={userLogin}
                />
              </Drawer>
            </List>
          )}
        </Box>
      )}

      <Box
        sx={{
          width: "100%",
          top: "auto",
          bottom: 20,
          right: 20,
          position: "fixed",
          zIndex: 200,
          display: "flex",
          justifyContent: "right",
        }}
      >
        <IconButton
          color="inherit"
          aria-label="open drawer"
          onClick={handleOpen}
          sx={{
            bgcolor: "#082137 !important",
            height: "56px",
            width: "56px",
            borderRadius: "50%",
            textDecoration: "none",
          }}
        >
          <AddIcon sx={{ color: "#fff", fontSize: "30px" }} />
        </IconButton>
        <Drawer
          sx={{
            flexShrink: 0,
            "& .MuiDrawer-paper": {
              width: "100%",
            },
          }}
          anchor="right"
          open={open}
        >
          <CreateTakeleave
            open={open}
            handleClose={handleClose}
            setRefetch={refetch}
            dialogTitle={"Create"}
            userLogin={userLogin}
          />
        </Drawer>
      </Box>
    </div>
  );
}
