import { gql } from "@apollo/client";

export const CREATE_COVIDVACCINATION = gql`
  mutation CreateStaffCovidVaccination(
    $input: CovidVaccinationInput
    $staffInfoId: ID
  ) {
    createStaffCovidVaccination(input: $input, staffInfoId: $staffInfoId) {
      success
      message
    }
  }
`;

export const UPDATE_COVIDVACCINATION = gql`
  mutation UpdateStaffCovidVaccination($input: CovidVaccinationInput, $id: ID) {
    updateStaffCovidVaccination(input: $input, _id: $id) {
      success
      message
    }
  }
`;

export const GET_COVID_COVIDVACCINATION_BY_STAFF = gql`
  query GetCovidVacinByStaffId($staffId: ID!) {
    getCovidVacinByStaffId(staffId: $staffId) {
      _id
      imageName
      imageSrc
      dosesNumber
      vaccinName
      vaccinationLocation
      vaccinationDate
    }
  }
`;

export const DELETE_COVIDVACCINATION = gql`
  mutation DeleteStaffCovidVaccination($id: ID) {
    deleteStaffCovidVaccination(_id: $id) {
      success
      message
    }
  }
`;
