import React from "react";
import "./listdata.scss";
import { Typography, Stack, Box, Button, Grid } from "@mui/material";
import { useNavigate } from "react-router-dom";
//Icons
import { FcDepartment } from "react-icons/fc";
import { GiOfficeChair } from "react-icons/gi";
import { MdMedicalServices } from "react-icons/md";
import { FaBookReader } from "react-icons/fa";

export default function Report() {
  const navigate = useNavigate();

  return (
    <div className="setting-page">
      <Stack direction="row" sx={{ height: "50px" }}>
        <Stack direction="column" justifyContent="center">
          <Typography className="page-title">Reports</Typography>
        </Stack>
      </Stack>

      <Box className="body-container">
        <Grid container spacing={4}>
          <Grid item xs={12} sm={6} lg={4}>
            <Button
              className="btn-setting"
              onClick={() => navigate("/report/takeleave-report")}
            >
              <Stack direction="row" spacing={2}>
                <Stack direction="column" justifyContent="center">
                  <MdMedicalServices className="btn-icon" />
                </Stack>

                <Stack direction="column" spacing={1}>
                  <Typography className="btn-setting-title">
                    Take Leave Report
                  </Typography>
                  <Typography className="btn-setting-des">
                    Search, filter and print take leave.
                  </Typography>
                </Stack>
              </Stack>
            </Button>
          </Grid>

          <Grid item xs={12} sm={6} lg={4}>
            <Button
              className="btn-setting"
              onClick={() => navigate("/report/overtime-report")}
            >
              <Stack direction="row" spacing={2}>
                <Stack direction="column" justifyContent="center">
                  <GiOfficeChair className="btn-icon" />
                </Stack>

                <Stack direction="column" spacing={1}>
                  <Typography className="btn-setting-title">
                    Overtime Work Report
                  </Typography>
                  <Typography className="btn-setting-des">
                    Search, filter and print overtime work.
                  </Typography>
                </Stack>
              </Stack>
            </Button>
          </Grid>
        </Grid>
      </Box>
    </div>
  );
}
