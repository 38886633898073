import React, { useState, useContext, useEffect } from "react";
import "./overtime.scss";
import {
  Typography,
  Stack,
  Box,
  Button,
  Grid,
  Drawer,
  List,
  ListItemButton,
  Avatar,
  IconButton,
} from "@mui/material";
import LibraryAddOutlinedIcon from "@mui/icons-material/LibraryAddOutlined";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useQuery } from "@apollo/client";
import moment from "moment";
//src
import { AuthContext } from "../Context/AuthContext";
import EmptyData from "../Components/Include/EmptyData";
import LoadingPage from "../Components/Include/LoadingPage";
import CreateTakeleave from "../Components/TakeLeave/CreateTakeleave";
import TakeleaveCard from "../Components/TakeLeave/TakeleaveCard";
import TakeleaveMobile from "../Components/TakeLeave/TakeleaveMobile";
import { GET_TAKELEAVE_WITH_PAGINATION } from "../Schema/TakeLeave";

export default function TakeLeave() {
 
  const [loading, setLoading] = useState(true);
  const [seeMoreStatus, setSeeMoreStatus] = useState(false);
  const { setAlert } = useContext(AuthContext);
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  //get userLoging from local
  let userLogin = JSON.parse(window.localStorage.getItem("userLogin"));

  //query
  const [tableData, setTableData] = useState([]);
  const [completedData, setCompletedData] = useState([]);
  const [requestModifyData, setRequestModifyData] = useState([]);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(5);
  const [completedLimit, setCompletedLimit] = useState(5);
  const [keyword, setKeyword] = useState("");

  const { data, refetch } = useQuery(GET_TAKELEAVE_WITH_PAGINATION, {
    variables: {
      page: page,
      limit: limit,
      keyword: keyword,
      pagination: true,
      userId: userLogin?._id,
      status: ["Pending"],
    },
    onCompleted: ({ getTakeLeaveWithPagination }) => {
      setTableData(getTakeLeaveWithPagination?.data);
      setLoading(false);
      setTakeLeaveDetails(getTakeLeaveWithPagination?.data[0]);
    },
    onError: (error) => {
      setAlert(true, "error", error?.message);
      // console.log(error)
    },
  });

  const { data: dataRequestModify} = useQuery(GET_TAKELEAVE_WITH_PAGINATION, {
    variables: {
      page: page,
      limit: completedLimit,
      keyword: "",
      pagination: true,
      userId: userLogin?._id,
      status: ["Approved", "Denied"],
      requestModifyStatus: ["Pending"]
    },
    onCompleted: ({ getTakeLeaveWithPagination }) => {
      setRequestModifyData(getTakeLeaveWithPagination?.data);
      setLoading(false);
      // console.log("getTakeLeaveWithPagination::", getTakeLeaveWithPagination)
    },
    onError: (error) => {
      setAlert(true, "error", error?.message);
      // console.log(error)
    },
  });

  const { data: dataCompleted } = useQuery(GET_TAKELEAVE_WITH_PAGINATION, {
    variables: {
      page: page,
      limit: completedLimit,
      keyword: "",
      pagination: true,
      userId: userLogin?._id,
      status: ["Approved", "Denied"],
      requestModifyStatus: ["No Request", "Approved", "Denied"]
    },
    onCompleted: ({ getTakeLeaveWithPagination }) => {
      setCompletedData(getTakeLeaveWithPagination?.data);
      setLoading(false);
      // console.log("getTakeLeaveWithPagination::", getTakeLeaveWithPagination)
    },
    onError: (error) => {
      setAlert(true, "error", error?.message);
      // console.log(error)
    },
  });

  const handleSeeMore = (e) => {
    setSeeMoreStatus(!seeMoreStatus);
    setLimit(limit + 5);
  };

  const handleCompletedSeeMore = (e) => {
    setCompletedLimit(limit + 5);
  };

  // function select
  const [takeLeaveDetails, setTakeLeaveDetails] = useState();
  const [selectedIndex, setSelectedIndex] = useState();
  const handleListItemClick = (event, index, element) => {
    setTakeLeaveDetails(element);
    setSelectedIndex(index);
  };

  // console.log("completedData::", completedData);

  let displayModalWeb = { xs: "none", sm: "flex" };
  let displayModalMobile = { xs: "block", sm: "none" };

  return (
    <div style={{ width: "100%" }}>
      <Box sx={{ display: displayModalMobile }}>
        <TakeleaveMobile />
      </Box>
      <Box sx={{ display: displayModalWeb }}>
        <div className="overtime-container">
          <Stack direction="row" sx={{ height: "50px" }}>
            <Stack direction="column" justifyContent="center">
              <Typography className="page-title">Take Leave</Typography>
            </Stack>
            <Box sx={{ flexGrow: 1 }} />
            <Stack direction="column" justifyContent="center" spacing={2}>
              <Button
                onClick={handleOpen}
                className="btn-add"
                startIcon={<LibraryAddOutlinedIcon className="icon-add" />}
              >
                Create
              </Button>
            </Stack>

            <Drawer
              sx={{
                flexShrink: 0,
                "& .MuiDrawer-paper": {
                  width: 600,
                },
              }}
              anchor="right"
              open={open}
            >
              <CreateTakeleave
                open={open}
                handleClose={handleClose}
                setRefetch={refetch}
                dialogTitle={"Create"}
                userLogin={userLogin}
              />
            </Drawer>
          </Stack>

          <Box className="body-container">
            <Grid container spacing={3}>
              <Grid item xs={12} md={5} lg={4}>
                {loading ? (
                  <Box sx={{ width: "100%" }}>
                    <LoadingPage />
                  </Box>
                ) : (
                  <Box sx={{ width: "100%" }}>
                    {tableData?.length === 0 ? (
                      ""
                    ) : (
                      <>
                        <Typography className="tabs-title">
                          In progress
                        </Typography>
                        <List component="nav" aria-label="main mailbox folders">
                          {tableData?.map((element, index) => {
                            return (
                              <ListItemButton
                                sx={{ height: "60px" }}
                                key={index}
                                selected={selectedIndex === index}
                                onClick={(event) =>
                                  handleListItemClick(event, index, element)
                                }
                              >
                                <Stack direction="row" spacing={1}>
                                  <Avatar
                                    alt="User"
                                    sx={{ width: 40, height: 40 }}
                                    src={
                                      element?.createdBy?.staffProfile?.imageSrc
                                    }
                                  />
                                  <Stack
                                    direction="column"
                                    justifyContent="space-between"
                                    className="stack-user-container"
                                  >
                                    <Typography className="user-request">
                                      {element?.createdBy?.staffProfile
                                        ?.englishData?.firstNameLatin +
                                        " " +
                                        element?.createdBy?.staffProfile
                                          ?.englishData?.lastNameLatin +
                                        " requested overtime"}
                                    </Typography>
                                    <Typography className="date-request">
                                      {moment(element?.createdAt)
                                        .utc()
                                        .format("MMM DD, YYYY")}
                                    </Typography>
                                  </Stack>
                                </Stack>
                              </ListItemButton>
                            );
                          })}

                          <ListItemButton
                            sx={{ height: "50px" }}
                            onClick={handleSeeMore}
                          >
                            <Stack direction="row" spacing={1}>
                              <IconButton sx={{ width: 35, height: 35 }}>
                                {/* {seeMoreStatus ? (
                                <ExpandLessIcon />
                              ) : ( */}
                                <ExpandMoreIcon />
                                {/* )} */}
                              </IconButton>

                              <Stack direction="column" justifyContent="center">
                                <Typography className="seemore-text">
                                  See More
                                </Typography>
                              </Stack>
                            </Stack>
                          </ListItemButton>
                        </List>
                      </>
                    )}
                  </Box>
                )}

                {loading ? (
                  <Box sx={{ width: "100%" }}>
                    <LoadingPage />
                  </Box>
                ) : (
                  <Box sx={{ width: "100%" }}>
                    {requestModifyData?.length === 0 ? (
                      ""
                    ) : (
                      <>
                        <Typography className="tabs-title">
                          Requesting Modify
                        </Typography>
                        <List component="nav" aria-label="main mailbox folders">
                          {requestModifyData?.map((element, index) => {
                            return (
                              <ListItemButton
                                sx={{ height: "60px" }}
                                key={index}
                                selected={selectedIndex === index}
                                onClick={(event) =>
                                  handleListItemClick(event, index, element)
                                }
                              >
                                <Stack direction="row" spacing={1}>
                                  <Avatar
                                    alt="User"
                                    sx={{ width: 40, height: 40 }}
                                    src={
                                      element?.createdBy?.staffProfile?.imageSrc
                                    }
                                  />
                                  <Stack
                                    direction="column"
                                    justifyContent="space-between"
                                    className="stack-user-container"
                                  >
                                    <Typography className="user-request">
                                      {element?.createdBy?.staffProfile
                                        ?.englishData?.firstNameLatin +
                                        " " +
                                        element?.createdBy?.staffProfile
                                          ?.englishData?.lastNameLatin +
                                        " requested overtime"}
                                    </Typography>
                                    <Typography className="date-request">
                                      {moment(element?.createdAt)
                                        .utc()
                                        .format("MMM DD, YYYY")}
                                    </Typography>
                                  </Stack>
                                </Stack>
                              </ListItemButton>
                            );
                          })}

                          <ListItemButton
                            sx={{ height: "50px" }}
                            onClick={handleSeeMore}
                          >
                            <Stack direction="row" spacing={1}>
                              <IconButton sx={{ width: 35, height: 35 }}>
                                {/* {seeMoreStatus ? (
                                <ExpandLessIcon />
                              ) : ( */}
                                <ExpandMoreIcon />
                                {/* )} */}
                              </IconButton>

                              <Stack direction="column" justifyContent="center">
                                <Typography className="seemore-text">
                                  See More
                                </Typography>
                              </Stack>
                            </Stack>
                          </ListItemButton>
                        </List>
                      </>
                    )}
                  </Box>
                )}

                {loading ? (
                  <Box sx={{ width: "100%" }}>
                    <LoadingPage />
                  </Box>
                ) : (
                  <Box sx={{ width: "100%" }}>
                    {completedData?.length === 0 ? (
                      ""
                    ) : (
                      <>
                        <Typography className="tabs-title">
                          Completed
                        </Typography>
                        <List component="nav" aria-label="main mailbox folders">
                          {completedData?.map((element, index) => {
                            return (
                              <ListItemButton
                                sx={{ height: "60px" }}
                                key={index}
                                selected={
                                  selectedIndex === tableData?.length + index
                                }
                                onClick={(event) =>
                                  handleListItemClick(
                                    event,
                                    tableData?.length + index,
                                    element
                                  )
                                }
                              >
                                <Stack direction="row" spacing={1}>
                                  <Avatar
                                    alt="User"
                                    sx={{ width: 40, height: 40 }}
                                    src={
                                      element?.approvedOrDeniedBy?.staffProfile
                                        ?.imageSrc
                                    }
                                  />
                                  <Stack
                                    direction="column"
                                    justifyContent="space-between"
                                    className="stack-user-container"
                                  >
                                    {element?.status === "Denied" ? (
                                      <Typography className="user-request">
                                        {element?.approvedOrDeniedBy
                                          ?.staffProfile?.englishData
                                          ?.firstNameLatin +
                                          " " +
                                          element?.approvedOrDeniedBy
                                            ?.staffProfile?.englishData
                                            ?.lastNameLatin +
                                          " denied overtime"}
                                      </Typography>
                                    ) : (
                                      <Typography className="user-request">
                                        {element?.approvedOrDeniedBy
                                          ?.staffProfile?.englishData
                                          ?.firstNameLatin +
                                          " " +
                                          element?.approvedOrDeniedBy
                                            ?.staffProfile?.englishData
                                            ?.lastNameLatin +
                                          " approved overtime"}
                                      </Typography>
                                    )}
                                    <Typography className="date-request">
                                      {moment(element?.createdAt)
                                        .utc()
                                        .format("MMM DD, YYYY")}
                                    </Typography>
                                  </Stack>
                                </Stack>
                              </ListItemButton>
                            );
                          })}

                          <ListItemButton
                            sx={{ height: "50px" }}
                            onClick={handleCompletedSeeMore}
                          >
                            <Stack direction="row" spacing={1}>
                              <IconButton sx={{ width: 35, height: 35 }}>
                                <ExpandMoreIcon />
                              </IconButton>

                              <Stack direction="column" justifyContent="center">
                                <Typography className="seemore-text">
                                  See More
                                </Typography>
                              </Stack>
                            </Stack>
                          </ListItemButton>
                        </List>
                      </>
                    )}
                  </Box>
                )}
              </Grid>

              <Grid
                item
                xs={12}
                md={7}
                lg={8}
                sx={{
                  borderLeft: { xs: "none", md: "2px solid #e1e3e6" },
                }}
              >
                <TakeleaveCard
                  loading={loading}
                  setRefetch={refetch}
                  userLogin={userLogin}
                  takeLeaveDetails={takeLeaveDetails}
                  tableData={tableData}
                  completedData={completedData}
                />
              </Grid>
            </Grid>
          </Box>
        </div>
      </Box>
    </div>
  );
}
