import React, { useEffect, useState, useContext } from "react";
import "../../Style/createstyle.scss";
import { useFormik, Form, FormikProvider } from "formik";
import * as Yup from "yup";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import { Grid, Stack, Box, TextField, Typography, Button, FormHelperText, Select, MenuItem} from "@mui/material";
import { useMutation } from "@apollo/client";
import Textarea from '../LayoutComponents/Textarea'
import { TextareaAutosize as BaseTextareaAutosize } from '@mui/base/TextareaAutosize';
import { styled } from '@mui/system';
//Icons
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
//Src
import { SelectStaff } from "../../DynamicComp/DynamicSelect";
import { AuthContext } from "../../Context/AuthContext";
import { CREATE_STAFF_RESIGN } from "../../Schema/StaffResignAndExitInterview";
import TextAreaCustomStyle from "../LayoutComponents/Textarea"

const TextareaWithStyle = styled(BaseTextareaAutosize)(
    ({ theme }) => TextAreaCustomStyle(),
  );

export default function CreateStaffResign({
    open,
    handleClose,
    setRefetch,
    dialogTitle
}) {
    const { setAlert } = useContext(AuthContext);
    const [loading, setLoading] = useState(false);
    const [staffVal, setStaffVal] = useState({ id: "", title: "" });


    const [createStaffResignInterview, { data, error }] = useMutation(CREATE_STAFF_RESIGN, {
        onCompleted: ({ createStaffResignInterview }) => {
            console.log(createStaffResignInterview, "createStaffResignInterview");
            setLoading(false);
            if (createStaffResignInterview?.success === true) {
                setAlert(true, "success", createStaffResignInterview?.message);
                handleClose();
                resetForm();
                setRefetch();
            } else {
                setAlert(true, "error", createStaffResignInterview?.message);
            }
        },
        onError: (error) => {
            setLoading(false);
            setAlert(true, "error", error?.message);
        },
    });

    // const [updateMajor] = useMutation(UPDATE_MAJOR, {
    //     onCompleted: ({ updateMajor }) => {
    //         // console.log(updateMajor);
    //         setLoading(false);
    //         if (updateMajor?.success === true) {
    //             setAlert(true, "success", updateMajor?.message);
    //             handleClose();
    //             setRefetch();
    //         } else {
    //             setAlert(true, "error", updateMajor?.message);
    //         }
    //     },
    //     onError: (error) => {
    //         setLoading(false);
    //         setAlert(true, "error", error?.message);
    //     },
    // });

    //fromik
    const validationSchema = Yup.object().shape({
        staffInfoId: Yup.string().required("require!"),
        sendEmailStatus: Yup.string(),
        textForSendEmail: Yup.string()
    });

    const formik = useFormik({
        initialValues: {
            staffInfoId: "",
            sendEmailStatus: 'Send now',
            textForSendEmail: ""
        
        },

        validationSchema: validationSchema,
        onSubmit: async (values) => {
            // console.log("values:::", values);
            createStaffResignInterview({
                variables: {
                    input: {
                        staffInfoId: staffVal?.id,
                        sendEmailStatus: values?.sendEmailStatus,
                        textForSendEmail: values?.textForSendEmail
                    },
                },
            });
        },
    });

    const {
        errors,
        touched,
        handleSubmit,
        getFieldProps,
        setFieldValue,
        values,
        resetForm,
    } = formik;


    useEffect(() => {
        if (staffVal?.id !== "") {
          setFieldValue("staffInfoId", staffVal?.id);
        } else {
          setFieldValue("staffInfoId", "");
        }
      }, [staffVal?.id]);

    return (
        <>
            <Dialog open={open} className="create-container" >
                <DialogTitle sx={{ padding: "10px 20px" }}>
                    <Stack direction="row" spacing={2}>
                        <Stack direction="column" justifyContent="center">
                            <Typography className="dialog-title">
                                {dialogTitle} Exit Interview Form
                            </Typography>
                        </Stack>

                        <Box sx={{ flexGrow: 1 }} />
                        <IconButton onClick={handleClose}>
                            <CloseIcon className="close-icon" />
                        </IconButton>
                    </Stack>
                </DialogTitle>

                <DialogContent dividers>
                    <FormikProvider value={formik}>
                        <Form noValidate autoComplete="off" onSubmit={handleSubmit}>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <Typography className="field-title">Select staff</Typography>
                                    <SelectStaff
                                        selectedVal={staffVal}
                                        setSelectedVal={setStaffVal}
                                    />
                                    {!!errors.staffInfoId && touched.staffInfoId && (
                                        <FormHelperText error id="outlined-adornment-password">
                                            {errors.staffInfoId}
                                        </FormHelperText>
                                    )}
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography className="field-title">Sent to staff</Typography>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        fullWidth
                                        size="small"
                                        className="text-field"
                                        value={values.sendEmailStatus}
                                        onChange={(event) => {
                                            setFieldValue("sendEmailStatus", event.target.value);
                                        }}
                                        error={Boolean(touched.sendEmailStatus && errors.sendEmailStatus)}
                                        helperText={touched.sendEmailStatus && errors.sendEmailStatus}
                                    >
                                        <MenuItem value={"Send now"}>Sent now</MenuItem>
                                        <MenuItem value={"Send later"}>Sent later</MenuItem>
                                    </Select>
                                </Grid>
                                <Grid item xs={12} disabled 
                                    style={{opacity: values.sendEmailStatus === 'Send now' ? '100%' : '50%'}}
                                >
                                    <Typography className="field-title">Text for send email </Typography>
                                    <TextareaWithStyle
                                        disabled={values.sendEmailStatus === "Send now" ? false : true}
                                        minRows={3}
                                        placeholder={"say somethings to staff..."}
                                        defaultValue={""}
                                        {...getFieldProps("textForSendEmail")}
                                        error={Boolean(touched.textForSendEmail && errors.textForSendEmail)}
                                        helperText={touched.textForSendEmail && errors.textForSendEmail}
                                    />
                                </Grid>
                            </Grid>
                        </Form>
                    </FormikProvider>
                </DialogContent>

                <DialogActions>
                    <Stack direction="row" spacing={4} className="action-container">
                        <Button className="btn-cencel" fullWidth onClick={handleClose}>
                            Cencel
                        </Button>

                        {loading ? (
                            <Button className="btn-create" fullWidth>
                                Loading...
                            </Button>
                        ) : (
                            <Button className="btn-create" fullWidth onClick={handleSubmit}>
                                {dialogTitle}
                            </Button>
                        )}
                    </Stack>
                </DialogActions>
            </Dialog>
        </>
    )
}