import React, { useState, useContext } from "react";
import "../../../Style/deletestyle.scss";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import { Stack, Box } from "@mui/material";
import { useMutation } from "@apollo/client";
import { ref, deleteObject } from "firebase/storage";
import { storage } from "../../../firebase";
//Schema
import { AuthContext } from "../../../Context/AuthContext";
import { DELETE_WORKING_HISTORY } from "../../../Schema/WorkHistory";

export default function DeleteWorkingHistory({
  open,
  handleClose,
  editData,
  setRefetch,
}) {
  const { setAlert } = useContext(AuthContext);
  const [loading, setLoading] = useState(false);

  const [deleteWorkingHistory] = useMutation(DELETE_WORKING_HISTORY, {
    onCompleted: ({ deleteWorkingHistory }) => {
      // console.log(deleteWorkingHistory);
      setLoading(false);
      if (deleteWorkingHistory?.success === true) {
        setAlert(true, "success", deleteWorkingHistory?.message);
        handleClose();
        setRefetch();
      } else {
        setAlert(true, "error", deleteWorkingHistory?.message);
      }
    },
    onError: (error) => {
      console.log(error);
    },
  });

  const handleDelete = () => {
    setLoading(true);
    deleteImage()
    deleteWorkingHistory({
      variables: {
        id: editData?._id,
      },
    });
  };

  const deleteImage = () => {
    if (editData?.fileUpload) {
      const deleteRef = ref(storage, editData?.fileUpload);
      deleteObject(deleteRef)
        .then(() => {
          localStorage.removeItem("staffDocumentSrc");
          console.log("delete this image successfully!");
        })
        .catch((err) => {
          console.log("can not delete this image!", err);
        });
    }
  };

  return (
    <Dialog open={open} className="delete-container">
      <DialogTitle sx={{ padding: "10px 20px" }}>
        <Stack direction="row" spacing={2}>
          <Stack direction="column" justifyContent="center">
            <Typography className="dialog-title">
              Delete Working history
            </Typography>
          </Stack>
          <Box sx={{ flexGrow: 1 }} />
          <IconButton onClick={handleClose}>
            <CloseIcon className="close-icon" />
          </IconButton>
        </Stack>
      </DialogTitle>

      <DialogContent>
        <Stack direction="row" spacing={1}>
          <Typography>Are you sure to delete this data ?</Typography>
        </Stack>
      </DialogContent>

      <DialogActions>
        <Stack direction="row" spacing={4} className="action-container">
          <Button className="btn-cencel" fullWidth onClick={handleClose}>
            Cencel
          </Button>

          {loading ? (
            <Button className="btn-delete" fullWidth>
              Loading...
            </Button>
          ) : (
            <Button className="btn-delete" fullWidth onClick={handleDelete}>
              Delete
            </Button>
          )}
        </Stack>
      </DialogActions>
    </Dialog>
  );
}
