import { gql } from "@apollo/client";

export const GET_TAKELEAVE_REPORT = gql`
  query GetTakeLeaveReport(
    $page: Int
    $limit: Int
    $fromDate: String
    $toDate: String
    $staffId: String
    $takeLeaveType: String
    $status: String
  ) {
    getTakeLeaveReport(
      page: $page
      limit: $limit
      fromDate: $fromDate
      toDate: $toDate
      staffId: $staffId
      takeLeaveType: $takeLeaveType
      status: $status
    ) {
      data {
        _id
        isAdjust
        totalLeaveAsDay
        startDate
        endDate
        returnDate
        reason
        status

        createdBy {
          _id
          currentSituation
          startedDate
          systemAccessData {
            emailAddress
            role
            isActive
          }
          staffProfile {
            imageName
            imageSrc
            englishData {
              firstNameLatin
              lastNameLatin
              gender
              identityCardEng
              passportsNumerEng
              phoneNumberEng
              nationalityEng
              dateOfBirthEng
              maritalStatusEng
              placeOfBirthEng {
                villageEng
                communeEng
                districtEng
                cityEng
              }
              permananceAddressEng {
                villageEng
                communeEng
                districtEng
                cityEng
              }
              temporaryAddressEng {
                villageEng
                communeEng
                districtEng
                cityEng
              }
            }
            khmerData {
              firstNameKh
              lastNameKh
              placeOfBirthKh {
                villageKh
                communeKh
                districtKh
                cityKh
              }
              permananceAddressKh {
                villageKh
                communeKh
                districtKh
                cityKh
              }
              temporaryAddressKh {
                villageKh
                communeKh
                districtKh
                cityKh
              }
            }
          }
          currentContract {
            _id
            startDate
            endDate
            typeOfEmployment
            positions {
              _id
              positionName
              positionNameKhmer
              inDepartment {
                _id
                departmentName
                remark
                createdAt
                updatedAt
              }
              isDepartmentManager
              remark
            }
            officeBase {
              _id
              officeBaseName
              remark
              createdAt
              updatedAt
            }
            workingBase {
              _id
              workingBaseName
              remark
              createdAt
              updatedAt
            }
            workingPlace {
              _id
              workingPlaceName
              remark
              createdAt
              updatedAt
            }
          }
        }
        requestTo {
          _id
          staffProfile {
            imageName
            imageSrc
            khmerData {
              firstNameKh
              lastNameKh
            }
          }
        }
        approvedOrDeniedBy {
          _id
          staffProfile {
            imageName
            imageSrc
            khmerData {
              firstNameKh
              lastNameKh
            }
          }
        }
        approvedOrDeniedDate
        paymentOfTakeLeaveQuantity {
          takeLeaveType
          fromDate
          validUntil
          paidMonths
          lastYearRemainQty
          currentYearQty
          UsedQty
        }
        managerReply
        createdAt
        updatedAt
        warningMessage
        takeLeaveTypeUsed {
          takeLeaveType
          quantityDayLeave
        }
      }
      paginator {
        slNo
        prev
        next
        perPage
        totalPosts
        totalPages
        currentPage
        hasPrevPage
        hasNextPage
        totalDocs
      }
    }
  }
`;

export const GET_ALL_DRIVERLICENCE_REPORT = gql`
  query DrivingLicenceReport {
    drivingLicenceReport {
      staffID
      englishName
      positionName
      nationality
      gender
      drivingId
      manualAuto
      issueDate
      expiryDate
      drivingType
    }
  }
`;

export const GET_NFFSMEMBERSHIP_CARD_REPORT = gql`
  query NffsMemberShipCardReport {
    nffsMemberShipCardReport {
      staffID
      englishName
      gender
      positionName
      nffsCardId
    }
  }
`;

export const GET_COVIDVACCINATION_REPORT = gql`
  query CovidVacinationReport {
    covidVacinationReport {
      staffID
      englishName
      gender
      positionName
      covidVacinIdCard
      doesQty
    }
  }
`;

export const GET_ANNUALLEAVE_REPORT = gql`
  query AnnualLeaveReport($fromDate: Int!, $toDate: Int!) {
    annualLeaveReport(fromDate: $fromDate, toDate: $toDate) {
      latinName
      position
      gender
      takeLeaveUsed
      totalUsed
      previousYearRemaining
      totalAL
      currYearOveralRemaining
    }
  }
`;

export const GET_SICKLEAVE_REPORT = gql`
  query SickLeaveReport($fromDate: Int!, $toDate: Int!) {
    sickLeaveReport(fromDate: $fromDate, toDate: $toDate) {
      latinName
      position
      gender
      takeLeaveUsed
      totalUsed
      totalSickLeave
      currYearOveralRemaining
    }
  }
`;
export const GET_MATERNITYLEAVE_REPORT = gql`
  query MaternityLeaveReport($fromDate: Int!, $toDate: Int!) {
    maternityLeaveReport(fromDate: $fromDate, toDate: $toDate) {
      latinName
      positions
      gender
      detail
      startDate
      endDate
      totalLeave
    }
  }
`;
export const GET_BREASTFEEDINGLEAVE_REPORT = gql`
  query BreastfeedingLeaveReport($fromDate: Int!, $toDate: Int!) {
    breastfeedingLeaveReport(fromDate: $fromDate, toDate: $toDate) {
      latinName
      positions
      gender
      startDate
      endDate
      note
      totalLeaveAsHours
    }
  }
`;
export const GET_COMPASSIONATELEAVE_REPORT = gql`
  query CompassionateLeaveReport($fromDate: Int!, $toDate: Int!) {
    compassionateLeaveReport(fromDate: $fromDate, toDate: $toDate) {
      latinName
      positions
      gender
      detail
      startDate
      endDate
      totalLeave
    }
  }
`;

export const GET_UNPAIDLEAVE_REPORT = gql`
  query UnpaidLeave($fromDate: Int!, $toDate: Int!) {
    unpaidLeave(fromDate: $fromDate, toDate: $toDate) {
      latinName
      positions
      gender
      detail
      startDate
      endDate
      totalLeave
    }
  }
`;

export const GET_TAKELEAVE_SUMMARY_REPORT = gql`
  query TakeLeaveSummaryReport($fromDate: Int!, $toDate: Int!, $staffId: ID!) {
    takeLeaveSummaryReport(
      fromDate: $fromDate
      toDate: $toDate
      staffId: $staffId
    ) {
      annualLeave {
        totalUsed
        previousYearRemaining
        totalPerYear
        currentYearRemaining
      }
      sickLeave {
        totalUsed
        totalPerYear
        currentYearRemaining
      }
      maternityLeave {
        totalUsed
        period {
          startDate
          endDate
        }
      }
      breastfeedingLeave {
        totalUsedAsHours
        period {
          startDate
          endDate
        }
      }
      compassionateLeave {
        totalUsed
      }
      unpaidLeave {
        totalUsed
      }
    }
  }
`;

export const GET_OVERTIME_REPORT = gql`
  query GetOvertimeReport(
    $page: Int
    $limit: Int
    $pagination: Boolean
    $fromDate: String
    $toDate: String
    $staffId: String
    $status: String
  ) {
    getOvertimeReport(
      page: $page
      limit: $limit
      pagination: $pagination
      fromDate: $fromDate
      toDate: $toDate
      staffId: $staffId
      status: $status
    ) {
      data {
        _id
        timeType
        totalOvertimeAsDay
        totalOvertimeAsHours
        startDate
        endDate
        startTime
        endTime
        reason
        status
        createdBy {
          _id
          currentSituation
          startedDate
          systemAccessData {
            emailAddress
            role
            isActive
          }
          staffProfile {
            imageName
            imageSrc
            englishData {
              firstNameLatin
              lastNameLatin
              gender
              identityCardEng
              passportsNumerEng
              phoneNumberEng
              nationalityEng
              dateOfBirthEng
              maritalStatusEng
              placeOfBirthEng {
                villageEng
                communeEng
                districtEng
                cityEng
              }
              permananceAddressEng {
                villageEng
                communeEng
                districtEng
                cityEng
              }
              temporaryAddressEng {
                villageEng
                communeEng
                districtEng
                cityEng
              }
            }
            khmerData {
              firstNameKh
              lastNameKh
              placeOfBirthKh {
                villageKh
                communeKh
                districtKh
                cityKh
              }
              permananceAddressKh {
                villageKh
                communeKh
                districtKh
                cityKh
              }
              temporaryAddressKh {
                villageKh
                communeKh
                districtKh
                cityKh
              }
            }
          }
          currentContract {
            _id
            startDate
            endDate
            typeOfEmployment
            positions {
              _id
              positionName
              positionNameKhmer
              inDepartment {
                _id
                departmentName
                remark
                createdAt
                updatedAt
              }
              isDepartmentManager
              remark
            }
            officeBase {
              _id
              officeBaseName
              remark
              createdAt
              updatedAt
            }
            workingBase {
              _id
              workingBaseName
              remark
              createdAt
              updatedAt
            }
            workingPlace {
              _id
              workingPlaceName
              remark
              createdAt
              updatedAt
            }
          }
        }
        requestTo {
          _id
          staffProfile {
            imageName
            imageSrc
            khmerData {
              firstNameKh
              lastNameKh
            }
          }
        }
        approvedOrDeniedBy {
          _id
          staffProfile {
            imageName
            imageSrc
            khmerData {
              firstNameKh
              lastNameKh
            }
          }
        }
        managerReply
        updatedAt
        createdAt
      }
      paginator {
        slNo
        prev
        next
        perPage
        totalPosts
        totalPages
        currentPage
        hasPrevPage
        hasNextPage
        totalDocs
      }
    }
  }
`;
