import { useState, useEffect, useContext } from "react";
import "./dynamicselect.scss";
import { useQuery } from "@apollo/client";
import { Autocomplete, Button, TextField } from "@mui/material";
import { styled, lighten, darken } from "@mui/system";
import AddIcon from "@mui/icons-material/Add";
//Components
import { AuthContext } from "../Context/AuthContext";
import CreateMajor from "../Components/Major/CreateMajor";
import CreatePositions from "../Components/Positions/CreatePositions";
//graphql
import { GET_MAJOR_WITH_PAGINATION } from "../Schema/Major";
import { GET_POSITIONS_WITH_PAGINATION } from "../Schema/Positions";
import { GET_DEPARTMENTS_WITH_PAGINATION } from "../Schema/Department";
import { GET_OFFICE_BASE_WITH_PAGINATION } from "../Schema/OfficeBase";
import { GET_WORKINGBASE_WITH_PAGINATION } from "../Schema/WorkingBase";
import { GET_WORKINGPLACE_WITH_PAGINATION } from "../Schema/WorkingPlace";
import { GET_STAFFINFO_WITH_PAGINATION } from "../Schema/StaffInformation";

const GroupHeader = styled("div")(({ theme }) => ({
  position: "sticky",
  top: "-8px",
  padding: "4px 10px",
  color: theme.palette.primary.main,
}));

const GroupItems = styled("ul")({
  padding: 0,
});

export function SelectMajor({ selectedVal, setSelectedVal }) {
  const { setAlert } = useContext(AuthContext);
  const [data, setData] = useState([]);
  const [majorOptions, setMajorOptions] = useState([]);

  //function create major
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const { data: majorData, refetch } = useQuery(GET_MAJOR_WITH_PAGINATION, {
    variables: {
      page: 1,
      keyword: "",
      pagination: false,
    },
    onCompleted: ({ getMajorWithPagination }) => {
      setData(getMajorWithPagination?.data);
    },
    onError: (error) => {
      setAlert(true, "error", error?.message);
      console.log(error?.message);
    },
  });

  useEffect(() => {
    let rows = [];
    const addObject = { id: "major001", title: "add-btn" };
    if (data?.length !== 0) {
      data?.map((d) => {
        const allRows = {
          id: d?._id,
          title: d?.majorName,
        };
        rows.push(allRows);
      });
    }
    rows.unshift(addObject);
    setMajorOptions(rows);
  }, [data]);

  const handleSelect = (newVal) => {
    setSelectedVal(newVal);
  };

  return (
    <>
      <CreateMajor
        open={open}
        handleClose={handleClose}
        setRefetch={refetch}
        dialogTitle={"Create"}
      />
      <Autocomplete
        value={selectedVal}
        options={majorOptions}
        groupBy={(option) => option.title === "add-btn"}
        onChange={(event, newVal) => handleSelect(newVal)}
        getOptionLabel={(option) =>
          option.title === undefined ? "select major" : option.title
        }
        isOptionEqualToValue={(option, value) => option.value === value.value}
        renderInput={(params) => (
          <TextField {...params} size="small" placeholder="select major" />
        )}
        renderGroup={(params) => {
          return (
            <>
              {params?.children?.length > 1 ? (
                <li>
                  <GroupHeader>
                    <Button
                      className="btn-add"
                      startIcon={<AddIcon />}
                      fullWidth
                      onClick={handleOpen}
                    >
                      Add New
                    </Button>
                  </GroupHeader>
                  <GroupItems>{params.children}</GroupItems>
                </li>
              ) : (
                ""
              )}
            </>
          );
        }}
      />
    </>
  );
}

export function SelectPositions({ selectedVal, setSelectedVal }) {
  const { setAlert } = useContext(AuthContext);
  const [data, setData] = useState([]);
  const [positionsOptions, setPositionsOptions] = useState([]);

  //function create position
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const { data: positionsData, refetch } = useQuery(
    GET_POSITIONS_WITH_PAGINATION,
    {
      variables: {
        page: 1,
        keyword: "",
        pagination: false,
      },
      onCompleted: ({ getPositionsWithPagination }) => {
        setData(getPositionsWithPagination?.data);
        // console.log(getPositionsWithPagination)
      },
      onError: (error) => {
        setAlert(true, "error", error?.message);
        console.log(error?.message);
      },
    }
  );

  useEffect(() => {
    let rows = [];
    const addObject = { id: "positions001", title: "add-btn" };
    if (data?.length !== 0) {
      data?.map((d) => {
        const allRows = {
          id: d?._id,
          title: d?.positionName,
        };
        rows.push(allRows);
      });
    }
    rows.unshift(addObject);
    setPositionsOptions(rows);
  }, [data]);

  const handleSelect = (newVal) => {
    setSelectedVal(newVal);
  };

  return (
    <>
      <CreatePositions
        open={open}
        handleClose={handleClose}
        setRefetch={refetch}
        dialogTitle={"Create"}
      />
      <Autocomplete
        value={selectedVal}
        options={positionsOptions}
        groupBy={(option) => option.title === "add-btn"}
        onChange={(event, newVal) => handleSelect(newVal)}
        getOptionLabel={(option) =>
          option.title === undefined ? "select positions" : option.title
        }
        isOptionEqualToValue={(option, value) => option.value === value.value}
        renderInput={(params) => (
          <TextField {...params} size="small" placeholder="select positions" />
        )}
        renderGroup={(params) => {
          return (
            <>
              {params?.children?.length > 1 ? (
                <li>
                  <GroupHeader>
                    <Button
                      className="btn-add"
                      startIcon={<AddIcon />}
                      fullWidth
                      onClick={handleOpen}
                    >
                      Add New
                    </Button>
                  </GroupHeader>
                  <GroupItems>{params.children}</GroupItems>
                </li>
              ) : (
                ""
              )}
            </>
          );
        }}
      />
    </>
  );
}

export function SelectDepartment({ selectedVal, setSelectedVal, className }) {
  const { setAlert } = useContext(AuthContext);
  const [data, setData] = useState([]);
  const [departmentOptions, setDepartmentOptions] = useState([]);

  const { data: departmentData, refetch } = useQuery(
    GET_DEPARTMENTS_WITH_PAGINATION,
    {
      variables: {
        page: 1,
        keyword: "",
        pagination: false,
      },
      onCompleted: ({ getDepartmentsPagination }) => {
        setData(getDepartmentsPagination?.data);
        // console.log(getDepartmentsPagination)
      },
      onError: (error) => {
        setAlert(true, "error", error?.message);
        console.log(error?.message);
      },
    }
  );

  useEffect(() => {
    let rows = [];
    if (data?.length !== 0) {
      data?.map((d) => {
        const allRows = {
          id: d?._id,
          title: d?.departmentName,
        };
        rows.push(allRows);
        setDepartmentOptions(rows);
      });
    }
  }, [data]);

  const handleSelect = (newVal) => {
    setSelectedVal(newVal);
  };

  return (
    <>
      <Autocomplete
        className={className}
        value={selectedVal}
        options={departmentOptions}
        onChange={(event, newVal) => handleSelect(newVal)}
        getOptionLabel={(option) =>
          option.title === undefined ? "select department" : option.title
        }
        isOptionEqualToValue={(option, value) => option.value === value.value}
        renderInput={(params) => (
          <TextField {...params} size="small" placeholder="select department" />
        )}
      />
    </>
  );
}

export function SelectOfficeBase({ selectedVal, setSelectedVal }) {
  const { setAlert } = useContext(AuthContext);
  const [data, setData] = useState([]);
  const [options, setOptions] = useState([]);

  const { data: officeBaseData, refetch } = useQuery(
    GET_OFFICE_BASE_WITH_PAGINATION,
    {
      variables: {
        page: 1,
        keyword: "",
        pagination: false,
      },
      onCompleted: ({ getOfficeBasesPagination }) => {
        setData(getOfficeBasesPagination?.data);
      },
      onError: (error) => {
        setAlert(true, "error", error?.message);
        console.log(error);
      },
    }
  );

  useEffect(() => {
    let rows = [];
    if (data?.length !== 0) {
      data?.map((d) => {
        const allRows = {
          id: d?._id,
          title: d?.officeBaseName,
        };
        rows.push(allRows);
        setOptions(rows);
      });
    }
  }, [data]);

  const handleSelect = (newVal) => {
    setSelectedVal(newVal);
  };

  return (
    <>
      <Autocomplete
        value={selectedVal}
        options={options}
        onChange={(event, newVal) => handleSelect(newVal)}
        getOptionLabel={(option) =>
          option.title === undefined ? "select officebase" : option.title
        }
        isOptionEqualToValue={(option, value) => option.value === value.value}
        renderInput={(params) => (
          <TextField {...params} size="small" placeholder="select officebase" />
        )}
      />
    </>
  );
}

export function SelectWorkingBase({ selectedVal, setSelectedVal }) {
  const { setAlert } = useContext(AuthContext);
  const [data, setData] = useState([]);
  const [options, setOptions] = useState([]);

  const { data: officeWorkingBaseData, refetch } = useQuery(
    GET_WORKINGBASE_WITH_PAGINATION,
    {
      variables: {
        page: 1,
        keyword: "",
        pagination: false,
      },
      onCompleted: ({ getWorkingBasePagination }) => {
        // console.log("getWorkingBasePagination::", getWorkingBasePagination);
        setData(getWorkingBasePagination?.data);
      },
      onError: (error) => {
        setAlert(true, "error", error?.message);
      },
    }
  );

  useEffect(() => {
    let rows = [];
    if (data?.length !== 0) {
      data?.map((d) => {
        const allRows = {
          id: d?._id,
          title: d?.workingBaseName,
        };
        rows.push(allRows);
        setOptions(rows);
      });
    }
  }, [data]);

  const handleSelect = (newVal) => {
    setSelectedVal(newVal);
  };

  return (
    <>
      <Autocomplete
        value={selectedVal}
        options={options}
        onChange={(event, newVal) => handleSelect(newVal)}
        getOptionLabel={(option) =>
          option.title === undefined ? "select workingbase" : option.title
        }
        isOptionEqualToValue={(option, value) => option.value === value.value}
        renderInput={(params) => (
          <TextField {...params} size="small" placeholder="select workingbase" />
        )}
      />
    </>
  );
}

export function SelectWorkingPlace({ selectedVal, setSelectedVal }) {
  const { setAlert } = useContext(AuthContext);
  const [data, setData] = useState([]);
  const [options, setOptions] = useState([]);

  const { data: officeWorkingPlaceData, refetch } = useQuery(
    GET_WORKINGPLACE_WITH_PAGINATION,
    {
      variables: {
        page: 1,
        keyword: "",
        pagination: false,
      },
      onCompleted: ({ getWorkingPlacePagination }) => {
        // console.log("getWorkingPlacePagination::", getWorkingPlacePagination);
        setData(getWorkingPlacePagination?.data);
      },
      onError: (error) => {
        setAlert(true, "error", error?.message);
      },
    }
  );

  useEffect(() => {
    let rows = [];
    if (data?.length !== 0) {
      data?.map((d) => {
        const allRows = {
          id: d?._id,
          title: d?.workingPlaceName,
        };
        rows.push(allRows);
        setOptions(rows);
      });
    }
  }, [data]);

  const handleSelect = (newVal) => {
    setSelectedVal(newVal);
  };

  return (
    <>
      <Autocomplete
        value={selectedVal}
        options={options}
        onChange={(event, newVal) => handleSelect(newVal)}
        getOptionLabel={(option) =>
          option.title === undefined ? "select workingplace" : option.title
        }
        isOptionEqualToValue={(option, value) => option.value === value.value}
        renderInput={(params) => (
          <TextField {...params} size="small" placeholder="select workingplace" />
        )}
      />
    </>
  );
}

export function SelectStaff({ selectedVal, setSelectedVal, className }) {
  const { setAlert } = useContext(AuthContext);
  const [data, setData] = useState([]);
  const [options, setOptions] = useState([]);

  const { data: officeWorkingPlaceData, refetch } = useQuery(
    GET_STAFFINFO_WITH_PAGINATION,
    {
      variables: {
        page: 1,
        keyword: "",
        pagination: false,
        currentSituation: "Working",
        typeOfEmployment: "All",
        sort: []
      },
      onCompleted: ({ getStaffInfoPagination }) => {
        // console.log("getStaffInfoPagination::", getStaffInfoPagination);
        setData(getStaffInfoPagination?.data);
      },
      onError: (error) => {
        setAlert(true, "error", error?.message);
      },
    }
  );

  useEffect(() => {
    let rows = [];
    if (data?.length !== 0) {
      data?.map((d) => {
        const allRows = {
          id: d?._id,
          title: d?.staffProfile?.englishData
            ?.firstNameLatin + " " + d?.staffProfile?.englishData
              ?.lastNameLatin,
        };
        rows.push(allRows);
        setOptions(rows);
      });
    }
  }, [data]);

  const handleSelect = (newVal) => {
    setSelectedVal(newVal);
  };

  return (
    <>
      <Autocomplete
        className={className}
        value={selectedVal}
        options={options}
        onChange={(event, newVal) => handleSelect(newVal)}
        getOptionLabel={(option) =>
          option.title === undefined ? "select staff" : option.title
        }
        isOptionEqualToValue={(option, value) => option.value === value.value}
        renderInput={(params) => (
          <TextField {...params} size="small" placeholder="staff filter" />
        )}
      />
    </>
  );
}

export function SelectStatus({ selectedVal, setSelectedVal, className }) {
  const { setAlert } = useContext(AuthContext);
  const [options, setOptions] = useState(["Pending", "Approved", "Denied"]);

  const handleSelect = (newVal) => {
    setSelectedVal(newVal);
  };

  return (
    <>
      <Autocomplete
        className={className}
        value={selectedVal}
        options={options}
        onChange={(event, newVal) => handleSelect(newVal)}
        getOptionLabel={(option) =>
          option === undefined ? "select status" : option
        }
        isOptionEqualToValue={(option, value) => option.value === value.value}
        renderInput={(params) => (
          <TextField {...params} size="small" placeholder="status filter" />
        )}
      />
    </>
  );
}

export function SelectTakeLeaveType({ selectedVal, setSelectedVal, className }) {
  const { setAlert } = useContext(AuthContext);
  const [options, setOptions] = useState([
    "Compensate Leave",
    "Annual Leave",
    "Sick Leave",
    "Maternity Leave",
    "Paternity Leave",
    "Breastfeeding",
    "Compassionate(wedding) Leave",
    "Compassionate(death) Leave",
    "Unpaid Leave",
  ]);

  const handleSelect = (newVal) => {
    setSelectedVal(newVal);
  };

  return (
    <>
      <Autocomplete
        className={className}
        value={selectedVal}
        options={options}
        onChange={(event, newVal) => handleSelect(newVal)}
        getOptionLabel={(option) =>
          option === undefined ? "select take leave type" : option
        }
        isOptionEqualToValue={(option, value) => option.value === value.value}
        renderInput={(params) => (
          <TextField {...params} size="small" placeholder="take leave type filter" />
        )}
      />
    </>
  );
}
