import React, { useContext, useEffect, useState } from "react";
import "../../../Style/createstyle.scss";
import { useFormik, Form, FormikProvider } from "formik";
import * as Yup from "yup";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import {
  Grid,
  Stack,
  Box,
  TextField,
  Typography,
  Button,
  FormControl,
  Select,
  MenuItem,
  FormHelperText,
} from "@mui/material";
import { useMutation } from "@apollo/client";
import PropTypes from "prop-types";
import LinearProgress from "@mui/material/LinearProgress";
//Icons
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
//date picker
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import moment from "moment";
//Src
import { AuthContext } from "../../../Context/AuthContext";
import { SelectMajor } from "../../../DynamicComp/DynamicSelect";
import {
  CREATE_STAFF_EDUCATION,
  UPDATE_STAFF_EDUCATION,
} from "../../../Schema/StaffEducation";
import FileThumnail from "../../../DynamicComp/FileThumnail";
import ViewDocument from "../Document/ViewDocument";
import EmptyPdfImage from "../../../Assets/empty-image.png";

function LinearProgressWithLabel(props) {
  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <Box sx={{ width: "100%", mr: 1 }}>
        <LinearProgress variant="determinate" {...props} />
      </Box>
      <Box sx={{ minWidth: 35 }}>
        <Typography variant="body2" color="text.secondary">{`${Math.round(
          props.value
        )}%`}</Typography>
      </Box>
    </Box>
  );
}
LinearProgressWithLabel.propTypes = {
  value: PropTypes.number.isRequired,
};

export default function CreateEducation({
  open,
  handleClose,
  dialogTitle,
  staffId,
  editData,
  setRefetch,
}) {
  const { setAlert } = useContext(AuthContext);
  const [loading, setLoading] = useState(false);
  const [majorVal, setMajorVal] = useState({ id: "", title: "" });

  //---------------Progress------------------
  const [statusProgress, setStatusProgress] = React.useState(false);
  const [progress, setProgress] = React.useState(10);
  // console.log("statusProgress", statusProgress);
  // console.log("progress", progress);
  React.useEffect(() => {
    const timer = setInterval(() => {
      setProgress((prevProgress) =>
        prevProgress >= 100 ? 100 : prevProgress + 10
      );
    }, 500);
    return () => {
      clearInterval(timer);
    };
  }, []);

  // upload Image
  const [pdfFile, setPdfFile] = useState(null);
  const [uploadFile, setUploadFile] = useState(null);
  const [documentSrc, setDocumentSrc] = useState("");
  const [openView, setOpenView] = useState(false);
  const handleCloseView = () => {
    setOpenView(false);
    setPdfFile(null);
  };
  const fileType = ["application/pdf"];
  const handleUploadImage = (e) => {
    let fileSelected = e.target.files[0];
    setUploadFile(fileSelected);
    //For thumbnails
    if (fileSelected) {
      if (fileSelected && fileType.includes(fileSelected.type)) {
        let reader = new FileReader();
        reader.readAsDataURL(fileSelected);
        reader.onload = (e) => {
          setPdfFile(e.target.result);
        };
      } else {
        setPdfFile(null);
      }
    } else {
      console.log("Please select file!");
    }
    setOpenView(true);
  };

  //----------------- End -------------------------

  const [createStaffEducation, { data, error }] = useMutation(
    CREATE_STAFF_EDUCATION,
    {
      onCompleted: ({ createStaffEducation }) => {
        setLoading(false);
        if (createStaffEducation?.success === true) {
          setAlert(true, "success", createStaffEducation?.message);
          handleClose();
          resetForm();
          setRefetch();
          setMajorVal({ id: "", title: "" });
          setDocumentSrc("");
          setUploadFile(null);
          setPdfFile(null);
        } else {
          setAlert(true, "error", createStaffEducation?.message);
        }
      },
      onError: (error) => {
        setLoading(false);
        setAlert(true, "error", error?.message);
      },
    }
  );

  const [updateStaffEducation] = useMutation(UPDATE_STAFF_EDUCATION, {
    onCompleted: ({ updateStaffEducation }) => {
      // console.log(updateStaffEducation);
      setLoading(false);
      if (updateStaffEducation?.success === true) {
        setAlert(true, "success", updateStaffEducation?.message);
        handleClose();
        setRefetch();
        setDocumentSrc("");
        setUploadFile(null);
        setPdfFile(null);
      } else {
        setAlert(true, "error", updateStaffEducation?.message);
      }
    },
    onError: (error) => {
      setLoading(false);
      setAlert(true, "error", error?.message);
    },
  });

  //fromik
  const CreateStaffEducation = Yup.object().shape({
    universityName: Yup.string().required("require!"),
    level: Yup.string().required("require!"),
    major: Yup.string().required("require!"),
    graduactionDate: Yup.date().required("require!"),
    remark: Yup.string(),
  });

  const formik = useFormik({
    initialValues: {
      universityName: "",
      level: "Bachelor Degree",
      major: "",
      graduactionDate: moment(),
      remark: "",
    },

    validationSchema: CreateStaffEducation,
    onSubmit: async (values) => {
      setLoading(true);
      // console.log("values:::", values, staffId);
      if (dialogTitle !== "Create") {
        updateStaffEducation({
          variables: {
            id: editData?._id,
            input: {
              fileUpload: documentSrc ? documentSrc : "",
              ...values,
            },
          },
        });
      } else {
        setLoading(true);
        createStaffEducation({
          variables: {
            staffInfoId: staffId,
            input: {
              fileUpload: documentSrc,
              ...values,
            },
          },
        });
      }
    },
  });

  const {
    errors,
    touched,
    handleSubmit,
    getFieldProps,
    setFieldValue,
    values,
    resetForm,
  } = formik;

  // console.log("editData::", editData);

  useEffect(() => {
    if (editData) {
      setDocumentSrc(editData?.fileUpload);
      setFieldValue("universityName", editData?.universityName);
      setFieldValue("level", editData?.level);
      setFieldValue("major", editData?.major?._id);
      setMajorVal({
        id: editData?.major?._id,
        title: editData?.major?.majorName,
      });
      setFieldValue("graduactionDate", editData?.graduactionDate);
      setFieldValue(
        "remark",
        editData?.remark === null ? "" : editData?.remark
      );
    }
  }, [editData]);

  useEffect(() => {
    if (majorVal?.id !== undefined) {
      setFieldValue("major", majorVal?.id);
    } else {
      setFieldValue("major", "");
    }
  }, [majorVal?.id]);

  return (
    <Dialog open={open} className="create-container">
      <DialogTitle sx={{ padding: "10px 20px" }}>
        <Stack direction="row" spacing={2}>
          <Stack direction="column" justifyContent="center">
            <Typography className="dialog-title">
              {dialogTitle} Staff's Education
            </Typography>
          </Stack>

          <Box sx={{ flexGrow: 1 }} />
          <IconButton onClick={handleClose}>
            <CloseIcon className="close-icon" />
          </IconButton>
        </Stack>
      </DialogTitle>

      <DialogContent dividers>
        <FormikProvider value={formik}>
          <Form noValidate autoComplete="off" onSubmit={handleSubmit}>
            <Grid container rowSpacing={2} columnSpacing={4}>
              <Grid item xs={12}>
                <Stack direction="column" spacing={1}>
                  <Stack direction="row" justifyContent="center">
                    {/* =============================== For view outside​ =================================  */}
                    {!openView ? (
                      <Button component="label">
                        <TextField
                          type="file"
                          id="image"
                          sx={{ display: "none" }}
                          onChange={handleUploadImage}
                        />
                        {editData?.fileUpload ? (
                          <FileThumnail pdfFile={editData?.fileUpload} />
                        ) : (
                          <>
                            {uploadFile ? (
                              <FileThumnail pdfFile={pdfFile} />
                            ) : (
                              <img
                                style={{ width: "200px", height: "200px" }}
                                src={EmptyPdfImage}
                              />
                            )}
                          </>
                        )}
                        <input type="file" hidden />
                      </Button>
                    ) : (
                      <ViewDocument
                        setProgress={setProgress}
                        setStatusProgress={setStatusProgress}
                        open={openView}
                        setOpenView={setOpenView}
                        handleClose={handleCloseView}
                        pdfFile={pdfFile}
                        uploadFile={uploadFile}
                        setUploadFile={setUploadFile}
                        setDocumentSrc={setDocumentSrc}
                      />
                    )}
                  </Stack>

                  <Stack
                    direction="row"
                    justifyContent="center"
                    spacing={2}
                    sx={{ mt: 1 }}
                  >
                    {statusProgress && progress < 100 ? (
                      <Box sx={{ width: "100%", marginLeft: "20px" }}>
                        <LinearProgressWithLabel value={progress} />
                      </Box>
                    ) : (
                      <Typography className="text-En">Upload pdf</Typography>
                    )}
                  </Stack>
                </Stack>
              </Grid>

              <Grid item xs={6}>
                <Typography className="field-title">University</Typography>
                <TextField
                  fullWidth
                  size="small"
                  className="text-field"
                  placeholder="university"
                  {...getFieldProps("universityName")}
                  error={Boolean(
                    touched.universityName && errors.universityName
                  )}
                  helperText={touched.universityName && errors.universityName}
                />
              </Grid>

              <Grid item xs={6}>
                <Typography className="field-title">Level</Typography>
                <FormControl size="small" fullWidth className="text-field">
                  <Select
                    labelId="demo-select-small"
                    id="demo-select-small"
                    value={values?.level}
                    onChange={(event) => {
                      setFieldValue("level", event.target.value);
                    }}
                    error={Boolean(touched.level && errors.level)}
                    helperText={touched.level && errors.level}
                  >
                    <MenuItem value={"High School"}>High School</MenuItem>
                    <MenuItem value={"Bachelor Degree"}>
                      Bachelor Degree
                    </MenuItem>
                    <MenuItem value={"Master Degree"}>Master Degree</MenuItem>
                    <MenuItem value={"Doctor Philosophy"}>
                      Doctor Philosophy
                    </MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={6}>
                <Typography className="field-title">Major</Typography>
                <SelectMajor
                  selectedVal={majorVal}
                  setSelectedVal={setMajorVal}
                />
                {!!errors.major && touched.major && (
                  <FormHelperText error id="outlined-adornment-password">
                    {errors.major}
                  </FormHelperText>
                )}
              </Grid>
              <Grid item xs={6}>
                <Typography className="field-title">Gratuation Date</Typography>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                    value={values?.graduactionDate}
                    onChange={(e) => setFieldValue("graduactionDate", e)}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        className="text-field"
                        size="small"
                        fullWidth
                      />
                    )}
                  />
                </LocalizationProvider>
              </Grid>
              <Grid item xs={12}>
                <Typography className="field-title">Remark</Typography>
                <TextField
                  fullWidth
                  size="small"
                  className="text-field"
                  multiline
                  placeholder="remark"
                  {...getFieldProps("remark")}
                  error={Boolean(touched.remark && errors.remark)}
                  helperText={touched.remark && errors.remark}
                />
              </Grid>
            </Grid>
          </Form>
        </FormikProvider>
      </DialogContent>

      <DialogActions>
        <Stack direction="row" spacing={4} className="action-container">
          <Button className="btn-cencel" fullWidth onClick={handleClose}>
            Cencel
          </Button>

          {loading ? (
            <Button className="btn-create" fullWidth>
              Loading...
            </Button>
          ) : (
            <Button className="btn-create" fullWidth onClick={handleSubmit}>
              {dialogTitle}
            </Button>
          )}
        </Stack>
      </DialogActions>
    </Dialog>
  );
}
