export const getWorkYearDate = function (time) {
    var validUntilDate;
    var fromDate;

    let date = new Date()
    const lastDayOfAug = new Date(date.getFullYear(), 8, 0).getDate()
 
    if(date.getMonth()+1 < 9){
        fromDate = new Date(`${date.getFullYear()-1}-09-01T00:00:00.000Z`)
        validUntilDate = new Date(`${date.getFullYear()}-08-${lastDayOfAug}T16:59:59.999Z`)
    }else if(date.getMonth()+1 >= 9){
        fromDate = new Date(`${date.getFullYear()}-09-01T00:00:00.000Z`)
        validUntilDate = new Date(`${date.getFullYear()+1}-08-${lastDayOfAug}T16:59:59.999Z`)
    }

    if(time === 'start'){
        return fromDate
    }else if(time === 'end'){
        return validUntilDate
    }
  };