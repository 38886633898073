import React from 'react'
import { Box, Grid, Stack, Typography } from "@mui/material";
//Srcs
import TakeLeaveCard from "./UserDashboard/TakeLeaveCard";
import LastOvertime from "./AdminDashboard/LastOvertime.jsx";
import LastTakeleave from "./AdminDashboard/LastTakeleave.jsx";
import TakeLeavePaymentTable from './UserDashboard/TakeLeavePaymentTable';


function UserDashboard() {
  return (
    <Box sx={{ flexGrow: 1, marginTop: "20px" }}>
      <Grid container spacing={3}>
        <Grid item xs={12} >
          <TakeLeaveCard />
        </Grid>
        <Grid item xs={12} lg={12} xl={12}>
          <TakeLeavePaymentTable />
        </Grid>
        <Grid item xs={12} md={6} lg={6} xl={6}>
          <LastTakeleave />
        </Grid>
        <Grid item xs={12} md={6} xl={6}>
          <LastOvertime />
        </Grid>
      </Grid>
    </Box>
  )
}

export default UserDashboard