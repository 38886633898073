import { gql } from "@apollo/client";


export const GET_TOTAL_DEPARTMENT = gql`
  query Query {
    totalDepartment
  }
`;

export const GET_TOTAL_STAFF = gql`
  query Query {
    totalStaff
  }
`;

export const GET_TOTAL_DRIVERLICENCE = gql`
  query Query {
    totalStaffHaveDrivingLicense
  }
`;

export const GET_TOTAL_COVID_VACCINATION = gql`
  query Query {
    totalStaffCovidVaccinated
  }
`;

export const GET_PERSONAL_TAKELEAVE_CHART = gql`
  query GetPersonalTakeLeaveChart($userId: ID!) {
    getPersonalTakeLeaveChart(userId: $userId) {
      totalRemaining
      totalUsed
    }
  }
`;

export const GET_LAST_TAKELEAVE = gql`
  query LastTakeLeave($userId: ID!) {
    lastTakeLeave(userId: $userId) {
      approvedOrDeniedBy {
        _id
        staffProfile {
          imageName
          imageSrc
          englishData {
            firstNameLatin
            lastNameLatin
          }
        }
      }
      approvedOrDeniedDate
      createdAt
      createdBy {
        _id
        staffProfile {
          imageName
          imageSrc
          englishData {
            firstNameLatin
            lastNameLatin
          }
        }
      }
      startDate
      endDate
      status
      totalLeaveAsDay
    }
  }
`;

export const GET_LAST_OVERTIME = gql`
  query LastOvertime($userId: ID!) {
  lastOvertime(userId: $userId) {
    approvedOrDeniedBy {
      _id
      staffProfile {
        imageName
        imageSrc
        englishData {
          firstNameLatin
          lastNameLatin
        }
      }
    }
    createdAt
    createdBy {
      _id
      staffProfile {
        imageName
        imageSrc
        englishData {
          firstNameLatin
          lastNameLatin
        }
      }
    }
    endDate
    endTime
    startDate
    startTime
    status
    totalOvertimeAsHours
  }
}
`;

export const GET_STAFF_STATISTICS = gql`
  query Query {
    staffStatistic {
      series
      label
    }
  }
`;

export const GET_TAKE_LEAVE_PAYMENT_DASHBOARD = gql`
query GetTakeLeavePaymentDashboard($staffId: ID!) {
  getTakeLeavePaymentDashboard(staffId: $staffId) {
    takeLeaveType
    lastYearRemainQty
    currentYearQty
    UsedQty
  }
}
`;