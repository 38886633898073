import React, { useState, useContext, useRef, useEffect } from "react";
import "../Style/pagestyle.scss";
import {
  Typography,
  Stack,
  Box,
  Button,
  Grid,
  TableContainer,
  Table,
  TableRow,
  TableCell,
  TableBody,
  Pagination,
  TableHead,
  TextField,
  InputAdornment,
  Select,
  MenuItem,
  Avatar,
  FormControl,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { SiMicrosoftexcel } from "react-icons/si";
import LibraryAddOutlinedIcon from "@mui/icons-material/LibraryAddOutlined";
import { useQuery } from "@apollo/client";
import { useNavigate } from "react-router-dom";
import { useDownloadExcel } from "react-export-table-to-excel";
import moment from "moment/moment";
//src
import { AuthContext } from "../Context/AuthContext";
import EmptyData from "../Components/Include/EmptyData";
import LoadingPage from "../Components/Include/LoadingPage";
import { GET_STAFFINFO_WITH_PAGINATION } from "../Schema/StaffInformation";
import StaffInfoAction from "../Components/StaffInformation/StaffInfoAction";
import EastOutlinedIcon from "@mui/icons-material/EastOutlined";

export default function StaffInformation() {
  const [loading, setLoading] = useState(true);
  const { setAlert } = useContext(AuthContext);
  const navigate = useNavigate();
  const [checkExport, setCheckExport] = useState(false);

  //Export to excel
  const tableRef = useRef(null);
  const { onDownload } = useDownloadExcel({
    currentTableRef: tableRef.current,
    filename: "Staff-Information-Table",
    sheet: "Staff-Information",
  });
  const handleExportExcel = () => {
    setCheckExport(true);
    setTimeout(() => {
      onDownload();
      setCheckExport(false);
    }, 2000);
  };

  //query
  const [tableData, setTableData] = useState([]);
  const [paginator, setPaginator] = useState([]);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(null);
  const [keyword, setKeyword] = useState("");
  const [currentSituation, setCurrentSituation] = useState("Working");
  const [staffType, setStaffType] = useState("All");
  // console.log(staffType, "staffType");
  //@Sort Field
  const [sortLatinName, setSortLatinName] = useState(1);

  const { refetch } = useQuery(GET_STAFFINFO_WITH_PAGINATION, {
    variables: {
      page: page,
      limit: limit,
      keyword: keyword,
      pagination: true,
      currentSituation: currentSituation,
      typeOfEmployment: staffType,
      sort: [
        {
          fieldName: "staffProfile.englishData.firstNameLatin",
          sortValue: sortLatinName,
        },
        // {
        //   fieldName:"createdAt",
        //   sortValue: -1
        // }
      ],
    },
    onCompleted: ({ getStaffInfoPagination }) => {
      setTableData(getStaffInfoPagination?.data);
      setPaginator(getStaffInfoPagination?.paginator);
      setLoading(false);

      //for All/Page Default
      if (limit === null) {
        setLimit(getStaffInfoPagination?.paginator?.totalDocs);
      }
    },
    onError: (error) => {
      setAlert(true, "error", error?.message);
      // console.log(error)
    },
  });

  //@ When search filter and sort change the value, in fetching data not yet found shows the loading instead
  // useEffect(()=>{setLoading(true);}, [keyword])
  // useEffect(()=>{setLoading(true);}, [staffType])
  // useEffect(()=>{setLoading(true);}, [currentSituation])
  // useEffect(()=>{setLoading(true);}, [staffType])

  useEffect(() => {
    // setLoading(true);
    refetch();
  }, [keyword, staffType, currentSituation, staffType, sortLatinName]);

  //Functiuon
  const handleDetails = (staffId) => {
    navigate(`/staff-info/staff-details?staffId=${staffId}`);
  };

  const handleLimit = (e) => {
    setLimit(e.target.value);
  };

  //===================== Resize width Screen=====================
  const [width, setWidth] = React.useState(window.innerWidth);
  const updateDimensions = () => {
    setWidth(window.innerWidth - 50);
  };
  React.useEffect(() => {
    window.addEventListener("resize", updateDimensions);
    return () => window.removeEventListener("resize", updateDimensions);
  }, []);

  // console.log(tableData, "tableData")

  return (
    <div className="page-container">
      <Stack direction="row" sx={{ height: "50px" }}>
        <Stack direction="column" justifyContent="center">
          <Typography className="page-title">Staff Information</Typography>
        </Stack>
        <Box sx={{ flexGrow: 1 }} />
        <Stack direction="row" spacing={3} justifyContent="right">
          <Stack direction="column" justifyContent="center" spacing={2}>
            <Button
              onClick={handleExportExcel}
              className="btn-add"
              startIcon={<SiMicrosoftexcel className="icon-add" />}
            >
              Export Excel
            </Button>
          </Stack>
          <Stack direction="column" justifyContent="center" spacing={2}>
            <Button
              onClick={() => navigate("/staff-info/create-staff")}
              className="btn-add"
              startIcon={<LibraryAddOutlinedIcon className="icon-add" />}
            >
              Create
            </Button>
          </Stack>
        </Stack>
      </Stack>

      <Grid item container spacing={2} sx={{ marginTop: "10px" }}>
        <Grid item xs={6} md={4} lg={2.4}>
          <TextField
            placeholder="search"
            size="small"
            className="text-field"
            onChange={(e) => setKeyword(e.target.value)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start" sx={{ mr: 1 }}>
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />
        </Grid>
        <Grid item xs={6} md={4} lg={2.4}>
          <FormControl fullWidth className="text-field" size="small">
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={currentSituation}
              onChange={(e) => setCurrentSituation(e.target.value)}
            >
              <MenuItem value={"Working"}>Working</MenuItem>
              <MenuItem value={"Stop"}>Stop</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={6} md={4} lg={2.4}>
          <FormControl fullWidth className="text-field" size="small">
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={staffType}
              onChange={(e) => setStaffType(e.target.value)}
            >
              <MenuItem value={"All"}>All</MenuItem>
              <MenuItem value={"Board of Directors"}>
                Board of Directors
              </MenuItem>
              <MenuItem value={"Total Staff"}>Total Staff</MenuItem>
              <MenuItem value={"Full Time"}>Full Time</MenuItem>
              <MenuItem value={"Part Time"}>Part Time</MenuItem>
              <MenuItem value={"Probation"}>Probation</MenuItem>
              <MenuItem value={"Volunteer"}>Volunteer</MenuItem>
              <MenuItem value={"Internship"}>Internship</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={6} md={4} lg={2.4}>
          <FormControl fullWidth className="text-field" size="small">
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={sortLatinName}
              onChange={(e) => setSortLatinName(e.target.value)}
            >
              <MenuItem value={1}>
                Latin name: A{" "}
                {
                  <>
                    {" "}
                    <EastOutlinedIcon style={{ fontSize: "13px" }} />{" "}
                  </>
                }{" "}
                Z
              </MenuItem>
              <MenuItem value={-1}>
                Latin name: Z{" "}
                {
                  <>
                    {" "}
                    <EastOutlinedIcon style={{ fontSize: "13px" }} />{" "}
                  </>
                }{" "}
                A
              </MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={6} md={4} lg={2.4}>
          <Stack
            direction={"row"}
            spacing={{ xs: 2 }}
            style={{ paddingTop: "9px" }}
          >
            <Stack direction={"row"} spacing={{ xs: 1 }}>
              <Typography>Total: </Typography>
              <Typography style={{ fontWeight: "bold" }}>
                {
                  tableData.filter((e) => e._id !== "641877d7c75cf00e0fc814f1")
                    ?.length
                }
                ,
              </Typography>
            </Stack>
            <Stack direction={"row"} spacing={{ xs: 1 }}>
              <Typography>Female: </Typography>
              <Typography style={{ fontWeight: "bold" }}>
                {
                  tableData?.filter(
                    (e) => e.staffProfile.englishData.gender === "Female"
                  )?.length
                }
              </Typography>
            </Stack>
          </Stack>
        </Grid>
        <Grid item xs={3}></Grid>
      </Grid>

      <Box className="body-container">
        <TableContainer sx={{ maxWidth: `${width}px` }}>
          <Table className="table" aria-label="simple table" ref={tableRef}>
            <TableHead className="header-row ">
              <TableCell className="header-title-start">N°</TableCell>
              <TableCell
                className="header-title header-sticky-left"
                sx={{ minWidth: "300px" }}
              >
                Latin Name
              </TableCell>
              <TableCell
                className="header-title khmer-name"
                sx={{ minWidth: "200px" }}
              >
                ឈ្មោះខ្មែរ
              </TableCell>
              <TableCell className="header-title" sx={{ minWidth: "90px" }}>
                Gender
              </TableCell>
              <TableCell className="header-title" sx={{ minWidth: "200px" }}>
                Position
              </TableCell>
              <TableCell
                className="header-title khmer-name"
                sx={{ minWidth: "200px" }}
              >
                តួនាទី
              </TableCell>
              <TableCell className="header-title" sx={{ minWidth: "250px" }}>
                Email
              </TableCell>
              <TableCell className="header-title" sx={{ minWidth: "150px" }}>
                Phone
              </TableCell>
              <TableCell className="header-title">Nationlity</TableCell>
              <TableCell className="header-title" sx={{ minWidth: "150px" }}>
                Working Base
              </TableCell>
              <TableCell className="header-title" sx={{ minWidth: "150px" }}>
                Office Base
              </TableCell>
              <TableCell className="header-title" sx={{ minWidth: "250px" }}>
                Department
              </TableCell>
              <TableCell className="header-title" sx={{ minWidth: "150px" }}>
                Date Of Birth
              </TableCell>
              <TableCell className="header-title" sx={{ minWidth: "90px" }}>
                Age
              </TableCell>
              <TableCell className="header-title" sx={{ minWidth: "150px" }}>
                Marital Status
              </TableCell>
              <TableCell className="header-title" sx={{ minWidth: "300px" }}>
                Place of Birth
              </TableCell>
              {/* <TableCell className="header-title" sx={{ minWidth: "300px" }}>
                Permanance Address
              </TableCell>
              <TableCell className="header-title" sx={{ minWidth: "300px" }}>
                Temporary Address
              </TableCell> */}
              <TableCell className="header-title" sx={{ minWidth: "150px" }}>
                Started Date
              </TableCell>
              <TableCell className="header-title" sx={{ minWidth: "120px" }}>
                Work Age
              </TableCell>
              <TableCell className="header-title" sx={{ minWidth: "150px" }}>
                Identity Card
              </TableCell>
              <TableCell className="header-title" sx={{ minWidth: "150px" }}>
                Passports Number
              </TableCell>
              <TableCell className="header-title" sx={{ minWidth: "150px" }}>
                Working Place
              </TableCell>
              <TableCell className="header-title">Type of Employment</TableCell>
              <TableCell
                className="header-title-end header-sticky-right"
                align="right"
              >
                Action
              </TableCell>
            </TableHead>
            {loading ? (
              <TableBody className="body">
                <TableRow className="body-row-gray">
                  <TableCell className="body-cell" colSpan={8}>
                    <LoadingPage />
                  </TableCell>
                </TableRow>
              </TableBody>
            ) : (
              <TableBody className="body">
                {tableData?.length === 0 ? (
                  <TableRow className="body-row-gray">
                    <TableCell className="body-cell" colSpan={8}>
                      <EmptyData />
                    </TableCell>
                  </TableRow>
                ) : (
                  <>
                    {tableData?.map((row, index) => {
                      return (
                        <TableRow
                          className={
                            (index + 1) % 2 === 0 ? "body-row" : "body-row-gray"
                          }
                          key={index}
                        >
                          <TableCell
                            className="body-cell-start"
                            onClick={() => handleDetails(row?._id)}
                          >
                            {index + paginator?.slNo}
                          </TableCell>
                          <TableCell
                            className="body-cell cell-sticky-left"
                            onClick={() => handleDetails(row?._id)}
                          >
                            <Stack direction="row" spacing={1}>
                              {checkExport ? null : (
                                <Avatar
                                  alt="Staff"
                                  src={row?.staffProfile?.imageSrc}
                                />
                              )}

                              <Stack direction="column" justifyContent="center">
                                <Typography>
                                  {row?.staffProfile?.englishData
                                    ?.firstNameLatin +
                                    " " +
                                    row?.staffProfile?.englishData
                                      ?.lastNameLatin}
                                </Typography>
                              </Stack>
                            </Stack>
                          </TableCell>
                          <TableCell
                            className="body-cell khmer-name"
                            onClick={() => handleDetails(row?._id)}
                          >
                            {row?.staffProfile?.khmerData?.lastNameKh +
                              " " +
                              row?.staffProfile?.khmerData?.firstNameKh}
                          </TableCell>
                          <TableCell
                            className="body-cell"
                            onClick={() => handleDetails(row?._id)}
                          >
                            {row?.staffProfile?.englishData?.gender}
                          </TableCell>
                          <TableCell
                            className="body-cell"
                            onClick={() => handleDetails(row?._id)}
                          >
                            {row?.currentContract?.positions?.positionName}
                          </TableCell>
                          <TableCell
                            className="body-cell"
                            onClick={() => handleDetails(row?._id)}
                          >
                            {row?.currentContract?.positions?.positionNameKhmer}
                          </TableCell>
                          <TableCell
                            className="body-cell"
                            onClick={() => handleDetails(row?._id)}
                          >
                            {row?.systemAccessData?.emailAddress}
                          </TableCell>
                          <TableCell
                            className="body-cell"
                            onClick={() => handleDetails(row?._id)}
                          >
                            {row?.staffProfile?.englishData?.phoneNumberEng}
                          </TableCell>
                          <TableCell
                            className="body-cell"
                            onClick={() => handleDetails(row?._id)}
                          >
                            {row?.staffProfile?.englishData?.nationalityEng}
                          </TableCell>
                          <TableCell
                            className="body-cell"
                            onClick={() => handleDetails(row?._id)}
                          >
                            {row?.currentContract?.workingBase?.workingBaseName}
                          </TableCell>
                          <TableCell
                            className="body-cell"
                            onClick={() => handleDetails(row?._id)}
                          >
                            {row?.currentContract?.officeBase?.officeBaseName}
                          </TableCell>
                          <TableCell
                            className="body-cell"
                            onClick={() => handleDetails(row?._id)}
                          >
                            {
                              row?.currentContract?.positions?.inDepartment
                                ?.departmentName
                            }
                          </TableCell>
                          <TableCell
                            className="body-cell"
                            onClick={() => handleDetails(row?._id)}
                          >
                            {moment(
                              row?.staffProfile?.englishData?.dateOfBirthEng
                            ).format("DD-MM-YYYY")}
                          </TableCell>
                          <TableCell
                            className="body-cell"
                            onClick={() => handleDetails(row?._id)}
                          >
                            {moment().diff(
                              moment(
                                row?.staffProfile?.englishData?.dateOfBirthEng
                              ),
                              "years"
                            )}
                          </TableCell>
                          <TableCell
                            className="body-cell"
                            onClick={() => handleDetails(row?._id)}
                          >
                            {row?.staffProfile?.englishData?.maritalStatusEng}
                          </TableCell>
                          <TableCell
                            className="body-cell"
                            onClick={() => handleDetails(row?._id)}
                          >
                            {
                              row?.staffProfile?.englishData?.placeOfBirthEng
                                ?.villageEng
                            }
                          </TableCell>
                          {/* <TableCell
                            className="body-cell"
                            onClick={() => handleDetails(row?._id)}
                          >
                            {
                              row?.staffProfile?.englishData
                                ?.permananceAddressEng?.villageEng
                            }
                          </TableCell>
                          <TableCell
                            className="body-cell"
                            onClick={() => handleDetails(row?._id)}
                          >
                            {
                              row?.staffProfile?.englishData
                                ?.temporaryAddressEng?.villageEng
                            }
                          </TableCell> */}
                          <TableCell
                            className="body-cell"
                            onClick={() => handleDetails(row?._id)}
                          >
                            {moment(row?.startedDate).format("DD-MMM-YYYY")}
                          </TableCell>
                          <TableCell
                            className="body-cell"
                            onClick={() => handleDetails(row?._id)}
                          >
                            {moment().diff(moment(row?.startedDate), "years")}
                          </TableCell>
                          <TableCell
                            className="body-cell"
                            onClick={() => handleDetails(row?._id)}
                          >
                            {row?.staffProfile?.englishData?.identityCardEng}
                          </TableCell>
                          <TableCell
                            className="body-cell"
                            onClick={() => handleDetails(row?._id)}
                          >
                            {row?.staffProfile?.englishData?.passportsNumerEng}
                          </TableCell>
                          <TableCell
                            className="body-cell"
                            onClick={() => handleDetails(row?._id)}
                          >
                            {
                              row?.currentContract?.workingPlace
                                ?.workingPlaceName
                            }
                          </TableCell>
                          <TableCell
                            className="body-cell"
                            onClick={() => handleDetails(row?._id)}
                          >
                            {row?.currentContract?.typeOfEmployment}
                          </TableCell>

                          <TableCell
                            className="body-cell-end cell-sticky-right"
                            align="right"
                          >
                            <StaffInfoAction
                              editData={row}
                              setRefetch={refetch}
                            />
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </>
                )}
              </TableBody>
            )}
          </Table>
        </TableContainer>

        <Stack
          direction="row"
          justifyContent="right"
          spacing={2}
          sx={{ margin: "20px 30px 0px 0px" }}
        >
          <Stack direction="column" justifyContent="center">
            <Pagination
              hideNextButton={false}
              hidePrevButton={false}
              count={paginator?.totalPages}
              color="primary"
              variant="outlined"
              onChange={(event, pageNumber) => setPage(parseInt(pageNumber))}
            />
          </Stack>
          <Select
            size="small"
            value={limit}
            onChange={handleLimit}
            defaultValue="All/Page"
          >
            <MenuItem value={10}>10/Page</MenuItem>
            <MenuItem value={30}>30/Page</MenuItem>
            <MenuItem value={60}>60/Page</MenuItem>
            <MenuItem value={paginator?.totalDocs}>All/Page</MenuItem>
          </Select>
        </Stack>
      </Box>
    </div>
  );
}
