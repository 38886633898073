import React, { useContext, useEffect, useState } from "react";
import "../../Style/createstyle.scss";
import { useFormik, Form, FormikProvider } from "formik";
import * as Yup from "yup";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import {
  Grid,
  Stack,
  Box,
  TextField,
  Typography,
  Button,
  FormControl,
  Select,
  MenuItem,
  FormHelperText
} from "@mui/material";
import { useMutation, useQuery } from "@apollo/client";
//date picker
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import moment from "moment";
//Icons
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
//Src
import { AuthContext } from "../../Context/AuthContext";
import { SelectStaff } from "../../DynamicComp/DynamicSelect";
import { ADJUST_TAKE_LEAVE, UPDATE_TAKELEAVE, GET_PAYMENT_OF_TAKELEAVE_QTY } from "../../Schema/TakeLeave";
import { GET_TAKELEAVE_TYPE_BY_USERID } from "../../Schema/TakeLeave";
import { CottageSharp } from "@mui/icons-material";
import {getWorkYearDate} from "../../Function/GetWorkYearDate"

export default function CreateAdjustmentTakeleave({
  open,
  handleClose,
  dialogTitle,
  editData,
  setRefetch,
}) {
  const { setAlert } = useContext(AuthContext);
  const [loading, setLoading] = useState(false);
  const [takeleaveTypes, setTakeleaveTypes] = useState([]);
  const [staffVal, setStaffVal] = useState({ id: "", title: "" });
  const [paymentOfTakeLeaveQuantity, setPaymentOfTakeLeaveQuantity] = useState([]);
  const [takeLeaveTypeSelected, setTakeLeaveTypeSelected] = useState(null);

  let userLogin = JSON.parse(window.localStorage.getItem("userLogin"));

  const [adjustTakeLeave, { data, error }] = useMutation(ADJUST_TAKE_LEAVE, {
    onCompleted: ({ adjustTakeLeave }) => {
      // console.log(adjustTakeLeave);
      setLoading(false);
      if (adjustTakeLeave?.success === true) {
        setAlert(true, "success", adjustTakeLeave?.message);
        handleClose();
        resetForm();
        setRefetch();

        setStaffVal({ id: "", title: "" })
      } else {
        setAlert(true, "error", adjustTakeLeave?.message);
      }
    },
    onError: (error) => {
      setLoading(false);
      setAlert(true, "error", error?.message);
    },
  });

  const [updateTakeLeave] = useMutation(UPDATE_TAKELEAVE, {
    onCompleted: ({ updateTakeLeave }) => {
      setLoading(false);
      if (updateTakeLeave?.success === true) {
        setAlert(true, "success", updateTakeLeave?.message);
        handleClose();
        setRefetch();
      } else {
        setAlert(true, "error", updateTakeLeave?.message);
      }
    },
    onError: (error) => {
      setLoading(false);
      setAlert(true, "error", error?.message);
    },
  });

  //fromik
  const CreatePostions = Yup.object().shape({
    createdBy: Yup.string().required("require!"),
    takeLeaveType: Yup.string().required("require!"),
    fromDate: Yup.date().required("require!"),
    validUntil: Yup.date().required("require!"),
    lastYearRemainQty: Yup.number().required("require!"),
    currentYearQty: Yup.number().required("require!"),
    UsedQty: Yup.number().required("require!"),
  });
  // console.log(moment(paymentOfTakeLeaveQuantity[0]?.fromDate), "AAAA");
  // console.log(getWorkYearDate('start'), "startDate");
  const formik = useFormik({
    initialValues: {
      createdBy: "",
      takeLeaveType: "",
      fromDate: getWorkYearDate('start'),
      validUntil: getWorkYearDate('end'),
      lastYearRemainQty: 0,
      currentYearQty: 0,
      UsedQty: 0,
    },

    validationSchema: CreatePostions,
    onSubmit: async (values) => {
      // console.log("values:::", values);
      setLoading(true);
      if (dialogTitle === "Create") {
        adjustTakeLeave({
          variables: {
            input: {
              createdBy: staffVal?.id,
              takeLeaveTypeUsed: [
                {
                  takeLeaveType: values?.takeLeaveType,
                  quantityDayLeave: 0,
                }
              ],
              isAdjust: true,
              paymentOfTakeLeaveQuantity: [{
                takeLeaveType: values?.takeLeaveType,
                fromDate: values?.fromDate,
                validUntil: values?.validUntil,
                lastYearRemainQty: values?.lastYearRemainQty,
                currentYearQty: values?.currentYearQty,
                UsedQty: values?.UsedQty,
                paidMonths: null
              }],
            },
          },
        });
      } else {
        updateTakeLeave({
          variables: {
            id: editData?._id,
            input: {
              createdBy: staffVal?.id,
              takeLeaveTypeUsed: [
                {
                  takeLeaveType: values?.takeLeaveType,
                  quantityDayLeave: 0,
                }
              ],
              isAdjust: true,
              paymentOfTakeLeaveQuantity: [{
                takeLeaveType: values?.takeLeaveType,
                fromDate: values?.fromDate,
                validUntil: values?.validUntil,
                lastYearRemainQty: values?.lastYearRemainQty,
                currentYearQty: values?.currentYearQty,
                UsedQty: values?.UsedQty,
                paidMonths: null
              }],
            },
          },
        });
      }
    },
  });

  const {
    errors,
    touched,
    handleSubmit,
    getFieldProps,
    setFieldValue,
    values,
    resetForm,
  } = formik;

  // console.log("editData::", editData);
  // console.log("values::", values);

  const { refetch } = useQuery(GET_TAKELEAVE_TYPE_BY_USERID, {
    variables: {
      userId: staffVal?.id,
    },
    onCompleted: ({ getTakeLeaveTypeByUserId }) => {
      // console.log("getTakeLeaveTypeByUserId:", getTakeLeaveTypeByUserId);
      setTakeleaveTypes(getTakeLeaveTypeByUserId);
    },
    onError: (error) => {
      console.log(error);
    },
  });
  // const { refetch: refetchQty } = useQuery(GET_PAYMENT_OF_TAKELEAVE_QTY, {
  //   variables: {
  //     staffId: staffVal?.id,
  //     takeLeaveType:[values?.takeLeaveType]
  //   },
  //   onCompleted: ({ getPaymentOfTakeLeaveQty }) => {
  //     setPaymentOfTakeLeaveQuantity(getPaymentOfTakeLeaveQty);
  //   },
  //   onError: (error) => {
  //     console.log(error);
  //   },
  // });
  // console.log("paymentOfTakeLeaveQuantity:: ",paymentOfTakeLeaveQuantity[0]);

  useEffect(() => {
    refetch();
    setFieldValue("takeLeaveType", "");
  }, [staffVal?.id]);
  // console.log("takeLeaveTypeSelected::", takeLeaveTypeSelected);
 
  // useEffect(() => {
  //     // if (takeLeaveTypeSelected !== '') {
  //     //   setFieldValue("fromDate", moment(paymentOfTakeLeaveQuantity[0]?.fromDate));
  //     //   // setFieldValue("validUntil", moment(paymentOfTakeLeaveQuantity[0]?.validUntil));
  //     // } else {
  //     //   setFieldValue("fromDate", moment());
  //     //   // setFieldValue("validUntil", moment());
  //     // }
  //     if(takeLeaveTypeSelected){
  //       setFieldValue(
  //         "lastYearRemainQty",
  //         paymentOfTakeLeaveQuantity[0]?.lastYearRemainQty
  //       );
  //       setFieldValue(
  //         "currentYearQty",
  //         paymentOfTakeLeaveQuantity[0]?.currentYearQty
  //       );
  //       setFieldValue(
  //         "UsedQty",
  //         paymentOfTakeLeaveQuantity[0]?.UsedQty
  //       );
  //     }
  //   }, [takeLeaveTypeSelected]);

  useEffect(() => {
    if (staffVal?.id !== "") {
      setFieldValue("createdBy", staffVal?.id);
    } else {
      setFieldValue("createdBy", "");
    }
  }, [staffVal?.id]);
  // console.log(editData?.paymentOfTakeLeaveQuantity[0], "editData");
  useEffect(() => {
    if (editData) {
      
      setFieldValue("createdBy", editData?.createdBy?._id);
      setStaffVal({
        id: editData?.createdBy?._id,
        title:
          editData?.createdBy?.staffProfile?.englishData?.firstNameLatin +
          " " +
          editData?.createdBy?.staffProfile?.englishData?.lastNameLatin,
      });
      setFieldValue("takeLeaveType", editData?.takeLeaveTypeUsed[0]?.takeLeaveType);

      setFieldValue(
        "fromDate",
        editData?.paymentOfTakeLeaveQuantity[0]?.fromDate
      );
      setFieldValue(
        "validUntil",
        editData?.paymentOfTakeLeaveQuantity[0]?.validUntil
      );
      setFieldValue(
        "lastYearRemainQty",
        editData?.paymentOfTakeLeaveQuantity[0]?.lastYearRemainQty
      );
      setFieldValue(
        "currentYearQty",
        editData?.paymentOfTakeLeaveQuantity[0]?.currentYearQty
      );
      setFieldValue(
        "UsedQty",
        editData?.paymentOfTakeLeaveQuantity[0]?.UsedQty
      );
    }
  }, [editData]);

  return (
    <Dialog open={open} className="create-container" fullWidth maxWidth="sm">
      <DialogTitle>
        <Stack direction="row" spacing={2}>
          <Stack direction="column" justifyContent="center">
            <Typography className="dialog-title">
              {dialogTitle} Adjustment Takeleave
            </Typography>
          </Stack>

          <Box sx={{ flexGrow: 1 }} />
          <IconButton onClick={handleClose}>
            <CloseIcon className="close-icon" />
          </IconButton>
        </Stack>
      </DialogTitle>

      <DialogContent dividers>
        <FormikProvider value={formik}>
          <Form noValidate autoComplete="off" onSubmit={handleSubmit}>
            <Grid container rowSpacing={2} columnSpacing={3}>
              <Grid item xs={6}>
                <Typography className="field-title">Staff</Typography>
                <SelectStaff
                  selectedVal={staffVal}
                  setSelectedVal={setStaffVal}
                />
                {!!errors.createdBy && touched.createdBy && (
                  <FormHelperText error id="outlined-adornment-password">
                    {errors.createdBy}
                  </FormHelperText>
                )}
              </Grid>
              <Grid item xs={6}>
                <FormControl size="small" className="text-field" fullWidth>
                  <Typography className="field-title">
                    Take leave type
                  </Typography>
                  <Select
                    labelId="demo-select-small"
                    id="demo-select-small"
                    value={values?.takeLeaveType}
                    // placeholder="hello"
                    onChange={(event) => {
                      // setTakeLeaveTypeSelected(event.target.value)
                      setFieldValue("takeLeaveType", event.target.value);
                      
                    }}
                    error={Boolean(
                      touched.takeLeaveType && errors.takeLeaveType
                    )}
                    helperText={touched.takeLeaveType && errors.takeLeaveType}
                  >
                    {takeleaveTypes?.map((type) => {
                      return (
                        <MenuItem key={type} value={type}>
                          {type}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
                {!!errors.takeLeaveType && touched.takeLeaveType && (
                  <FormHelperText error id="outlined-adornment-password">
                    {errors.takeLeaveType}
                  </FormHelperText>
                )}
              </Grid>

              <Grid item xs={6}>
                <Typography className="field-title">From date</Typography>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                    value={values?.fromDate}
                    onChange={(e) => setFieldValue("fromDate", e)}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        className="text-field"
                        size="small"
                        fullWidth
                      />
                    )}
                  />
                </LocalizationProvider>
              </Grid>

              <Grid item xs={6}>
                <Typography className="field-title">Valid date</Typography>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                    value={values?.validUntil}
                    onChange={(e) => setFieldValue("validUntil", e)}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        className="text-field"
                        size="small"
                        fullWidth
                      />
                    )}
                  />
                </LocalizationProvider>
              </Grid>


              <Grid item xs={6}>
                <Typography className="field-title">Last year remain qty</Typography>
                <TextField
                  fullWidth
                  type="number"
                  size="small"
                  className="text-field"
                  placeholder="remaining form last year"
                  {...getFieldProps("lastYearRemainQty")}
                  error={Boolean(touched.lastYearRemainQty && errors.lastYearRemainQty)}
                  helperText={touched.lastYearRemainQty && errors.lastYearRemainQty}
                />
              </Grid>

              <Grid item xs={6}>
                <Typography className="field-title">Current year qty</Typography>
                <TextField
                  fullWidth
                  type="number"
                  size="small"
                  className="text-field"
                  placeholder="current year quantity"
                  {...getFieldProps("currentYearQty")}
                  error={Boolean(touched.currentYearQty && errors.currentYearQty)}
                  helperText={touched.currentYearQty && errors.currentYearQty}
                />
              </Grid>

              <Grid item xs={6}>
                <Typography className="field-title">Used qty</Typography>
                <TextField
                  fullWidth
                  type="number"
                  size="small"
                  className="text-field"
                  placeholder="total used quantity"
                  {...getFieldProps("UsedQty")}
                  error={Boolean(
                    touched.UsedQty && errors.UsedQty
                  )}
                  helperText={touched.UsedQty && errors.UsedQty}
                />
              </Grid>
            </Grid>
          </Form>
        </FormikProvider>
      </DialogContent>

      <DialogActions>
        <Stack direction="row" spacing={4} className="action-container">
          <Button className="btn-cencel" fullWidth onClick={handleClose}>
            Cencel
          </Button>

          {loading ? (
            <Button className="btn-create" fullWidth>
              Loading...
            </Button>
          ) : (
            <Button className="btn-create" fullWidth onClick={handleSubmit}>
              {dialogTitle}
            </Button>
          )}
        </Stack>
      </DialogActions>
    </Dialog>
  );
}
