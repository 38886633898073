import React, { useContext, useEffect, useState } from "react";
import "../../../Style/createstyle.scss";
import { useFormik, Form, FormikProvider } from "formik";
import * as Yup from "yup";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import {
  Grid,
  Stack,
  Box,
  TextField,
  Typography,
  Button,
  FormControl,
  Select,
  MenuItem,
} from "@mui/material";
import LinearProgress from "@mui/material/LinearProgress";
import PropTypes from "prop-types";
import { useMutation } from "@apollo/client";
import { ref, deleteObject } from "firebase/storage";
import { storage } from "../../../firebase";
//Icons
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
//date picker
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import moment from "moment";
//Srcs
import { AuthContext } from "../../../Context/AuthContext";
import {
  CREATE_COVIDVACCINATION,
  UPDATE_COVIDVACCINATION,
} from "../../../Schema/CovidVaccination";
import EmptyPdfImage from "../../../Assets/empty-image.png";
import CropImageDriverLicense from "../../CropImage/CropImageDriverLicense";

function LinearProgressWithLabel(props) {
  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <Box sx={{ width: "100%", mr: 1 }}>
        <LinearProgress variant="determinate" {...props} />
      </Box>
      <Box sx={{ minWidth: 35 }}>
        <Typography variant="body2" color="text.secondary">{`${Math.round(
          props.value
        )}%`}</Typography>
      </Box>
    </Box>
  );
}
LinearProgressWithLabel.propTypes = {
  value: PropTypes.number.isRequired,
};

export default function CreateCovidVaccination({
  open,
  handleClose,
  dialogTitle,
  staffId,
  editData,
  setRefetch,
}) {
  const { setAlert } = useContext(AuthContext);
  const [loading, setLoading] = useState(false);

  let driverLicenseImageFromLocal = localStorage.getItem(
    "driverLicenseImageFromLocal"
  );

  const deleteImageFromLocal = () => {
    if (driverLicenseImageFromLocal) {
      setImageFile(null);
      const deleteImageCrop = ref(storage, driverLicenseImageFromLocal);
      deleteObject(deleteImageCrop)
        .then(() => {
          localStorage.removeItem("driverLicenseImageFromLocal");
          console.log("delete this draft image successfully!");
        })
        .catch((err) => {
          console.log("can not delete this draft image!", err);
        });
    }
  };

  // upload Image
  const [profileHook, setProfileHook] = useState("");
  const [imageFile, setImageFile] = useState(null);
  const [photoURL, setPhotoURL] = useState(null);
  const [openCrop, setOpenCrop] = useState(false);

  //----Progress------
  const [statusProgress, setStatusProgress] = React.useState(false);
  const [progress, setProgress] = React.useState(10);
  // console.log("statusProgress", statusProgress);
  // console.log("progress", progress);
  React.useEffect(() => {
    const timer = setInterval(() => {
      setProgress((prevProgress) =>
        prevProgress >= 100 ? 100 : prevProgress + 10
      );
    }, 500);
    return () => {
      clearInterval(timer);
    };
  }, []);
  //-----------------

  const handleUploadImage = (e) => {
    const imageFile = e.target.files[0];
    if (imageFile) {
      setImageFile(imageFile);
      setPhotoURL(URL.createObjectURL(imageFile));
      setOpenCrop(true);
    }
  };

  const [createStaffCovidVaccination, { data, error }] = useMutation(
    CREATE_COVIDVACCINATION,
    {
      onCompleted: ({ createStaffCovidVaccination }) => {
        setLoading(false);
        // console.log(createStaffCovidVaccination, "createStaffCovidVaccination")
        if (createStaffCovidVaccination?.success === true) {
          setAlert(true, "success", createStaffCovidVaccination?.message);
          handleClose();
          resetForm();
          setRefetch();
          setImageFile("");
          setProfileHook("");
        } else {
          setAlert(true, "error", createStaffCovidVaccination?.message);
        }
      },
      onError: (error) => {
        setLoading(false);
        setAlert(true, "error", error?.message);
      },
    }
  );

  const [updateStaffCovidVaccination] = useMutation(UPDATE_COVIDVACCINATION, {
    onCompleted: ({ updateStaffCovidVaccination }) => {
      // console.log(updateStaffCovidVaccination);
      setLoading(false);
      if (updateStaffCovidVaccination?.success === true) {
        setAlert(true, "success", updateStaffCovidVaccination?.message);
        handleClose();
        setRefetch();
      } else {
        setAlert(true, "error", updateStaffCovidVaccination?.message);
      }
    },
    onError: (error) => {
      setLoading(false);
      setAlert(true, "error", error?.message);
    },
  });

  //fromik
  const CreateStaffCovidVaccination = Yup.object().shape({
    dosesNumber: Yup.string().required("require!"),
    vaccinName: Yup.string().required("require!"),
    vaccinationDate: Yup.date().required("require!"),
    vaccinationLocation: Yup.string().required("require!"),
  });

  const formik = useFormik({
    initialValues: {
      dosesNumber: "1st dose",
      vaccinName: "Pizer/BioNTech",
      vaccinationDate: moment(),
      vaccinationLocation: "",
    },

    validationSchema: CreateStaffCovidVaccination,
    onSubmit: async (values) => {
      // console.log("values:::", values, staffId);
      if (dialogTitle !== "Create") {
        setLoading(true);
        updateStaffCovidVaccination({
          variables: {
            id: editData?._id,
            input: {
              ...values,
              imageName: "Covid vaccination card",
              imageSrc: profileHook,
            },
          },
        });
      } else {
        setLoading(true);
        createStaffCovidVaccination({
          variables: {
            staffInfoId: staffId,
            input: {
              ...values,
              imageName: imageFile ? "Covid vaccination card" : "",
              imageSrc: imageFile ? profileHook : "",
            },
          },
        });
      }
    },
  });

  const {
    errors,
    touched,
    handleSubmit,
    getFieldProps,
    setFieldValue,
    values,
    resetForm,
  } = formik;

  // console.log("editData::", editData);

  useEffect(() => {
    if (editData) {
      setProfileHook(editData?.imageSrc);
      setFieldValue("dosesNumber", editData?.dosesNumber);
      setFieldValue("vaccination", editData?.vaccination);
      setFieldValue("date", editData?.date);
      setFieldValue("vaccinationLocation", editData?.vaccinationLocation);
    }
  }, [editData]);

  return (
    <Dialog open={open} className="create-container">
      <DialogTitle sx={{ padding: "10px 20px" }}>
        <Stack direction="row" spacing={2}>
          <Stack direction="column" justifyContent="center">
            <Typography className="dialog-title">
              {dialogTitle} Staff's Covid Vaccination
            </Typography>
          </Stack>

          <Box sx={{ flexGrow: 1 }} />
          <IconButton
            onClick={() => {
              handleClose();
              deleteImageFromLocal();
            }}
          >
            <CloseIcon className="close-icon" />
          </IconButton>
        </Stack>
      </DialogTitle>

      <DialogContent dividers>
        <FormikProvider value={formik}>
          <Form noValidate autoComplete="off" onSubmit={handleSubmit}>
            <Grid container rowSpacing={2} columnSpacing={4}>
              <Grid item xs={12}>
                <Stack direction="row" justifyContent="center">
                  {!openCrop ? (
                    <Box className="title-add">
                      <Stack
                        className="profile"
                        direction="row"
                        justifycontent="center"
                      >
                        <Button component="label">
                          <TextField
                            type="file"
                            id="image"
                            sx={{ display: "none" }}
                            onChange={handleUploadImage}
                          />
                          <img
                            className="button-image"
                            src={
                              imageFile
                                ? URL.createObjectURL(imageFile)
                                : editData?.imageSrc
                                ? editData?.imageSrc
                                : EmptyPdfImage
                            }
                          />
                          <input type="file" hidden />
                        </Button>
                      </Stack>

                      <Stack
                        direction="row"
                        justifyContent="center"
                        spacing={2}
                        sx={{ mt: 1 }}
                      >
                        {statusProgress && progress < 100 ? (
                          <Box sx={{ width: "100%", marginLeft: "20px" }}>
                            <LinearProgressWithLabel value={progress} />
                          </Box>
                        ) : (
                          <Typography className="text-En">
                            Covid vaccination image
                          </Typography>
                        )}
                      </Stack>
                    </Box>
                  ) : (
                    <CropImageDriverLicense
                      setProgress={setProgress}
                      setStatusProgress={setStatusProgress}
                      setImageFile={setImageFile}
                      photoURL={photoURL}
                      openCrop={openCrop}
                      setOpenCrop={setOpenCrop}
                      setPhotoURL={setPhotoURL}
                      setUploadHook={setProfileHook}
                      srcFromBackend={
                        editData?.imageSrc ? editData?.imageSrc : ""
                      }
                    />
                  )}
                </Stack>
              </Grid>
              <Grid item xs={6}>
                <Typography className="field-title">Doses number</Typography>
                {/* <TextField
                  fullWidth
                  size="small"
                  type="number"
                  className="text-field"
                  endAdornment={<InputAdornment position="end">does</InputAdornment>}
                  placeholder="doses number"
                  {...getFieldProps("dosesNumber")}
                  error={Boolean(
                    touched.dosesNumber && errors.dosesNumber
                  )}
                  helperText={touched.dosesNumber && errors.dosesNumber}
                /> */}
                <FormControl size="small" fullWidth className="text-field">
                  <Select
                    labelId="demo-select-small"
                    id="demo-select-small"
                    value={values?.dosesNumber}
                    onChange={(event) => {
                      setFieldValue("dosesNumber", event.target.value);
                    }}
                    error={Boolean(touched.dosesNumber && errors.dosesNumber)}
                    helperText={touched.dosesNumber && errors.dosesNumber}
                  >
                    <MenuItem value={"1st dose"}>1st dose</MenuItem>
                    <MenuItem value={"2nd dose"}>2nd dose</MenuItem>
                    <MenuItem value={"3rd dose"}>3rd dose</MenuItem>
                    <MenuItem value={"4th dose"}>4th dose</MenuItem>
                    <MenuItem value={"5th dose"}>5th dose</MenuItem>
                    <MenuItem value={"6th dose"}>6th dose</MenuItem>
                    <MenuItem value={"7th dose"}>7th dose</MenuItem>
                    <MenuItem value={"8th dose"}>8th dose</MenuItem>
                    <MenuItem value={"9th dose"}>9th dose</MenuItem>
                    <MenuItem value={"10th dose"}>10th dose</MenuItem>
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={6}>
                <Typography className="field-title">Vaccine name</Typography>

                <FormControl size="small" fullWidth className="text-field">
                  <Select
                    labelId="demo-select-small"
                    id="demo-select-small"
                    value={values?.vaccinName}
                    onChange={(event) => {
                      setFieldValue("vaccinName", event.target.value);
                    }}
                    error={Boolean(touched.vaccinName && errors.vaccinName)}
                    helperText={touched.vaccinName && errors.vaccinName}
                  >
                    <MenuItem value={"Sinovac"}>Sinovac</MenuItem>
                    <MenuItem value={"Sinopharm"}>Sinopharm</MenuItem>
                    <MenuItem value={"AstraZeneca"}>AstraZeneca</MenuItem>
                    <MenuItem value={"Pizer/BioNTech"}>Pizer/BioNTech</MenuItem>
                    <MenuItem value={"Janssen"}>Janssen</MenuItem>
                    <MenuItem value={"Moderna"}>Moderna</MenuItem>
                    <MenuItem value={"Novavax"}>Novavax</MenuItem>
                    <MenuItem value={"Bhara Biotech"}>Bhara Biotech</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={6}>
                <Typography className="field-title">Date</Typography>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                    value={values?.vaccinationDate}
                    onChange={(e) => setFieldValue("vaccinationDate", e)}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        className="text-field"
                        size="small"
                        fullWidth
                      />
                    )}
                  />
                </LocalizationProvider>
              </Grid>
              <Grid item xs={6}>
                <Typography className="field-title">
                  Vaccination location
                </Typography>
                <TextField
                  fullWidth
                  size="small"
                  className="text-field"
                  placeholder="vaccination location"
                  {...getFieldProps("vaccinationLocation")}
                  error={Boolean(
                    touched.vaccinationLocation && errors.vaccinationLocation
                  )}
                  helperText={
                    touched.vaccinationLocation && errors.vaccinationLocation
                  }
                />
              </Grid>
            </Grid>
          </Form>
        </FormikProvider>
      </DialogContent>

      <DialogActions>
        <Stack direction="row" spacing={4} className="action-container">
          <Button
            className="btn-cencel"
            fullWidth
            onClick={() => {
              handleClose();
              deleteImageFromLocal();
            }}
          >
            Cencel
          </Button>

          {loading ? (
            <Button className="btn-create" fullWidth>
              Loading...
            </Button>
          ) : (
            <Button className="btn-create" fullWidth onClick={handleSubmit}>
              {dialogTitle}
            </Button>
          )}
        </Stack>
      </DialogActions>
    </Dialog>
  );
}
