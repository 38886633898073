import React, { useContext, useEffect, useState } from "react";
import "../../Style/createstyle.scss";
import { useFormik, Form, FormikProvider } from "formik";
import * as Yup from "yup";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import {
  Grid,
  Stack,
  Box,
  TextField,
  Typography,
  Button,
  Switch,
  FormHelperText,
  IconButton
} from "@mui/material";
import { useMutation } from "@apollo/client";
//Icons
import CloseIcon from "@mui/icons-material/Close";
//Src
import { SelectDepartment, SelectPositions } from "../../DynamicComp/DynamicSelect";
import { AuthContext } from "../../Context/AuthContext";
import { CREATE_POSITIONS, UPDATE_POSITIONS } from "../../Schema/Positions";

export default function CreatePositions({
  open,
  handleClose,
  dialogTitle,
  editData,
  setRefetch,
}) {
  const { setAlert } = useContext(AuthContext);
  const [loading, setLoading] = useState(false);
  const [departmentVal, setDepartmentVal] = useState({ id: "", title: "" });
  const [positionsVal, setPositionsVal] = useState({ id: "", title: "" });

  const [createPositions, { data, error }] = useMutation(CREATE_POSITIONS, {
    onCompleted: ({ createPositions }) => {
      // console.log(createPositions);
      setLoading(false);
      if (createPositions?.success === true) {
        setAlert(true, "success", createPositions?.message);
        handleClose();
        resetForm();
        setRefetch();

        setDepartmentVal({ id: "", title: "" })
        setPositionsVal({ id: "", title: "" })
      } else {
        setAlert(true, "error", createPositions?.message);
      }
    },
    onError: (error) => {
      setLoading(false);
      setAlert(true, "error", error?.message);
    },
  });

  const [updatePositions] = useMutation(UPDATE_POSITIONS, {
    onCompleted: ({ updatePositions }) => {
      setLoading(false);
      if (updatePositions?.success === true) {
        setAlert(true, "success", updatePositions?.message);
        handleClose();
        setRefetch();
      } else {
        setAlert(true, "error", updatePositions?.message);
      }
    },
    onError: (error) => {
      setLoading(false);
      setAlert(true, "error", error?.message);
    },
  });

  //fromik
  const CreatePostions = Yup.object().shape({
    positionName: Yup.string().required("require!"),
    positionNameKhmer: Yup.string().required("require!"),
    inDepartment: Yup.string().required("require!"),
    isDepartmentManager: Yup.string().required("require!"),
    // managerPositions: Yup.string(),
    remark: Yup.string(),
  });

  const formik = useFormik({
    initialValues: {
      positionName: "",
      positionNameKhmer: "",
      inDepartment: "",
      isDepartmentManager: false,
      managerPositions: null,
      remark: "",
    },

    validationSchema: CreatePostions,
    onSubmit: async (values) => {
      // console.log("values:::", values);
      setLoading(true);
      if (dialogTitle !== "Create") {
        updatePositions({
          variables: {
            positionsId: editData?._id,
            input: {
              ...values,
            },
          },
        });
      } else {
        createPositions({
          variables: {
            input: {
              ...values,
            },
          },
        });
      }
    },
  });

  const {
    errors,
    touched,
    handleSubmit,
    getFieldProps,
    setFieldValue,
    values,
    resetForm,
  } = formik;

 
  // console.log("editData::", editData);

  useEffect(() => {
    if (editData) {
      setFieldValue("positionName", editData?.positionName);
      setFieldValue("positionNameKhmer", editData?.positionNameKhmer);
      setFieldValue("inDepartment", editData?.inDepartment?._id);
      setDepartmentVal({
        id: editData?.inDepartment?._id,
        title: editData?.inDepartment?.departmentName,
      });
      setFieldValue("isDepartmentManager", editData?.isDepartmentManager);

      setPositionsVal({
        id: editData?.managerPositions?._id,
        title: editData?.managerPositions?.positionName,
      });
      setFieldValue("managerPositions", editData?.managerPositions?._id);

      setFieldValue(
        "remark",
        editData?.remark === null ? "" : editData?.remark
      );
    }
  }, [editData]);

  useEffect(() => {
    if (departmentVal?.id !== "") {
      setFieldValue("inDepartment", departmentVal?.id);
    } else {
      setFieldValue("inDepartment", "");
    }
  }, [departmentVal?.id]);

  useEffect(() => {
    if (positionsVal?.id) {
      setFieldValue("managerPositions", positionsVal?.id);
    } else {
      // setFieldValue("managerPositions", "");
      setFieldValue("managerPositions", null);
    }
  }, [positionsVal?.id]);

  return (
    <Dialog open={open} className="create-container">
      <DialogTitle sx={{ padding: "10px 20px" }}>
        <Stack direction="row" spacing={2}>
          <Stack direction="column" justifyContent="center">
            <Typography className="dialog-title">
              {dialogTitle} Positions
            </Typography>
          </Stack>

          <Box sx={{ flexGrow: 1 }} />
          <IconButton onClick={handleClose}>
            <CloseIcon className="close-icon" />
          </IconButton>
        </Stack>
      </DialogTitle>

      <DialogContent dividers>
        <FormikProvider value={formik}>
          <Form noValidate autoComplete="off" onSubmit={handleSubmit}>
            <Grid container rowSpacing={2} columnSpacing={4}>
              <Grid item xs={6}>
                <Typography className="field-title">Positions Name</Typography>
                <TextField
                  fullWidth
                  size="small"
                  className="text-field"
                  placeholder="postions"
                  {...getFieldProps("positionName")}
                  error={Boolean(touched.positionName && errors.positionName)}
                  helperText={touched.positionName && errors.positionName}
                />
              </Grid>

              <Grid item xs={6}>
                <Typography className="field-title">
                  Positions Khmer Name
                </Typography>
                <TextField
                  fullWidth
                  size="small"
                  className="text-field"
                  placeholder="postions"
                  {...getFieldProps("positionNameKhmer")}
                  error={Boolean(
                    touched.positionNameKhmer && errors.positionNameKhmer
                  )}
                  helperText={
                    touched.positionNameKhmer && errors.positionNameKhmer
                  }
                />
              </Grid>

              <Grid item xs={6}>
                <Stack direction="row" justifyContent="center">
                  <Box>
                    <Typography className="field-title">Is Manager?</Typography>
                    <Switch
                      checked={values?.isDepartmentManager}
                      onChange={(e) =>
                        setFieldValue("isDepartmentManager", e.target.checked)
                      }
                      inputProps={{ "aria-label": "controlled" }}
                    />
                  </Box>
                </Stack>
              </Grid>

              <Grid item xs={6}>
                <Typography className="field-title">
                  Select Department
                </Typography>

                <SelectDepartment
                  selectedVal={departmentVal}
                  setSelectedVal={setDepartmentVal}
                />
                {!!errors.managerPositions && touched.managerPositions && (
                  <FormHelperText error id="outlined-adornment-password">
                    {errors.managerPositions}
                  </FormHelperText>
                )}
              </Grid>

              <Grid item xs={6}>
                <Typography className="field-title">Manager</Typography>
                <SelectPositions
                  selectedVal={positionsVal}
                  setSelectedVal={setPositionsVal}
                />
                {!!errors.inDepartment && touched.inDepartment && (
                  <FormHelperText error id="outlined-adornment-password">
                    {errors.inDepartment}
                  </FormHelperText>
                )}
              </Grid>

              <Grid item xs={6}>
                <Typography className="field-title">Remark</Typography>
                <TextField
                  fullWidth
                  size="small"
                  className="text-field"
                  placeholder="remark"
                  {...getFieldProps("remark")}
                  error={Boolean(touched.remark && errors.remark)}
                  helperText={touched.remark && errors.remark}
                />
              </Grid>
            </Grid>
          </Form>
        </FormikProvider>
        <Typography
          sx={{
            fontSize: '13px',
            opacity: '80%',
            marginTop: '15px'
          }}
        >
          Note: The Manager field represents the positions who has the right to approve or deny the request (take leave and overtime work) of this new positions.
        </Typography>
      </DialogContent>

      <DialogActions>
        <Stack direction="row" spacing={4} className="action-container">
          <Button className="btn-cencel" fullWidth onClick={handleClose}>
            Cencel
          </Button>

          {loading ? (
            <Button className="btn-create" fullWidth>
              Loading...
            </Button>
          ) : (
            <Button className="btn-create" fullWidth onClick={handleSubmit}>
              {dialogTitle}
            </Button>
          )}
        </Stack>
      </DialogActions>
    </Dialog>
  );
}
