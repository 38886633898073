import { gql } from "@apollo/client";

export const CREATE_MAJOR = gql`
  mutation CreateMajor($input: MajorInput) {
    createMajor(input: $input) {
      success
      message
    }
  }
`;

export const GET_MAJOR_WITH_PAGINATION = gql`
  query GetMajorWithPagination(
    $page: Int
    $limit: Int
    $keyword: String
    $pagination: Boolean
  ) {
    getMajorWithPagination(
      page: $page
      limit: $limit
      keyword: $keyword
      pagination: $pagination
    ) {
      data {
        _id
        majorName
        remark
        createdAt
        updatedAt
      }
      paginator {
        slNo
        prev
        next
        perPage
        totalPosts
        totalPages
        currentPage
        hasPrevPage
        hasNextPage
        totalDocs
      }
    }
  }
`;

export const UPDATE_MAJOR = gql`
  mutation UpdateMajor($input: MajorInput, $id: ID) {
    updateMajor(input: $input, _id: $id) {
      success
      message
    }
  }
`;

export const DELETE_MAJOR = gql`
  mutation DeleteMajor($id: ID) {
    deleteMajor(_id: $id) {
      success
      message
    }
  }
`;
