import React, { useContext, useEffect, useState } from "react";
import "../../Style/createstyle.scss";
import { useFormik, Form, FormikProvider } from "formik";
import * as Yup from "yup";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import { Grid, Stack, Box, TextField, Typography, Button } from "@mui/material";
import { useMutation } from "@apollo/client";
//Icons
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
//Src
import { AuthContext } from "../../Context/AuthContext";
import {
  CREATE_WORKINGPLACE,
  UPDATE_WORKINGPLACE,
} from "../../Schema/WorkingPlace";

export default function CreateWorkingPlace({
  open,
  handleClose,
  dialogTitle,
  editData,
  setRefetch,
}) {
  const { setAlert } = useContext(AuthContext);
  const [loading, setLoading] = useState(false);

  const [createWorkingPlace, { data, error }] = useMutation(
    CREATE_WORKINGPLACE,
    {
      onCompleted: ({ createWorkingPlace }) => {
        // console.log(createWorkingPlace);
        setLoading(false);
        if (createWorkingPlace?.success === true) {
          setAlert(true, "success", createWorkingPlace?.message);
          handleClose();
          resetForm();
          setRefetch();
        } else {
          setAlert(true, "error", createWorkingPlace?.message);
        }
      },
      onError: (error) => {
        setLoading(false);
        setAlert(true, "error", error?.message);
      },
    }
  );

  const [updateWorkingPlace] = useMutation(UPDATE_WORKINGPLACE, {
    onCompleted: ({ updateWorkingPlace }) => {
      // console.log(updateWorkingPlace);
      setLoading(false);
      if (updateWorkingPlace?.success === true) {
        setAlert(true, "success", updateWorkingPlace?.message);
        handleClose();
        setRefetch();
      } else {
        setAlert(true, "error", updateWorkingPlace?.message);
      }
    },
    onError: (error) => {
      setLoading(false);
      setAlert(true, "error", error?.message);
    },
  });

  //fromik
  const CreateWorkingPlace = Yup.object().shape({
    workingPlaceName: Yup.string().required("require!"),
    remark: Yup.string(),
  });

  const formik = useFormik({
    initialValues: {
      workingPlaceName: "",
      remark: "",
    },

    validationSchema: CreateWorkingPlace,
    onSubmit: async (values) => {
      // console.log("values:::", values);
      // console.log("dialogTitle:::", dialogTitle);
      setLoading(true);
      if (dialogTitle !== "Create") {
        updateWorkingPlace({
          variables: {
            id: editData?._id,
            input: {
              ...values,
            },
          },
        });
      } else {
        createWorkingPlace({
          variables: {
            input: {
              ...values,
            },
          },
        });
      }
    },
  });

  const {
    errors,
    touched,
    handleSubmit,
    getFieldProps,
    setFieldValue,
    values,
    resetForm,
  } = formik;

  // console.log(editData)

  useEffect(() => {
    if (editData) {
      setFieldValue("workingPlaceName", editData?.workingPlaceName);
      setFieldValue(
        "remark",
        editData?.remark === null ? "" : editData?.remark
      );
    }
  }, [editData]);

  return (
    <Dialog open={open} className="create-container">
      <DialogTitle sx={{ padding: "10px 20px" }}>
        <Stack direction="row" spacing={2}>
          <Stack direction="column" justifyContent="center">
            <Typography className="dialog-title">
              {dialogTitle} Working place
            </Typography>
          </Stack>

          <Box sx={{ flexGrow: 1 }} />
          <IconButton onClick={handleClose}>
            <CloseIcon className="close-icon" />
          </IconButton>
        </Stack>
      </DialogTitle>

      <DialogContent dividers>
        <FormikProvider value={formik}>
          <Form noValidate autoComplete="off" onSubmit={handleSubmit}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography className="field-title">Working place</Typography>
                <TextField
                  fullWidth
                  size="small"
                  className="text-field"
                  placeholder="postions"
                  {...getFieldProps("workingPlaceName")}
                  error={Boolean(
                    touched.workingPlaceName && errors.workingPlaceName
                  )}
                  helperText={
                    touched.workingPlaceName && errors.workingPlaceName
                  }
                />
              </Grid>

              <Grid item xs={12}>
                <Typography className="field-title">Remark</Typography>
                <TextField
                  fullWidth
                  size="small"
                  className="text-field"
                  placeholder="remark"
                  {...getFieldProps("remark")}
                  error={Boolean(touched.remark && errors.remark)}
                  helperText={touched.remark && errors.remark}
                />
              </Grid>
            </Grid>
          </Form>
        </FormikProvider>
      </DialogContent>

      <DialogActions>
        <Stack direction="row" spacing={4} className="action-container">
          <Button className="btn-cencel" fullWidth onClick={handleClose}>
            Cencel
          </Button>

          {loading ? (
            <Button className="btn-create" fullWidth>
              Loading...
            </Button>
          ) : (
            <Button className="btn-create" fullWidth onClick={handleSubmit}>
              {dialogTitle}
            </Button>
          )}
        </Stack>
      </DialogActions>
    </Dialog>
  );
}
