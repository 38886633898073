import * as React from "react";
import "../../../Style/actionstyle.scss";
import { IconButton, Typography, Stack } from "@mui/material";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import BorderColorOutlinedIcon from "@mui/icons-material/BorderColorOutlined";
import DeleteForeverOutlinedIcon from "@mui/icons-material/DeleteForeverOutlined";
import { IoDocumentTextSharp } from "react-icons/io5";
//Srcs
import CreateCovidVaccination from "./CreateCovidVaccination";
import DeleteCovidVaccination from "./DeleteCovidVaccination";

export default function EducationAction({ editData, setRefetch, userRole }) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  //Modal view
  const [openEdit, setOpenEdit] = React.useState(false);
  const handleOpenEdit = () => setOpenEdit(true);
  const handleCloseEdit = () => setOpenEdit(false);

  //Modal delete
  const [openDel, setOpenDel] = React.useState(false);
  const handleOpenDel = () => setOpenDel(true);
  const handleCloseDel = () => setOpenDel(false);

  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <Stack direction="row" spacing={1} justifyContent="right">
        {editData?.imageSrc ? (
          <IconButton sx={{ width: "42px", height: "42px" }}>
            <a
              href={editData?.imageSrc}
              target="_blank"
              rel="noopener noreferrer"
            >
              <IoDocumentTextSharp
                className="more-icon"
                style={{ color: "green" }}
              />
            </a>
          </IconButton>
        ) : null}

        {userRole === "Admin" ? (
          <IconButton
            onClick={handleClick}
            sx={{ width: "42px", height: "42px" }}
          >
            <MoreVertIcon className="more-icon" />
          </IconButton>
        ) : null}
      </Stack>
      <Menu
        id="basic-button"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <MenuItem
          onClick={() => {
            handleOpenEdit();
            handleClose();
          }}
        >
          <Stack direction="row" spacing={1}>
            <BorderColorOutlinedIcon className="update-icon" />
            <Typography className="update-title">Update</Typography>
          </Stack>
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleOpenDel();
            handleClose();
          }}
        >
          <Stack direction="row" spacing={1}>
            <DeleteForeverOutlinedIcon className="delete-icon" />
            <Typography className="update-title">Delete</Typography>
          </Stack>
        </MenuItem>
      </Menu>

      <CreateCovidVaccination
        open={openEdit}
        handleClose={handleCloseEdit}
        editData={editData}
        dialogTitle={"Update"}
        setRefetch={setRefetch}
      />

      <DeleteCovidVaccination
        open={openDel}
        handleClose={handleCloseDel}
        editData={editData}
        setRefetch={setRefetch}
      />
    </div>
  );
}
