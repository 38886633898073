import React, { useState, useEffect, useRef } from "react";
import {
  Box,
  Grid,
  Stack,
  Typography,
  IconButton,
  Button,
  Checkbox,
  Divider,
} from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import CloseIcon from "@mui/icons-material/Close";
import PrintOutlinedIcon from "@mui/icons-material/PrintOutlined";
import { useReactToPrint } from "react-to-print";
import moment from "moment";
//Srcs
import "../../../Style/createstyle.scss";
import ImageLogo from "../../../Assets/PEPY-transparent.png";
import {
  getKhmerMonth,
  getKhmerNumber,
} from "../../../Function/GetKhmerNumber";

export default function PrintTakeLeave({
  open,
  handleClose,
  printTakeLeaveData,
}) {
  //for print
  const componentRef = useRef();
  const handalePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const [annualLeave, setAnnualLeave] = useState(false);
  const [maternityLeave, setMaternityLeave] = useState(false);
  const [compensateLeave, setCompensateLeave] = useState(false);
  const [weddingLeave, setWeddingLeave] = useState(false);
  const [paternityLeave, setPaternityLeave] = useState(false);
  const [sickLeave, setSickLeave] = useState(false);
  const [deadLeave, setDeadLeave] = useState(false);
  const [unpaidLeave, setUnpaidLeave] = useState(false);
  const [breastfeeding, setBreastfeeding] = useState(false);
  const [otherLeave, setOtherLeave] = useState(false);

  // console.log("printTakeLeaveData::", printTakeLeaveData);

  useEffect(() => {
    if (printTakeLeaveData) {
      printTakeLeaveData?.takeLeaveTypeUsed?.map((d) => {
        if (d?.takeLeaveType === "Annual Leave") {
          setAnnualLeave(true);
        } else if (d?.takeLeaveType === "Maternity Leave") {
          setMaternityLeave(true);
        } else if (d?.takeLeaveType === "Compensate Leave") {
          setCompensateLeave(true);
        } else if (d?.takeLeaveType === "Compassionate(wedding) Leave") {
          setWeddingLeave(true);
        } else if (d?.takeLeaveType === "Compassionate(death) Leave") {
          setPaternityLeave(true);
        } else if (d?.takeLeaveType === "Sick Leave") {
          setSickLeave(true);
        } else if (d?.takeLeaveType === "Compassionate(death) Leave") {
          setDeadLeave(true);
        } else if (d?.takeLeaveType === "Unpaid Leave") {
          setUnpaidLeave(true);
        } else if (d?.takeLeaveType === "Breastfeeding") {
          setBreastfeeding(true);
        } else {
          setOtherLeave(true);
        }
      });
    }
  }, [printTakeLeaveData]);

  const currentYearQty = printTakeLeaveData?.paymentOfTakeLeaveQuantity?.reduce(
    (accumulator, object) => {
      return accumulator + object?.currentYearQty;
    },
    0
  );
  const lastYearRemaining =
    printTakeLeaveData?.paymentOfTakeLeaveQuantity?.reduce(
      (accumulator, object) => {
        return accumulator + object?.lastYearRemainQty;
      },
      0
    );

  let totalTakeleave = currentYearQty + lastYearRemaining;
  // console.log(printTakeLeaveData?.createdBy?.staffProfile?.khmerData, "Khmer Name");
  return (
    <Dialog open={open} className="create-container" fullWidth maxWidth="md">
      <DialogTitle sx={{ padding: "10px 20px" }}>
        <Stack direction="row" spacing={2}>
          <Stack direction="column" justifyContent="center">
            <Typography className="dialog-title">Print takeleave</Typography>
          </Stack>

          <Box sx={{ flexGrow: 1 }} />
          <IconButton onClick={handleClose}>
            <CloseIcon className="close-icon" />
          </IconButton>
        </Stack>
      </DialogTitle>

      <DialogContent dividers>
        <div className="setup-report-container" ref={componentRef}>
          <Grid container spacing={1}>
            <Grid item xs={4}>
              <Box className="logo-container">
                <img src={ImageLogo} className="logo-company" />
              </Box>
            </Grid>
            <Grid item xs={4}>
              <Typography
                className="report-top-title"
                sx={{ marginTop: "10px" }}
              >
                ពាក្យសុំច្បាប់សម្រាក
              </Typography>
            </Grid>
            <Grid item xs={4}></Grid>
          </Grid>
          <div className="table-report-container" style={{ marginTop: "5px" }}>
            {/* ========================================== Khmer data ========================================= */}
            <Stack direction="column" spacing={0.8}>
              <Stack direction="row" justifyContent="space-between">
                <Typography className="takeleave-des">ឈ្មោះ</Typography>
                <Typography className="takeleave-item">
                  {printTakeLeaveData?.createdBy?.staffProfile?.khmerData
                    ?.firstNameKh
                    ? printTakeLeaveData?.createdBy?.staffProfile?.khmerData
                        ?.firstNameKh +
                      " " +
                      printTakeLeaveData?.createdBy?.staffProfile?.khmerData
                        ?.lastNameKh
                    : printTakeLeaveData?.createdBy?.staffProfile?.englishData
                        ?.firstNameLatin +
                      " " +
                      printTakeLeaveData?.createdBy?.staffProfile?.englishData
                        ?.lastNameLatin
                        }
                </Typography>
                <Typography className="takeleave-des">តួនាទី</Typography>
                <Typography className="takeleave-item">
                  {
                    printTakeLeaveData?.createdBy?.currentContract?.positions
                      ?.positionNameKhmer
                  }
                </Typography>
                <Typography className="takeleave-des">កម្មវិធី</Typography>
                <Typography className="takeleave-item">
                  {
                    printTakeLeaveData?.createdBy?.currentContract?.positions
                      ?.inDepartment?.departmentName
                  }
                </Typography>
              </Stack>

              <Stack direction="row" justifyContent="space-between">
                <Typography className="takeleave-des">
                  ខ្ញុំបាទ/នាងខ្ញុំសុំអនុញ្ញាតច្បាប់ឈប់សម្រាកចំនួន
                </Typography>
                <Typography className="takeleave-item">
                  {printTakeLeaveData?.totalLeaveAsDay}
                </Typography>
                <Typography className="takeleave-des">
                  ថ្ងៃដោយគិតចាប់ពីថ្ងៃទី
                </Typography>
                <Typography className="takeleave-item">
                  {`${getKhmerNumber(
                    moment(printTakeLeaveData?.startDate).format("DD")
                  )} ខែ${getKhmerMonth(
                    moment(printTakeLeaveData?.startDate).format("M")
                  )} ឆ្នាំ${getKhmerNumber(
                    moment(printTakeLeaveData?.startDate).format("YYYY")
                  )}`}
                </Typography>
                <Typography className="takeleave-des">ដល់</Typography>
                <Typography className="takeleave-item">
                  {`${getKhmerNumber(
                    moment(printTakeLeaveData?.returnDate).format("DD")
                  )} ខែ${getKhmerMonth(
                    moment(printTakeLeaveData?.returnDate).format("M")
                  )} ឆ្នាំ${getKhmerNumber(
                    moment(printTakeLeaveData?.returnDate).format("YYYY")
                  )}`}
                </Typography>
              </Stack>

              <Stack direction="row" spacing={2}>
                <Typography className="takeleave-des">
                  ចូលធ្វើការវិញនៅថ្ងៃទី
                </Typography>
                <Typography className="takeleave-item">
                  {/* {moment(printTakeLeaveData?.returnDate).format("DD-MMM-YYY")} */}

                  {`${getKhmerNumber(
                    moment(printTakeLeaveData?.returnDate).format("DD")
                  )} ខែ${getKhmerMonth(
                    moment(printTakeLeaveData?.returnDate).format("M")
                  )} ឆ្នាំ${getKhmerNumber(
                    moment(printTakeLeaveData?.returnDate).format("YYYY")
                  )}`}
                </Typography>
              </Stack>

              <Stack direction="row" justifyContent="space-between">
                <Stack direction="column" spacing={1}>
                  <Stack direction="row" spacing={0.8}>
                    <Checkbox
                      checked={annualLeave}
                      size="small"
                      sx={{ padding: "0px !important", marginTop: "-4px" }}
                    />
                    <Typography className="takeleave-des">
                      ថ្ងៃសម្រាកប្រចាំឆ្នាំ
                    </Typography>
                  </Stack>
                  <Stack direction="row" spacing={1}>
                    <Checkbox
                      checked={weddingLeave}
                      size="small"
                      sx={{ padding: "0px !important", marginTop: "-4px" }}
                    />
                    <Typography className="takeleave-des">
                      ថ្ងៃសម្រាកអាពាហ៍ពិពាហ៍
                    </Typography>
                  </Stack>
                  <Stack direction="row" spacing={1}>
                    <Checkbox
                      checked={deadLeave}
                      size="small"
                      sx={{ padding: "0px !important", marginTop: "-4px" }}
                    />
                    <Typography className="takeleave-des">
                      ថ្ងៃសម្រាកមរណភាព
                    </Typography>
                  </Stack>
                </Stack>

                <Stack direction="column" spacing={0.8}>
                  <Stack direction="row" spacing={1}>
                    <Checkbox
                      checked={maternityLeave}
                      size="small"
                      sx={{ padding: "0px !important", marginTop: "-4px" }}
                    />
                    <Typography className="takeleave-des">
                      ថ្ងៃសម្រាកលំហែរមាតុភាព
                    </Typography>
                  </Stack>
                  <Stack direction="row" spacing={1}>
                    <Checkbox
                      checked={paternityLeave}
                      size="small"
                      sx={{ padding: "0px !important", marginTop: "-4px" }}
                    />
                    <Typography className="takeleave-des">
                      ថ្ងៃសម្រាកលំហែរបិតុភាព
                    </Typography>
                  </Stack>
                  <Stack direction="row" spacing={1}>
                    <Checkbox
                      checked={unpaidLeave}
                      size="small"
                      sx={{ padding: "0px !important", marginTop: "-4px" }}
                    />
                    <Typography className="takeleave-des">
                      ថ្ងៃសម្រាកគ្មានប្រាក់បៀវត្ស
                    </Typography>
                  </Stack>
                </Stack>

                <Stack direction="column" spacing={1}>
                  <Stack direction="row" spacing={0.8}>
                    <Checkbox
                      checked={compensateLeave}
                      size="small"
                      sx={{ padding: "0px !important", marginTop: "-4px" }}
                    />
                    <Typography className="takeleave-des">
                      ថ្ងៃសម្រាកជំនួស
                    </Typography>
                  </Stack>
                  <Stack direction="row" spacing={1}>
                    <Checkbox
                      checked={sickLeave}
                      size="small"
                      sx={{ padding: "0px !important", marginTop: "-4px" }}
                    />
                    <Typography className="takeleave-des">
                      ថ្ងៃសម្រាកពេលមានជំងឺ
                    </Typography>
                  </Stack>
                  <Stack direction="row" spacing={1}>
                    <Checkbox
                      checked={breastfeeding}
                      size="small"
                      sx={{ padding: "0px !important", marginTop: "-4px" }}
                    />
                    <Typography className="takeleave-des">
                      ថ្ងៃសម្រាកបំបៅកូន
                    </Typography>
                  </Stack>
                </Stack>
              </Stack>

              <Stack direction="row" spacing={2}>
                <Typography className="takeleave-des">មូលហេតុ</Typography>
                <Typography className="takeleave-item">
                  {printTakeLeaveData?.reason}
                </Typography>
              </Stack>

              <Stack direction="row" justifyContent="space-between">
                <Typography className="takeleave-des">
                  ហត្ថលេខាបុគ្គលិកស្នើសុំ
                </Typography>
                <Typography className="takeleave-item">.....</Typography>
                <Typography className="takeleave-des">កាលបរិច្ឆេទ</Typography>
                <Typography className="takeleave-item">
                  {`${getKhmerNumber(moment().format("DD"))} ខែ${getKhmerMonth(
                    moment().format("M")
                  )} ឆ្នាំ${getKhmerNumber(moment().format("YYYY"))}`}
                </Typography>
              </Stack>

              <Typography className="takeleave-des-center">
                ការទូទាត់ចំនួនថ្ងៃឈប់សម្រាកពីការងារ
              </Typography>

              <Stack direction="row" justifyContent="space-between">
                <Typography className="takeleave-des">
                  ចំនួនថ្ងៃឈប់សម្រាកដែលមាន
                </Typography>
                <Typography className="takeleave-item">
                  {totalTakeleave}
                </Typography>
                <Typography className="takeleave-des">
                  ចំនួនថ្ងៃឈប់សម្រាកពីការងារ
                </Typography>
                <Typography className="takeleave-item">
                  {printTakeLeaveData?.totalLeaveAsDay}
                </Typography>
                <Typography className="takeleave-des">ចាប់ពីខែ</Typography>
                <Typography className="takeleave-item">
                  {getKhmerMonth("9")}
                </Typography>
              </Stack>

              <Stack direction="row" spacing={2}>
                <Typography className="takeleave-des">
                  ចំនួនថ្ងៃឈប់សម្រាកពីការងារដែលនៅសល់
                </Typography>
                <Typography className="takeleave-item">
                  {totalTakeleave - printTakeLeaveData?.totalLeaveAsDay}
                </Typography>
              </Stack>

              <Typography className="takeleave-des-center">
                យល់ព្រមដោយ
              </Typography>

              <Stack direction="row">
                <Typography
                  sx={{ width: "50%" }}
                  className="takeleave-des-footer"
                >
                  យល់ព្រមដោយ
                </Typography>
                <Typography
                  sx={{ width: "50%" }}
                  className="takeleave-des-footer"
                >
                  អ្នកគ្រប់គ្រងកម្មវិធី
                </Typography>
              </Stack>
            </Stack>

            {/* ========================================== English data ========================================= */}
            <Box sx={{ marginTop: "120px" }}></Box>
            <Divider/>
            <Box sx={{ marginTop: "10px" }}>
              <Grid container spacing={1}>
                <Grid item xs={4}>
                  <Box className="logo-container">
                    <img src={ImageLogo} className="logo-company" />
                  </Box>
                </Grid>
                <Grid item xs={4}>
                  <Typography
                    className="report-top-title-head"
                    sx={{ marginTop: "10px" }}
                  >
                    Take Leave Application
                  </Typography>
                </Grid>
                <Grid item xs={4}></Grid>
              </Grid>
            </Box>
            <Stack direction="column" spacing={0.8}>
              <Stack direction="row" justifyContent="space-between">
                <Typography className="takeleave-des">Name</Typography>
                <Typography className="takeleave-item">
                  {printTakeLeaveData?.createdBy?.staffProfile?.englishData
                    ?.firstNameLatin +
                    " " +
                    printTakeLeaveData?.createdBy?.staffProfile?.englishData
                      ?.lastNameLatin}
                </Typography>
                <Typography className="takeleave-des">Positions</Typography>
                <Typography className="takeleave-item">
                  {
                    printTakeLeaveData?.createdBy?.currentContract?.positions
                      ?.positionName
                  }
                </Typography>
                <Typography className="takeleave-des">Programme</Typography>
                <Typography className="takeleave-item">
                  {
                    printTakeLeaveData?.createdBy?.currentContract?.positions
                      ?.inDepartment?.departmentName
                  }
                </Typography>
              </Stack>

              <Stack direction="row" justifyContent="space-between">
                <Typography className="takeleave-des">
                  I am requesting
                </Typography>
                <Typography className="takeleave-item">
                  {printTakeLeaveData?.totalLeaveAsDay}
                </Typography>
                <Typography className="takeleave-des">
                  days, leave from
                </Typography>
                <Typography className="takeleave-item">
                  {moment(printTakeLeaveData?.startDate).format("DD-MMM-YYY")}
                </Typography>
                <Typography className="takeleave-des">to</Typography>
                <Typography className="takeleave-item">
                  {moment(printTakeLeaveData?.returnDate).format("DD-MMM-YYY")}
                </Typography>
              </Stack>

              <Stack direction="row" spacing={2}>
                <Typography className="takeleave-des">
                  return to work on
                </Typography>
                <Typography className="takeleave-item">
                  {moment(printTakeLeaveData?.returnDate).format("DD-MMM-YYY")}
                </Typography>
              </Stack>

              <Stack direction="row" justifyContent="space-between">
                <Stack direction="column" spacing={1}>
                  <Stack direction="row" spacing={0.8}>
                    <Checkbox
                      checked={annualLeave}
                      size="small"
                      sx={{ padding: "0px !important", marginTop: "-4px" }}
                    />
                    <Typography className="takeleave-des">
                      Annual Leave
                    </Typography>
                  </Stack>
                  <Stack direction="row" spacing={1}>
                    <Checkbox
                      checked={weddingLeave}
                      size="small"
                      sx={{ padding: "0px !important", marginTop: "-4px" }}
                    />
                    <Typography className="takeleave-des">
                      Compassionate(wedding) Leave
                    </Typography>
                  </Stack>
                  <Stack direction="row" spacing={1}>
                    <Checkbox
                      checked={deadLeave}
                      size="small"
                      sx={{ padding: "0px !important", marginTop: "-4px" }}
                    />
                    <Typography className="takeleave-des">
                      Compassionate(death) Leave
                    </Typography>
                  </Stack>
                </Stack>

                <Stack direction="column" spacing={0.8}>
                  <Stack direction="row" spacing={1}>
                    <Checkbox
                      checked={maternityLeave}
                      size="small"
                      sx={{ padding: "0px !important", marginTop: "-4px" }}
                    />
                    <Typography className="takeleave-des">
                      Maternity Leave
                    </Typography>
                  </Stack>
                  <Stack direction="row" spacing={1}>
                    <Checkbox
                      checked={paternityLeave}
                      size="small"
                      sx={{ padding: "0px !important", marginTop: "-4px" }}
                    />
                    <Typography className="takeleave-des">
                      Paternity Leave
                    </Typography>
                  </Stack>
                  <Stack direction="row" spacing={1}>
                    <Checkbox
                      checked={unpaidLeave}
                      size="small"
                      sx={{ padding: "0px !important", marginTop: "-4px" }}
                    />
                    <Typography className="takeleave-des">
                      Unpaid Leave
                    </Typography>
                  </Stack>
                </Stack>

                <Stack direction="column" spacing={1}>
                  <Stack direction="row" spacing={0.8}>
                    <Checkbox
                      checked={compensateLeave}
                      size="small"
                      sx={{ padding: "0px !important", marginTop: "-4px" }}
                    />
                    <Typography className="takeleave-des">
                      Compensate Leave
                    </Typography>
                  </Stack>
                  <Stack direction="row" spacing={1}>
                    <Checkbox
                      checked={sickLeave}
                      size="small"
                      sx={{ padding: "0px !important", marginTop: "-4px" }}
                    />
                    <Typography className="takeleave-des">
                      Sick Leave
                    </Typography>
                  </Stack>
                  <Stack direction="row" spacing={1}>
                    <Checkbox
                      checked={breastfeeding}
                      size="small"
                      sx={{ padding: "0px !important", marginTop: "-4px" }}
                    />
                    <Typography className="takeleave-des">
                      Breastfeeding
                    </Typography>
                  </Stack>
                </Stack>
              </Stack>

              <Stack direction="row" spacing={2}>
                <Typography className="takeleave-des">Reason</Typography>
                <Typography className="takeleave-item">
                  {printTakeLeaveData?.reason}
                </Typography>
              </Stack>

              <Stack direction="row" justifyContent="space-between">
                <Typography className="takeleave-des">
                  Request staff signature
                </Typography>
                <Typography className="takeleave-item">.....</Typography>
                <Typography className="takeleave-des">Date</Typography>
                <Typography className="takeleave-item">
                  {moment().format("DD-MMM-YYYY")}
                </Typography>
              </Stack>

              <Typography className="takeleave-des-center">
                Payment of number of takeleave from work
              </Typography>

              <Stack direction="row" justifyContent="space-between">
                <Typography className="takeleave-des">
                  Total available takeleave
                </Typography>
                <Typography className="takeleave-item">
                  {totalTakeleave}
                </Typography>
                <Typography className="takeleave-des">Total used</Typography>
                <Typography className="takeleave-item">
                  {printTakeLeaveData?.totalLeaveAsDay}
                </Typography>
                <Typography className="takeleave-des">from</Typography>
                <Typography className="takeleave-item">
                  {moment("09-09-2023").format("MMMM")}
                </Typography>
              </Stack>

              <Stack direction="row" spacing={2}>
                <Typography className="takeleave-des">
                  Remaining takeleave
                </Typography>
                <Typography className="takeleave-item">
                  {totalTakeleave - printTakeLeaveData?.totalLeaveAsDay}
                </Typography>
              </Stack>

              <Typography className="takeleave-des-center">
                Approved by
              </Typography>

              <Stack direction="row">
                <Typography
                  sx={{ width: "50%" }}
                  className="takeleave-des-footer-eng"
                >
                  Human Resource
                </Typography>
                <Typography
                  sx={{ width: "50%" }}
                  className="takeleave-des-footer-eng"
                >
                  Programme Manager
                </Typography>
              </Stack>
            </Stack>
          </div>
        </div>
      </DialogContent>

      <DialogActions>
        <Stack direction="row" justifyContent="right">
          <Button
            onClick={handalePrint}
            startIcon={<PrintOutlinedIcon />}
            className="btn-print"
          >
            Print
          </Button>
        </Stack>
      </DialogActions>
    </Dialog>
  );
}
