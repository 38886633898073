import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getStorage } from "firebase/storage";

// See: https://firebase.google.com/docs/web/learn-more#config-object

// Productoin new SDK
const firebaseConfig = {
  apiKey: "AIzaSyCRHrwxHdoUMyCJma7N3PdNpx_1xJ03O5k",
  authDomain: "human-resource-ms-193b3.firebaseapp.com",
  projectId: "human-resource-ms-193b3",
  storageBucket: "human-resource-ms-193b3.appspot.com",
  messagingSenderId: "796927013810",
  appId: "1:796927013810:web:6a6aadafd84e7cd4b8eaab",
  measurementId: "G-R87Z6YN5QS"
};

//Producion Old SDK
// const firebaseConfig = {
//   apiKey: "AIzaSyA1f5L5JxrhBKo8aFm33bTUEEpph9rBr38",
//   authDomain: "human-resource-ms.firebaseapp.com",
//   projectId: "human-resource-ms",
//   storageBucket: "human-resource-ms.appspot.com",
//   messagingSenderId: "526799769489",
//   appId: "1:526799769489:web:403ddc82135ee97c281430",
//   measurementId: "G-WEQN04ZP72"
// };

// Initialize Firebase
const app = initializeApp(firebaseConfig);

export const auth = getAuth(app);
export const storage = getStorage();
// Initialize Firebase Authentication and get a reference to the service
export default app;
