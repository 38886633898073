import { gql } from "@apollo/client";


export const CREATE_WORKINGBASE = gql`
mutation CreateWorkingBase($input: WorkingBaseInput) {
  createWorkingBase(input: $input) {
    success
    message
  }
}
`;

export const GET_WORKINGBASE_WITH_PAGINATION = gql`
  query GetWorkingBasePagination($page: Int, $limit: Int, $keyword: String, $pagination: Boolean) {
  getWorkingBasePagination(page: $page, limit: $limit, keyword: $keyword, pagination: $pagination) {
    data {
      _id
      workingBaseName
      remark
      createdAt
      updatedAt
    }
    paginator {
      slNo
      prev
      next
      perPage
      totalPosts
      totalPages
      currentPage
      hasPrevPage
      hasNextPage
      totalDocs
    }
  }
}
`;

export const UPDATE_WORKINGBASE = gql`
mutation UpdateWorkingBase($input: WorkingBaseInput, $id: ID) {
  updateWorkingBase(input: $input, _id: $id) {
    success
    message
  }
}
`;

export const DELETE_WORKINGBASE = gql`
  mutation DeleteWorkingBase($id: ID) {
  deleteWorkingBase(_id: $id) {
    success
    message
  }
}
`;
