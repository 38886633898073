import React, { useRef } from "react";
import {
  Box,
  Grid,
  Stack,
  Typography,
  IconButton,
  Button,
  Divider,
} from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import CloseIcon from "@mui/icons-material/Close";
import PrintOutlinedIcon from "@mui/icons-material/PrintOutlined";
import { useReactToPrint } from "react-to-print";
import moment from "moment";
import { useQuery } from "@apollo/client";
//Srcs
import "../../Style/createstyle.scss";
import { GET_STUFF_INFO_FOR_PRINT } from "../../Schema/StaffInformation";
import { useState } from "react";
import { useEffect } from "react";

export default function PrintStaffInfo({ open, handleClose, staffByIdData }) {
  const [printData, setPrintData] = useState();
  //for print
  const componentRef = useRef();
  const handalePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const { refetch } = useQuery(GET_STUFF_INFO_FOR_PRINT, {
    variables: {
      // staffId: "642bb98d083ea7a167012d49",
      staffId: staffByIdData?._id
    },
    onCompleted: ({ getStaffInfoForPrint }) => {
      // console.log("getStaffInfoForPrint::", getStaffInfoForPrint);
      setPrintData(getStaffInfoForPrint);
      // setLoading(false);
    },
    onError: (error) => {
      console.log("error::", error);
    },
  });

  useEffect(() => {
    refetch();
  }, [open]);

  // console.log("staffByIdData::", staffByIdData?._id);

  return (
    <Dialog open={open} className="create-container" fullWidth maxWidth="md">
      <DialogTitle sx={{ padding: "10px 20px" }}>
        <Stack direction="row" spacing={2}>
          <Stack direction="column" justifyContent="center">
            <Typography className="dialog-title">
              Print staff information
            </Typography>
          </Stack>

          <Box sx={{ flexGrow: 1 }} />
          <IconButton onClick={handleClose}>
            <CloseIcon className="close-icon" />
          </IconButton>
        </Stack>
      </DialogTitle>

      <DialogContent dividers>
        <div ref={componentRef}>
          <Grid container>
            <Grid item xs={5}>
              <Box className="print-staff-info-profile-container">
                <Stack direction="row" justifyContent="center">
                  <img
                    className="print-staff-info-image"
                    src={staffByIdData?.staffProfile?.imageSrc}
                  />
                </Stack>

                <Stack
                  direction="column"
                  spacing={0.5}
                  sx={{ marginTop: "40px" }}
                >
                  <Typography className="print-staff-info-title">
                    Profile
                  </Typography>
                  <Divider sx={{ borderBottom: "1px solid #fff" }} />

                  <Stack direction="row">
                    <Typography
                      sx={{ width: "35%" }}
                      className="print-staff-info-item"
                    >
                      First name
                    </Typography>
                    <Typography
                      sx={{ width: "65%" }}
                      className="print-staff-info-item"
                    >
                      : {printData?.firstNameLatin}
                    </Typography>
                  </Stack>
                  <Stack direction="row">
                    <Typography
                      sx={{ width: "35%" }}
                      className="print-staff-info-item"
                    >
                      Last name
                    </Typography>
                    <Typography
                      sx={{ width: "65%" }}
                      className="print-staff-info-item"
                    >
                      : {printData?.lastNameLatin}
                    </Typography>
                  </Stack>
                  <Stack direction="row">
                    <Typography
                      sx={{ width: "35%" }}
                      className="print-staff-info-item"
                    >
                      Date of birth
                    </Typography>
                    <Typography
                      sx={{ width: "65%" }}
                      className="print-staff-info-item"
                    >
                      :{" "}
                      {moment(printData?.dateOfBirthEng).format("DD-MMM-YYYY")}
                    </Typography>
                  </Stack>
                  <Stack direction="row">
                    <Typography
                      sx={{ width: "35%" }}
                      className="print-staff-info-item"
                    >
                      Gender
                    </Typography>
                    <Typography
                      sx={{ width: "65%" }}
                      className="print-staff-info-item"
                    >
                      : {printData?.gender}
                    </Typography>
                  </Stack>
                  <Stack direction="row">
                    <Typography
                      sx={{ width: "35%" }}
                      className="print-staff-info-item"
                    >
                      Marital status
                    </Typography>
                    <Typography
                      sx={{ width: "65%" }}
                      className="print-staff-info-item"
                    >
                      : {printData?.maritalStatusEng}
                    </Typography>
                  </Stack>
                  <Stack direction="row">
                    <Typography
                      sx={{ width: "35%" }}
                      className="print-staff-info-item"
                    >
                      Nationality
                    </Typography>
                    <Typography
                      sx={{ width: "65%" }}
                      className="print-staff-info-item"
                    >
                      : {printData?.nationalityEng}
                    </Typography>
                  </Stack>

                  <Stack direction="row">
                    <Typography
                      sx={{ width: "35%" }}
                      className="print-staff-info-item"
                    >
                      Indentity card
                    </Typography>
                    <Typography
                      sx={{ width: "65%" }}
                      className="print-staff-info-item"
                    >
                      : {printData?.identityCardEng}
                    </Typography>
                  </Stack>
                  <Stack direction="row">
                    <Typography
                      sx={{ width: "35%" }}
                      className="print-staff-info-item"
                    >
                      Passport card
                    </Typography>
                    <Typography
                      sx={{ width: "65%" }}
                      className="print-staff-info-item"
                    >
                      : {printData?.passportsNumerEng}
                    </Typography>
                  </Stack>
                  <Stack direction="row">
                    <Typography
                      sx={{ width: "35%" }}
                      className="print-staff-info-item"
                    >
                      Current situation
                    </Typography>
                    <Typography
                      sx={{ width: "65%" }}
                      className="print-staff-info-item"
                    >
                      : {staffByIdData?.currentSituation}
                    </Typography>
                  </Stack>

                  <Stack direction="row">
                    <Typography
                      sx={{ width: "35%" }}
                      className="print-staff-info-item"
                    >
                      Start date
                    </Typography>
                    <Typography
                      sx={{ width: "65%" }}
                      className="print-staff-info-item"
                    >
                      :{" "}
                      {moment(staffByIdData?.staffProfile?.startedDate).format(
                        "DD-MMM-YYYY"
                      )}
                    </Typography>
                  </Stack>
                  {/* ============================== Place of birth ============================= */}
                  <Typography className="print-staff-info-item-bold">
                    Place of birth
                  </Typography>

                  <Stack direction="row">
                    <Typography
                      sx={{ width: "35%" }}
                      className="print-staff-info-item"
                    >
                      Village
                    </Typography>
                    <Typography
                      sx={{ width: "65%" }}
                      className="print-staff-info-item"
                    >
                      : {printData?.placeOfBirthEng?.villageEng}
                    </Typography>
                  </Stack>
                  <Stack direction="row">
                    <Typography
                      sx={{ width: "35%" }}
                      className="print-staff-info-item"
                    >
                      Commune
                    </Typography>
                    <Typography
                      sx={{ width: "65%" }}
                      className="print-staff-info-item"
                    >
                      : {printData?.placeOfBirthEng?.communeEng}
                    </Typography>
                  </Stack>
                  <Stack direction="row">
                    <Typography
                      sx={{ width: "35%" }}
                      className="print-staff-info-item"
                    >
                      District
                    </Typography>
                    <Typography
                      sx={{ width: "65%" }}
                      className="print-staff-info-item"
                    >
                      : {printData?.placeOfBirthEng?.districtEng}
                    </Typography>
                  </Stack>
                  <Stack direction="row">
                    <Typography
                      sx={{ width: "35%" }}
                      className="print-staff-info-item"
                    >
                      Province
                    </Typography>
                    <Typography
                      sx={{ width: "65%" }}
                      className="print-staff-info-item"
                    >
                      : {printData?.placeOfBirthEng?.cityEng}
                    </Typography>
                  </Stack>

                  {/* ============================== Permanaces address ============================= */}
                  <Typography className="print-staff-info-item-bold">
                    Permanance address
                  </Typography>

                  <Stack direction="row">
                    <Typography
                      sx={{ width: "35%" }}
                      className="print-staff-info-item"
                    >
                      Village
                    </Typography>
                    <Typography
                      sx={{ width: "65%" }}
                      className="print-staff-info-item"
                    >
                      : {printData?.permananceAddressEng?.villageEng}
                    </Typography>
                  </Stack>
                  <Stack direction="row">
                    <Typography
                      sx={{ width: "35%" }}
                      className="print-staff-info-item"
                    >
                      Commune
                    </Typography>
                    <Typography
                      sx={{ width: "65%" }}
                      className="print-staff-info-item"
                    >
                      : {printData?.permananceAddressEng?.communeEng}
                    </Typography>
                  </Stack>
                  <Stack direction="row">
                    <Typography
                      sx={{ width: "35%" }}
                      className="print-staff-info-item"
                    >
                      District
                    </Typography>
                    <Typography
                      sx={{ width: "65%" }}
                      className="print-staff-info-item"
                    >
                      : {printData?.permananceAddressEng?.districtEng}
                    </Typography>
                  </Stack>
                  <Stack direction="row">
                    <Typography
                      sx={{ width: "35%" }}
                      className="print-staff-info-item"
                    >
                      Province
                    </Typography>
                    <Typography
                      sx={{ width: "65%" }}
                      className="print-staff-info-item"
                    >
                      : {printData?.permananceAddressEng?.cityEng}
                    </Typography>
                  </Stack>

                  {/* ============================== Temporary address ============================= */}
                  <Typography className="print-staff-info-item-bold">
                    Temporary address
                  </Typography>

                  <Stack direction="row">
                    <Typography
                      sx={{ width: "35%" }}
                      className="print-staff-info-item"
                    >
                      Village
                    </Typography>
                    <Typography
                      sx={{ width: "65%" }}
                      className="print-staff-info-item"
                    >
                      : {printData?.temporaryAddressEng?.villageEng}
                    </Typography>
                  </Stack>
                  <Stack direction="row">
                    <Typography
                      sx={{ width: "35%" }}
                      className="print-staff-info-item"
                    >
                      Commune
                    </Typography>
                    <Typography
                      sx={{ width: "65%" }}
                      className="print-staff-info-item"
                    >
                      : {printData?.temporaryAddressEng?.communeEng}
                    </Typography>
                  </Stack>
                  <Stack direction="row">
                    <Typography
                      sx={{ width: "35%" }}
                      className="print-staff-info-item"
                    >
                      District
                    </Typography>
                    <Typography
                      sx={{ width: "65%" }}
                      className="print-staff-info-item"
                    >
                      : {printData?.temporaryAddressEng?.districtEng}
                    </Typography>
                  </Stack>
                  <Stack direction="row">
                    <Typography
                      sx={{ width: "35%" }}
                      className="print-staff-info-item"
                    >
                      Province
                    </Typography>
                    <Typography
                      sx={{ width: "65%" }}
                      className="print-staff-info-item"
                    >
                      : {printData?.temporaryAddressEng?.cityEng}
                    </Typography>
                  </Stack>
                </Stack>

                <Stack
                  direction="column"
                  spacing={0.5}
                  sx={{ marginTop: "20px" }}
                >
                  <Typography className="print-staff-info-title">
                    Contact & Other
                  </Typography>
                  <Divider sx={{ borderBottom: "1px solid #fff" }} />

                  <Stack direction="row">
                    {/* <Typography
                      sx={{ width: "35%" }}
                      className="print-staff-info-item"
                    >
                      Phone number
                    </Typography> */}
                    <Typography
                      className="print-staff-info-item"
                    >
                      {printData?.phoneNumberEng}
                    </Typography>
                  </Stack>

                  <Stack direction="row">
                    {/* <Typography
                      sx={{ width: "35%" }}
                      className="print-staff-info-item"
                    >
                      Email
                    </Typography> */}
                    <Typography
                      className="print-staff-info-item"
                    >
                      {staffByIdData?.systemAccessData?.emailAddress}
                    </Typography>
                  </Stack>
                </Stack>
              </Box>
            </Grid>

            {/* =========================================== staff name container ======================================== */}
            <Grid item xs={7}>
              <Box className="print-staff-info-name-container">
                <Stack
                  direction="column"
                  spacing={0.5}
                  sx={{ height: "223px" }}
                  justifyContent="center"
                >
                  <Typography className="print-staff-info-staff-name">
                    {printData?.firstNameLatin + " " + printData?.lastNameLatin}
                  </Typography>
                  <Typography className="print-staff-info-staff-positions">
                    {printData?.currentPositoins}
                  </Typography>
                </Stack>

                {/* ======================================= Work in PEPY =================================== */}
                <Stack direction="column" spacing={0.2}>
                  <Typography className="print-staff-name-title">
                    Work In PEPEY
                  </Typography>
                  <Divider sx={{ borderBottom: "1px solid rgb(50, 50, 71)" }} />

                  {printData?.workInPEPY?.map((work, index) => {
                    return (
                      <span key={index}>
                        <span className="print-staff-name-item-bold">
                          {work?.positionName} :
                        </span>
                        <span
                          style={{ marginLeft: "8px" }}
                          className="print-staff-name-item"
                        >
                          Office based in {work?.officeBaseName}, work from{" "}
                          {moment(work?.startDate).format("DD-MMM-YYYY")} to{" "}
                          {moment(work?.endDate).format("DD-MMM-YYYY")}
                        </span>
                      </span>
                    );
                  })}
                </Stack>

                {/* ======================================= Education =================================== */}
                <Stack
                  direction="column"
                  spacing={0.2}
                  sx={{ marginTop: "10px" }}
                >
                  <Typography className="print-staff-name-title">
                    Education
                  </Typography>
                  <Divider sx={{ borderBottom: "1px solid rgb(50, 50, 71)" }} />

                  {printData?.educationBackground?.map((education, index) => {
                    return (
                      <Stack direction="column" key={index}>
                        <span className="print-staff-name-item-bold">
                          {education?.majorName}
                        </span>
                        <Stack direction="column">
                          <span className="print-staff-name-item">
                            Intitule : {education?.universityName}
                          </span>
                          <span className="print-staff-name-item">
                            Gratuation date :{" "}
                            {moment(education?.endDate).format("DD-MMM-YYYY")}
                          </span>
                        </Stack>
                      </Stack>
                    );
                  })}
                </Stack>

                {/* ======================================= Work History =================================== */}
                <Stack
                  direction="column"
                  spacing={0.2}
                  sx={{ marginTop: "10px" }}
                >
                  <Typography className="print-staff-name-title">
                    Work History
                  </Typography>
                  <Divider sx={{ borderBottom: "1px solid rgb(50, 50, 71)" }} />

                  {printData?.workHistory?.map((work, index) => {
                    return (
                      <span key={index}>
                        <span className="print-staff-name-item-bold">
                          {work?.positions} :
                        </span>
                        <span
                          style={{ marginLeft: "8px" }}
                          className="print-staff-name-item"
                        >
                          Working at {work?.workingPlace}, from{" "}
                          {moment(work?.startDate).format("DD-MMM-YYYY")} to{" "}
                          {moment(work?.endDate).format("DD-MMM-YYYY")}
                        </span>
                      </span>
                    );
                  })}
                </Stack>

                {/* ======================================= Covid Vaccination =================================== */}
                <Stack
                  direction="column"
                  spacing={0.2}
                  sx={{ marginTop: "10px" }}
                >
                  <Typography className="print-staff-name-title">
                    Covid Vaccination
                  </Typography>
                  <Divider sx={{ borderBottom: "1px solid rgb(50, 50, 71)" }} />

                  {printData?.covidVaccination?.map((covid, index) => {
                    // console.log("covid::", covid)
                    return (
                      <Stack direction="row" spacing={1} key={index}>
                        <span className="print-staff-name-item-bold">
                          {covid?.dosesNumber} :
                        </span>
                        <span className="print-staff-name-item">
                          {covid?.vaccinName}, on {moment(covid?.vaccinationDate).format("DD-MMM-YYYY")}
                        </span>
                      </Stack>
                    );
                  })}
                </Stack>

                {/* ======================================= Covid Vaccination =================================== */}
                <Stack
                  direction="column"
                  spacing={0.2}
                  sx={{ marginTop: "10px" }}
                >
                  <Typography className="print-staff-name-title">
                    Driver License
                  </Typography>
                  <Divider sx={{ borderBottom: "1px solid rgb(50, 50, 71)" }} />

                  {printData?.drivingLicense?.map((driver, index) => {
                    return (
                      <Stack direction="column" key={index}>
                        <Stack direction="row" spacing={1}>
                          <span className="print-staff-name-item-bold">
                            Driving ID : {driver?.drivingId}
                          </span>
                          <span className="print-staff-name-item">
                            | {driver?.manualOrAuto}
                          </span>
                        </Stack>
                        <span className="print-staff-name-item">
                          Drining Type : {driver?.drivingType}
                        </span>
                        <span className="print-staff-name-item">
                          Expired Date:{" "}
                          {moment(driver?.expiryDate).format("DD-MMM-YYYY")}
                        </span>
                      </Stack>
                    );
                  })}
                </Stack>
              </Box>
            </Grid>
          </Grid>
        </div>
      </DialogContent>

      <DialogActions>
        <Stack direction="row" justifyContent="right">
          <Button
            onClick={handalePrint}
            startIcon={<PrintOutlinedIcon />}
            className="btn-print"
          >
            Print
          </Button>
        </Stack>
      </DialogActions>
    </Dialog>
  );
}
