import { gql } from "@apollo/client";

export const CREATE_STAFF_CONTRACT = gql`
  mutation CreateContract($staffInfoId: ID!, $input: ContractInput) {
    createContract(staffInfoId: $staffInfoId, input: $input) {
      success
      message
    }
  }
`;

export const GET_CONTRACT_BY_STAFF = gql`
  query GetContractByStaffId($staffId: ID!) {
    getContractByStaffId(staffId: $staffId) {
      _id
      fileUpload
      startDate
      endDate
      typeOfEmployment
      isActive
      positions {
        _id
        positionName
        positionNameKhmer
        inDepartment {
          _id
          departmentName
          remark
          createdAt
          updatedAt
        }
        isDepartmentManager
        remark
      }
      officeBase {
        _id
        officeBaseName
        remark
        createdAt
        updatedAt
      }
      workingBase {
        _id
        workingBaseName
        remark
        createdAt
        updatedAt
      }
      workingPlace {
        _id
        workingPlaceName
        remark
        createdAt
        updatedAt
      }
      remark
    }
  }
`;

export const UPDATE_STAFF_CONTRACT = gql`
  mutation UpdateContract($input: ContractInput, $id: ID) {
    updateContract(input: $input, _id: $id) {
      success
      message
    }
  }
`;

export const UPDATE_CONTRACT_STATUS = gql`
  mutation EditContractStatus($id: String!, $isActive: Boolean!) {
    editContractStatus(_id: $id, isActive: $isActive) {
      success
      message
    }
  }
`;

export const DELETE_STAFF_CONTRACT = gql`
  mutation DeleteContract($id: ID) {
    deleteContract(_id: $id) {
      success
      message
    }
  }
`;

export const EDIT_CONTRACT_STATUS = gql`
  mutation EditContractStatus($id: String!, $isActive: Boolean!) {
  editContractStatus(_id: $id, isActive: $isActive) {
    success
    message
  }
}
`;
